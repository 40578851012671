/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/Account/AccountTabList.module.scss';

/* Component definition */
class AccountTabList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
    };
  }

  componentDidMount() {
    this.setState({ tabIndex: this.props.tabIndex });
  }

  clickTab = index => {
    const { setTabIndex } = this.props;
    this.setState({ tabIndex: index });

    setTabIndex(index);
  };

  render() {
    const { tabIndex } = this.state;
    return (
      <div>
        <div className={styles.title}>Account Details</div>
        <div className={styles.list}>
          <div
            className={tabIndex === 1 ? styles.active : styles.general}
            onClick={() => this.clickTab(1)}
          >
            General
          </div>
          <div
            className={tabIndex === 2 ? styles.active : styles.general}
            onClick={() => this.clickTab(2)}
          >
            <span className={styles.dot}>&bull;</span>Contact
          </div>
          <div
            className={tabIndex === 3 ? styles.active : styles.general}
            onClick={() => this.clickTab(3)}
          >
            <span className={styles.dot}>&bull;</span>Qualifications
          </div>
          <div
            className={tabIndex === 4 ? styles.active : styles.general}
            onClick={() => this.clickTab(4)}
          >
            <span className={styles.dot}>&bull;</span>Basic Level Requirements (all guides) page
          </div>
        </div>
      </div>
    );
  }
}

AccountTabList.propTypes = {
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

export default AccountTabList;
