import { createAction } from 'redux-actions';

import {
  NEW_ADVENTURE_BOOK,
  DELETE_ADVENTURE_BOOK,
  START_PAY_ADVENTURE_BOOK,
  SET_ADVENTURE_BOOKING_ID,
  CREATE_ADVENTURE_BOOKING_PAYMENT,
  UPDATE_ADVENTURE_BOOKING_PAYMENT,
} from './types';

export const bookNewAdventure = createAction(NEW_ADVENTURE_BOOK);
export const deleteBookAdventure = createAction(DELETE_ADVENTURE_BOOK);
export const readyPayBookAdventure = createAction(START_PAY_ADVENTURE_BOOK);
export const setBookingId = createAction(SET_ADVENTURE_BOOKING_ID);
export const createBookingPayment = createAction(CREATE_ADVENTURE_BOOKING_PAYMENT);
export const updateBookingPayment = createAction(UPDATE_ADVENTURE_BOOKING_PAYMENT);
