import React from 'react';
import PropTypes from 'prop-types';

import Addition from './Addition';
import ReferenceIcon from './ReferenceIcon';
import Rating from 'components/Rating';
import HtmlParse from 'components/HTMLPARSE';

import styles from 'styles/components/GuideDetails/Description.module.scss';

// import curl from 'curl-request';

/* Component definition */
class Description extends React.Component {
  state = {
    isMobileBookAdventure: false,
  };

  componentDidMount() {}

  showBookAdventure = () => {
    this.setState({ isMobileBookAdventure: true });
  };

  generateUniqueCategories = adventures => {
    const all_categories = adventures && adventures.map(adventure => adventure.categories);
    const filteredCategories = [...new Set(all_categories.map(item => item))];

    return filteredCategories;
  };

  render() {
    const { guide } = this.props;
    const categories = this.generateUniqueCategories(guide.adventures);

    return (
      <div className={styles.wrapper}>
        <div className={styles.categories}>
          {categories.map((category, index) => (
            <div key={index}>
              {index !== 0 && <span>,&nbsp;</span>} {category}
            </div>
          ))}
        </div>
        <div className={styles.name}>
          Hello, my name is {guide.fullName}
          <p className={styles.address}>{guide.businessAddress}</p>
        </div>
        <HtmlParse className='aboutme'>{guide.aboutMe}</HtmlParse>

        {/* Additional Data */}
        {guide.technicalSkills && (
          <Addition title='Technical Skills' content={guide.technicalSkills} />
        )}
        {guide.firstAidTraining && (
          <Addition title='First Aid and Wilderness' content={guide.firstAidTraining} />
        )}
        {guide.customerServiceSkills && (
          <Addition
            title='Customer Service And Management Skills'
            content={guide.customerServiceSkills}
          />
        )}
        {guide.expertise && (
          <Addition title='Experties In Natural And Cultural History' content={guide.expertise} />
        )}
        {guide.sustainableStandards && (
          <Addition title='Sustainable Standards' content={guide.sustainableStandards} />
        )}

        {/* Icons for Rating, Review... */}
        <div className={styles.icons}>
          {guide.extra && guide.extra.rating !== undefined && (
            <div className={styles.icons_item}>
              <Rating rating={parseInt(guide.extra.rating)} />
            </div>
          )}

          {guide.extra && guide.extra.NumOfReviews !== undefined && (
            <div className={styles.icons_item}>
              <ReferenceIcon
                iconName='fa fa-comment-o'
                title='REVIEWS'
                value={guide.extra.NumOfReviews}
              />
            </div>
          )}

          {/* {guide.adventures.length > 0 && (
            <div className={styles.icons_item}>
              <ReferenceIcon
                iconName='fa fa-compass'
                title='ADVENTURES'
                value={guide.adventures.length}
              />
            </div>
          )} */}

          {guide.expertise && (
            <div className={styles.icons_item}>
              <ReferenceIcon iconName='fa fa-certificate' title='EXPERT' value={guide.expertise} />
            </div>
          )}
        </div>
      </div>
    );
  }
}

/* PropTypes definition */
Description.propTypes = {
  guide: PropTypes.object.isRequired,
};

export default Description;
