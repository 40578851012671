export function validateRequired(value) {
  return value || (typeof value === 'boolean' && value);
}

export function validateStringNotEmpty(value) {
  return value && value !== '';
}

export function validateIsNumber(value) {
  return Boolean(value.match(/^\d*$/));
}

export function validateIsEmail(value) {
  return Boolean(
    value.match(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/
    )
  );
}
