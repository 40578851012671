// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { GUIDES_REGION_GET } from '../modules/GuidesRegions/types';
import { getUserRegion } from 'api/userGuides';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doGetGuides = apiCall({
  type: GUIDES_REGION_GET,
  method: 'POST',
  baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
  path: '',
  body: { query: getUserRegion, variables: {} },
});

export default function* rootSaga() {
  yield takeLatest(GUIDES_REGION_GET, doGetGuides);
}
