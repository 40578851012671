import React, { Component } from 'react';

import Container from '../common/container/Container';
import ProfileLeft from '../components/GuideProfile/ProfileLeft';
import ProfileRight from '../components/GuideProfile/ProfileRight';

class GuideProfile extends Component {
  render() {
    return (
      <section className='guide-profile-page'>
        <Container>
          <div className='details'>
            <ProfileLeft />
            <ProfileRight />
          </div>
        </Container>
      </section>
    );
  }
}

export default GuideProfile;
