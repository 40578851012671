import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { TOKEN_GET } from '../modules/PayPal/types';

export const doGetToken = apiCall({
  type: TOKEN_GET,
  method: 'POST',
  baseURL: 'https://agile-meadow-25610.herokuapp.com/get/token',
  path: '',
  body: ({ payload }) => {
    return {
      email: payload.email,
    };
  },
});

export default function* rootSaga() {
  yield takeLatest(TOKEN_GET, doGetToken);
}
