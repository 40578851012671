// import { put, takeLatest } from 'redux-saga/effects'
import { call, select, takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { ADVENTURES_GET, ADVENTURE_DELETE } from '../modules/adventures/types';
import { getAdventures } from 'api/adventures';

import { filterSelector } from '../modules/AdventuresFilter/selectors';
import { userSelector } from '../modules/users/selectors';

import { getActivelymeAPIEnvUrl } from 'utils/initializers';

const moment = require('moment');

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doAPICALL = variables =>
  apiCall({
    type: ADVENTURES_GET,
    method: 'POST',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    path: '',
    body: {
      query: getAdventures,
      variables: {
        startDate:
          variables.startDate === null || variables.startDate === ''
            ? moment(new Date()).locale('')
            : variables.startDate,
        endDate:
          variables.endDate === null || variables.endDate === ''
            ? moment(new Date().getFullYear() + 1 + '-12-31').locale('')
            : variables.endDate,
        location:
          variables.location !== null && variables.location !== '' ? variables.location : '',
        category:
          variables.category !== null && variables.category !== '' ? variables.category : '',
        dayOrMulti:
          variables.day_multi !== null && variables.day_multi !== '' ? variables.day_multi : '',
        difficulty: variables.difficulty,
      },
    },
    payloadOnSuccess: (responseData, action) => {
      responseData.isLoadMore = action.payload.isLoadMore;
      if (action.payload.userAdventure) {
        responseData.userAdventure = action.payload.userAdventure;
      } else responseData.userAdventure = false;
      return responseData;
    },
  });

const doAPIDeleteAdventure = id => {
  return apiCall({
    type: ADVENTURE_DELETE,
    method: 'DELETE',
    baseURL: getActivelymeAPIEnvUrl(),
    path: `/adventure/v1/adventures/${id}`,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: {},
  });
};

const doGetAdventures = function*(params) {
  const filter = yield select(filterSelector);
  yield call(doAPICALL(filter), { payload: params.payload });
};

const doDeleteAdventures = function*(params) {
  yield call(doAPIDeleteAdventure(params.payload), { payload: '' });
  const filter = yield select(filterSelector);
  const user = yield select(userSelector);
  yield call(doAPICALL(filter), { payload: { isLoadMore: false, userAdventure: user.fullName } });
};

export default function* rootSaga() {
  yield takeLatest(ADVENTURES_GET, doGetAdventures);
  yield takeLatest(ADVENTURE_DELETE, doDeleteAdventures);
}
