import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import location from '../../../assets/adventures/Location.svg';
import './Adventures.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

class AdventuresItem extends Component {
  render() {
    const { allData, userImg, openSendMessageModal, openBookNowModal } = this.props;
    return (
      <article className='adventures-item'>
        <div className='card'>
          <div className='imageInfo'>
            <img src={allData.advertiseItem.mainImage} alt='img1' className='main-img' />
            <div className='review'>
              <div className='user'>
                <img src={userImg} alt='User' />
              </div>
              <div className='rating'>
                <b>{allData.advertiseItem.userName}</b>
                <ul>
                  <li>
                    <i className='fa fa-star' aria-hidden='true' /> 4.9
                  </li>
                  <li>
                    <i className='fa fa-circle' aria-hidden='true' /> 225 reviews
                  </li>
                  <li>
                    <i className='fa fa-circle' aria-hidden='true' /> 4 adventures
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='box'>
            <div className='status'>
              <span>{allData.advertiseItem.status.category}</span>
              <span>{allData.advertiseItem.status.date}</span>
            </div>
            <h2>{allData.advertiseItem.title}</h2>
            <div className='place'>
              <LazyLoadImage alt='location' src={location} effect='blur' />
              {allData.advertiseItem.location}
            </div>

            <div className='description'>
              <p>
                {allData.advertiseItem.description1}
                <a href='http://localhost'>Read More</a>
              </p>
            </div>
            <div className='shop'>
              <div className='regular-price'>
                <span>{allData.advertiseItem.show.regular_price}</span>
              </div>
              <div className='book-now p-0'>
                <button
                  className='btn orange-btn m-1 w-100'
                  onClick={() => openSendMessageModal(allData.advertiseItem.title)}
                >
                  Request information
                </button>
                <button
                  className='btn orange-btn m-1 w-100'
                  onClick={() => openBookNowModal(allData.advertiseItem.title, allData.dates)}
                >
                  Book Now
                </button>
              </div>
            </div>
          </div>
        </div>
      </article>
    );
  }
}

AdventuresItem.propTypes = {
  allData: PropTypes.object,
  userImg: PropTypes.string,
  openSendMessageModal: PropTypes.func,
  openBookNowModal: PropTypes.func,
};

export default AdventuresItem;
