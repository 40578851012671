import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import styles from 'styles/components/GuideDetails/Avatar.module.scss';
import guideStyles from 'styles/components/GuideDetails/GuideAdventures.module.scss';
import { Link } from 'react-router-dom';

/* Component definition */
class Avatar extends React.Component {
  render() {
    const { guide, adventures } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.desktopBookForm}>
          <div>
            <header>
              <div className={styles.avatar}>
                <img src={guide.profilePictureUrl} alt='avatar' />
              </div>
              <div className={styles.guide}>
                <h4>{guide.fullName}</h4>
                {guide.adventures.length > 0 && (
                  <div className={styles.numberOfAdventures}>
                    &nbsp;&bull;&nbsp;{guide.adventures.length} adventures
                  </div>
                )}
              </div>
              <div className={guideStyles.wrapper}>
                <div className={guideStyles.adventureLength}>Adventures {adventures.length}</div>
                <div className={guideStyles.row}>
                  {adventures &&
                    adventures.map((adventure, index) => (
                      <div key={index} className={guideStyles.item}>
                        <Link to={`/adventure/${adventure.id}`}>
                          <div className={guideStyles.backgroundImage}>
                            <img
                              src={
                                adventure.media && adventure.media.length
                                  ? adventure.media[0].imageHQUrl
                                  : ''
                              }
                            />
                          </div>
                          <div className={guideStyles.adventureInfo}>
                            <h6>{adventure.categories}</h6>
                            <div className={guideStyles.title}>{adventure.title}</div>
                          </div>
                        </Link>
                      </div>
                    ))}
                </div>
              </div>
            </header>
          </div>
        </div>
      </div>
    );
  }
}

/* PropTypes definition */
Avatar.propTypes = {
  guide: PropTypes.object.isRequired,
  adventures: PropTypes.array,
};

export default compose(withRouter)(Avatar);
