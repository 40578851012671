// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { CATEGORIES_GET } from '../modules/categories/types';
import { CATEGORIES } from 'api/categories';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doGetCategories = apiCall({
  type: CATEGORIES_GET,
  method: 'POST',
  baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
  path: '',
  body: { query: CATEGORIES, variables: { type: 'Category' } },
});

export default function* rootSaga() {
  yield takeLatest(CATEGORIES_GET, doGetCategories);
}
