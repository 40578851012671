/**
 * @file Holds the <AdventurePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { createStructuredSelector } from 'reselect';

import styles from 'styles/pages/Payment.module.scss';
import paypalIcon from 'assets/payment/paypal.png';
import creditCard from 'assets/payment/credit_card.png';

import {
  actions as adventuresByIdActions,
  selectors as adventuresByIdSelector,
} from '../redux/modules/AdventuresID';
import { selectors as adventuresSearchFilterSelectors } from '../redux/modules/AdventuresFilter';
import { actions as PayPalActions, selectors as PayPalSelectors } from '../redux/modules/PayPal';
import { selectors as BookAdventureSelectors } from '../redux/modules/BookAdventure';
import { selectors as usersSelectors } from '../redux/modules/users';

const moment = require('moment');

/* Component definition */
class PayAdventure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adventure: null,
      env: 'sandbox',
      addedInsurance: true,
      client: {
        sandbox: 'AY_VzrzAXo4AyfJhexBOm3r_W_dkRHDTibX5GtnbfHKCCKAOobUXuvLb3Xaneq0a8dtmVBBVWAwEkn83',
        production:
          'ASAnlODjnfm2gqz0nOHXUM2Qez7Ais8aoYK-lGVzHXgLuBv7b2bjKjr7eNAs2khFVQM7P4NcyQHTVNt2',
      },
      commit: true,
    };
  }

  componentDidMount() {
    const { adventure, getSecureToken, getAdventuresById, bookingId } = this.props;

    this.setState({ adventure: adventure });
    localStorage.setItem('page', 'pay-adventure');

    if (bookingId === null) {
      this.props.history.push('/');
    }
    getAdventuresById({ id: this.props.match.params.id });
    getSecureToken({ email: 'testtest123@test.com' });
    window.addEventListener('popstate', this.getResponseFromTransaction);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.adventure != this.props.adventure && nextProps.adventure) {
      this.setState({ adventure: nextProps.adventure });
    }
  }

  getResponseFromTransaction = () => {};

  isPermittedGuide = () => {
    const { addedInsurance } = this.state;
    this.setState({ addedInsurance: !addedInsurance });
  };

  saveUser = () => {
    const { userId } = this.props;

    localStorage.setItem('userId', userId);
  };
  render() {
    const { adventure, token, tokenId } = this.props;
    const { addedInsurance } = this.state;

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {adventure && (
            <div className={styles.adventure}>
              <div className={styles.title}>Payment Details</div>
              <div className={styles.back}>
                <Link to='/account/trip'>{'< '}Back to the trips</Link>
              </div>
              <div className={styles.paymentContent}>
                <div className={styles.price}>
                  <div className={styles.adTtile}>
                    <div className={styles.sectionTitle}>{adventure.title}</div>
                    <div className={styles.categories}>
                      <div className={styles.subInfo}>{adventure.categories} Adventure</div>
                      <div className={styles.subInfo}>
                        &nbsp;&bull;&nbsp;{moment(adventure.dates[0]).format('DD MMM, YYYY')}
                      </div>
                      <div className={styles.subInfo}>&nbsp;&bull;&nbsp;2 adults</div>
                    </div>
                  </div>
                  <div className={styles.value}>${adventure.price}</div>
                </div>
                <div className={styles.service}>
                  <div className={styles.section}>
                    <div>
                      <label className='checkbox'>
                        <input
                          type='checkbox'
                          className='checkboxInput'
                          checked={addedInsurance}
                          onChange={this.isPermittedGuide}
                        />
                        <span className='checkmark' />
                      </label>
                    </div>
                    <div className={styles.serviceName}>
                      <div>Add Insurance of your trip</div>
                      <div className={styles.insurance}>
                        <div className={styles.subInfo}>
                          Full insurance, any medical help for $150 000
                        </div>
                        <div className={styles.details}>Details</div>
                      </div>
                    </div>
                  </div>
                  <div className={styles.value}>$20</div>
                </div>
                <div className={styles.hr_br} />
                <div className={styles.totalPrice}>
                  <div className={styles.context} styles={{ fontSize: '15px' }}>
                    TAX included or something else
                  </div>
                  <div className={styles.value}>${adventure.price + 20} Total</div>
                </div>
              </div>
            </div>
          )}
          <div className={styles.paymentMethod}>
            <div className={styles.ppTitle}>Choose Payment Method</div>
            <div className={styles.methdos}>
              <div className={styles.method}>
                <div className={styles.paymentName} onClick={() => this.saveUser()}>
                  <form method='post' action='https://payflowlink.paypal.com' target='paypal'>
                    <input type='hidden' name='SECURETOKEN' value={token === null ? '' : token} />
                    <input
                      type='hidden'
                      name='SECURETOKENID'
                      value={tokenId === null ? '' : tokenId}
                    />
                    <input
                      className={styles.paypalButton}
                      type='submit'
                      value='Pay with Credit Card'
                    />
                  </form>
                  {/* <Link to='/payment'>Pay with Credit Card</Link> */}
                </div>
                <div>
                  <img src={creditCard} />
                </div>
              </div>
              <div className={styles.method}>
                <div className={styles.paymentName} onClick={() => this.saveUser()}>
                  <form method='post' action='https://payflowlink.paypal.com' target='paypal'>
                    <input type='hidden' name='SECURETOKEN' value={token === null ? '' : token} />
                    <input
                      type='hidden'
                      name='SECURETOKENID'
                      value={tokenId === null ? '' : tokenId}
                    />
                    <input
                      className={styles.paypalButton}
                      type='submit'
                      value='Proceed to Checkout'
                    />
                  </form>
                  <Link to='/payment'>Proceed to Checkout</Link>
                </div>
                <div>
                  <img src={paypalIcon} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

/* Prop types definition */
PayAdventure.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  adventure: PropTypes.object,
  adventureId: PropTypes.string,
  getAdventuresById: PropTypes.func.isRequired,
  getSecureToken: PropTypes.func.isRequired,
  token: PropTypes.string,
  tokenId: PropTypes.string,
  bookingId: PropTypes.string,
  userId: PropTypes.string,
};

const selectors = createStructuredSelector({
  token: PayPalSelectors.tokenSelector,
  tokenId: PayPalSelectors.tokenIdSelector,
  adventure: adventuresByIdSelector.adventuresByIdSelector,
  adventureId: adventuresSearchFilterSelectors.adventureIdSelector,
  bookingId: BookAdventureSelectors.bookingIdSelector,
  userId: usersSelectors.userIdSelector,
});

const actions = {
  getAdventuresById: adventuresByIdActions.getAdventuresById,
  getSecureToken: PayPalActions.getSecureToken,
};

export default compose(
  withApollo,
  connect(
    selectors,
    actions
  )
)(PayAdventure);
