import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RichEditor from 'components/RichEditor';
import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import ErrorHandling from '../ErrorHandling';

import Style from '../../styles/pages/CreateAdventure.module.scss';
import './index.scss';

class AddDescription extends Component {
  constructor(props) {
    super(props);
    this.state = {
      description: props.description,
      requirements: props.requirements,
      errorName: '',
      errorType: '',
    };
  }
  nextStep = () => {
    const { nextStep, setAdventureFilter } = this.props;
    const { description, requirements } = this.state;

    setAdventureFilter({
      ['description']: description,
      ['requirements']: requirements,
    });

    if (this.checkValue() === '') {
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  checkValue = () => {
    const { description, requirements } = this.state;
    let errorType = '';

    if (!description && description === '') {
      errorType = errorType.concat('description');
    }
    if (!requirements && requirements === '') {
      errorType = errorType.concat('requirements');
    }

    this.setState({ errorType });
    return errorType;
  };

  handleDescriptionEditor = value => {
    this.setState({ description: value });
  };

  handleRequirementsEditor = value => {
    this.setState({ requirements: value });
  };

  render() {
    const { description, requirements, errorType } = this.state;
    return (
      <div className={Style.add_description}>
        <div className={'descriptionAdd ' + Style.header_title}>
          <h2>Adventure Description</h2>
          <p>Describe your adventure and provide more information</p>
          <RichEditor
            value={description}
            handleFroalaModelChange={this.handleDescriptionEditor}
            isEditable={true}
          />
        </div>
        {errorType.includes('description') ? <ErrorHandling category='Description' /> : ''}
        <div className={'requirementsAdd form-group ' + Style.multipleList}>
          <label>Requirements</label>
          <RichEditor
            value={requirements}
            handleFroalaModelChange={this.handleRequirementsEditor}
            isEditable={true}
          />
        </div>
        {errorType.includes('requirements') ? <ErrorHandling category='Requirements' /> : ''}
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
      </div>
    );
  }
}

AddDescription.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  description: PropTypes.string,
  requirements: PropTypes.string,
};

const selectors = createStructuredSelector({
  description: createAdventureSelectors.descriptionSelector,
  requirements: createAdventureSelectors.requirementsSelector,
});

const actions = {
  setAdventureFilter: createAdventureActions.setAdventureFilter,
};

export default connect(
  selectors,
  actions
)(AddDescription);
