import React from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';

// import { LazyLoadImage } from 'react-lazy-load-image-component';

import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from 'styles/components/GuideCard.module.scss';

const GuideCard = ({ guide }) => (
  <div className={styles.wrapper}>
    <Link to={`/guides/${guide.id}`}>
      <div className={styles.thumb}>
        {/*
          <LazyLoadImage 
            src={guide.profilePictureUrl} effect='blur' 
          />
        */}
        <img src={guide.profilePictureUrl} alt='thumbnail' />
      </div>

      <div className={styles.description}>
        <div className={styles.location}>
          <img src={guide.flagIcon} alt={guide.region || guide.businessAddress || 'flag'} />
          <span>{guide.businessAddress}</span>
        </div>

        <p>{guide.fullName}</p>

        <div className={styles.info}>
          {guide.extra ? (
            <div className={styles.rating}>
              <Icon name='star' />
              &nbsp;
              <span>{guide.extra.rating}</span>
              <span>&nbsp;</span>
            </div>
          ) : (
            ''
          )}
          {guide.extra ? (
            <div>
              <span>&bull;</span>
              <span>{guide.extra.NumOfReviews} reviews</span>
              <span>&nbsp;</span>
            </div>
          ) : (
            ''
          )}
          <div>
            {guide.extra ? <span>&bull;</span> : ''}
            <span>{guide.adventures.length} adventures</span>
          </div>
        </div>
      </div>
    </Link>
  </div>
);

GuideCard.propTypes = {
  guide: PropTypes.object.isRequired,
};

export default compose(withRouter)(GuideCard);
