import fp from 'lodash/fp';

const getFilter = state => {
  const filters = {
    startDate: state.startDate,
    endDate: state.endDate,
    location: state.location,
    day_multi: state.day_multi,
    category: state.category,
    difficulty: state.difficulty,
  };

  return filters;
};

export const adventureIdSelector = fp.get('AdventuresFilter.id');
export const startDateSelector = fp.get('AdventuresFilter.startDate');
export const endDateSelector = fp.get('AdventuresFilter.endDate');
export const locationSelector = fp.get('AdventuresFilter.location');
export const dayMultiSelector = fp.get('AdventuresFilter.dayMulti');
export const categorySelector = fp.get('AdventuresFilter.category');
export const difficultySelector = fp.get('AdventuresFilter.difficulty');

export const filterSelector = fp.compose(
  getFilter,
  fp.get('AdventuresFilter')
);
