import React, { Component } from 'react';

import PropTypes from 'prop-types';
import Container from '../../../common/container/Container';
import Header from '../header/Header';

import './Hero.scss';

class Hero extends Component {
  render() {
    let { data } = this.props;
    let bgImage = {
      backgroundImage: 'url(' + data[0].great_guides[0].header.content.heroImage + ')',
    };
    return (
      <div className='hero ladakh' style={bgImage}>
        <Container>
          <Header data={data} btnName={data[0].great_guides[0].header.button} />
          <div className='content'>
            <h3>{data[0].great_guides[0].header.content.subTitle}</h3>
            <h1>{data[0].great_guides[0].header.content.title}</h1>
            <p>{data[0].great_guides[0].header.content.description}</p>
          </div>
        </Container>
      </div>
    );
  }
}

Hero.propTypes = {
  data: PropTypes.array,
  openSendMessageModal: PropTypes.func,
};

export default Hero;
