/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';
import { getGreatGuidesEnvUrl } from 'utils/initializer';

import { actions as sendContactActions } from '../../redux/modules/SendContact';
import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import {
  actions as updateUserActions,
  selectors as updateUserSelectors,
} from '../../redux/modules/UpdateUser';
import { selectors as userSelectors } from '../../redux/modules/users';

import styles from 'styles/pages/NewGuidePage.module.scss';
import cStyles from 'styles/components/guide/Certification.module.scss';

/* Component definition */
class Certification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: null,
      date: null,
      licenses: [],
    };
  }

  componentDidMount() {
    // const { getLoggedInUser } = this.props;
    // getLoggedInUser();
  }

  shouldComponentUpdate(nextProps) {
    const { userStatus } = nextProps;

    if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('success') && this.props.userStatus.includes('pending')) {
        toast.success('Successfully created your profile', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('fail')) {
        toast.error('Failed to create your profile.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    return true;
  }

  onInputTextChange = () => {};

  onChangeFocus = focused => {
    this.setState({ focused });
  };

  nextStep = () => {
    const { sendContact, nextStep, updateUserRole, loggedUser } = this.props;
    const { userRole } = this.props;

    if (userRole === 'Client') {
      sendContact({
        email: localStorage.email,
        tripMessage: 'New Guide has signed up',
        name: localStorage.name,
      });

      if (loggedUser) updateUserRole();
    }

    nextStep();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  onFileLoader = file => {
    let reader = new FileReader();
    const fileName = file.path;

    reader.onload = function(e) {
      try {
        let { licenses } = this.state;
        licenses.push({ content: e.target.result, name: fileName });
        this.setState({ licenses: licenses });
      } catch (e) {
        console.log(e);
      }
    }.bind(this);

    reader.readAsDataURL(file);
  };

  uploadLicenses = async files => {
    if (files.length > 0) {
      const baseUrl = `${getGreatGuidesEnvUrl()}media/v1/users/profilePicture`;
      let response;
      try {
        response = await fetch(baseUrl, {
          method: 'PUT',
          body: files[0],
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Bearer ${localStorage.id_token}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
      // let imageJson;
      try {
        let imageJson = response ? await response.json() : undefined;
        console.log(imageJson);
      } catch (e) {
        console.log(e);
      }

      files.map(file => {
        this.onFileLoader(file);
      });
    }
  };

  render() {
    return (
      <div>
        <div>
          <h2>Basic guide certification with GreatGuides</h2>
          <p>
            Complete &quot;Basic e-Learning&quot; module, then click finish and take a quick
            assessment and get your
            <br />
            basic guide certification with GreatGuides
          </p>
        </div>

        <div className={cStyles.section}>
          {/* <div className={cStyles.youtube}>EMBED YOUTUBE PLAYER / PLAYLIST</div> */}
          <iframe
            width='560'
            height='315'
            src='https://www.youtube.com/embed/tmQx4Axep-0'
            frameBorder='0'
            allow='accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture'
            allowFullScreen
          />
        </div>

        <div className={cStyles.section}>
          <form>
            <div className={styles.form_group + ' finish_btn'}>
              <button type='button' className={styles.form_button} onClick={() => this.nextStep()}>
                <a href='https://forms.gle/QLmZGSFtyeDxvBmy6' className='finish_google_survey'>
                  FINISH WITH SURVEY
                </a>
              </button>
              <button
                type='button'
                className={styles.form_button}
                onClick={() => this.previewStep()}
              >
                Back
              </button>
            </div>
          </form>
        </div>

        <div className={cStyles.section}>
          <div className={cStyles.agree}>
            By clicking {' Finish '} I agree to{' '}
            <a href='/privacyPolicy' target='_blank'>
              Guide Agreement
            </a>
          </div>
        </div>
        <ToastContainer autoClose={3000} />
      </div>
    );
  }
}

Certification.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  updateUserRole: PropTypes.func.isRequired,
  userStatus: PropTypes.string,
  sendContact: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  loggedUser: PropTypes.string,
};

const selectors = createStructuredSelector({
  userRole: GuideSetFilterSelectors.userRoleSelector,
  loggedUser: userSelectors.userIdSelector,
  userStatus: updateUserSelectors.userStatusSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserRole: updateUserActions.updateUserRole,
  sendContact: sendContactActions.sendContact,
};

export default connect(selectors, actions)(Certification);
