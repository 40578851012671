/**
 * @file Holds the <AdventureCard> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';

//import { LazyLoadImage } from 'react-lazy-load-image-component';

import { actions as adventuresActions } from '../redux/modules/adventures';

import 'react-lazy-load-image-component/src/effects/blur.css';
import styles from 'styles/components/AdventureCard.module.scss';

/* Component definition */
const AdventureCard = ({ deleteAdventure, adventure, edit }) => (
  <div className={styles.wrapper}>
    <div className={styles.thumb}>
      <div className={styles.price}>US${adventure.pricePerPerson}</div>
      {edit ? (
        <>
          <Link to={`/edit-adventure/${adventure.id}`}>
            <div className={styles.edit}>
              <Icon name='edit' />
            </div>
          </Link>
          <div className={styles.delete} onClick={() => deleteAdventure(adventure.id)}>
            <Icon name='delete' />
          </div>
        </>
      ) : (
        <div className={styles.like}>
          <Icon name='heart' />
        </div>
      )}

      <div className={styles.guide}>
        <div className={styles.face}>
          <img src={adventure.guide.profilePictureUrl} alt='avatar' />
        </div>
        <p>{adventure.guide.fullName}</p>
        {adventure.guide.extra && (
          <div className={styles.info}>
            <Icon name='star' />
            <span>{adventure.guide.extra.rating.substring(0, 3)}</span>
            <span>&bull;</span>
            <span>{adventure.guide.extra.NumOfReviews} reviews</span>
          </div>
        )}
      </div>
      <Link to={`/adventure/${adventure.id}`} className={styles.background}>
        {/*
        <LazyLoadImage
          src={adventure.media[0] ? adventure.media[0].imageHQUrl : ''}
          effect='blur'
        />
      </Link>
      */}
        <img src={adventure.media[0] ? adventure.media[0].imageHQUrl : ''} alt='thumbnail' />
      </Link>
    </div>

    <Link to={`/adventure/${adventure.id}`}>
      <div className={styles.description}>
        <div className={styles.type}>
          {adventure.categories}
          <span>&nbsp;&bull;&nbsp;</span>
          {adventure.durationInDays} {adventure.durationInDays == 1 ? 'Day' : 'Days'}
        </div>
        <p>{adventure.title}</p>
        <div className={styles.location}>
          <Icon name='location' />
          {adventure.address}
        </div>
      </div>
    </Link>
  </div>
);

/* Prop types definition */
AdventureCard.propTypes = {
  deleteAdventure: PropTypes.func.isRequired,
  adventure: PropTypes.object.isRequired,
  edit: PropTypes.bool,
};

const selectors = createStructuredSelector({});

const actions = {
  deleteAdventure: adventuresActions.deleteAdventure,
};

export default connect(selectors, actions)(withRouter(AdventureCard));
