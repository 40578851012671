import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { GUIDES_ID_GET } from './types';

const initialState = {
  data: null,
  status: null,
  guide: null,
  guide_loading: true,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(GUIDES_ID_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(GUIDES_ID_GET),
      error: false,
      guide: null,
      guide_loading: true,
      message: '',
    }),

    [requestSuccess(GUIDES_ID_GET)]: (state, { payload }) => {
      const guides = payload.users;
      const id = payload.id;

      if (id) {
        guides.map(item => {
          if (item.id === payload.id) {
            state.guide = item;
          }
        });
      }

      return {
        ...state,
        status: requestSuccess(GUIDES_ID_GET),
        data: payload,
        guide_loading: false,
        error: null,
      };
    },

    [requestFail(GUIDES_ID_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(GUIDES_ID_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
