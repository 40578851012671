/**
 * @file Holds the <HomeHeader> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { LazyLoadComponent, LazyLoadImage } from 'react-lazy-load-image-component';

import styles from 'styles/components/HomeHeader.module.scss';
import '../styles/components/react_dates_overridesHome.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';
import SearchFilter from './SearchFilter/SearchFilter';
/* Component definition */
class HomeHeader extends React.Component {
  state = {
    guideIndex: null,
  };

  componentDidMount() {
    window.addEventListener('mouseup', this.hideGuides);
  }

  componentWillUnmount() {
    window.removeEventListener('mouseup', this.hideGuides);
  }

  hideGuides = event => {
    let pos = document.getElementById('guideIcons');
    const { guideIndex } = this.state;
    if (event.target !== pos && event.target.parentNode !== pos && Number.isInteger(guideIndex)) {
      this.setState({ guideIndex: null });
    }
  };

  onClickGuide = index => {
    this.setState({ guideIndex: index });
  };

  scrollToGuides = () => {
    let guidesSection = document.getElementById('guidesSection');
    guidesSection.scrollIntoView();
  };

  render() {
    const {
      locations,
      filterLocation,
      endDate,
      category,
      dayMulti,
      setFilter,
      submitFilter,
      categories,
    } = this.props;
    const { guideIndex } = this.state;

    const topGuides = []
      .concat(this.props.guides)
      .sort((a, b) => a.rating > b.rating)
      .slice(0, 4);
    const hideGuide = {
      zIndex: '1',
      filter: 'blur(1px)',
    };
    const showGuide = {
      zIndex: '3',
    };
    const showDetail = {
      zIndex: '2',
    };
    return (
      <LazyLoadComponent>
        <div className={styles.wrapper}>
          <div className={styles.row}>
            <div className={styles.grid}>
              <div className={styles.grid_column}>
                <h1>Great Adventures need Great Guides!</h1>
              </div>
              <div className={styles.grid_column}>
                <div className={styles.guides_faces}>
                  <div className={styles.faces_wrapper} id='guideIcons'>
                    {topGuides.length
                      ? topGuides.map((guide, index) => (
                        <div key={index}>
                          <div
                            key={index}
                            className={styles.face}
                            onClick={() => this.onClickGuide(index)}
                            style={
                              guideIndex === index
                                ? showGuide
                                : guideIndex === null
                                  ? {}
                                  : hideGuide
                            }
                          >
                            <LazyLoadImage
                              src={guide.profilePictureUrl}
                              alt='face'
                              effect='blur'
                              style={
                                guideIndex === index
                                  ? showGuide
                                  : guideIndex === null
                                    ? {}
                                    : hideGuide
                              }
                            />
                            {guideIndex === index ? (
                              <div
                                className={styles.details}
                                style={
                                  guideIndex === index
                                    ? showDetail
                                    : guideIndex === null
                                      ? {}
                                      : hideGuide
                                }
                              >
                                <div className={styles.country}>
                                  {/* {guide.city}, {guide.country} */}
                                  {guide.businessAddress}
                                </div>
                                <div className={styles.name}>{guide.fullName}</div>
                                <div className={styles.extra}>
                                  <div className={styles.rating}>
                                    <span className={styles.star}>&#9733;</span>
                                      &nbsp;
                                    <span>{guide.extra ? guide.extra.rating : ''}</span>
                                    <span>&nbsp;</span>
                                  </div>
                                  <div className={styles.review}>
                                    <span>&bull;</span>
                                    <span>
                                      {guide.extra ? guide.extra.NumOfReviews : ''} reviews
                                    </span>
                                    <span>&nbsp;</span>
                                  </div>
                                  <div className={styles.adventures}>
                                    {guide.extra ? <span>&bull;</span> : ''}
                                    <span>{guide.adventures.length} adventures</span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              ''
                            )}
                          </div>
                        </div>
                      ))
                      : '0'}
                  </div>
                  <span className={styles.allGuides} onClick={() => this.scrollToGuides()}>
                    All Guides
                  </span>
                </div>

                <hr className={`short_hr ${styles.divider}`} />

                <p className={styles.explanation}>
                  GreatGuides.com connects adventurous travellers with great guides. We just
                  launched. Join us in building our community and find your adventure!
                </p>
              </div>
            </div>
            <SearchFilter
              locations={locations}
              categories={categories}
              location={filterLocation}
              endDate={endDate}
              category={category}
              dayMulti={dayMulti}
              setFilter={setFilter}
              submitFilter={submitFilter}
              displayStyleField={false}
              customStyleName='header-filter'
            />
          </div>
        </div>
      </LazyLoadComponent>
    );
  }
}

HomeHeader.propTypes = {
  guides: PropTypes.array.isRequired,
  locations: PropTypes.array.isRequired,
  categories: PropTypes.array.isRequired,
  filterLocation: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  dayMulti: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  submitFilter: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({});

const actions = {};

export default compose(withRouter, connect(selectors, actions))(HomeHeader);
