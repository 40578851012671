import React from 'react';
import PropTypes from 'prop-types';

import RichEditor from 'components/RichEditor';
import Icon from 'components/Icon';
import styles from 'styles/components/TourDescription.module.scss';
import '../styles/fr-elements.scss';

const tags = {
  duration: 'days',
  distance: 'distance',
  season: 'season',
  minimum_age: 'years',
  elevation: 'elevation',
  difficulty: '',
  // availability_months: '',
};

const renderSpecs = specs =>
  Object.keys(specs).map((key, index) => (
    <div className={styles.specs_items + ' id_' + index} key={index}>
      <Icon name={key} />
      <p>{key.replace(/_/g, ' ').toUpperCase()}</p>
      <div>
        <span className={specs[key] !== null ? styles.specs_items_suffix : ''}>{specs[key]}</span>
        {specs[key] === null ? <span>no {tags[key]}</span> : <span>{tags[key]}</span>}
      </div>
    </div>
  ));

class TourDescription extends React.Component {
  state = {
    isExtended: false,
  };

  handleExtendDescription = () => {
    const { isExtended } = this.state;
    this.setState({
      isExtended: !isExtended,
    });
  };

  render() {
    const { description, specs } = this.props;

    return (
      <div className={styles.wrapper}>
        <h3>Tour Description</h3>
        <RichEditor value={description} isEditable={false} />
        {/* <div className={styles.read_more} onClick={() => this.handleExtendDescription()}>
           {!isExtended ? <span>Read more</span> : <span>Show less</span>} 
          <Icon name='read-more-grey' /> 
        </div> */}
        {<div className={styles.specs}>{renderSpecs(specs)}</div>}
      </div>
    );
  }
}

TourDescription.propTypes = {
  description: PropTypes.string.isRequired,
  specs: PropTypes.object.isRequired,
};

export default TourDescription;
