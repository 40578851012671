import React, { Component } from 'react';

class SideBar extends Component {
  render() {
    return (
      <div className='account-sidebar'>
        <ul className='account-sidebar-menu'>
          <li>
            <a href='/account/detail'>Account Details</a>
          </li>
          <li>
            <a href='/account/favorites'>my favorites</a>
          </li>
          <li>
            <a href='/account/trip'>my trip</a>
          </li>
          <li>
            <a href='/account/messages'>my messages</a>
          </li>
          <li>
            <a href='/account/blog'>Blog</a>
          </li>
          <li>
            <a href='/'>signout</a>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideBar;
