import React, { Component } from 'react';

import NewsComponent from '../components/News/NewsComponent';

class News extends Component {
  render() {
    return (
      <div className='news-page'>
        <NewsComponent />
      </div>
    );
  }
}

export default News;
