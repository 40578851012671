import gql from 'graphql-tag';

export const CATEGORIES = gql`
  query categories($type: String!) {
    __type(name: $type) {
      name
      enumValues {
        name
      }
    }
  }
`;
