/**
 * @file Holds the <SubscribeToNewsletter> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import { validateEmail } from 'utils/initializer';
import Response from '../components/AdventureDetails/Response';
import styles from 'styles/components/SubscribeToNewsletter.module.scss';

/* Component definition */
class SubscribeToNewsletter extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isValidEmail: true,
      invalidEmail: 'Please enter valid email',
      sendSubscribe: false,
      email: '',
    };
  }

  handleChange = e => {
    this.setState({ email: e.target.value });
    if (validateEmail(this.state.email)) this.setState({ isValidEmail: true });
    else this.setState({ isValidEmail: false });
  };

  onSubmit = e => {
    const { isValidEmail, email } = this.state;
    const { sendContact } = this.props;

    if (isValidEmail && email !== '') {
      sendContact(email);
      this.setState({
        sendSubscribe: true,
      });
    } else {
      this.setState({ isValidEmail: false });
    }

    e.preventDefault();
    this.setState({
      showSubscribe: false,
    });
    sessionStorage.setItem('subscribe', 'hidden');
  };

  render() {
    const { isValidEmail, invalidEmail, sendSubscribe } = this.state;
    return (
      <div className={styles.wrapper}>
        {sendSubscribe && (
          <Response
            heading='Thank you for subscribing to awesome trips'
            paragraph='We respect privacy of your email at all times.'
            isBookingForm={true}
            id='432e2432'
          />
        )}
        <h3>Sign up for our monthly newsletter </h3>
        <p>Get the latest stories on our guides and trips </p>

        <form onSubmit={this.onSubmit}>
          <input
            className={!isValidEmail ? styles.inputError : styles.input}
            type='text'
            onChange={e => this.handleChange(e)}
            placeholder='Your email'
          />
          <button type='submit' disabled={!isValidEmail}>
            Subscribe
            <Icon name='arrow-right-big' />
          </button>
          {!isValidEmail ? <div className='errorEmail-popup'>{invalidEmail}</div> : null}
        </form>
      </div>
    );
  }
}

SubscribeToNewsletter.propTypes = {
  sendContact: PropTypes.func.isRequired,
};

export default SubscribeToNewsletter;
