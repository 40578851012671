import PropTypes from 'prop-types';
import React from 'react';
import ReactPhoneInput from 'react-phone-input-2';

//import 'react-phone-input-2/dist/style.css';
import 'styles/components/guide/PhoneInput.overrides.scss';

class InputField extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phone: '',
      value: '',
    };
  }

  componentDidMount() {
    const { value } = this.props;
    this.setState({ value: value });
  }
  onChange = (e, name) => {
    const { setFieldValue } = this.props;
    this.setState({ value: e.target.value });
    setFieldValue(e.target.value, name);
  };

  handleOnChange = value => {
    const { setFieldValue } = this.props;

    this.setState({ phone: value });
    setFieldValue(value, 'phone');
  };

  render() {
    let {
      wrapClass,
      labelClass,
      inputClass,
      inputErrorClass,
      errorText,
      label,
      type,
      holder,
      errorName,
      value,
      name,
    } = this.props;
    return (
      <div className={wrapClass}>
        <div>
          <label className={labelClass}>{label}</label>
        </div>
        {name !== 'phone' ? (
          <input
            type={type}
            className={name && errorName && name == errorName ? inputErrorClass : inputClass}
            placeholder={holder}
            value={value || ''}
            onChange={e => this.onChange(e, name)}
          />
        ) : (
          <ReactPhoneInput
            inputExtraProps={{
              name: 'phone',
              required: true,
              autoFocus: true,
            }}
            value={value || ''}
            onChange={this.handleOnChange}
          />
        )}
        {name && errorName && name == errorName && (
          <div className={errorText}>{errorName} is required</div>
        )}
      </div>
    );
  }
}

InputField.propTypes = {
  setFieldValue: PropTypes.func,
  wrapClass: PropTypes.string,
  labelClass: PropTypes.string,
  inputClass: PropTypes.string,
  inputErrorClass: PropTypes.string,
  errorText: PropTypes.string,
  label: PropTypes.string,
  type: PropTypes.string,
  holder: PropTypes.string,
  onChange: PropTypes.func,
  value: PropTypes.any,
  error: PropTypes.string,
  name: PropTypes.any,
  errorName: PropTypes.string,
};

export default InputField;
