import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
// import Select from 'react-select';

import RichEditor from 'components/RichEditor';
import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import ErrorHandling from '../ErrorHandling';

import Style from '../../styles/pages/CreateAdventure.module.scss';
import './index.scss';

// const difficulties = [
//   { value: 'easy', label: 'Easy' },
//   { value: 'medium', label: 'Medium' },
//   { value: 'hard', label: 'Hard' },
// ];

class AddItinerary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutMe: '',
      errorName: '',
      itineraryDays: [
        {
          description: props.itinerary,
          // activeHours: '',
          // dayDifficulty: '',
        },
      ],
      errorType: '',
    };
  }
  nextStep = () => {
    const { nextStep, setAdventureFilter } = this.props;
    const { itineraryDays } = this.state;

    let data = '';
    itineraryDays.forEach(item => {
      data += item.description + '<br/>';
    });

    setAdventureFilter({
      ['itinerary']: data,
    });
    if (this.checkValue() === '') {
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  checkValue = () => {
    const { itineraryDays } = this.state;
    let errorType = '';
    itineraryDays.forEach(itinerary => {
      if (itinerary.description === '') {
        errorType = errorType.concat('itineraryDays');
      }
    });

    this.setState({ errorType });
    return errorType;
  };

  handleDescription = (itinerary, index) => {
    const { itineraryDays } = this.state;
    itineraryDays[index].description = itinerary;
  };

  addDay = () => {
    let itinerar = [];
    itinerar = this.state.itineraryDays;
    itinerar.push({
      description: '',
    });
    this.setState({
      itineraryDays: itinerar,
    });
  };

  // onChangeDifficulty = (selected, index) => {
  //   const { itineraryDays } = this.state;
  //   itineraryDays[index].dayDifficulty = selected;
  // };
  //
  // handleHours = (e, index) => {
  //   const { itineraryDays } = this.state;
  //   itineraryDays[index].activeHours = e.target.value;
  // };

  render() {
    const { itineraryDays, errorType } = this.state;

    // const customStyles = {
    //   control: () => ({
    //     // none of react-select's styles are passed to <Control />
    //     border: 'none',
    //     'align-items': 'center',
    //     display: 'flex',
    //     'flex-wrap': 'wrap',
    //     'justify-content': 'space-between',
    //     position: 'relative',
    //     'white-space': 'nowrap',
    //     ':focus-within > :last-child': {
    //       transform: 'rotate(180deg)',
    //     },
    //   }),
    //   indicatorSeparator: () => ({
    //     display: 'none',
    //   }),
    //   input: () => ({
    //     visibility: 'visible',
    //     color: 'hsl(0,0%,20%)',
    //     height: '20px',
    //     'box-sizing': 'border-box',
    //   }),
    //   dropdownIndicator: () => ({
    //     color: 'hsl(0,0%,80%)',
    //     display: 'flex',
    //     transition: 'color 150ms',
    //     'box-sizing': 'border-box',
    //   }),
    //   menu: styles => {
    //     return {
    //       ...styles,
    //       width: '250px',
    //       left: '-12px',
    //       top: 'calc((100% + 14px))',
    //     };
    //   },
    // };

    return (
      <div className='descriptionAdd'>
        <div className={Style.header_title}>
          <h2>Adventure Itinerary</h2>
          <p>Describe your adventure and provide more information</p>
        </div>
        {itineraryDays.map((day, idx) => {
          return (
            <div key={idx}>
              <div className='itinerary-label'>Itinerary of trip: Day {idx + 1} </div>
              <RichEditor
                value={day.description}
                handleFroalaModelChange={model => this.handleDescription(model, idx)}
                isEditable={true}
              />
              {/*<div className={Style.inline_group}>*/}
              {/*  <label>Details of the Day {idx + 1}</label>*/}
              {/*  <div className={Style.participants}>*/}
              {/*    <div className='form-group'>*/}
              {/*      <input*/}
              {/*        type='text'*/}
              {/*        placeholder='Activity hours'*/}
              {/*        onChange={e => this.handleHours(e, idx)}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*    <div className={Style.input}>*/}
              {/*      <Select*/}
              {/*        value={day.difficulty}*/}
              {/*        options={difficulties}*/}
              {/*        styles={customStyles}*/}
              {/*        placeholder='Difficulty'*/}
              {/*        isSearchable={false}*/}
              {/*        onChange={selected => this.onChangeDifficulty(selected, idx)}*/}
              {/*      />*/}
              {/*    </div>*/}
              {/*  </div>*/}
              {/*</div>*/}
              {errorType.includes('itineraryDays') ? <ErrorHandling category='Itinerary' /> : ''}
            </div>
          );
        })}
        <div className={Style.add_day}>
          <span onClick={this.addDay}>+Add one more day</span>
        </div>
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
      </div>
    );
  }
}

AddItinerary.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  itinerary: PropTypes.string,
};

const selectors = createStructuredSelector({
  itinerary: createAdventureSelectors.itinerarySelector,
});

const actions = {
  setAdventureFilter: createAdventureActions.setAdventureFilter,
};

export default connect(selectors, actions)(AddItinerary);
