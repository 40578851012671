import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

import styles from 'styles/components/HeaderContinents.module.scss';

function HeaderContinents({ continents }) {
  return (
    <span className={[`${styles.menu__title}`]}>
      Locations
      <div className={[`${styles.submenu}`]}>
        {Object.entries(continents).map(([key, value]) => (
          <div key={key} className={[`${styles.submenu__item}`]}>
            <h4>{key}</h4>
            {value.map(name => (
              <Link key={name} to={`/adventures?location=${name}`}>
                {name}
              </Link>
            ))}
          </div>
        ))}
      </div>
    </span>
  );
}

HeaderContinents.propTypes = {
  continents: PropTypes.objectOf(PropTypes.arrayOf(PropTypes.string)).isRequired,
};

export default HeaderContinents;
