import gql from 'graphql-tag';

export const getAdventureById = gql`
  query adventureById($id: ID) {
    adventure(where: { id: $id }) {
      id
      title
      categories
      description
      createdAt
      guide {
        id
        fullName
        extra
        profilePictureUrl
        adventures {
          id
        }
      }
      media {
        imageHQUrl
        imageMQUrl
        imageVHQUrl
        fileName
        copyright
      }
      reviews
      comments {
        id
        author {
          id
          fullName
          profilePictureUrl
        }
        note
        rating
        createdAt
      }
      address
      dates
      difficulty
      durationInDays
      thingsToCarryList
      similarAdventures
      itinerary
      reviews
      maxParticipants
      minParticipants
      price
      pricePerPerson
      pricePerGroup
      requirements
      directions
      advisory
      exclusions
      inclusions
      highlights
      latitude
      longitude
      distance
      elevation
      season
      minimumAge
      extra
      availabilityMonths
    }
  }
`;

/********************** Get Adventures with startdate, enddate, location, category, day or multi, difficulty **********************/
export const getAdventures = gql`
  query adventuresByDateRangeCategoryAndLocation(
    $startDate: DateTime!
    $endDate: DateTime!
    $qlocation: String
    $qday_multi: String
    $qdifficulty: String
  ) {
    adventuresByDateRangeCategoryAndLocation(
      startDate: $startDate
      endDate: $endDate
      location: $qlocation
      category: qcateogry
      difficulty: $qdifficulty
      dayOrMulti: $qday_multi
    ) {
      id
      title
      categories
      pricePerPerson
      address
      guide {
        profilePictureUrl
        fullName
        extra
      }
      media {
        imageMQUrl
      }
    }
  }
`;

/********************** Get Adventures with startdate, enddate, location, day or multi, difficulty **********************/
export const getAdventuresWithoutCategory = gql`
  query adventuresByDateRangeCategoryAndLocation(
    $startDate: DateTime!
    $endDate: DateTime!
    $qlocation: String
    $qday_multi: String
    $qdifficulty: String
  ) {
    adventuresByDateRangeCategoryAndLocation(
      where: {
        startDate: $startDate
        endDate: $endDate
        location: $qlocation
        difficulty: $qdifficulty
        dayOrMulti: $qday_multi
      }
    ) {
      id
      title
      categories
      pricePerPerson
      address
      guide {
        profilePictureUrl
        fullName
        extra
      }
      media {
        imageMQUrl
      }
    }
  }
`;

export const getLocations = gql`
  query locations {
    availableLocations {
      id
      country
      state
      city
      imageUrl
      flagIcon
    }
  }
`;
