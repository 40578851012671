export function environment() {
  return 'development';
  // return 'development'
}

export function getGreatGuidesEnvUrl() {
  const env = environment();
  if (env === 'production') {
    return 'https://api.services.activelyme.com/';
  }
  return 'https://api-test.services.activelyme.com/';
}

export function validateEmail(email) {
  var res = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return res.test(email);
}
