/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { ToastContainer, toast } from 'react-toastify';
import Dropzone from 'react-dropzone';

import AccountTabList from './AccountTabList';
import InputSelect from '../InputSelect';
import InputField from '../InputField';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import {
  actions as updateUserActions,
  selectors as updateUserSelectors,
} from '../../redux/modules/UpdateUser';
import { selectors as userSelectors } from '../../redux/modules/users';

import avatar from '../../assets/guides/avatar.svg';
import styles from 'styles/pages/AccountDetailsPage.module.scss';
import 'react-toastify/dist/ReactToastify.min.css';

import { getGreatGuidesEnvUrl } from 'utils/initializer';

/* Component definition */
class GuideInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      firstName: '',
      lastName: '',
      emailAddress: '',
      profilePicture: '',
      profilePictureContent: '',
      gender: '',
    };
  }

  componentDidMount() {
    const { firstName, lastName, emailAddress, profilePictureUrl, gender } = this.props;
    const { updateUserStatus } = this.props;

    updateUserStatus({
      ['status']: null,
    });
    this.setState({
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      profilePicture: profilePictureUrl,
      profilePictureContent: profilePictureUrl,
      gender: gender,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { userStatus } = nextProps;

    if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('success') && this.props.userStatus.includes('pending')) {
        toast.success('Successfully updated your profile', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('fail')) {
        toast.error('Failed to update your profile.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    return true;
  }

  onInputTextChange = () => {};

  saveGuideInfo = () => {
    if (!this.validateForm()) {
      return;
    }

    const { setGuideInfo, updateUserInfo, loggedUser } = this.props;
    const { firstName, lastName, emailAddress, gender, profilePicture } = this.state;

    //Set Guides' information
    setGuideInfo({
      ['firstName']: firstName,
      ['lastName']: lastName,
      ['emailAddress']: emailAddress,
      ['gender']: gender,
      ['profilePictureUrl']: profilePicture,
    });

    if (loggedUser) updateUserInfo();
  };

  validateForm() {
    const { emailAddress } = this.state;

    if (emailAddress === '') {
      this.setState({ errorName: 'Email' });
      return false;
    }
    return true;
  }

  setFieldValue = (value, type) => {
    if (type === 'firstName') this.setState({ firstName: value });
    else if (type === 'lastName') this.setState({ lastName: value });
    else if (type === 'Email') this.setState({ emailAddress: value });
    else if (type === 'password') console.log('password');
  };

  uploadAvatar = async files => {
    let reader = new FileReader();

    if (files.length > 0) {
      const baseUrl = `${getGreatGuidesEnvUrl()}media/v1/users/profilePicture`;
      let response;
      try {
        response = await fetch(baseUrl, {
          method: 'PUT',
          body: files[0],
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Bearer ${localStorage.id_token}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
      let imageJson;
      try {
        if (response) {
          imageJson = await response.json();
          console.log(imageJson);
        } else imageJson = undefined;
      } catch (e) {
        console.log(e);
      }

      reader.onload = function(e) {
        try {
          this.setState({ profilePictureContent: e.target.result });
        } catch (e) {
          console.log(e);
        }
      }.bind(this);

      reader.readAsDataURL(files[0]);

      if (imageJson) {
        this.setState({ profilePicture: imageJson.imageUrl });
      }
    } else alert('Image size should be less or equal to 800kb');
  };

  selectGender = type => {
    this.setState({ gender: type });
  };

  setTabIndex = index => {
    const { setTabIndex } = this.props;

    setTabIndex(index);
    this.saveGuideInfo();
  };

  render() {
    const { tabIndex } = this.props;
    const {
      firstName,
      lastName,
      emailAddress,
      profilePictureContent,
      gender,
      errorName,
    } = this.state;

    const dropzoneStyle = {
      border: 'none',
      position: 'absolute',
      width: '400px',
      height: '63px',
      borderStyle: 'noen',
      top: 0,
    };

    return (
      <div>
        <AccountTabList setTabIndex={this.setTabIndex} tabIndex={tabIndex} />
        <form className={styles.form}>
          <div className={styles.form_content}>
            <span styles={styles.form_avatar}>
              <img
                src={profilePictureContent ? profilePictureContent : avatar}
                className={styles.avatar}
                alt='avatar'
              />
            </span>
            <div className={styles.form_avatar_exp}>
              <Dropzone
                onDrop={this.uploadAvatar}
                accept='image/jpeg, image/png'
                style={dropzoneStyle}
                multiple
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <h4 className={styles.form_uploadAvatar}>Upload Image</h4>
                        <p className={styles.form_title}>
                          A square image 400x400 px is recommended
                        </p>
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            </div>
          </div>
          <div className={styles.form_fields}>
            <div className={styles.form_group}>
              <div>
                <label className={styles.label}>Gender</label>
              </div>
              <InputSelect
                categories={['male', 'female']}
                selectGender={this.selectGender}
                value={gender}
              />
            </div>
            <div>
              <InputField
                wrapClass={styles.form_group}
                label='What is your first name?'
                type='text'
                holder='First name'
                name='firstName'
                value={firstName}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                setFieldValue={this.setFieldValue}
              />
            </div>
            <div>
              <InputField
                wrapClass={styles.form_group}
                label='What is your last name?'
                type='text'
                holder='Last name'
                name='lastName'
                value={lastName}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                setFieldValue={this.setFieldValue}
              />
            </div>
            <div>
              <InputField
                wrapClass={styles.form_group}
                label='Email address'
                type='email'
                holder='Email address'
                name='Email'
                value={emailAddress}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                inputErrorClass={styles.inputBorderError}
                errorName={errorName}
                errorText={styles.errorText}
                setFieldValue={this.setFieldValue}
              />
            </div>
            <div>
              <InputField
                wrapClass={styles.form_group}
                label='Password'
                type='password'
                holder='**************'
                name='password'
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                setFieldValue={this.setFieldValue}
              />
            </div>
          </div>

          <div className={styles.form_group}>
            <button
              type='button'
              className={styles.form_button}
              onClick={() => this.saveGuideInfo()}
            >
              Save Changes
            </button>
          </div>
        </form>
        {tabIndex === 1 && <ToastContainer autoClose={3000} />}
      </div>
    );
  }
}

GuideInfo.propTypes = {
  props: PropTypes.object,
  profilePicture: PropTypes.array,
  setGuideInfo: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  userStatus: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailAddress: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  gender: PropTypes.string,
  loggedUser: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({
  firstName: GuideSetFilterSelectors.firstNameSelector,
  lastName: GuideSetFilterSelectors.lastNameSelector,
  emailAddress: GuideSetFilterSelectors.emailAddressSelector,
  profilePictureUrl: GuideSetFilterSelectors.profilePictureUrlSelector,
  gender: GuideSetFilterSelectors.genderSelector,
  loggedUser: userSelectors.userIdSelector,
  userStatus: updateUserSelectors.userStatusSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserInfo: updateUserActions.updateUserInfo,
  updateUserStatus: updateUserActions.updateUserStatus,
};

export default connect(
  selectors,
  actions
)(GuideInfo);
