import fp from 'lodash/fp';

export const guidesAllSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('guides.data.users')
);

export const guidesSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('guides.guides')
);
