import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/GuideDetails/Addition.module.scss';

/* Component definition */
class Addition extends React.Component {
  render() {
    const { title, content } = this.props;

    return (
      <div className={styles.wrapper}>
        <div className={styles.section}>
          <h4 className={styles.title}>{title}</h4>
          <div className={styles.content}>{content}</div>
        </div>
      </div>
    );
  }
}

/* PropTypes definition */
Addition.propTypes = {
  title: PropTypes.string.isRequired,
  content: PropTypes.string.isRequired,
};

export default Addition;
