import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import InputForm from '../../common/Form/Input/Input';
import Calendar from './Calendar';
import ErrorHandling from '../ErrorHandling';
import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import Style from '../../styles/pages/CreateAdventure.module.scss';

const moment = require('moment');

const monthName = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const monthIdx = {
  January: 1,
  February: 2,
  March: 3,
  April: 4,
  May: 5,
  June: 6,
  July: 7,
  August: 8,
  September: 9,
  October: 10,
  November: 11,
  December: 12,
};

class AddDates extends Component {
  constructor(props) {
    super(props);
    this.state = {
      durationInDays: props.durationInDays,
      date: moment('2019-05-09'),
      currentYear: moment().format('YYYY'),
      groupMonthIdx: 0,
      selectedDays: props.dates.map(item => {
        return moment(item).format('YYYY-MM-DD');
      }),
      groupMonth: [
        { month: 'January', year: moment().format('YYYY') },
        { month: 'February', year: moment().format('YYYY') },
        { month: 'March', year: moment().format('YYYY') },
        { month: 'April', year: moment().format('YYYY') },
      ],
      errorType: '',
    };
    this.handleGroupCalendar = this.handleGroupCalendar.bind(this);
  }

  componentDidMount() {
    const { groupMonth } = this.state;
    this.generateGroupMonth();

    const groupMonthFirstDates = groupMonth.map(date => {
      return moment(`${date.year}-${date.month}-01`).format('YYYY-MM-01');
    });

    this.setState({ groupMonthFirstDates });
  }

  nextStep = () => {
    const { nextStep, setAdventureFilter } = this.props;
    const { selectedDays, durationInDays } = this.state;

    setAdventureFilter({
      ['dates']: selectedDays,
      ['durationInDays']: durationInDays,
    });

    if (this.checkValue() === '') {
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  generateGroupMonth = async () => {
    const { groupMonth } = this.state;

    const currentYear = Number(moment().format('YYYY'));
    const currentMonth = Number(moment().format('MM')) - 1;

    [0, 1, 2, 3].forEach(group => {
      const startM = currentMonth + group;
      groupMonth[group] = {
        month: monthName[startM % 12],
        year: startM >= 12 ? currentYear + 1 : currentYear,
      };
    });

    await this.setState({ groupMonth });
  };

  handleGroupMonth = type => {
    const { groupMonth } = this.state;

    if (type === 'prev') {
      [0, 1, 2, 3].forEach(group => {
        const prevMonth = monthIdx[groupMonth[group].month] - 5;

        groupMonth[group] = {
          month: monthName[prevMonth >= 0 ? prevMonth : (prevMonth + 12) % 12],
          year: prevMonth >= 0 ? groupMonth[group].year : groupMonth[group].year - 1,
        };
      });
    } else {
      [0, 1, 2, 3].forEach(group => {
        const nextMonth = monthIdx[groupMonth[group].month] + 3;

        groupMonth[group] = {
          month: monthName[nextMonth >= 12 ? nextMonth % 12 : nextMonth],
          year: nextMonth >= 12 ? groupMonth[group].year + 1 : groupMonth[group].year,
        };
      });
    }

    this.setState({ groupMonth });

    const groupMonthFirstDates = groupMonth.map(date => {
      return moment(`${date.year}-${date.month}-01`).format('YYYY-MM-01');
    });

    this.setState({ groupMonth, groupMonthFirstDates });
  };

  checkValue = () => {
    const { selectedDays, durationInDays } = this.state;
    let errorType = '';

    if (selectedDays.length === 0) {
      errorType = errorType.concat('selectedDays');
    }
    if (!durationInDays) {
      errorType = errorType.concat('durationInDays');
    }

    this.setState({ errorType });
    return errorType;
  };

  handleGroupCalendar(type) {
    switch (type) {
    case 'prev':
      this.handleGroupMonth('prev');
      break;
    case 'next':
      this.handleGroupMonth('next');
      break;
    default:
      break;
    }
  }

  handleChangeInput = e => {
    this.setState({ durationInDays: e.target.value });
  };

  handleSelectDate = day => {
    const { selectedDays } = this.state;
    const isExisting = selectedDays.indexOf(day);

    if (isExisting < 0) {
      selectedDays.push(day);
    } else {
      selectedDays.splice(isExisting, 1);
    }

    this.setState({ selectedDays });
  };

  render() {
    const {
      groupMonth,
      groupMonthFirstDates,
      selectedDays,
      durationInDays,
      errorType,
    } = this.state;

    return (
      <div className={Style.add_dates}>
        <div className={Style.header_title}>
          <h2>Adventure Dates</h2>
          <p>Select start dates of your adventure</p>
          <p>Which months are this trip offered on?</p>
        </div>
        <div className={Style.grid}>
          {groupMonthFirstDates && (
            <Calendar
              dates={groupMonthFirstDates}
              selectedDates={selectedDays}
              selected={this.handleSelectDate}
            />
          )}
        </div>
        <div className={Style.calendarButtons}>
          <div className='leftArrow' onClick={() => this.handleGroupCalendar('prev')}>
            <i className='leftArrow__arrow'></i>
          </div>
          <div className={Style.months}>
            {groupMonth !== null
              ? `${groupMonth[0].month} - ${groupMonth[3].month} ${groupMonth[3].year}`
              : ''}
          </div>
          <div className='rightArrow' onClick={() => this.handleGroupCalendar('next')}>
            <i className='rightArrow__arrow'></i>
          </div>
        </div>
        {errorType.includes('selectedDays') ? <ErrorHandling category='Seleting days' /> : ''}
        <div className={Style.duration}>
          <InputForm
            type='text'
            placeholder=''
            labelText='Duration (in days)'
            labelFor='duration'
            inputVal={durationInDays}
            onChange={this.handleChangeInput}
          />
        </div>
        {errorType.includes('durationInDays') ? <ErrorHandling category='Duration Days' /> : ''}
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
      </div>
    );
  }
}

AddDates.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  dates: PropTypes.array,
  durationInDays: PropTypes.number,
};

const selectors = createStructuredSelector({
  dates: createAdventureSelectors.datesSelector,
  durationInDays: createAdventureSelectors.durationInDaysSelector,
});

const actions = {
  setAdventureFilter: createAdventureActions.setAdventureFilter,
};

export default connect(
  selectors,
  actions
)(AddDates);
