/**
 * @file Holds the <Header> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import matchMedia from 'utils/matchMedia';
import Transition from 'react-addons-css-transition-group';

import { actions as userActions, selectors as userSelectors } from '../redux/modules/users';

import Icon from 'components/Icon';

import HomeFilter from './HomeFilter';
import HeaderContinents from './HeaderContinents';

import {
  actions as locationsActions,
  selectors as locationsSelectors,
} from '../redux/modules/locations';

import styles from 'styles/components/Header.module.scss';

import country from 'country-list-js';

/* Constants definition */
const navItems = [
  // {
  //   title: 'Guides',
  //   link: '/',
  // },
  {
    title: 'New Adventure',
    link: '/create-adventure',
  },
  {
    title: 'Stories',
    link: '/stories',
  },
  {
    title: 'Team',
    link: '/team',
  },
  {
    title: 'Become a guide',
    link: '/new-guide',
  },
  // {
  //   title: 'Stories',
  //   link: '/',
  // },
];

const dropClientItems = [
  {
    title: 'Account Details',
    link: '/profile/account',
  },
  {
    title: 'My Trips',
    link: '/account/trip',
  },
];

const dropGuideItems = [
  {
    title: 'Account Details',
    link: '/profile/account',
  },
  {
    title: 'My Adventures',
    link: '/account/my-adventures',
  },
  {
    title: 'Customer Trips',
    link: '/account/trip',
  },
];

/* Component definition */
class Header extends React.Component {
  state = {
    userRole: '',
    mql: null,
    isDropDown: false,
    setMenu: false,
    continents: null,
  };

  constructor(props) {
    super(props);
  }

  UNSAFE_componentWillMount() {
    window.addEventListener('resize', this.updateView);
    this.updateView();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.user) {
      this.setState({ userRole: nextProps.user.userRole });
    }
  }

  componentDidMount() {
    const { getLocations } = this.props;
    if (localStorage.userRole === 'Client') {
      this.setState({ userRole: 'Client' });
    } else if (localStorage.userRole === 'Guide' || localStorage.userRole === 'GuidePending') {
      this.setState({ userRole: localStorage.userRole });
    }
    getLocations();
    document.addEventListener('mousedown', this.handleClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.handleClickOutside);
    window.removeEventListener('resize', this.updateView);
  }

  componentDidUpdate() {
    const { locations } = this.props;
    const { continents } = this.state;
    if (!continents && locations.length > 0) {
      this.convertContinents(locations);
    }
  }

  convertContinents = locations => {
    const newContinents = locations.reduce((acc, { country: countryName }) => {
      let object = acc;
      let continent = country.findByName(countryName);
      if (countryName === 'Galápagos') {
        continent = {
          continent: 'South America',
          name: countryName,
        };
      }
      if (continent) {
        if (countryName === 'Greenland') {
          continent.continent = 'Europe';
        }
        let newArray = acc[continent.continent] ? [...acc[continent.continent]] : [];
        if (!newArray.includes(continent.name)) {
          newArray = [...newArray, continent.name];
        }
        object = {
          ...acc,
          [continent.continent]: newArray,
        };
      }
      return object;
    }, {});
    this.setState({
      continents: newContinents,
    });
  };

  updateView = () => {
    this.setState({ mql: matchMedia() });
  };

  handleLogin = () => {
    this.props.auth.login();
  };

  handleSignup = () => {
    this.props.auth.signup();
  };

  handleLogout = () => {
    const { setUser } = this.props;

    this.props.auth.logout(this.props.history);

    setUser({
      ['id']: null,
      ['data']: null,
    });
  };

  handleToggleDropDown = () => {
    this.setState({
      isDropDown: !this.state.isDropDown,
    });
  };

  handleToggleDropDownMenu = () => {
    this.setState({
      isDropDown: !this.state.isDropDown,
    });
  };

  setDropDownRef = node => {
    this.dropDownRef = node;
  };

  // handleClickOutside = event => {
  //   if (this.dropDownRef && !this.dropDownRef.contains(event.target)) {
  //     this.handleToggleDropDown();
  //   }
  // };

  onMenu = () => {
    this.setState({ setMenu: true });
  };

  closeMenu = status => {
    this.setState({ setMenu: status });
  };

  render() {
    const { auth, location } = this.props,
      { isDropDown, mql, setMenu, userRole, continents } = this.state;

    const isHomePage = location.pathname === '/';
    return (
      <Fragment>
        <header
          className={[`${styles.wrapper} ${isHomePage && styles.home_page}`]}
          onClick={() => this.onMenu}
        >
          <div className={styles.logo}>
            <Link to='/'>
              <Icon name='logo' className='icon_logo' onClick={this.onMenu} />
            </Link>
          </div>
          {/* Comment out phone number -Atma
          <div className={styles.phoneNumber}>
            <span>
              <a href='tel:12068995460'>
                Call: <b>1 206 899 5460</b>
              </a>
            </span>
          </div>
          */}
          {mql !== 'mobile' && (
            <nav className={styles.nav}>
              {auth.isAuthenticated() && userRole === 'Guide' && (
                <Link to={navItems[0].link}>{navItems[0].title}</Link>
              )}
              {continents && mql !== 'mobile' && <HeaderContinents continents={continents} />}
              {navItems.map(
                (item, index) =>
                  index >= 1 && (
                    <Link key={index} to={item.link}>
                      {item.title}
                    </Link>
                  )
              )}
            </nav>
          )}
          <div className={styles.right_column}>
            {/* <Icon name='search' className={styles.icon_search} /> */}
            {mql !== 'desktop' && (
              <div className={styles.hamburgerMenu} onClick={this.handleToggleDropDownMenu}>
                <Icon name='burger' className={styles.icon_burger} />
              </div>
            )}
            <div className={styles.mobileMenu}>
              {auth.isAuthenticated() ? (
                <div className={styles.profile} onClick={this.handleToggleDropDown}>
                  <div className={styles.profilePicture} onClick={this.handleToggleDropDownMenu}>
                    <img src={auth.getUserData().picture} alt='avatar' />
                  </div>

                  {isDropDown && (
                    <Transition
                      transitionName='fadeIn'
                      transitionAppear={true}
                      transitionAppearTimeout={300}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <ul className={styles.dropdown} ref={this.setDropDownRef}>
                        {userRole === 'Guide' || userRole === 'GuidePending'
                          ? dropGuideItems.map((item, index) => (
                            <li key={index}>
                              <Link to={item.link}>{item.title}</Link>
                            </li>
                          ))
                          : ''}
                        {userRole === 'Client'
                          ? dropClientItems.map((item, index) => (
                            <li key={index}>
                              <Link to={item.link}>{item.title}</Link>
                            </li>
                          ))
                          : ''}
                        {mql !== 'desktop'
                          ? navItems.map((item, index) => (
                            <li key={index}>
                              <Link to={item.link}>{item.title}</Link>
                            </li>
                          ))
                          : null}
                        <li>
                          <button onClick={this.handleLogout}>Signout</button>
                        </li>
                      </ul>
                    </Transition>
                  )}
                </div>
              ) : (
                <>
                  <div className={styles.buttons + ' ' + styles.desktopbtn}>
                    <button onClick={this.handleLogin}>Log in</button>
                    <span>or</span>
                    <button onClick={this.handleSignup}>Sign up</button>
                  </div>
                  {isDropDown && (
                    <Transition
                      transitionName='fadeIn'
                      transitionAppear={true}
                      transitionAppearTimeout={300}
                      transitionEnter={false}
                      transitionLeave={false}
                    >
                      <div className={styles.buttons}>
                        <ul className={styles.dropdown} ref={this.setDropDownRef}>
                          {auth.isAuthenticated() && userRole === 'Guide' && (
                            <li>
                              <Link to={navItems[0].link}>{navItems[0].title}</Link>
                            </li>
                          )}
                          {navItems.map(
                            (item, index) =>
                              index >= 1 && (
                                <li key={index}>
                                  <Link to={item.link}>{item.title}</Link>
                                </li>
                              )
                          )}
                          <li>
                            <button onClick={this.handleLogin}>Log in</button>
                          </li>
                          <li>
                            <button onClick={this.handleSignup}>Sign up</button>
                          </li>
                        </ul>
                      </div>
                    </Transition>
                  )}
                </>
              )}
            </div>
          </div>
        </header>
        {setMenu ? <HomeFilter statusMenu={this.closeMenu} /> : ''}
        {/* <div className={[`${styles.hamburgeMenu}`]}>
          <HomeFilter />
        </div> */}
      </Fragment>
    );
  }
}

/* PropTypes definition */
Header.propTypes = {
  history: PropTypes.object,
  auth: PropTypes.object,
  mql: PropTypes.string,
  location: PropTypes.object,
  getUser: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  user: PropTypes.object,
  getLocations: PropTypes.func.isRequired,
  locations: PropTypes.arrayOf(PropTypes.shape({ country: PropTypes.string })),
};

Header.defaultProps = {
  locations: undefined,
};

const selectors = createStructuredSelector({
  user: userSelectors.userSelector,
  locations: locationsSelectors.locationsSelector,
});

const actions = {
  getUser: userActions.getUser,
  setUser: userActions.setUser,
  getLocations: locationsActions.getLocations,
};

export default connect(selectors, actions)(Header);
