import fp from 'lodash/fp';

export const adventuresAllSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('adventures.data.adventuresByDateRangeCategoryAndLocation')
);

export const adventuresSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('adventures.adventures')
);

export const loadingSelector = fp.compose(fp.get('adventures.loading'));
