import React, { Component } from 'react';

import teamData from '../../mocks/team.json';
import Container from '../../common/container/Container';
import TeamItem from './TeamItem';

import './Team.scss';

class TeamComponent extends Component {
  render() {
    let team = teamData[0].team[0].teamItem;
    return (
      <Container>
        <div className='news-title'>
          <h1>{teamData[0].team[0].title}</h1>
          <p>{teamData[0].team[0].description}</p>
        </div>
        <div className='news-list'>
          {team.map((advertise, i) => (
            <TeamItem key={i} allData={advertise} />
          ))}
        </div>
      </Container>
    );
  }
}

export default TeamComponent;
