/**
 * @file Holds the <AdventurePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import { selectors as PayPalSelectors } from '../redux/modules/PayPal';

import styles from 'styles/pages/Payment.module.scss';

/* Component definition */
class PayPal extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  render() {
    const { token, tokenId } = this.props;
    const url = `https://payflowlink.paypal.com?MODE=TEST&SECURETOKENID=${tokenId}&SECURETOKEN=${token}`;

    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <iframe src={url} name='test_iframe' scrolling='no' width='570px' height='540px' />
        </div>
      </div>
    );
  }
}

/* Prop types definition */
PayPal.propTypes = {
  token: PropTypes.string,
  tokenId: PropTypes.string,
};

const selectors = createStructuredSelector({
  token: PayPalSelectors.tokenSelector,
  tokenId: PayPalSelectors.tokenIdSelector,
});

const actions = {};

export default compose(
  connect(
    selectors,
    actions
  )
)(PayPal);
