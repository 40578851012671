// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest, select, call } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { USER_GET, USER_ID_GET } from '../modules/users/types';

import { getUserDetails } from 'api/userDetails';
import { getUserAuthID } from 'api/user';

import { doGetUserAllInfo } from './GuideFilter';
import { userIdSelector } from '../modules/users/selectors';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doAPICall = id =>
  apiCall({
    type: USER_GET,
    method: 'POST',
    path: '',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: { query: getUserDetails, variables: { id: id } },
  });

const doUserIDAPICall = () =>
  apiCall({
    type: USER_ID_GET,
    method: 'POST',
    path: '',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: { query: getUserAuthID, variables: {} },
  });

const doGetUsers = function*() {
  const id = yield select(userIdSelector);
  yield call(doAPICall(id), { payload: '' });
};

export const doGetUsersID = function*() {
  yield call(doUserIDAPICall(), { payload: '' });
  yield call(doGetUsers, { payload: '' });
  yield call(doGetUserAllInfo, { payload: '' });
};

export default function* rootSaga() {
  yield takeLatest(USER_GET, doGetUsers);
  yield takeLatest(USER_ID_GET, doGetUsersID);
}
