import React, { Component } from 'react';

import * as MetaData from '../mocks/agreement';
import styles from 'styles/pages/GuideAgreementDoc.module.scss';

class GuideAgreementDoc extends Component {
  render() {
    const {
      SCOPE,
      PARTICIPANT_RECITALS,
      PARTICIPANT_ACCEPTABLE_USE_SUBLIST,
      PARTICIPANT_PAYMENT,
      PARTICIPANT_OFAC_DATA,
      SEVERABILITY,
      SURVIVAL,
      PARTICIPANT_INDEMNIFICATION,
      INTEGRATION,
      PARTICIPANT_DISPUTE_DATA,
      BINDING_DATA,
      PARTICIPANT_NOTICE_DATA,
      PARTICIPANT_CONFIDENTIALITY_DATA,
      PARTICIPANT_LAW_DATA,
      PARTICIPANT_ACCEPTABLE_USE_DATA,
      NO_ENDORSEMENT_DATA,
      PARTICIPANT_ACCURATE_INFO,
      PARTICIPANT_EXPENSES,
      PARTICIPANT_LIABILITY_DATA,
      PARTICIPANT_LICENSE_DATA,
    } = MetaData;
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className='privacy_col'>
            <div className='terms_head'>
              <h1>PARTICIPANT AGREEMENT</h1>
            </div>
            <div className={styles.guide_agreement}>
              Please read this Participant Agreement (the “Agreement”) carefully as it contains
              important information about your legal rights, remedies, and obligations. By using the
              GreatGuides Platform, You (“You” or the “Participant”) agree to comply with and be
              bound by this Agreement by and between You and GreatGuides (“GreatGuides”)
              (collectively the “Parties” and each individually a “Party”).
            </div>
            <h1>RECITALS</h1>
            <ul className={styles.privacy_list}>
              {PARTICIPANT_RECITALS.map((data, index) => (
                <li key={index}>
                  <span>{data}</span>
                </li>
              ))}
            </ul>

            <p className='agree_data'>
              NOW THEREFORE, for good and valuable consideration, the Parties agree as follows:
            </p>
            <h2 className={styles.profile_head}>1. Scope of Use.</h2>
            <ul className={styles.privacy_list}>
              {SCOPE.map((data, index) => (
                <li key={index}>
                  {data[0] && (
                    <span className={styles.tag}>
                      <strong>{data[0]} </strong>
                    </span>
                  )}
                  <span className={styles.list_txt}>{data[1]}</span>
                </li>
              ))}
            </ul>

            <div>
              <h2 className={styles.profile_head}>2. Payment Terms.</h2>
              {PARTICIPANT_PAYMENT.map((data, index) => (
                <p
                  key={index}
                  className={index === 0 ? styles.compensation_data : styles.compensation_data_nb}
                >
                  {data}
                </p>
              ))}
            </div>
            <div>
              <h2 className={styles.profile_head}>3. No Endorsement.</h2>
              <p className={styles.compensation_data}>{NO_ENDORSEMENT_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>4. Assumption of Risk and Liability Waiver.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_LIABILITY_DATA.toUpperCase()}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>5. Insurance and License.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_LICENSE_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>6. Expenses.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_EXPENSES}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>7. Accurate Information.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_ACCURATE_INFO}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>8. OFAC Compliance.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_OFAC_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>9. Acceptable Use.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_ACCEPTABLE_USE_DATA}</p>
              <div className={styles.sublst}>
                <ul className={styles.privacy_list}>
                  {PARTICIPANT_ACCEPTABLE_USE_SUBLIST.map((data, index) => (
                    <li key={index}>
                      <span>{data}</span>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div>
              <h2 className={styles.profile_head}>10. Confidentiality. </h2>
              <p className={styles.compensation_data}>{PARTICIPANT_CONFIDENTIALITY_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>11. Governing Law and Venue.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_LAW_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>
                12. Dispute Resolution and Class Action Waiver.
              </h2>
              <p className={styles.compensation_data}>{PARTICIPANT_DISPUTE_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>13. Notice.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_NOTICE_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>14. Binding Agreement and Assignment.</h2>
              <p className={styles.compensation_data}>{BINDING_DATA}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>15. Severability.</h2>
              <p className={styles.compensation_data}>{SEVERABILITY}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>16. Indemnification.</h2>
              <p className={styles.compensation_data}>{PARTICIPANT_INDEMNIFICATION}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>17. Integration; Amendment; No Waiver.</h2>
              <p className={styles.compensation_data}>{INTEGRATION}</p>
            </div>
            <div>
              <h2 className={styles.profile_head}>18. Survival.</h2>
              <p className={styles.compensation_data}>{SURVIVAL}</p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default GuideAgreementDoc;
