import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Autocomplete from 'react-google-autocomplete';

import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import InputForm from '../../common/Form/Input/Input';
import ErrorHandling from '../ErrorHandling';

import Style from '../../styles/pages/CreateAdventure.module.scss';

class AddLocation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      address: props.address,
      longitude: props.longitude,
      latitude: props.latitude,
      errorType: '',
    };
  }
  nextStep = () => {
    const { nextStep, setAdventureFilter } = this.props;
    const { address, longitude, latitude } = this.state;

    setAdventureFilter({
      ['address']: address,
      ['longitude']: longitude,
      ['latitude']: latitude,
    });

    if (this.checkValue() === '') {
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  checkValue = () => {
    const { address, longitude, latitude } = this.state;

    let errorType = '';

    if (!address && address === '') {
      errorType = errorType.concat('address');
    }
    if (!longitude) {
      errorType = errorType.concat('longitude');
    }
    if (!latitude) {
      errorType = errorType.concat('latitude');
    }

    this.setState({ errorType });
    return errorType;
  };

  handleChangeInput = (e, type) => {
    switch (type) {
    case 'longitude':
      this.setState({ longitude: e.target.value });
      break;
    case 'latitude':
      this.setState({ latitude: e.target.value });
      break;
    default:
      break;
    }
  };

  handleInputPlace = place => {
    this.setState({
      latitude: place.geometry.location.lat(),
      longitude: place.geometry.location.lng(),
      address: place.address_components[0].long_name,
    });
  };

  render() {
    const { address, longitude, latitude, errorType } = this.state;
    return (
      <div className={Style.add_location}>
        <div className={Style.header_title}>
          <h2>Adventure Location</h2>
          <p>Where is your Adventure located</p>
          <div className={Style.addressLabel}> What is your Adventure Address?</div>
          <Autocomplete
            style={{
              border: '1px solid #e6e6e6',
              height: '53px',
              borderRadius: '4px',
              width: '100%',
              paddingLeft: '15px',
              marginTop: '15px',
              marginBottom: '20px',
            }}
            onPlaceSelected={place => {
              this.handleInputPlace(place);
            }}
            types={['(regions)']}
            defaultValue={address}
          />
          {errorType.includes('address') ? <ErrorHandling category='Address' /> : ''}
          <InputForm
            type='text'
            placeholder='Latitude of your place'
            labelText='Latitude'
            labelFor='latitude'
            inputVal={latitude}
            borderColor={errorType.includes('latitude') ? 'errorBorder' : ''}
            onChange={this.handleChangeInput}
          />
          {errorType.includes('latitude') ? <ErrorHandling category='Latitude' /> : ''}
          <InputForm
            type='text'
            placeholder='Longitude of your place'
            labelText='Longitude'
            labelFor='longitude'
            inputVal={longitude}
            borderColor={errorType.includes('longitude') ? 'errorBorder' : ''}
            onChange={this.handleChangeInput}
          />
          {errorType.includes('longitude') ? <ErrorHandling category='Longitude' /> : ''}
        </div>
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
      </div>
    );
  }
}

AddLocation.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  address: PropTypes.string,
  longitude: PropTypes.any,
  latitude: PropTypes.any,
};

const selectors = createStructuredSelector({
  address: createAdventureSelectors.addressSelector,
  longitude: createAdventureSelectors.longitudeSelector,
  latitude: createAdventureSelectors.latitudeSelector,
});

const actions = {
  setAdventureFilter: createAdventureActions.setAdventureFilter,
};

export default connect(
  selectors,
  actions
)(AddLocation);
