import React, { Component } from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { graphql, withApollo } from 'react-apollo';
import { getUserAuthID } from 'api/user';
import { createStructuredSelector } from 'reselect';
import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../redux/modules/SendContact';
import ResponceModal from '../common/modals/ResponceModal';

import data from '../mocks/ladakh.json';
import Hero from '../components/Ladakh/Hero/Hero';
import Ladakh from '../components/Ladakh/adventures/Adventures';
import Question from '../components/Adventures/questions/Questions';
import Footer from '../components/Adventures/footer/Footer';
import '../styles/Ladakh.scss';
/* Component adventures */
class AdventuresLadakh extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloader: false,
      initial: true,
      adventure: {},
      selectedDays: [],
      yearAndMonthToOpen: '',
      tripMessage: 'test',
      numberOfTravelers: '',
      isModalVisible: false,
      isParticipantAgree: true,
      isOpenSendMessageModal: false,
      isOpenBookNowModal: false,
      name: '',
      email: '',
      bookDescription: 'I would like to book a trip to ',
      sendMessageError: false,
      openBookTourModal: false,
      errorName: '',
      dateRange: '01/04/2019',
      questName: '',
      questEmail: '',
      QuestTripMessage: '',
      adventureTitle: '',
      bookTitle: '',
      startDate: null,
      departureDate: null,
      advantureDates: null,
    };
    this.sendContact = this.sendContact.bind(this);
  }
  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  handleDateChange = date => {
    const moment = require('moment');

    this.setState({
      startDate: date,
      departureDate: date && moment(date).format('DD MMM YYYY'),
    });
  };
  handleSendMessageModal = () => {
    this.setState({
      isOpenSendMessageModal: false,
    });
  };
  renderSendMessageModal = title => {
    const { name, email, bookDescription, bookTitle, sendMessageError } = this.state;

    if (title) {
      this.setState({
        isOpenSendMessageModal: true,
        bookTitle: bookDescription + ' ' + title,
      });
    }

    return (
      <div className='send_message'>
        <div className='send_center_div'>
          <input
            type='button'
            onClick={this.handleSendMessageModal}
            value={'X'}
            className='cancelText'
          />
          <h2>Contact Detail. </h2>
          <div className='noticePara'>
            <p>We need some contact information to confirm your request.</p>
          </div>
          <div className='form-group adventureBook person_no'>
            <input
              type='text'
              min={1}
              name='name'
              placeholder='Your Name'
              value={name}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group adventureBook person_no emailField'>
            <input
              type='text'
              min={1}
              name='email'
              placeholder='Email'
              value={email}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group adventureBook adventureBook person_no emailField'>
            <textarea
              min={1}
              name='bookDescription'
              className='bookDescription'
              placeholder='Enter num of travelers and desired dates for tour'
              value={bookTitle + '.'}
              onChange={this.handleChange}
            />
          </div>
          {sendMessageError && (
            <p className='error_text'>Please enter information in all 3 fields</p>
          )}
          <div className='request_btn'>
            <input
              type='button'
              value='Send Request'
              className='btn orangebtn pointer_event'
              onClick={() => this.sendContact()}
            />
          </div>
        </div>
      </div>
    );
  };

  sendContact = () => {
    const { sendContact } = this.props;
    const {
      name,
      email,
      bookTitle,
      departureDate,
      numberOfTravelers,
      isOpenBookNowModal,
    } = this.state;
    if (
      name === '' ||
      email === '' ||
      bookTitle === '' ||
      (isOpenBookNowModal && (numberOfTravelers == '' || departureDate == null))
    ) {
      this.setState({
        sendMessageError: true,
      });
    } else {
      if (isOpenBookNowModal) {
        sendContact({
          name: name,
          email: email,
          departureDate: departureDate,
          numberOfTravelers: numberOfTravelers,
          tripMessage: bookTitle,
        });
      } else {
        sendContact({
          name: name,
          email: email,
          tripMessage: bookTitle,
        });
      }
      this.setState({
        sendMessageError: false,
        isModalVisible: true,
        isOpenSendMessageModal: false,
        isOpenBookNowModal: false,
      });
    }
  };

  handleBookNowModal = () => {
    this.setState({
      isOpenBookNowModal: false,
    });
  };
  renderBookNowModal = (title, dates) => {
    const {
      name,
      email,
      bookDescription,
      bookTitle,
      numberOfTravelers,
      sendMessageError,
      startDate,
      departureDate,
      advantureDates,
    } = this.state;

    if (title) {
      this.setState({
        isOpenBookNowModal: true,
        bookTitle: bookDescription + ' ' + title,
      });
    }

    if (dates) {
      this.setState({
        advantureDates: dates,
      });
    }

    const datepicker = dates => {
      return (
        <DatePicker
          selected={startDate}
          onChange={e => this.handleDateChange(e)}
          value={departureDate}
          includeDates={dates}
          dateFormat='DD MMM YYYY'
          placeholderText='Choose fixed start date’'
        />
      );
    };

    return (
      <div className='send_message'>
        <div className='send_center_div'>
          <input
            type='button'
            onClick={this.handleBookNowModal}
            value={'X'}
            className='cancelText'
          />
          <h2>Contact Detail. </h2>
          <div className='noticePara'>
            <p>We need some contact information to confirm your request.</p>
          </div>
          <div className='form-group adventureBook person_no'>
            <input
              type='text'
              min={1}
              name='name'
              placeholder='Your Name'
              value={name}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group adventureBook person_no emailField'>
            <input
              type='text'
              min={1}
              name='email'
              placeholder='Email'
              value={email}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group adventureBook person_no emailField'>
            <textarea
              min={1}
              name='bookDescription'
              className='bookDescription'
              placeholder='Enter num of travelers and desired dates for tour'
              value={bookTitle + '.'}
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group adventureBook person_no'>{datepicker(advantureDates)}</div>
          <div className='form-group adventureBook person_no emailField'>
            <input
              type='number'
              min={0}
              name='numberOfTravelers'
              placeholder='Number of travelers'
              value={numberOfTravelers}
              onChange={this.handleChange}
            />
          </div>
          {sendMessageError && (
            <p className='error_text'>Please enter information in all 3 fields</p>
          )}
          <div className='request_btn'>
            <input
              type='button'
              value='Book Now'
              className='btn orangebtn pointer_event'
              onClick={() => this.sendContact()}
            />
          </div>
        </div>
      </div>
    );
  };

  render() {
    const { isOpenSendMessageModal, isModalVisible, isOpenBookNowModal } = this.state;

    return (
      <div>
        {isOpenSendMessageModal && this.renderSendMessageModal()}
        {isOpenBookNowModal && this.renderBookNowModal()}
        {isModalVisible &&
          (this.state.questName.length > 0 ? (
            <ResponceModal
              heading='Thank for your message request.'
              para='We will respond within 24 hours.'
            />
          ) : (
            <ResponceModal
              heading='Thank for your booking request.'
              para='We will respond within 24 hours.'
            />
          ))}
        <Hero data={data} openSendMessageModal={this.renderSendMessageModal} />
        <Ladakh
          data={data}
          openSendMessageModal={this.renderSendMessageModal}
          openBookNowModal={this.renderBookNowModal}
        />
        <Question />
        <Footer />
      </div>
    );
  }
}

AdventuresLadakh.propTypes = {
  sendContact: PropTypes.func.isRequired,
  renderSendMessageModal: PropTypes.func,
};

const userId = {
  name: 'loggedInUser',
  // options for the initial query
  options() {
    return {
      header: {
        Authorization: `Bearer ${localStorage.id_token}`,
      },
      variables: {},
    };
  },
  // convert the ugly apollo results into custom props
  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

const selectors = createStructuredSelector({
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
});

const actions = {
  sendContact: sendContactActions.sendContact,
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(
    selectors,
    actions
  )
)(AdventuresLadakh);
