import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { graphql, withApollo } from 'react-apollo';
import { getUserAuthID } from 'api/user';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';

import bgPattern from '../../../assets/adventures/bg_pattern.png';
import './Questions.scss';

import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../../../redux/modules/SendContact';

class Questions extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: null,
      email: null,
      message: null,
      errorName: null,
      isError: null,
      showModal: false,
    };
    this.sendContact = this.sendContact.bind(this);
  }

  handleChange = e => {
    this.setState({
      [e.target.name]: e.target.value,
    });
  };
  sendContact = () => {
    const { sendContact } = this.props;
    const { name, email, message } = this.state;
    if (name === null && email === null && message === null) {
      this.setState({
        errorName: true,
      });
    } else {
      this.setState({
        errorName: false,
        showModal: true,
      });
      sendContact({
        name: this.state.name,
        email: this.state.email,
        tripMessage: this.state.message,
      });
    }
  };
  render() {
    let bgImage = {
      backgroundImage: 'url(' + bgPattern + ')',
    };
    const { errorName } = this.state;
    return (
      <div className='questions' style={bgImage}>
        {this.state.showModal ? (
          <div className='signup_popup'>
            <div className='center_div'>
              <h2>Thank for your message request.</h2>
              <p>We will respond within 24 hours.</p>
              <div className='text-center'>
                <Link to='/' className='guide_btn'>
                  OK
                </Link>
              </div>
            </div>
          </div>
        ) : null}
        <h2 className='subTitle'>Have any questions?</h2>
        <p className='smallDescription'>Contact us through this form:</p>
        {errorName && <p className='error_text'>Name, Email and Message are required fields</p>}
        <form>
          <div className='form-group'>
            <input
              className='form-controle'
              type='email'
              name='email'
              placeholder='Your Email'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <input
              className='form-controle'
              type='text'
              name='name'
              placeholder='Your Name'
              onChange={this.handleChange}
            />
          </div>
          <div className='form-group'>
            <textarea
              className='form-controle'
              placeholder='Your message'
              name='message'
              onChange={this.handleChange}
            />
          </div>
          <input
            type='button'
            value='Send a message'
            className='full-orange-btn btn'
            onClick={this.sendContact}
          />
        </form>
      </div>
    );
  }
}

Questions.propTypes = {
  sendContact: PropTypes.func.isRequired,
};

const userId = {
  name: 'loggedInUser',
  // options for the initial query
  options() {
    return {
      header: {
        Authorization: `Bearer ${localStorage.id_token}`,
      },
      variables: {},
    };
  },
  // convert the ugly apollo results into custom props
  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

const selectors = createStructuredSelector({
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
});

const actions = {
  sendContact: sendContactActions.sendContact,
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(
    selectors,
    actions
  )
)(Questions);
