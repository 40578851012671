import { createAction } from 'redux-actions';

import {
  ADVENTURE_CREATE,
  ADVENTURE_UPDATE,
  ADVENTURE_FILTER_SET,
  ADVENTURE_FILTER_CLEAR,
  ADVENTURE_ID_SET,
  ADVENTURE_IMAGE_UPLOAD,
  ADVENTURE_IMAGE_REMOVE,
} from './types';

export const createAdventure = createAction(ADVENTURE_CREATE);

export const updateAdventure = createAction(ADVENTURE_UPDATE);

export const uploadAdventureMedia = createAction(ADVENTURE_IMAGE_UPLOAD);

export const removeAdventureMedia = createAction(ADVENTURE_IMAGE_REMOVE);

export const setAdventureFilter = createAction(ADVENTURE_FILTER_SET);

export const clearAdventureFilter = createAction(ADVENTURE_FILTER_CLEAR);

export const setAdventureID = createAction(ADVENTURE_ID_SET);
