// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { CONTACT_SEND } from '../modules/SendContact/types';

import { getActivelymeAPIEnvUrl } from 'utils/initializers';

export const doSendContact = apiCall({
  type: CONTACT_SEND,
  method: 'POST',
  baseURL: getActivelymeAPIEnvUrl(),
  path: '/user/v1/users/email',
  body: ({ payload }) => {
    return {
      message: `Message => ${payload.tripMessage},
      Subject => ${!process.env.NODE_ENV || process.env.NODE_ENV === 'development' ? 'TEST' : ''},
      Email => ${payload.email === undefined ? '' : payload.email}, 
      Name => ${payload.name === undefined ? '' : payload.name}, 
      Number Of Travelers => ${
  payload.numberOfTravelers === undefined ? '' : payload.numberOfTravelers
},
      Date => ${payload.departureDate === undefined ? '' : payload.departureDate},`,
    };
  },
});

export default function* rootSaga() {
  yield takeLatest(CONTACT_SEND, doSendContact);
}
