import React from 'react';
import PropTypes from 'prop-types';

import '../styles/components/react_dates_overridesHome.scss';
import '../styles/components/grid_overrides.scss';
import { span } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { compose } from 'redux';

import icon1 from '../assets/locations/rwanda.svg';
// import icon2 from '../assets/locations/uganda.svg';
import icon3 from '../assets/locations/south-africa.svg';
import icon4 from '../assets/locations/zimbabwe.svg';
import icon5 from '../assets/locations/india.svg';
import icon6 from '../assets/locations/peru.svg';
import icon7 from '../assets/locations/norway.svg';
// import icon8 from '../assets/locations/italy.svg';
import iconEthiopia from '../assets/locations/ethiopia.svg';
// import iconCroatia from '../assets/locations/croatia.svg';
import iconBhutan from '../assets/locations/bhutan.svg';
// import iconPortugal from '../assets/locations/portugal.svg';
//import iconMadagascar from '../assets/locations/madagascar.svg';
// import iconGabon from '../assets/locations/gabon.svg';
import iconFinland from '../assets/locations/finland.svg';
// import iconMalaysia from '../assets/locations/malaysia.svg';
import iconIceland from '../assets/locations/iceland.svg';
import iconMongolia from '../assets/locations/mongolia.svg';
import iconGreenland from '../assets/locations/greenland.svg';
import iconGalapagos from '../assets/locations/galapagos-islands.svg';
import iconPanama from '../assets/locations/panama.svg';

/* Component definition */
class HomeLocations extends React.Component {
  onFind(value) {
    const { changePage } = this.props;
    changePage({ location: value });
  }

  render() {
    return (
      <div className='home-locations'>
        <div className='location-header'>
          <h2>Destinations</h2>
          <div className='location-text'>
            <hr className='short_hr' />
            <p>
              We have guides leading trips across the world . Select one country and explore it!
            </p>
          </div>
        </div>
        <ul>
          <li>
            <span onClick={() => this.onFind('Norway')}>
              <img src={icon7} alt='logo' />
              <div>
                <h3>Norway</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Iceland')}>
              <img src={iconIceland} alt='logo' />
              <div>
                <h3>Iceland</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Finland')}>
              <img src={iconFinland} alt='logo' />
              <div>
                <h3>Finland</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Greenland')}>
              <img src={iconGreenland} alt='logo' />
              <div>
                <h3>Greenland</h3>
                {/* <span>8 Adventures</span> */}
              </div>
              {/* <img src={rightArrow} className='rightArrow' alt='right arrow' /> */}
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('South Africa')}>
              <img src={icon3} alt='logo' />
              <div>
                <h3>South Africa</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Zimbabwe')}>
              <img src={icon4} alt='logo' />
              <div>
                <h3>Zimbabwe</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Rwanda')}>
              <img src={icon1} alt='logo' />
              <div>
                <h3>Rwanda</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Ethiopia')}>
              <img src={iconEthiopia} alt='logo' />
              <div>
                <h3>Ethiopia</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>

          <li>
            <span onClick={() => this.onFind('India')}>
              <img src={icon5} alt='logo' />
              <div>
                <h3>India</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Mongolia')}>
              <img src={iconMongolia} alt='logo' />
              <div>
                <h3>Mongolia</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Bhutan')}>
              <img src={iconBhutan} alt='logo' />
              <div>
                <h3>Bhutan</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Peru')}>
              <img src={icon6} alt='logo' />
              <div>
                <h3>Peru</h3>
                {/* <span>8 Adventures</span> */}
              </div>
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Galápagos')}>
              <img src={iconGalapagos} alt='logo' />
              <div>
                <h3>Galápagos</h3>
                {/* <span>8 Adventures</span> */}
              </div>
              {/* <img src={rightArrow} className='rightArrow' alt='right arrow' /> */}
            </span>
          </li>
          <li>
            <span onClick={() => this.onFind('Panama')}>
              <img src={iconPanama} alt='logo' />
              <div>
                <h3>Panama</h3>
                {/* <span>8 Adventures</span> */}
              </div>
              {/* <img src={rightArrow} className='rightArrow' alt='right arrow' /> */}
            </span>
          </li>
        </ul>
      </div>
    );
  }
}

HomeLocations.propTypes = {
  locations: PropTypes.array.isRequired,
  setAdventuresFilter: PropTypes.func.isRequired,
  changePage: PropTypes.func.isRequired,
};

export default compose(withRouter, connect())(HomeLocations);
