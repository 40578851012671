import React, { Component } from 'react';
import PropTypes from 'prop-types';

import './Input.scss';

class Input extends Component {
  render() {
    const {
      borderColor,
      imgUrl,
      type,
      placeholder,
      customClass,
      labelFor,
      labelText,
      inputVal,
      onChange,
    } = this.props;
    let bgImage = {
      backgroundImage: 'url(' + imgUrl + ')',
    };
    return (
      <div className='form-group'>
        <label htmlFor={labelFor}>{labelText}</label>
        <input
          type={type}
          id={labelFor}
          placeholder={placeholder}
          className={customClass + ' form-controle ' + borderColor}
          value={inputVal}
          style={bgImage}
          onChange={e => onChange(e, labelFor)}
        />
      </div>
    );
  }
}

Input.propTypes = {
  borderColor: PropTypes.string,
  imgUrl: PropTypes.string,
  type: PropTypes.string,
  placeholder: PropTypes.string,
  customClass: PropTypes.string,
  labelFor: PropTypes.string,
  labelText: PropTypes.string,
  inputVal: PropTypes.any,
  onChange: PropTypes.func,
};

export default Input;
