/**
 * @file Holds the <App> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React, { Component } from 'react';
import { withRouter } from 'react-router';
import { ApolloProvider } from 'react-apollo';
import ApolloClient from 'api';
import Routes from 'components/Routes';
import auth from 'utils/auth';
import { errorHandleWindow } from 'utils/error';
import PropTypes from 'prop-types';

/* Component definition */
class App extends Component {

  componentDidMount() {
    this.renewAuthSession();
    errorHandleWindow();
  }

  renewAuthSession() {
    const { history } = this.props;
    if (localStorage.isLoggedIn === 'true') {
      auth.renewSession(history);
    }
  }

  render() {
    return (
      <ApolloProvider client={ApolloClient}>
        <Routes/>
      </ApolloProvider>
    );
  }
}

/* PropTypes definition */
App.propTypes = {
  history: PropTypes.object,
};

export default withRouter(App);
