import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';

import { GUIDE_All_FILTER_SET, GUIDE_FILTER_SET, GUIDE_FILTER_CLEAR } from './types';

// var moment = require('moment');

const initialState = {
  fullName: '',
  handle: '',
  profilePictureUrl: '',
  profilePictureBase64: '',
  isUserActive: '',
  isEmailVerified: '',
  userRole: '',
  emailAddress: '',
  profile: '',
  gender: '',
  externalProfileLink: '',
  phoneNumber: '',
  businessName: '',
  businessAddress: '',
  website: '',
  youtubeChannel: '',
  otherQualifications: '',
  technicalSkills: '',
  firstAidTraining: '',
  customerServiceSkills: '',
  expertise: '',
  sustainableStandards: '',
  certificate: '',
  aboutMe: '',
  participantAgree: '',
  guideAgree: '',
  flagIcon: '',
  tripAdvisor: '',
  region: '',
  firstName: '',
  lastName: '',
  city: '',
  state: '',
  country: '',
  zip: '',
  extra: {},
  memberships: {},
  categories: {},
  adventures: {},
  GuideExp: '',
  LastTripTitle: '',
  LastTripDate: '',
  LastTripCompany: '',
  GuideLic: '',
  GuideLicLink: [],
  GuidePermit: '',
  GuideNoTrace: [],
  GuideFirstAid: [],
  GuideInsurance: [],
  GuideTripLink: '',
  data: null,
  status: null,
  error: null,
};

export default handleActions(
  {
    [requestPending(GUIDE_All_FILTER_SET)]: state => ({
      ...state,
      data: null,
      status: requestPending(GUIDE_All_FILTER_SET),
      error: false,
    }),

    [requestSuccess(GUIDE_All_FILTER_SET)]: (state, { payload }) => {
      const extra = payload.data.user.extra;
      return {
        ...state,
        ...payload.data.user,
        GuideExp: extra && extra.GuideExp !== undefined ? extra.GuideExp : '',
        LastTripTitle:
          extra && typeof extra.LastTripTitle !== 'undefined' ? extra.LastTripTitle : '',
        LastTripDate: extra && typeof extra.LastTripDate !== 'undefined' ? extra.LastTripDate : '',
        LastTripCompany:
          extra && typeof extra.LastTripCompany !== 'undefined' ? extra.LastTripCompany : '',
        GuideLic: extra && typeof extra.GuideLic !== 'undefined' ? extra.GuideLic : '',
        GuideLicLink: extra && typeof extra.GuideLicLink !== 'undefined' ? extra.GuideLicLink : [],
        GuidePermit: extra && typeof extra.GuidePermit !== 'undefined' ? extra.GuidePermit : '',
        GuideNoTrace: extra && typeof extra.GuideNoTrace !== 'undefined' ? extra.GuideNoTrace : [],
        GuideFirstAid:
          extra && typeof extra.GuideFirstAid !== 'undefined' ? extra.GuideFirstAid : [],
        GuideInsurance:
          extra && typeof extra.GuideInsurance !== 'undefined' ? extra.GuideInsurance : [],
        GuideTripLink:
          extra && typeof extra.GuideTripLink !== 'undefined' ? extra.GuideTripLink : '',
        status: requestSuccess(GUIDE_All_FILTER_SET),
        error: null,
      };
    },

    [requestFail(GUIDE_All_FILTER_SET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(GUIDE_All_FILTER_SET),
      error: true,
    }),
    [GUIDE_FILTER_SET]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [GUIDE_FILTER_CLEAR]: state => ({
      ...state,
      status: null,
      error: null,
      message: null,
    }),
  },
  initialState
);
