export const S3_CONFIG_LICENSE = {
  bucketName: 'activelyme-prod-media',
  region: 'us-west-2',
  dirName: 'GuideLicense',
  accessKeyId: 'AKIAZ4L6E3MQIVZH2LKE',
  secretAccessKey: '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',
};

export const S3_CONFIG_TRACE_CERTIFIACTE = {
  bucketName: 'activelyme-prod-media',
  region: 'us-west-2',
  dirName: 'GuideTraceCerticate',
  accessKeyId: 'AKIAZ4L6E3MQIVZH2LKE',
  secretAccessKey: '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',
};

export const S3_CONFIG_CPR_CERTICATE = {
  bucketName: 'activelyme-prod-media',
  region: 'us-west-2',
  dirName: 'GuideCPRCertificate',
  accessKeyId: 'AKIAZ4L6E3MQIVZH2LKE',
  secretAccessKey: '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',
};

export const S3_CONFIG_TRIP_INSURANCE = {
  bucketName: 'activelyme-prod-media',
  region: 'us-west-2',
  dirName: 'GuideTripInsurance',
  accessKeyId: 'AKIAZ4L6E3MQIVZH2LKE',
  secretAccessKey: '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',
};
