// import { put, takeLatest } from 'redux-saga/effects'
import { call, select, put, takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { ADVENTURES_ID_GET, ADVENTURES_ID_EDIT } from '../modules/AdventuresID/types';
import { setAdventureFilter, setAdventureID } from '../modules/CreateAdventure/actions';
import { getAdventureById } from 'api/queries';

import { adventuresByIdSelector } from '../modules/AdventuresID/selectors';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doAPICall = id => {
  return apiCall({
    type: ADVENTURES_ID_GET,
    method: 'POST',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    path: '',
    body: { query: getAdventureById, variables: { id: id } },
    payloadOnSuccess: (responseData, action) => {
      responseData.isLoadMore = action.payload.isLoadMore;
      return responseData;
    },
  });
};
export const doGetAdventureById = function*(params) {
  yield call(doAPICall(params.payload.id), { payload: '' });
};

const doEditAdventureById = function*(params) {
  yield put(setAdventureID({ adventureID: null }));
  yield call(doAPICall(params.payload.id), { payload: { isLoadMore: false } });

  const adventure = yield select(adventuresByIdSelector);
  yield put(setAdventureFilter(adventure));
};

export default function* rootSaga() {
  yield takeLatest(ADVENTURES_ID_GET, doGetAdventureById);
  yield takeLatest(ADVENTURES_ID_EDIT, doEditAdventureById);
}
