/**
 * @file Holds the <BookRequest> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */

import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Icon from 'components/Icon';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import ContactDetail from 'components/AdventureDetails/ContactDetail';
import GuidePersonalInfo from 'components/GuidePersonalInfo';
import 'react-datepicker/dist/react-datepicker.css';

import styles from 'styles/components/BookRequest.module.scss';
import 'styles/components/BookRequestCalendar.overrides.scss';
/* Component definition */
class BookRequest extends React.Component {
  state = {
    startDate: null,
    name: null,
    email: null,
    departureDate: null,
    numberOfTravelers: '',
    tripMessage: '',
    isParticipantAgree: true,
    isMobileBookAdventure: false,
    isBookingForm: false,
    errorName: null,
    isError: false,
  };

  componentDidMount() {
    const { guide, title } = this.props;

    const tripMessage = `Hi ${
      guide.fullName === undefined ? 'Guider' : guide.fullName
    }. I would like to book a trip to ${title}.`;

    this.setState({ tripMessage: tripMessage });
  }

  showBookAdventure = () => {
    this.setState({ isMobileBookAdventure: true });
  };

  closeBookForm = () => {
    this.setState({ isMobileBookAdventure: false });
  };

  checkBookFormData = () => {
    const { departureDate, numberOfTravelers, tripMessage, isParticipantAgree } = this.state;

    if (!departureDate) {
      return { errorName: 'departureDate', isError: true };
    }
    if (!numberOfTravelers) {
      return { errorName: 'numberOfTravelers', isError: true };
    }
    if (!tripMessage) {
      return { errorName: 'tripMessage', isError: true };
    }
    if (!isParticipantAgree) {
      return { errorName: 'isParticipantAgree', isError: true };
    }
    return { isError: false };
  };

  checkBookFormPaymentData = () => {
    const { departureDate, numberOfTravelers } = this.state;

    if (!departureDate) {
      return { errorName: 'departureDate', isError: true };
    }
    if (!numberOfTravelers) {
      return { errorName: 'numberOfTravelers', isError: true };
    }
    return { isError: false };
  };

  handleChange = date => {
    const moment = require('moment');

    this.setState({
      error: false,
      startDate: date,
      departureDate: date && moment(date).format('DD MMM YYYY'),
    });
  };

  handleInputMessage = e => {
    this.setState({ tripMessage: e.target.value });
  };

  isParticipantAgree = () => {
    this.setState({ isParticipantAgree: !this.state.isParticipantAgree });
  };

  handleInputTravelers = e => {
    this.setState({ numberOfTravelers: e.target.value });
  };

  handleBook = () => {
    const { handleBook } = this.props;
    const { departureDate, numberOfTravelers, tripMessage, isParticipantAgree } = this.state;
    const loggedInUser = localStorage.isLoggedIn;

    const isValidated = this.checkBookFormData();

    if (isValidated.isError) {
      this.setState({ errorName: isValidated.errorName, isError: isValidated.isError });
      return;
    }

    handleBook(loggedInUser, departureDate, numberOfTravelers, tripMessage, isParticipantAgree);

    this.setState({ isMobileBookAdventure: false });
  };

  handleBookToPayment = () => {
    const { sendContactDetail } = this.props;
    const {
      name,
      email,
      departureDate,
      numberOfTravelers,
      tripMessage,
      isBookingForm,
    } = this.state;
    const isValidated = this.checkBookFormData();

    if (isValidated.isError) {
      this.setState({ errorName: isValidated.errorName, isError: isValidated.isError });
      return;
    }
    let tripMess = this.props.adventureTitle + '- ' + tripMessage;
    sendContactDetail(name, email, tripMess, departureDate, numberOfTravelers, isBookingForm);

    this.setState({ isMobileBookAdventure: false });
  };

  sendContactDetail = (name, email, tripMessage, isBookingForm) => {
    const { sendContactDetail } = this.props;
    if (isBookingForm) {
      this.setState({
        name: name,
        email: email,
        tripMessage: tripMessage,
        isBookingForm: isBookingForm,
      });
    } else {
      let tripMess = this.props.adventureTitle + '- ' + tripMessage;
      sendContactDetail(name, email, tripMess);
      this.setState({ isBookingForm: isBookingForm });
    }
  };
  render() {
    const { guide, dates, closeContact } = this.props,
      {
        isBookingForm,
        isMobileBookAdventure,
        tripMessage,
        numberOfTravelers,
        isParticipantAgree,
        errorName,
      } = this.state;

    const datepicker = dates => {
      return (
        <DatePicker
          className={styles.form_control}
          selected={this.state.startDate}
          onChange={e => this.handleChange(e)}
          value={this.state.departureDate}
          includeDates={dates}
          dateFormat='DD MMM YYYY'
          placeholderText='Choose fixed start date'
        />
      );
    };

    return (
      <div className={styles.wrapper}>
        <div
          className={
            isMobileBookAdventure
              ? styles.mobileBookForm
              : `${styles.desktopBookForm} ${
                !localStorage.isLoggedIn ? styles.contactDesktopBody : ''
              }`
          }
        >
          <div
            className={
              isMobileBookAdventure
                ? `${styles.mobileBody} ${!localStorage.isLoggedIn ? styles.contactMobileBody : ''}`
                : ''
            }
          >
            <input
              className={styles.cancel}
              type='button'
              onClick={() => this.closeBookForm()}
              value='X'
            />
            <header>
              <div className={styles.avatar}>
                <img src={guide.profilePictureUrl} alt='avatar' />
              </div>
              <div className={styles.guide}>
                <span>{guide.fullName}</span>
                <GuidePersonalInfo guide={guide} />
              </div>
            </header>
            <div className={styles.content}>
              {!(localStorage.isLoggedIn || isBookingForm) && (
                <ContactDetail
                  sendContactDetail={this.sendContactDetail}
                  closeContact={closeContact}
                  adventureTitle={this.props.adventureTitle}
                  dates={dates}
                />
              )}
              {(localStorage.isLoggedIn || isBookingForm) && (
                <React.Fragment>
                  <div className={styles.input}>
                    <Icon name='season' />
                    {datepicker(dates)}
                    <Icon name='arrow-down' className={styles.arrow_down} />
                  </div>
                  {errorName === 'departureDate' && (
                    <p className={styles.errorText}>Date is Required </p>
                  )}
                  <div className={styles.input}>
                    <Icon name='persons' />
                    <input
                      className={styles.travelersInput}
                      type='number'
                      min={0}
                      name='numberOfTravelers'
                      placeholder='Number of travelers'
                      onChange={e => this.handleInputTravelers(e)}
                      required
                      value={numberOfTravelers}
                    />
                    {/* <Icon name='arrow-down' className={styles.arrow_down} /> */}
                  </div>
                  {errorName === 'numberOfTravelers' && (
                    <p className={styles.errorText}>Number of Person is Required</p>
                  )}
                  {isBookingForm && (
                    <button onClick={() => this.handleBookToPayment()}>BOOK NOW</button>
                  )}
                  {!isBookingForm && (
                    <div>
                      <div className={`${styles.input} ${styles.inputMessage}`}>
                        <Icon name='messages' />
                        <textarea
                          className={styles.messageBox}
                          value={tripMessage}
                          onChange={e => this.handleInputMessage(e)}
                          name='tripMessage'
                        />
                      </div>
                      {errorName === 'tripMessage' && (
                        <p className={styles.errorText}>Trip Message is Required </p>
                      )}
                      <button onClick={() => this.handleBook()}>Plan your adventure</button>
                      <div className={styles.terms}>
                        <label className='checkbox'>
                          I accept the <Link to='/participantAgreement'>Great Guides traveler</Link>{' '}
                          terms.
                          <input
                            type='checkbox'
                            className='checkboxInput'
                            checked={isParticipantAgree}
                            onChange={this.isParticipantAgree}
                          />
                          <span className='checkmark' />
                        </label>
                      </div>
                      {errorName === 'isParticipantAgree' && (
                        <p className={styles.errorText}>Accept Great Guides Traveler for Booking</p>
                      )}
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>
          </div>
        </div>
        <div className={styles.mobileFooter}>
          <div className={styles.avatar}>
            <img src={guide.profilePictureUrl} alt='avatar' />
          </div>
          <div className={styles.fullName}>{guide.fullName}</div>
          <div className={styles.bookTour}>
            <button onClick={() => this.showBookAdventure()}>Request Information</button>
          </div>
        </div>
      </div>
    );
  }
}

/* PropTypes definition */
BookRequest.propTypes = {
  guide: PropTypes.object.isRequired,
  dates: PropTypes.array.isRequired,
  handleBook: PropTypes.func.isRequired,
  title: PropTypes.string,
  sendContactDetail: PropTypes.func.isRequired,
  closeContact: PropTypes.func.isRequired,
  adventureTitle: PropTypes.string,
};

export default compose(withRouter)(BookRequest);
