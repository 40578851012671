import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../api/request';
import {
  ADVENTURE_CREATE,
  ADVENTURE_UPDATE,
  ADVENTURE_FILTER_SET,
  ADVENTURE_FILTER_CLEAR,
  ADVENTURE_ID_SET,
  ADVENTURE_IMAGE_UPLOAD,
  ADVENTURE_IMAGE_REMOVE,
} from './types';

const initialState = {
  title: '',
  categories: '',
  difficulty: {},
  season: {},
  minimumAge: null,
  distance: null,
  maxParticipants: null,
  minParticipants: null,
  price: null,
  pricePerPerson: null,
  pricePerGroup: null,
  address: '',
  longitude: null,
  latitude: null,
  dates: [],
  durationInDays: null,
  description: '',
  requirements: '',
  itinerary: '',
  advisory: '',
  inclusions: '',
  exclusions: '',
  directions: '',
  highlights: '',
  data: null,
  adventureID: null,
  media: [],
  status: null,
  loading: false,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(ADVENTURE_CREATE)]: state => ({
      ...state,
      data: null,
      status: requestPending(ADVENTURE_CREATE),
      loading: true,
      error: false,
      message: '',
    }),

    [requestSuccess(ADVENTURE_CREATE)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(ADVENTURE_CREATE),
        data: payload,
        loading: false,
        adventureID: payload.id,
        error: null,
      };
    },

    [requestFail(ADVENTURE_CREATE)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(ADVENTURE_CREATE),
      loading: false,
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),

    [requestPending(ADVENTURE_UPDATE)]: state => ({
      ...state,
      data: null,
      status: requestPending(ADVENTURE_UPDATE),
      loading: true,
      error: false,
      message: '',
    }),

    [requestSuccess(ADVENTURE_UPDATE)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(ADVENTURE_UPDATE),
        data: payload,
        loading: false,
        adventureID: payload.id,
        error: null,
      };
    },

    [requestFail(ADVENTURE_UPDATE)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(ADVENTURE_UPDATE),
      loading: false,
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),

    [requestPending(ADVENTURE_IMAGE_UPLOAD)]: state => ({
      ...state,
      status: requestPending(ADVENTURE_IMAGE_UPLOAD),
      loading: true,
      error: false,
      message: '',
    }),

    [requestSuccess(ADVENTURE_IMAGE_UPLOAD)]: (state, { payload }) => {
      let newMedia = state.media;
      newMedia.push(payload.id);

      return {
        ...state,
        media: newMedia,
        status: requestSuccess(ADVENTURE_IMAGE_UPLOAD),
        loading: false,
        error: null,
      };
    },

    [requestFail(ADVENTURE_IMAGE_UPLOAD)]: (state, { payload }) => ({
      ...state,
      status: requestFail(ADVENTURE_IMAGE_UPLOAD),
      loading: false,
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),

    [requestPending(ADVENTURE_IMAGE_REMOVE)]: state => ({
      ...state,
      status: requestPending(ADVENTURE_IMAGE_REMOVE),
      loading: true,
      error: false,
      message: '',
    }),

    [requestSuccess(ADVENTURE_IMAGE_REMOVE)]: (state, { payload }) => {
      let newMedia = state.media;
      newMedia.splice(payload.id, 1);

      return {
        ...state,
        media: newMedia,
        status: requestSuccess(ADVENTURE_IMAGE_REMOVE),
        loading: false,
        error: null,
      };
    },

    [requestFail(ADVENTURE_IMAGE_REMOVE)]: (state, { payload }) => ({
      ...state,
      status: requestFail(ADVENTURE_IMAGE_REMOVE),
      loading: false,
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),

    [ADVENTURE_FILTER_SET]: (state, { payload }) => {
      const id = state.adventureID;

      return {
        ...state,
        ...payload,
        adventureID: payload.id || id,
      };
    },

    [ADVENTURE_FILTER_CLEAR]: (state, { payload }) => {
      return {
        ...state,
        title: '',
        categories: '',
        difficulty: {},
        season: {},
        minimumAge: null,
        distance: null,
        maxParticipants: null,
        minParticipants: null,
        price: null,
        pricePerPerson: null,
        pricePerGroup: null,
        address: '',
        longitude: null,
        latitude: null,
        dates: [],
        durationInDays: null,
        description: '',
        requirements: '',
        itinerary: '',
        advisory: '',
        inclusions: '',
        exclusions: '',
        directions: '',
        highlights: '',
        ...payload,
      };
    },

    [ADVENTURE_ID_SET]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
        adventureID: payload.adventureID,
      };
    },
  },
  initialState
);
