import React, { Component } from 'react';

import TeamComponent from '../components/Team/Team';

class Team extends Component {
  render() {
    return (
      <div className='news-page'>
        <TeamComponent />
      </div>
    );
  }
}

export default Team;
