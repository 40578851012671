/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import Icon from 'components/Icon';
import styles from 'styles/components/guide/IntroduceForGuide.module.scss';

import guide1 from '../../assets/guides/guide1.png';
import guide2 from '../../assets/guides/guide2.png';
import guide3 from '../../assets/guides/guide3.png';
import guide4 from '../../assets/guides/guide4.png';
import {
  instruction_content,
  setup_guide,
  learn_guide,
  assessment,
  upload_trip,
} from './InstructionStatement';

/* Component definition */
class IntroduceForGuide extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount() {}

  nextStep = () => {
    const { nextStep } = this.props;
    nextStep();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <div className={styles.guides_wrapper}>
            <div className={styles.face}>
              <img className={styles.wdTwoImg} alt='new-guide-sample' src={guide1} />
            </div>
            <div className={styles.face}>
              <img className={styles.wdTwoImg} alt='new-guide-sample' src={guide2} />
            </div>
            <div className={styles.face}>
              <img className={styles.wdTwoImg} alt='new-guide-sample' src={guide3} />
            </div>
            <div className={styles.face}>
              <img className={styles.wdTwoImg} alt='new-guide-sample' src={guide4} />
            </div>
          </div>
          <h2>Becoming a Guide for GreatGuides</h2>
          <p>
            Are you an adventure tour guide? If you’re interested in becoming part of the <br />{' '}
            GreatGuides family, then here’s how what to do.
          </p>
          <div className={styles.statements}>
            <p className={styles.startQuestion}>Why guide with GreatGuides?</p>
            <p className={styles.startQuestion}>{instruction_content}</p>
            <p className={styles.instruction_step}>1. Set up a guide profile</p>
            <p className={styles.instruction}>{setup_guide}</p>
            <p className={styles.instruction_step}>2. Learn what we mean by “Great” Guide</p>
            <p className={styles.instruction}>{learn_guide}</p>
            <p className={styles.instruction_step}>3. Successfully pass the assessment</p>
            <p className={styles.instruction}>{assessment}</p>
            <p className={styles.instruction_step}>4. Upload at least one trip</p>
            <p className={styles.instruction}>{upload_trip}</p>
          </div>
          <div>
            <Link to='#'>
              <button className={styles.become_guide_button} onClick={() => this.nextStep()}>
                BECOME A GUIDE
                <Icon name='arrow-right-big' />
              </button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

IntroduceForGuide.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
};

export default IntroduceForGuide;
