import fp from 'lodash/fp';

const getFilter = state => {
  const filters = {
    title: state.title,
    categories: state.categories,
    difficulty: state.difficulty,
    season: state.season,
    minimumAge: state.minimumAge,
    distance: state.distance,
    maxParticipants: state.maxParticipants,
    minParticipants: state.minParticipants,
    price: state.price,
    pricePerGroup: state.pricePerGroup,
    pricePerPerson: state.pricePerPerson,
    address: state.address,
    longitude: state.longitude,
    latitude: state.latitude,
    dates: state.dates,
    durationInDays: state.durationInDays,
    description: state.description,
    requirements: state.requirements,
    itinerary: state.itinerary,
    advisory: state.advisory,
    inclusions: state.inclusions,
    exclusions: state.exclusions,
    directions: state.directions,
    highlights: state.highlights,
  };
  return filters;
};

export const titleSelector = fp.get('CreateAdventure.title');
export const categoriesSelector = fp.get('CreateAdventure.categories');
export const difficultySelector = fp.get('CreateAdventure.difficulty');
export const seasonSelector = fp.get('CreateAdventure.season');
export const minimumAgeSelector = fp.get('CreateAdventure.minimumAge');
export const distanceSelector = fp.get('CreateAdventure.distance');
export const maxParticipantsSelector = fp.get('CreateAdventure.maxParticipants');
export const minParticipantsySelector = fp.get('CreateAdventure.minParticipants');
export const priceSelector = fp.get('CreateAdventure.price');
export const pricePerPersonSelector = fp.get('CreateAdventure.pricePerPerson');
export const pricePerGroupSelector = fp.get('CreateAdventure.pricePerGroup');
export const addressSelector = fp.get('CreateAdventure.address');
export const longitudeSelector = fp.get('CreateAdventure.longitude');
export const latitudeSelector = fp.get('CreateAdventure.latitude');
export const datesSelector = fp.get('CreateAdventure.dates');
export const durationInDaysSelector = fp.get('CreateAdventure.durationInDays');
export const descriptionSelector = fp.get('CreateAdventure.description');
export const requirementsSelector = fp.get('CreateAdventure.requirements');
export const itinerarySelector = fp.get('CreateAdventure.itinerary');
export const advisorySelector = fp.get('CreateAdventure.advisory');
export const inclusionsSelector = fp.get('CreateAdventure.inclusions');
export const exclusionsSelector = fp.get('CreateAdventure.exclusions');
export const directionsSelector = fp.get('CreateAdventure.directions');
export const highlightsSelector = fp.get('CreateAdventure.highlights');
export const mediaSelector = fp.get('CreateAdventure.media');
export const loadSelector = fp.get('CreateAdventure.loading');
export const adventureIDSelector = fp.get('CreateAdventure.adventureID');

export const adventureSelector = fp.compose(
  getFilter,
  fp.get('CreateAdventure')
);
