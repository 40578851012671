import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { GUIDES_GET } from './types';

const initialState = {
  data: null,
  guides: [],
  filter: 'All',
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(GUIDES_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(GUIDES_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(GUIDES_GET)]: (state, { payload }) => {
      const { isLoadMore, users } = payload;
      let { guides } = state;
      users.sort((a, b) => new Date(a.updatedAt) - new Date(b.updatedAt));

      if (isLoadMore) {
        const totalLength = users.length;
        const currentLength = guides.length;

        if (currentLength < totalLength) {
          const addedData = users.slice(currentLength, Math.min(currentLength + 8, totalLength));

          guides = guides.concat(addedData);
        }
      } else {
        const totalLength = users.length;

        if (totalLength < 8) {
          guides = users;
        } else {
          guides = users.slice(0, 8);
        }
      }
      return {
        ...state,
        status: requestSuccess(GUIDES_GET),
        data: payload,
        guides,
        error: null,
      };
    },

    [requestFail(GUIDES_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(GUIDES_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
