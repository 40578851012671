// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { LOCATIONS_GET } from '../modules/locations/types';
import { getLocations } from 'api/queries';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doGetLocations = apiCall({
  type: LOCATIONS_GET,
  method: 'POST',
  baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
  path: '',
  body: { query: getLocations, variables: {} },
});

export default function* rootSaga() {
  yield takeLatest(LOCATIONS_GET, doGetLocations);
}
