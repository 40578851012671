/**
 * @file Holds the <AdventurePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { graphql, withApollo } from 'react-apollo';
import { createStructuredSelector } from 'reselect';
import { toast } from 'react-toastify';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';
import ReactGA from 'react-ga';

// Comment Similar Adventures - Atma
import AdventureCard from 'components/AdventureCard';
import Rating from 'components/Rating';
// Comment Similar Adventures - Atma
import AdventureCardList from 'containers/AdventureCardList';
import Icon from 'components/Icon';
import PhotoSlider from 'components/PhotoSlider';

//import DepartureDates from 'components/DepartureDates';

import TourDescription from 'components/TourDescription';
import CarryThings from 'components/CarryThings';
import RichEditor from 'components/RichEditor';
import Response from 'components/AdventureDetails/Response';
// Comment TourOnTheMap - Atma
//import TourOnTheMap from 'components/TourOnTheMap';
import PropTypes from 'prop-types';

import { getUserAuthID } from 'api/user';

import moment from 'moment';

import styles from 'styles/pages/AdventureDetailsPage.module.scss';
import 'styles/components/RichEditor.overrides.scss';
import './../styles/fr-elements.scss';

import {
  actions as adventuresActions,
  selectors as adventuresSelectors,
} from '../redux/modules/adventures';

import {
  actions as adventuresByIdActions,
  selectors as adventuresByIdSelector,
} from '../redux/modules/AdventuresID';

import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../redux/modules/SendContact';
import {
  actions as bookNewAdventureActions,
  selectors as bookAdventureDataSelector,
} from '../redux/modules/BookAdventure';

import { actions as adventuresSearchFilterActions } from '../redux/modules/AdventuresFilter';
import { actions as modalActions, selectors as modalSelectors } from '../redux/modules/general';
import AdventureAbout from '../components/AdventureAbout/AdventureAbout';
import AdventureDetailsPageModal from '../components/AdventureDetailPageModal/AdventureDetailPageModal';
import {
  ADVENTURE_DETAILS_MODAL,
  VALIDATOR_IS_EMAIL,
  VALIDATOR_IS_NUMBER,
  VALIDATOR_REQUIRED,
  VALIDATOR_STRING_NOT_EMPTY,
} from '../constants/common';
import { isDataValid, validate } from '../utils/validator';
// import mockData from 'mocks/adventures';

const trackingId = 'UA-24117780-3'; // Replace with your Google Analytics tracking ID
ReactGA.initialize(trackingId);
// ReactGA.set({
//   userId: auth.currentUserId(),
//   // any data that is relevant to the user session
//   // that you would like to track with google analytics
// })
/* Component definition */
class PureAdventureDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isBookingForm: false,
      name: null,
      email: null,
      departureDate: null,
      numberOfTravelers: null,
      tripMessage: null,
      showContactModal: false,
      showCallbacktModal: false,
      loading: true,
      mql: '',

      showModal: false,
      modalData: {
        inquiry: '',
        name: localStorage.getItem('name') || '',
        email: localStorage.getItem('email') || '',
        phone: '',
        numberOfTravelers: '',
        date: '',
      },
      validationRules: {
        inquiry: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        name: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        email: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_IS_EMAIL, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        numberOfTravelers: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY, VALIDATOR_IS_NUMBER],
          errorMessage: undefined,
        },
        date: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
      },
    };
  }

  componentDidMount() {
    const { getAdventuresById, getAdventures, setAdventureId } = this.props;

    getAdventures({ isLoadMore: true }); //Getting Adventures
    getAdventuresById({ id: this.props.match.params.id });

    // Setting Adventure ID
    setAdventureId({
      ['id']: this.props.match.params.id,
    });
    localStorage.setItem('adventureId', this.props.match.params.id);
    // gtag('event', 'conversion', { 'send_to': 'AW-960670234/ub3nCLjkt6kBEJrUisoD' });
    // gtag(' event', 'conversion', { 'send_to': 'AW-960670234/0uKvCPHnt6kBEJrUisoD' });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.adventure != this.props.adventure && nextProps.adventure) {
      this.setState({ loading: false });
    }
    if (nextProps.sendContactStatus !== this.props.sendContactStatus) {
      if (nextProps.sendContactStatus && nextProps.sendContactStatus === 'Success') {
        this.setState({ showContactModal: false });
        this.setState({ showCallbacktModal: true });
      }
      if (nextProps.sendContactStatus && nextProps.sendContactStatus === 'Failed') {
        toast.error('There is some problem to sending message', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
  }

  handleBook = () => {
    const loggedInUser = localStorage.getItem('isLoggedIn');
    if (!loggedInUser) {
      this.setState({ isSignUpModal: true });
    } else {
      const {
        adventure: {
          id,
          title,
          dates,
          guide: {
            profilePictureUrl = null,
            fullName = 'Guider',
            extra = { rating: null, NumOfReviews: null },
            adventures = null,
          },
          pricePerPerson = null,
          pricePerGroup = null,
          maxParticipants = null,
          minParticipants = null,
        },
        history: { push },
      } = this.props;
      push({
        pathname: '/book-adventure',
        state: {
          adventure: {
            id,
            title,
            dates,
            pricePerPerson,
            pricePerGroup,
            maxParticipants,
            minParticipants,
          },
          guide: { profilePictureUrl, fullName, extra, adventures },
        },
      });
    }
  };

  getSimilarAdventures = () => {
    const { adventures, adventure } = this.props;

    let similarAdventures = [];
    if (adventure && adventure.similarAdventures) {
      adventure &&
        adventure.similarAdventures &&
        adventure.similarAdventures.map(adventureItem => {
          if (adventures.length) {
            similarAdventures.push(adventures.filter(item => item.id === adventureItem)[0]);
          }
        });

      let newSimilarAdventures = similarAdventures.filter(item => item !== undefined);

      return newSimilarAdventures.length ? newSimilarAdventures : null;
    } else return [];
  };

  submitModal = () => {
    const { modalData, validationRules } = this.state;
    const isValid = this.validateData(modalData, validationRules);
    if (isValid) {
      const { toggleModal } = this.props;
      const { name, email, numberOfTravelers, date, inquiry, phone } = modalData;
      const {
        sendContact,
        adventure: { title },
      } = this.props;
      const message = `Adventure Name: ${title}, Message: ${inquiry}, Phone: ${phone}`;
      sendContact({
        name: name,
        email: email,
        departureDate: date,
        numberOfTravelers: numberOfTravelers,
        tripMessage: message,
      });
      ReactGA.event({
        category: 'Request Book',
        action: 'Book',
      });
      toggleModal();
    }
  };

  validateData = (modalData, validationRules) => {
    let modalErrors = null;
    Object.entries(modalData).forEach(([key, field]) => {
      if (validationRules[key]) {
        const isValid = validate(validationRules[key].rules, field);
        modalErrors = {
          ...modalErrors,
          [key]: {
            ...validationRules[key],
            errorMessage: isValid,
          },
        };
      }
    });
    const isValid = isDataValid(modalErrors);
    this.setState({
      validationRules: { ...modalErrors },
    });
    return isValid;
  };

  changeModalData = e => {
    const { modalData } = this.state;
    this.setState({
      modalData: {
        ...modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  convertDates = () => {
    const currentDate = moment();
    const {
      adventure: { dates },
    } = this.props;
    const newDates = dates
      .filter(item => moment(item) >= currentDate)
      .sort((a, b) => {
        return moment(a) - moment(b);
      });
    if (newDates.length > 0) {
      const fromDate = moment(newDates[0]).format('YYYY MMM');
      const toDate = moment(newDates[newDates.length - 1]).format('YYYY MMM');
      return `From ${fromDate} to ${toDate}`;
    }
    return 'No information';
  };

  render() {
    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view
    const {
      adventure,
      modalBlock: { isModalOpen, modalName },
      toggleModal,
    } = this.props;
    const { showCallbacktModal, isBookingForm, modalData, validationRules } = this.state;
    return adventure ? (
      <main className={styles.wrapper}>
        {showCallbacktModal && (
          <Response
            heading='Thank for your booking request.'
            paragraph='We will respond within 24 hours.'
            isBookingForm={isBookingForm}
            id={this.props.match.params.id}
          />
        )}
        <>
          {isModalOpen && modalName === ADVENTURE_DETAILS_MODAL && (
            <AdventureDetailsPageModal
              guide={adventure.guide}
              dates={adventure.dates}
              modalData={modalData}
              validationRules={validationRules}
              changeModalData={this.changeModalData}
              submitModal={this.submitModal}
              closeModal={toggleModal}
              buttonText='Request info'
              modalName={ADVENTURE_DETAILS_MODAL}
            />
          )}
          <div className={styles.row}>
            {/* Header */}
            <div className={styles.header}>
              <section>
                <div className={styles.type}>
                  {typeof adventure['categories'] !== undefined ? adventure.categories : ''}{' '}
                  Adventure{' '}
                  {adventure && adventure.durationInDays ? (
                    <span>
                      {adventure.durationInDays}{' '}
                      {adventure.durationInDays.lenght <= 1 ? <span>day</span> : <span>days</span>}
                    </span>
                  ) : null}
                </div>
                <h1>{adventure.title}</h1>
                <div className={styles.location}>
                  <Icon name='location' />
                  {adventure.address}
                </div>
              </section>
              <section>
                <hr />
                <div className={styles.price}>From US$ {adventure.price} per person</div>
                {adventure.guide.extra && adventure.guide.extra.rating && (
                  <Rating rating={adventure.guide.extra.rating} />
                )}
              </section>
            </div>
          </div>

          {adventure.media.length && <PhotoSlider photos={adventure.media} />}

          <div className={styles.row}>
            <React.Fragment>
              <div className={styles.row}>
                <Container>
                  <Row>
                    <Col lg={12} md={12}>
                      {/* Departure dates */}
                      {/* Comment out Calendar - Atma  <DepartureDates dates={adventure.dates} /> */}
                      {/* Tour description */}

                      <TourDescription
                        description={adventure.description}
                        specs={{
                          ...(adventure.distance && { distance: adventure.distance }),
                          ...(adventure.durationInDays && { duration: adventure.durationInDays }),
                          ...(adventure.season && { season: adventure.season }),
                          ...(adventure.minimumAge && { minimum_age: adventure.minimumAge }),
                          ...(adventure.elevation && { elevation: adventure.elevation }),
                          ...(adventure.difficulty && { difficulty: adventure.difficulty }),
                          /*...(adventure.dates &&
                            adventure.dates.length > 0 && {
                            availability_months: this.convertDates(),
                          }),*/
                        }}
                      />
                    </Col>
                  </Row>
                  <Row className='include_exclude'>
                    <Col md={6}>
                      <div className='included_list'>
                        <h3 className={styles.sectionTitle}>Included</h3>
                        <RichEditor value={adventure.inclusions} isEditable={false} />
                      </div>
                    </Col>
                    <Col md={6}>
                      <div className='included_list'>
                        <h3 className={styles.sectionTitle}>Excluded</h3>
                        <RichEditor value={adventure.exclusions} isEditable={false} />
                      </div>
                    </Col>
                  </Row>
                  <Row>
                    <Col lg={12} md={12} className='adventure-subTitle'>
                      {/* Carry */}
                      {adventure.thingsToCarryList > 0 && (
                        <CarryThings carry={adventure.thingsToCarryList} />
                      )}

                      <h3 className={styles.sectionTitle}>Itinerary</h3>

                      <RichEditor value={adventure.itinerary} isEditable={false} />

                      <h3 className={styles.sectionTitle}>Requirements</h3>

                      <RichEditor value={adventure.requirements} isEditable={false} />
                    </Col>
                  </Row>
                </Container>
              </div>
            </React.Fragment>
          </div>
          {/* Similar adventures */}
          {this.getSimilarAdventures() !== null && (
            <AdventureCardList bgColor='grey' isMore columns='c3'>
              <h2>Similiar Adventures</h2>

              {this.getSimilarAdventures().map((item, index) => (
                <AdventureCard adventure={item} key={index} />
              ))}
            </AdventureCardList>
          )}
          <AdventureAbout
            guide={adventure.guide}
            showModal={toggleModal}
            handleBook={this.handleBook}
          />
        </>
      </main>
    ) : (
      <div className='loaderSpinner'>
        <div className='loader' />
      </div>
    );
  }
}

/* Prop types definition */
PureAdventureDetailsPage.propTypes = {
  data: PropTypes.object,
  mql: PropTypes.string,
  match: PropTypes.object,
  sendContact: PropTypes.func.isRequired,
  sendContactStatus: PropTypes.string,
  bookNewAdventure: PropTypes.func.isRequired,
  bookAdventureData: PropTypes.array,
  getAdventuresById: PropTypes.func.isRequired,
  adventures: PropTypes.array.isRequired,
  setAdventureId: PropTypes.func.isRequired,
  adventure: PropTypes.object,
  getAdventures: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  modalBlock: PropTypes.shape({
    isModalOpen: PropTypes.bool,
    modalName: PropTypes.string,
  }).isRequired,
  toggleModal: PropTypes.func.isRequired,
  history: PropTypes.shape({
    push: PropTypes.func,
  }).isRequired,
};

const userId = {
  name: 'loggedInUser',
  // options for the initial query
  options() {
    return {
      header: {
        Authorization: `Bearer ${localStorage.id_token}`,
      },
      variables: {},
    };
  },
  // convert the ugly apollo results into custom props
  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

const selectors = createStructuredSelector({
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
  bookAdventureData: bookAdventureDataSelector.bookAdventureDataSelector,
  adventure: adventuresByIdSelector.adventuresByIdSelector,
  adventures: adventuresSelectors.adventuresAllSelector,
  modalBlock: modalSelectors.modalSelector,
});

const actions = {
  sendContact: sendContactActions.sendContact,
  bookNewAdventure: bookNewAdventureActions.bookNewAdventure,
  getAdventuresById: adventuresByIdActions.getAdventuresById,
  getAdventures: adventuresActions.getAdventures,
  setAdventureId: adventuresSearchFilterActions.setAdventuresFilter,
  toggleModal: modalActions.toggleModal,
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(selectors, actions)
)(PureAdventureDetailsPage);
