/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import RichEditor from 'components/RichEditor';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import { actions as updateUserActions } from '../../redux/modules/UpdateUser';
import { selectors as userSelectors } from '../../redux/modules/users';

import styles from 'styles/pages/NewGuidePage.module.scss';
import 'styles/components/RichEditorNewGuide.overrides.scss';

/* Component definition */
class AboutMe extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutMe: '',
      errorName: '',
    };
  }

  componentDidMount() {
    const { aboutMe } = this.props;
    this.setState({ aboutMe: aboutMe || '' });
  }

  onInputTextChange = () => {};

  nextStep = () => {
    if (!this.validateForm()) {
      return;
    }

    const { nextStep, updateUserAbout, setGuideInfo, loggedUser } = this.props;
    const { aboutMe } = this.state;
    //Set Guides' information
    setGuideInfo({
      ['aboutMe']: aboutMe,
    });
    if (loggedUser) updateUserAbout();

    nextStep();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  validateForm() {
    const { aboutMe } = this.state;

    if (aboutMe === '') {
      this.setState({ errorName: 'Your overview' });
      return false;
    }
    return true;
  }

  froalaEditorModel = value => {
    this.setState({ aboutMe: value });
  };

  render() {
    const { aboutMe, errorName } = this.state;

    return (
      <div>
        <div className='userAboutSection'>
          <h2>About me</h2>
          <p>
            Tell us a bit more about yourself, What inspired you to become a guide, Your story, Your
            expertise,
            <br />
            Links to videos, Images, ..
          </p>
          <form className={styles.form}>
            <div className={styles.form_group}>
              <RichEditor
                value={aboutMe}
                handleFroalaModelChange={this.froalaEditorModel}
                isEditable={true}
              />
            </div>
            {errorName && <div className={styles.errorText}>{errorName} is required</div>}
            <div className={styles.form_group}>
              <button type='button' className={styles.form_button} onClick={() => this.nextStep()}>
                Continue
              </button>
              <button
                type='button'
                className={styles.form_button}
                onClick={() => this.previewStep()}
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

AboutMe.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  updateUserAbout: PropTypes.func.isRequired,
  aboutMe: PropTypes.string,
  loggedUser: PropTypes.string,
};

const selectors = createStructuredSelector({
  aboutMe: GuideSetFilterSelectors.aboutMeSelector,
  loggedUser: userSelectors.userIdSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserAbout: updateUserActions.updateUserAbout,
};

export default connect(
  selectors,
  actions
)(AboutMe);
