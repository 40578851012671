import * as validators from './validatorFunctions';
import {
  VALIDATOR_IS_EMAIL,
  VALIDATOR_IS_NUMBER,
  VALIDATOR_REQUIRED,
  VALIDATOR_STRING_NOT_EMPTY,
} from '../constants/common';

export function validate(rules, value) {
  let isValid = true;
  let errMessage = undefined;

  rules.forEach(rule => {
    switch (rule) {
    case VALIDATOR_REQUIRED:
      isValid = validators.validateRequired(value);
      errMessage = !isValid && !errMessage ? 'Required field' : errMessage;
      break;
    case VALIDATOR_IS_NUMBER:
      isValid = validators.validateIsNumber(value);
      errMessage = !isValid && !errMessage ? 'Invalid number' : errMessage;
      break;
    case VALIDATOR_IS_EMAIL:
      isValid = validators.validateIsEmail(value);
      errMessage = !isValid && !errMessage ? 'Invalid email' : errMessage;
      break;
    case VALIDATOR_STRING_NOT_EMPTY:
      isValid = validators.validateStringNotEmpty(value);
      errMessage = !isValid && !errMessage ? 'Field should not be empty!' : errMessage;
      break;
    default:
    }
  });

  return errMessage;
}

export function isDataValid(validationRules) {
  let isValid = true;
  const rules = Object.keys(validationRules);
  rules.forEach(formRules => {
    if (validationRules[formRules].errorMessage) {
      isValid = false;
    }
    return isValid;
  });
  return isValid;
}
