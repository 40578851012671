import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/Rating.module.scss';

/* Component definition */
class Rating extends React.Component {
  render() {
    const { rating } = this.props;
    return (
      <div className={styles.star_ratings}>
        <div className={styles.star_ratings_top} style={{ width: `${(rating / 5.0) * 100}%` }}>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
        </div>
        <div className={styles.star_ratings_bottom}>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
          <span>★</span>
        </div>
        <div className={styles.ratingText}>{rating}/5</div>
      </div>
    );
  }
}

Rating.propTypes = {
  rating: PropTypes.any,
};

export default Rating;
