import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import TripList from 'containers/TripList';
import TripItem from 'components/Account/MyTrip/TripItem';
import CustomerTripItem from 'components/Account/MyTrip/CustomerTripItem';
import TripTabList from 'components/Account/MyTrip/TripTabList';
import styles from 'styles/pages/Account/MyTrip.module.scss';

import { actions as tripsActions, selectors as tripsSelectors } from '../../../redux/modules/trips';
import { selectors as userSelectors } from '../../../redux/modules/users';

class AccountTrip extends Component {
  constructor(props) {
    super(props);
    this.state = {
      myTrips: props.myTrips,
      customerTrips: props.customerTrips,
      userRole: '',
      tabIndex: 1,
      bookingStatus: 'ToBeApproved',
    };
  }

  componentDidMount() {
    const { getTrips } = this.props;

    getTrips();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.myTrips.length && nextProps.myTrips !== this.props.myTrips) {
      this.setState({ myTrips: nextProps.myTrips });
    }

    if (nextProps.customerTrips.length && nextProps.customerTrips !== this.props.customerTrips) {
      this.setState({ customerTrips: nextProps.customerTrips });
    }

    if (nextProps.user) {
      this.setState({ userRole: nextProps.user.userRole });
    }
  }

  setTabIndex = index => {
    this.setState({ tabIndex: index });

    switch (index) {
    case 1:
      this.setState({ bookingStatus: 'ToBeApproved' });
      break;
    case 2:
      this.setState({ bookingStatus: 'Approved' });
      break;
    case 3:
      this.setState({ bookingStatus: 'Paid' });
      break;
    case 4:
      this.setState({ bookingStatus: 'History' });
      break;
    }
  };

  render() {
    const { userRole, myTrips, customerTrips, tabIndex, bookingStatus } = this.state;
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {userRole === 'Client' && (
            <TripList>
              <h2>My Trips</h2>

              <p>Here are all your upcoming and latest trips and bookings.</p>
              {myTrips.map((item, index) => (
                <TripItem
                  key={index}
                  bookings={item.bookings}
                  border={myTrips.length === index + 1 ? true : false}
                />
              ))}
            </TripList>
          )}
          {userRole.includes('Guide') && (
            <TripList>
              <h2>
                <TripTabList tabIndex={tabIndex} setTabIndex={this.setTabIndex} />
              </h2>
              <div>
                <CustomerTripItem
                  bookings={
                    customerTrips
                      ? customerTrips.filter(item => item.bookingState === bookingStatus)
                      : []
                  }
                  border={true}
                />
              </div>
            </TripList>
          )}
        </div>
      </div>
    );
  }
}

AccountTrip.propTypes = {
  getTrips: PropTypes.func.isRequired,
  myTrips: PropTypes.array,
  customerTrips: PropTypes.array,
  user: PropTypes.object,
};

const selectors = createStructuredSelector({
  myTrips: tripsSelectors.myTripsSelector,
  customerTrips: tripsSelectors.customerTripsSelector,
  user: userSelectors.userSelector,
});

const actions = {
  getTrips: tripsActions.getTrips,
};

export default connect(
  selectors,
  actions
)(AccountTrip);
