import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import storage from 'redux-persist/lib/storage';
import { persistReducer } from 'redux-persist';

import * as reducers from './modules';

const persistConfig = {
  key: 'root',
  storage,
  whitelist: ['users'],
};

const persistedReducer = history => {
  const appreducers = combineReducers({
    ...reducers,
    router: connectRouter(history),
  });

  return persistReducer(persistConfig, appreducers);
};

export default persistedReducer;
