/**
 * @file Holds the <ReadMoreTextBlock> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import Icon from 'components/Icon';
import PropTypes from 'prop-types';
import { LazyLoadImage } from 'react-lazy-load-image-component';

import styles from 'styles/components/ReadMoreTextBlock.module.scss';
import 'react-lazy-load-image-component/src/effects/blur.css';

import aboutOne from '../assets/home/about1.jpg';
import aboutTwo from '../assets/home/about2.jpg';
import aboutThree from '../assets/home/about3.jpg';
import hiw1 from '../assets/home/hiw1.jpg';
import hiw2 from '../assets/home/hiw2.jpg';
import hiw3 from '../assets/home/hiw3.jpg';
import hiw4 from '../assets/home/hiw4.jpg';
import wwd1 from '../assets/home/wwd1.jpg';
import wwd2 from '../assets/home/wwd2.jpg';
import wwd3 from '../assets/home/wwd3.jpg';

/* Component definition */

class ReadMoreTextBlock extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      aboutShowMore: false,
      hitShowMore: false,
      wdShowMore: false,
    };
  }
  showMore = type => {
    const { aboutShowMore, hitShowMore, wdShowMore } = this.state;

    switch (type) {
    case 'about':
      this.setState({ aboutShowMore: !aboutShowMore });
      break;
    case 'howItWorks':
      this.setState({ hitShowMore: !hitShowMore });
      break;
    case 'whyDifferent':
      this.setState({ wdShowMore: !wdShowMore });
      break;
    default:
      break;
    }
  };

  render() {
    const { children, bgColor, isReverseOrder, type } = this.props,
      { aboutShowMore, hitShowMore, wdShowMore } = this.state;
    return (
      <div className={[`${styles.wrapper} ${styles[bgColor]}`]}>
        <div className={[`${styles.row} ${isReverseOrder && styles.reverse}`]}>
          <div className={styles.column}>
            {children.find(item => item.type === 'h3')}

            <hr className='short_hr' />

            {children.filter(item => item.type === 'p')}
            {(aboutShowMore && type === 'about') ||
            (hitShowMore && type === 'howItWorks') ||
            (wdShowMore && type === 'whyDifferent')
              ? children.filter(item => item.type === 'q')
              : ''}
            <div className={styles.read_more} onClick={() => this.showMore(type)}>
              {type === 'about' ? (
                <div>
                  {!aboutShowMore ? <span>Read more</span> : <span>Show less</span>}
                  <Icon name={`read-more${bgColor !== 'grey' ? '-grey' : ''}`} />
                </div>
              ) : (
                ''
              )}
              {type === 'howItWorks' ? (
                <div>
                  {!hitShowMore ? <span>Read more</span> : <span>Show less</span>}
                  <Icon name={`read-more${bgColor !== 'grey' ? '-grey' : ''}`} />
                </div>
              ) : (
                ''
              )}
              {type === 'whyDifferent' ? (
                <div>
                  {!wdShowMore ? <span>Read more</span> : <span>Show less</span>}
                  <Icon name={`read-more${bgColor !== 'grey' ? '-grey' : ''}`} />
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          <div className={styles.column}>
            {type === 'about' ? (
              <div className={styles.referenceImages}>
                <div className={styles.dots} />
                <div className={styles.partOne}>
                  <div className={styles.partOneImg}>
                    <LazyLoadImage
                      className={styles.imgContentFirst}
                      alt='about-us'
                      src={aboutOne}
                      effect='blur'
                    />
                  </div>
                  <div className={styles.partOneImg}>
                    <LazyLoadImage
                      className={styles.imgContentFirst}
                      alt='about-us'
                      src={aboutTwo}
                      effect='blur'
                    />
                  </div>
                </div>
                <div className={styles.partTwo}>
                  <LazyLoadImage
                    className={styles.imgContentThird}
                    alt='about-us'
                    src={aboutThree}
                    effect='blur'
                  />
                </div>
              </div>
            ) : (
              ''
            )}
            {type === 'howItWorks' ? (
              <div className={styles.hitImages}>
                <div className={styles.hitOneImg}>
                  <div className={styles.decCircle} />
                  <div className={styles.hitSquare}>
                    <LazyLoadImage
                      className={styles.hitOneFirst}
                      alt='about-us'
                      src={hiw1}
                      effect='blur'
                    />
                  </div>
                  <div className={styles.hitSquare}>
                    <LazyLoadImage
                      className={styles.hitSecond}
                      alt='about-us'
                      src={hiw2}
                      effect='blur'
                    />
                  </div>
                </div>
                <div className={styles.hitTwoImg}>
                  <div className={styles.hitSquare}>
                    <LazyLoadImage
                      className={styles.hitSecond}
                      alt='about-us'
                      src={hiw3}
                      effect='blur'
                    />
                  </div>
                  <div className={styles.hitSquare}>
                    <LazyLoadImage
                      className={styles.hitTwoFirst}
                      alt='about-us'
                      src={hiw4}
                      effect='blur'
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
            {type === 'whyDifferent' ? (
              <div className={styles.wdImages}>
                <div className={styles.wdMask}>
                  <div className={styles.wdOne}>
                    <LazyLoadImage
                      className={styles.wdOneImg}
                      alt='about-us'
                      src={wwd1}
                      effect='blur'
                    />
                  </div>
                </div>
                <div className={styles.wdMask}>
                  <div className={styles.wdTwo}>
                    <LazyLoadImage
                      className={styles.wdTwoImg}
                      alt='about-us'
                      src={wwd2}
                      effect='blur'
                    />
                  </div>
                </div>
                <div className={styles.wdMask}>
                  <div className={styles.wdThird}>
                    <LazyLoadImage
                      className={styles.wdThirdImg}
                      alt='about-us'
                      src={wwd3}
                      effect='blur'
                    />
                  </div>
                </div>
              </div>
            ) : (
              ''
            )}
          </div>
        </div>
      </div>
    );
  }
}

/* Prop types definition */
ReadMoreTextBlock.propTypes = {
  children: PropTypes.any.isRequired,
  bgColor: PropTypes.string.isRequired,
  isReverseOrder: PropTypes.any,
  type: PropTypes.string.isRequired,
};

export default ReadMoreTextBlock;
