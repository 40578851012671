import React, { Component } from 'react';

import Container from '../../common/container/Container';
import BlogItems from './BlogItem';
import './Blog.scss';

class NewsComponent extends Component {
  render() {
    return (
      <Container>
        <div className='blog-header'>
          <div className='pull-left'>
            <h1>Blog</h1>
            <p>Here are your articles</p>
          </div>
          <div className='pull-right'>
            <a href='https://www.google.com'>New Articles</a>
          </div>
        </div>
        <div className='blog-items'>
          <div className='row'>
            <BlogItems />
            <BlogItems />
            <BlogItems />
            <BlogItems />
          </div>
        </div>
      </Container>
    );
  }
}

export default NewsComponent;
