import React from 'react';

const BlogItem = () => (
  <div className='blog-item'>
    <div className='imageInfo'>
      <img src='https://i.ibb.co/VvdttDP/banner-1.jpg' alt='img1' className='main-img' />
      <div className='review'>
        <div className='user'>
          <img src='/static/media/user1.4c62583e.png' alt='User' />
        </div>
        <div className='rating'>
          <b>Alex Pesenka</b>
          <ul>
            <li>12 Jun, 2018</li>
          </ul>
        </div>
      </div>
    </div>
    <div className='box'>
      <h3>Botswania</h3>
      <h2>Children in the Wilderness: A quick dip that leaves a lasting mark</h2>
    </div>
  </div>
);

export default BlogItem;
