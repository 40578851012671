import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../../redux/modules/SendContact';
import {
  actions as bookNewAdventureActions,
  selectors as bookAdventureDataSelector,
} from '../../redux/modules/BookAdventure';
import {
  VALIDATOR_IS_EMAIL,
  VALIDATOR_IS_NUMBER,
  VALIDATOR_REQUIRED,
  VALIDATOR_STRING_NOT_EMPTY,
} from '../../constants/common';
import BookAdventureView from './BookAdventureView';

import '../../styles/pages/bookAdventure.scss';
import { isDataValid, validate } from '../../utils/validator';
import ReactGA from 'react-ga';

class BookAdventure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {
        inquiry: '',
        name: localStorage.getItem('name') || '',
        email: localStorage.getItem('email') || '',
        phone: '',
        numberOfTravelers: '',
        date: '',
      },
      validationRules: {
        inquiry: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        name: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        email: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_IS_EMAIL, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        numberOfTravelers: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY, VALIDATOR_IS_NUMBER],
          errorMessage: undefined,
        },
        date: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        phone: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
      },
      adventure: null,
      guide: null,
      loading: false,
    };
  }

  componentDidMount() {
    const {
      history: {
        location: { state: { adventure, guide } = { adventure: null, guide: null } },
        push,
      },
    } = this.props;
    const { data } = this.state;
    if (!adventure || !guide) {
      push('/');
    } else {
      this.setState({
        adventure,
        guide,
        data: {
          ...data,
          numberOfTravelers: `${adventure.minParticipants}`,
        },
      });
    }
  }

  componentDidUpdate() {
    const {
      bookAdventureData,
      history: { push },
    } = this.props;
    const {
      adventure: { id },
    } = this.state;
    if (
      !Array.isArray(bookAdventureData) &&
      bookAdventureData.id &&
      bookAdventureData.adventure.id === id
    ) {
      // const data = {
      //   price: bookAdventureData.price,
      //   travelers: bookAdventureData.numberOfTravelers,
      //   date: bookAdventureData.dateRange,
      //   bookingId: bookAdventureData.id,
      // };
      push('/');
    }
  }

  changeData = e => {
    const { data } = this.state;
    this.setState({
      data: {
        ...data,
        [e.target.name]: e.target.value,
      },
    });
  };

  validateData = (modalData, validationRules) => {
    let modalErrors = null;
    Object.entries(modalData).forEach(([key, field]) => {
      if (validationRules[key]) {
        const isValid = validate(validationRules[key].rules, field);
        modalErrors = {
          ...modalErrors,
          [key]: {
            ...validationRules[key],
            errorMessage: isValid,
          },
        };
      }
    });
    const isValid = isDataValid(modalErrors);
    this.setState({
      validationRules: { ...modalErrors },
    });
    return isValid;
  };

  submitData = () => {
    const {
      data,
      validationRules,
      adventure: { title, id },
    } = this.state;
    const isValid = this.validateData(data, validationRules);
    if (isValid) {
      this.setState({
        loading: true,
      });
      const { name, email, numberOfTravelers, date, inquiry, phone } = data;
      const { sendContact, bookNewAdventure } = this.props;
      const message = `Adventure Name: ${title}, Message: ${inquiry}, Phone: ${phone}`;
      sendContact({
        name: name,
        email: email,
        departureDate: date,
        numberOfTravelers: numberOfTravelers,
        tripMessage: message,
      });
      bookNewAdventure({
        departureDate: date,
        numberOfTravelers: numberOfTravelers,
        tripMessage: message,
        isParticipantAgree: true,
        userId: localStorage.getItem('loggedInUser'),
        adventureId: id,
      });
      ReactGA.event({
        category: 'Book Now',
        action: 'Book',
      });
    }
  };

  render() {
    const { adventure, guide, data, validationRules, loading } = this.state;
    return adventure && guide ? (
      <BookAdventureView
        data={data}
        validationRules={validationRules}
        guide={guide}
        adventure={adventure}
        changeData={this.changeData}
        submitData={this.submitData}
        loading={loading}
      />
    ) : (
      <div className='loaderSpinner'>
        <div className='loader' />
      </div>
    );
  }
}

BookAdventure.propTypes = {
  sendContactStatus: PropTypes.string.isRequired,
  sendContact: PropTypes.func.isRequired,
  bookNewAdventure: PropTypes.func.isRequired,
  history: PropTypes.shape({
    location: PropTypes.shape({
      state: PropTypes.shape({
        adventure: PropTypes.shape({
          id: PropTypes.string,
          adventure: PropTypes.string,
          dates: PropTypes.arrayOf(PropTypes.string),
          pricePerPerson: PropTypes.number,
          pricePerGroup: PropTypes.number,
        }),
        guide: PropTypes.shape({
          fullName: PropTypes.string,
          profilePictureUrl: PropTypes.string.isRequired,
          extra: PropTypes.shape({}),
          adventures: PropTypes.arrayOf(PropTypes.object),
        }),
      }),
    }).isRequired,
    push: PropTypes.func.isRequired,
  }),
  bookAdventureData: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.array]),
};

const selectors = createStructuredSelector({
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
  bookAdventureData: bookAdventureDataSelector.bookAdventureDataSelector,
});

const actions = {
  sendContact: sendContactActions.sendContact,
  bookNewAdventure: bookNewAdventureActions.bookNewAdventure,
};

export default connect(selectors, actions)(BookAdventure);
