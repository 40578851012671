/**
 * @file Holds the <AdventureCardList> container
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/containers/AdventureCardList.module.scss';
import { Container } from 'react-bootstrap';

import Icon from 'components/Icon';

/* Component definition */
class AdventureCardList extends React.Component {
  getLoadMore = () => {
    const { getLoadMore } = this.props;
    getLoadMore();
  };

  render() {
    const { children, bgColor, columns } = this.props;
    return (
      <div className={[`${styles.wrapper} ${styles[bgColor]}`]}>
        <div className={styles.row}>
          <Container>
            {children.find(item => item.type === 'h2')}

            {children.filter(item => item.type === 'p')}

            <div className={[`${styles.cards} ${styles[columns]}`]}>
              {children.find(item => item.length) &&
                children.find(item => item.length).map(item => item)}
            </div>
          </Container>
          <div className={styles.see_more} onClick={this.getLoadMore}>
            <span>See more</span>
            <Icon name='see-more' />
          </div>
        </div>
      </div>
    );
  }
}

/* Prop types definition */
AdventureCardList.propTypes = {
  getLoadMore: PropTypes.func,
  children: PropTypes.array.isRequired,
  bgColor: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
};

export default AdventureCardList;
