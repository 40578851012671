import gql from 'graphql-tag';

export const getGuideTrips = gql`
  query allBookings {
    bookings(where: { adventure: { guide: { id: "cjmqest8pl9rj0b77f5nw2gy9" } } }) {
      id
      bookingState
      numberOfTravelers
      updatedAt
      payment
      price
      adventure {
        id
        title
        categories
        address
        media {
          imageHQUrl
        }
        guide {
          id
          fullName
          profilePictureUrl
        }
      }
      user {
        id
        fullName
        profilePictureUrl
      }
    }
  }
`;
