import React, { Component } from 'react';

import Sidebar from '../../components/Account/SideBar';
import Container from '../../common/container/Container';

import './account.scss';

class AccountMessages extends Component {
  render() {
    return (
      <Container>
        <div className='account-page'>
          <Sidebar />
          <div className='account-right'>right sede</div>
        </div>
      </Container>
    );
  }
}

export default AccountMessages;
