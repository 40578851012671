import React from 'react';
import PropTypes from 'prop-types';
import GuideModalHead from '../GuideModal/GuideModalHead';
import GuideModalItem from '../GuideModal/GuideModalItem';
import Modal from '../Modal/Modal';
import Icon from 'components/Icon';
import user from '../../assets/AdeventureDetails/2-users.png';
import emailImg from '../../assets/AdeventureDetails/email.jpg';

function AdventureDetailsPageModal({
  guide,
  dates,
  modalData: { inquiry, name, email, phone, numberOfTravelers, date },
  validationRules: {
    inquiry: { errorMessage: inquiryError },
    name: { errorMessage: nameError },
    email: { errorMessage: emailError },
    numberOfTravelers: { errorMessage: numberOfTravelersError },
    date: { errorMessage: dateError },
  },
  changeModalData,
  submitModal,
  closeModal,
  buttonText,
  modalName,
}) {
  return (
    <Modal modalName={modalName}>
      <div className='guide-modal__close' onClick={() => closeModal()}>
        X
      </div>
      <GuideModalHead
        profilePictureUrl={guide.profilePictureUrl}
        fullName={guide.fullName}
        extra={guide.extra}
        adventures={guide.adventures}
      />
      <GuideModalItem
        field={inquiry}
        error={inquiryError}
        name='inquiry'
        type='textarea'
        placeholder='Please add your inquiry here'
        onChange={changeModalData}
        icon={<Icon name='messages' />}
      />
      <GuideModalItem
        field={name}
        error={nameError}
        name='name'
        type='text'
        placeholder='Your Name'
        onChange={changeModalData}
        icon={<img src={user} alt='icon' />}
      />
      <GuideModalItem
        field={email}
        error={emailError}
        name='email'
        type='text'
        placeholder='Your Email'
        onChange={changeModalData}
        icon={<img src={emailImg} alt='icon' />}
      />
      <GuideModalItem
        field={phone}
        name='phone'
        type='text'
        placeholder='Your Phone Number'
        onChange={changeModalData}
        icon={<Icon name='difficulty' />}
      />
      <GuideModalItem
        field={numberOfTravelers}
        error={numberOfTravelersError}
        name='numberOfTravelers'
        type='number'
        placeholder='Number of travelers'
        onChange={changeModalData}
        icon={<Icon name='persons' />}
      />
      <GuideModalItem
        field={date}
        error={dateError}
        name='date'
        type='datePicker'
        dates={dates}
        placeholder='Choose date'
        onChange={changeModalData}
        icon={<Icon name='season' />}
      />
      <button className='guide-modal__body__submit' onClick={submitModal}>
        {buttonText}
      </button>
    </Modal>
  );
}

AdventureDetailsPageModal.propTypes = {
  guide: PropTypes.shape({
    fullName: PropTypes.string,
    profilePictureUrl: PropTypes.string.isRequired,
    extra: PropTypes.shape({}),
    adventures: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  dates: PropTypes.arrayOf(PropTypes.string),
  modalData: PropTypes.shape({
    inquiry: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    numberOfTravelers: PropTypes.string,
    date: PropTypes.string,
  }),
  validationRules: PropTypes.shape({
    inquiry: PropTypes.shape({ errorMessage: PropTypes.string }),
    name: PropTypes.shape({ errorMessage: PropTypes.string }),
    email: PropTypes.shape({ errorMessage: PropTypes.string }),
    numberOfTravelers: PropTypes.shape({ errorMessage: PropTypes.string }),
    date: PropTypes.shape({ errorMessage: PropTypes.string }),
  }),
  changeModalData: PropTypes.func.isRequired,
  submitModal: PropTypes.func.isRequired,
  closeModal: PropTypes.func.isRequired,
  buttonText: PropTypes.string.isRequired,
  modalName: PropTypes.string.isRequired,
};

AdventureDetailsPageModal.defaultProps = {
  dates: undefined,
};

export default AdventureDetailsPageModal;
