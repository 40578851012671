import React from 'react';
import PropTypes from 'prop-types';
import DatePicker from 'react-datepicker';
import Icon from '../../components/Icon';

const moment = require('moment');

function GuideModalItem({ icon, field, error, placeholder, name, onChange, type, dates }) {
  const element = () => {
    switch (type) {
    case 'textarea':
      return <textarea value={field} placeholder={placeholder} onChange={onChange} name={name} />;
    case 'datePicker':
      return (
        <DatePicker
          onChange={date => {
            onChange({ target: { name: name, value: moment(date).format('DD MMM YYYY') } });
          }}
          value={field}
          includeDates={dates}
          dateFormat='dd MMM yyyy'
          placeholderText={placeholder}
        />
      );
    default:
      return (
        <input
          value={field}
          type={type}
          min={1}
          name={name}
          placeholder={placeholder}
          onChange={onChange}
        />
      );
    }
  };

  return (
    <div className={`${error ? 'guide-modal__body__error' : ''}`}>
      <div className='guide-modal__body__item'>
        {typeof icon === 'string' ? <Icon name={icon} /> : icon}
        {element()}
      </div>
      {error && <p>{error}</p>}
    </div>
  );
}

GuideModalItem.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  field: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  error: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  type: PropTypes.string.isRequired,
  dates: PropTypes.arrayOf(PropTypes.string),
};

GuideModalItem.defaultProps = {
  dates: [''],
  error: null,
};

export default GuideModalItem;
