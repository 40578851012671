import PropTypes from 'prop-types';
import React from 'react';

const HTMLPARSE = props => {
  const { children, className } = props;
  return <div className={className} dangerouslySetInnerHTML={{ __html: children }} />;
};

HTMLPARSE.propTypes = {
  className: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default HTMLPARSE;
