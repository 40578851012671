/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';

import FroalaEditor from 'react-froala-wysiwyg';
import $ from 'jquery';
import jQuery from 'jquery';

// Require Editor JS files.
import 'froala-editor/js/froala_editor.pkgd.min.js';
// Require Editor CSS files.
import 'froala-editor/css/froala_style.min.css';
import 'froala-editor/css/froala_editor.pkgd.min.css';
import FroalaEditorImg from 'react-froala-wysiwyg/FroalaEditorImg';

// Require Font Awesome.
import 'font-awesome/css/font-awesome.css';
import { non_EditableConfig, editableConfig } from 'utils/initializers.js';

window.$ = $;
window.jQuery = jQuery;

// That's where the magic happens

/* Component definition */
class RichEditor extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      model: '',
      isExtended: false,
      isEditable: false,
    };
  }

  componentDidMount() {
    this.setState({ model: this.props.value });
  }

  UNSAFE_componentWillMount() {
    async function loadEditor() {
      if (typeof window !== 'object') {
        global.window = global;
        global.window.navigator = {};
        await import('react-froala-wysiwyg');
      }
    }
    loadEditor();
  }

  shouldComponentUpdate(nextProps) {
    if (nextProps.value && nextProps.value !== this.props.value) {
      this.setState({ model: nextProps.value });
    }
    return true;
  }

  onInputTextChange = () => {};

  handleModelChange = model => {
    const { handleFroalaModelChange } = this.props;
    this.setState({
      model: model,
    });

    handleFroalaModelChange(this.state.model);
  };

  render() {
    const { isEditable } = this.props;

    return (
      <div className='sectionFroala'>
        <FroalaEditor
          tag='textarea'
          config={isEditable ? editableConfig : non_EditableConfig}
          model={this.state.model}
          onModelChange={this.handleModelChange}
          disabled={false}
        />
        {isEditable && (
          <FroalaEditorImg config={isEditable ? editableConfig : non_EditableConfig} />
        )}
      </div>
    );
  }
}

RichEditor.propTypes = {
  handleFroalaModelChange: PropTypes.func,
  value: PropTypes.string,
  isEditable: PropTypes.bool,
};

export default RichEditor;
