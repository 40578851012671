import { handleActions } from 'redux-actions';

import { ADVENTURES_FILTER_SET, ADVENTURES_FILTER_CLEAR } from './types';

// var moment = require('moment');

const initialState = {
  id: null,
  startDate: null,
  endDate: null,
  location: '',
  day_multi: '',
  category: '',
  difficulty: '',
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [ADVENTURES_FILTER_SET]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },

    [ADVENTURES_FILTER_CLEAR]: state => ({
      ...state,
      status: null,
      error: null,
      message: null,
    }),
  },
  initialState
);
