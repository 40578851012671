/**
 * @file Holds the <CardList> container
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import CategorySelector from '../components/CategorySelector';
// import TripDurationSelector from '../components/TripDurationSelector';
import Icon from 'components/Icon';
import styles from 'styles/containers/CardList.module.scss';

import {
  actions as adventuresSearchFilterActions,
  selectors as adventuresFilterSelectors,
} from '../redux/modules/AdventuresFilter';

import { actions as adventuresActions } from '../redux/modules/adventures';

/* Component definition */
class CardList extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedRegion: 0,
    };
  }

  componentDidMount() {}
  updateCategoryFilter = param => {
    const { setAdventuresFilter, getAdventures } = this.props;

    setAdventuresFilter({
      ['category']: param ? param : 'All',
      ['location']: '',
    });

    getAdventures({ isLoadMore: false });
  };

  updateDurationFilter = param => {
    const { setAdventuresFilter, getAdventures } = this.props;

    setAdventuresFilter({
      ['dayMulti']: param,
    });

    getAdventures({ isLoadMore: false });
  };

  selectRegion = (index, type) => {
    this.setState({ selectedRegion: index });

    const { updateRegion } = this.props;

    updateRegion(type);
  };

  getLoadMore = type => {
    const { getLoadMore } = this.props;

    getLoadMore(type);
  };

  render() {
    const { children, bgColor, isMore, columns, type } = this.props,
      { regions, categories } = this.props;
    const { selectedRegion } = this.state;

    const regionList = regions => {
      return (
        <div className={styles.regions}>
          {regions.map((region, index) => (
            <div
              key={index}
              className={index === selectedRegion ? styles.regionActive : styles.region}
              onClick={() => this.selectRegion(index, region)}
            >
              {index === 0 ? '' : <span>&nbsp;&bull;&nbsp;</span>}
              {region}
            </div>
          ))}
        </div>
      );
    };
    return (
      <div className={[`${styles.wrapper} ${styles[bgColor]}`]}>
        <div className={styles.row} id={`${type}Section`}>
          {children.find(item => item.type === 'h3')}

          <div className={styles.section}>
            {type === 'adventures' && categories ? (
              <div className={styles.category}>
                <div style={{ marginRight: '5px', marginBottom: '10px' }}>
                  <CategorySelector
                    categories={categories}
                    updateFilter={this.updateCategoryFilter}
                  />
                </div>
                <div>{/* <TripDurationSelector updateFilter={this.updateDurationFilter} /> */}</div>
              </div>
            ) : (
              ''
            )}
            {type === 'guides' ? <div className={styles.category}>{regionList(regions)}</div> : ''}
            {categories && (
              <div className={styles.detail}>
                <hr className='short_hr' />
                {children.filter(item => item.type === 'p')}
              </div>
            )}
          </div>

          <div className={[`${styles.cards} ${styles[columns]}`]}>
            {children.find(item => item.length) !== undefined
              ? children.find(item => item.length).map(item => item)
              : ''}
          </div>

          {isMore && (
            <div className={styles.see_more} onClick={() => this.getLoadMore(type)}>
              <span>See more</span>
              <Icon name='see-more' />
            </div>
          )}
        </div>
      </div>
    );
  }
}

/* Prop types definition */
CardList.propTypes = {
  children: PropTypes.array.isRequired,
  categories: PropTypes.array,
  regions: PropTypes.array,
  bgColor: PropTypes.string.isRequired,
  columns: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  isMore: PropTypes.any,
  category: PropTypes.string,
  dayMulti: PropTypes.string,
  setAdventuresFilter: PropTypes.func.isRequired,
  getAdventures: PropTypes.func.isRequired,
  updateRegion: PropTypes.func,
  getLoadMore: PropTypes.func,
};

const selectors = createStructuredSelector({
  dayMulti: adventuresFilterSelectors.dayMultiSelector,
  category: adventuresFilterSelectors.categorySelector,
});

const actions = {
  getAdventures: adventuresActions.getAdventures,
  setAdventuresFilter: adventuresSearchFilterActions.setAdventuresFilter,
};

export default connect(
  selectors,
  actions
)(CardList);
