import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/guides/avatar.svg';

function GuideModalHead({ profilePictureUrl, fullName, extra, adventures }) {
  const addDefaultSrc = e => {
    e.target.src = defaultImage;
  };

  return (
    <div className='guide-modal__header'>
      <div className='guide-modal__header__photo'>
        <img src={profilePictureUrl} alt={fullName} onError={addDefaultSrc} />
      </div>
      <div className='guide-modal__header__info'>
        <p>{fullName || 'Guider'}</p>
        {extra && (
          <div>
            {extra.rating && <span>★ {extra.rating}</span>}
            {extra.NumOfReviews && <span>{extra.NumOfReviews} reviews</span>}
            {adventures && <span>{adventures.length} adventures</span>}
          </div>
        )}
      </div>
    </div>
  );
}

GuideModalHead.propTypes = {
  fullName: PropTypes.string,
  profilePictureUrl: PropTypes.string.isRequired,
  extra: PropTypes.shape({
    rating: PropTypes.string,
    NumOfReviews: PropTypes.number,
  }),
  adventures: PropTypes.arrayOf(PropTypes.object),
};

GuideModalHead.defaultProps = {
  fullName: 'Guider',
  extra: {
    rating: null,
    NumOfReviews: null,
  },
  adventures: null,
};

export default GuideModalHead;
