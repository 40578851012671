import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { LOCATIONS_GET } from './types';

const initialState = {
  data: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(LOCATIONS_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(LOCATIONS_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(LOCATIONS_GET)]: (state, { payload }) => ({
      ...state,
      status: requestSuccess(LOCATIONS_GET),
      data: payload,
      error: null,
    }),

    [requestFail(LOCATIONS_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(LOCATIONS_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
