import React from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';

function FixedFooter({ children }) {
  const { scrollIsBottom } = useSelector(
    state => ({
      scrollIsBottom: state.general.scrollIsBottom,
    }),
    shallowEqual
  );
  return (
    <div className={`fixed-footer ${scrollIsBottom && 'hidden'}`}>
      <div className='fixed-footer__container'>{children}</div>
    </div>
  );
}

FixedFooter.propTypes = {
  children: PropTypes.node.isRequired,
};

export default FixedFooter;
