import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { USER_GET, USER_SET, USER_ID_GET } from './types';

const initialState = {
  data: null,
  id: null,
  userRole: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(USER_GET)]: state => ({
      ...state,
      status: requestPending(USER_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(USER_GET)]: (state, { payload }) => ({
      ...state,
      status: requestSuccess(USER_GET),
      data: payload,
      userRole: payload.data.user.userRole,
      error: null,
    }),

    [requestFail(USER_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(USER_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [requestPending(USER_ID_GET)]: state => ({
      ...state,
      status: requestPending(USER_ID_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(USER_ID_GET)]: (state, { payload }) => ({
      ...state,
      id: payload.data.loggedInUser.id,
      status: requestSuccess(USER_ID_GET),
      error: null,
    }),

    [requestFail(USER_ID_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(USER_ID_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [USER_SET]: (state, { payload }) => {
      return {
        ...state,
        ...payload,
      };
    },
  },
  initialState
);
