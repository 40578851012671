/**
 * @file Holds the <AdventurePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { withApollo } from 'react-apollo';
import { createStructuredSelector } from 'reselect';

import styles from 'styles/pages/Payment.module.scss';

import {
  actions as adventuresByIdActions,
  selectors as adventuresByIdSelector,
} from '../redux/modules/AdventuresID';
import { selectors as adventuresSearchFilterSelectors } from '../redux/modules/AdventuresFilter';
import { actions as bookAdventureActions } from '../redux/modules/BookAdventure';

// import { forEach } from 'gl-matrix/src/gl-matrix/vec4';

/* Component definition */
class PaySuccess extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      adventure: null,
      env: 'sandbox',
      addedInsurance: true,
      client: {
        sandbox: 'AY_VzrzAXo4AyfJhexBOm3r_W_dkRHDTibX5GtnbfHKCCKAOobUXuvLb3Xaneq0a8dtmVBBVWAwEkn83',
        production:
          'ASAnlODjnfm2gqz0nOHXUM2Qez7Ais8aoYK-lGVzHXgLuBv7b2bjKjr7eNAs2khFVQM7P4NcyQHTVNt2',
      },
      commit: true,
    };
  }

  componentDidMount() {
    const { adventure, getAdventuresById } = this.props;

    this.setState({ adventure: adventure });
    const searchParmas = this.props.history.location.search;
    this.toReceiveTransactionID(searchParmas);
    this.props.history.location.search = '';
    const history = this.props.history;
    this.props.history.replace(history);

    getAdventuresById({ id: this.props.match.params.id });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.adventure != this.props.adventure && nextProps.adventure) {
      this.setState({ adventure: nextProps.adventure });
    }
  }

  toReceiveTransactionID = searchParmas => {
    const { createBookingPayment } = this.props;
    const indexPNREF = searchParmas.search('PNREF=');
    const searchIndexAt = indexPNREF + searchParmas.substring(indexPNREF + 7).indexOf('&');
    const searchIndexSame = indexPNREF + searchParmas.substring(indexPNREF + 7).indexOf('=');
    const PNREF = searchParmas.substring(indexPNREF + 6, Math.min(searchIndexSame, searchIndexAt));
    if (PNREF)
      createBookingPayment({
        bookingId: localStorage.getItem('bookingId'),
        adventureId: localStorage.getItem('adventureId'),
        transactionId: PNREF,
      });
  };

  isPermittedGuide = () => {
    const { addedInsurance } = this.state;
    this.setState({ addedInsurance: !addedInsurance });
  };

  onAuthorize(data, actions) {
    console.log('checkingOnAuthorize', data, actions);
  }

  payment(data, actions) {
    console.log('checkingpayment', data, actions);
  }

  onSuccess = payment => {
    console.log('The payment was succeeded!', payment);
  };

  onCancel = data => {
    console.log('The payment was cancelled!', data);
  };

  onError = err => {
    console.log('Error!', err);
  };

  render() {
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          <h2>Success Payment</h2>
        </div>
      </div>
    );
  }
}

/* Prop types definition */
PaySuccess.propTypes = {
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  adventure: PropTypes.object,
  adventureId: PropTypes.string,
  getAdventuresById: PropTypes.func.isRequired,
  createBookingPayment: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({
  adventure: adventuresByIdSelector.adventuresByIdSelector,
  adventureId: adventuresSearchFilterSelectors.adventureIdSelector,
});

const actions = {
  getAdventuresById: adventuresByIdActions.getAdventuresById,
  createBookingPayment: bookAdventureActions.createBookingPayment,
  updateBookingPayment: bookAdventureActions.updateBookingPayment,
};

export default compose(
  withApollo,
  connect(
    selectors,
    actions
  )
)(PaySuccess);
