import PropTypes from 'prop-types';
import React from 'react';

import styles from 'styles/pages/CreateAdventure.module.scss';

const CircleStep = props => {
  const { step } = props;

  return (
    <div className={styles.circles}>
      <div className={step === 1 ? styles.circleActive : styles.circle} />
      <div className={step === 2 ? styles.circleActive : styles.circle} />
      <div className={step === 3 ? styles.circleActive : styles.circle} />
      <div className={step === 4 ? styles.circleActive : styles.circle} />
      <div className={step === 5 ? styles.circleActive : styles.circle} />
      <div className={step === 6 ? styles.circleActive : styles.circle} />
      <div className={step === 7 ? styles.circleActive : styles.circle} />
    </div>
  );
};

CircleStep.propTypes = {
  step: PropTypes.number.isRequired,
};

export default CircleStep;
