import { createAction } from 'redux-actions';

import {
  USER_INFO_UPDATE,
  USER_CONTACT_UPDATE,
  USER_ABOUT_UPDATE,
  USER_REQUIREMENT_UPDATE,
  USER_ROLE_UPDATE,
  USER_STATUS_UPDATE,
} from './types';

export const updateUserInfo = createAction(USER_INFO_UPDATE);
export const updateUserContact = createAction(USER_CONTACT_UPDATE);
export const updateUserAbout = createAction(USER_ABOUT_UPDATE);
export const updateUserRequirement = createAction(USER_REQUIREMENT_UPDATE);
export const updateUserRole = createAction(USER_ROLE_UPDATE);
export const updateUserStatus = createAction(USER_STATUS_UPDATE);
