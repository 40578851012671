// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { GUIDES_ID_GET } from '../modules/GuidesID/types';
import { getUserGuides } from 'api/userGuides';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doGetGuidesById = apiCall({
  type: GUIDES_ID_GET,
  method: 'POST',
  baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
  path: '',
  body: { query: getUserGuides, variables: {} },
  payloadOnSuccess: (responseData, action) => {
    responseData.id = action.payload.id;
    return responseData;
  },
});

export default function* rootSaga() {
  yield takeLatest(GUIDES_ID_GET, doGetGuidesById);
}
