// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest, call } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import {
  NEW_ADVENTURE_BOOK,
  DELETE_ADVENTURE_BOOK,
  CREATE_ADVENTURE_BOOKING_PAYMENT,
} from '../modules/BookAdventure/types';
// import { userSelector } from '../modules/users/selectors';
// import { myTripsSelector } from '../modules/trips/selectors';
// import { doGetTrips } from './trips';

import { getActivelymeAPIEnvUrl } from 'utils/initializers';

const doBookAPICall = method =>
  apiCall({
    type: NEW_ADVENTURE_BOOK,
    method: method,
    baseURL: getActivelymeAPIEnvUrl(),
    path: ({ payload }) => {
      return `/booking/v1/bookings/trips/user/${payload.userId}/adventure/${payload.adventureId}`;
    },
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: ({ payload }) => {
      return {
        dateRange: payload.departureDate,
        numberOfTravelers: Number(payload.numberOfTravelers),
        tripMessage: payload.tripMessage,
        participantAgree: payload.isParticipantAgree,
      };
    },
  });

const deleteBookAPICall = method =>
  apiCall({
    type: DELETE_ADVENTURE_BOOK,
    method: method,
    baseURL: getActivelymeAPIEnvUrl(),
    path: ({ payload }) => {
      return `/booking/v1/trips/booking/${payload.bookingId}`;
    },
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: {},
  });

const createBookPaymentAPICall = userId =>
  apiCall({
    type: CREATE_ADVENTURE_BOOKING_PAYMENT,
    method: 'post',
    baseURL: getActivelymeAPIEnvUrl(),
    path: ({ payload }) => {
      return `/booking/v1/trips/user/${userId}/booking/${payload.bookingId}/adventure/${
        payload.adventureId
      }/payer/${payload.transactionId}/excute`;
    },
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: {},
  });

// const updateBookPaymentAPICall = (method) => apiCall({
//   type: method,
//   method: method,
//   baseURL: getActivelymeAPIEnvUrl(),
//   path: ({payload}) => {
//     return `/booking/v1/trips/booking/${payload.bookingId}`;
//   },
//   headers: {
//     Authorization: `Bearer ${localStorage.id_token}`,
//   },
//   body: {}
// });

export const doBookAdventure = function*(params) {
  //To update booking request when it is already existing in Database
  // let myTrips = yield select(myTripsSelector);

  // if(myTrips.length === 0) {
  //   yield call(doGetTrips);
  //   myTrips = yield select(myTripsSelector);
  // }

  // let method, bookingId = '';
  // myTrips.map((item) => {
  //   item.bookings.map((book) => {
  //     if(book.adventure.id === params.payload.adventureId) {
  //       method = 'put';
  //       bookingId = book.id;
  //     } else {
  //       method = 'post';
  //     }
  //   });
  // });

  // params.payload['bookingId'] = bookingId;

  yield call(doBookAPICall('post'), { payload: params.payload });
};

const createBookPayment = function*(params) {
  console.log('helloStartedCreatBookPayment');
  const user = localStorage.getItem('userId');
  yield call(createBookPaymentAPICall(user), { payload: params.payload });
};

export const doDeleteBookAdventure = function*(params) {
  yield call(deleteBookAPICall('delete'), { payload: params.payload });
};

export default function* rootSaga() {
  yield takeLatest(NEW_ADVENTURE_BOOK, doBookAdventure);
  yield takeLatest(DELETE_ADVENTURE_BOOK, doDeleteBookAdventure);
  yield takeLatest(CREATE_ADVENTURE_BOOKING_PAYMENT, createBookPayment);
}
