import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { TOKEN_GET } from './types';

const initialState = {
  data: null,
  token: null,
  tokenId: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(TOKEN_GET)]: state => ({
      ...state,
      status: requestPending(TOKEN_GET),
      error: false,
      token: null,
      tokenId: null,
      message: '',
    }),

    [requestSuccess(TOKEN_GET)]: (state, { payload }) => {
      console.log('checkingTokenResult', payload);
      return {
        ...state,
        status: requestSuccess(TOKEN_GET),
        token: payload.secure_token,
        tokenId: payload.secure_token_id,
        error: null,
      };
    },

    [requestFail(TOKEN_GET)]: (state, { payload }) => ({
      ...state,
      token: null,
      tokenId: null,
      status: requestFail(TOKEN_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
