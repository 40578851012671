import React from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { actions } from '../../redux/modules/general';

function Modal({ className, children, modalName }) {
  const { isModalOpen, propsModalName } = useSelector(
    state => ({
      isModalOpen: state.general.modal.isModalOpen,
      propsModalName: state.general.modal.modalName,
    }),
    shallowEqual
  );
  const dispatch = useDispatch();

  const toggle = () => {
    dispatch(actions.toggleModal());
  };

  const onOverlayClick = e => {
    if (e.target.className === 'modal-overlay') {
      toggle();
    }
  };

  return modalName === propsModalName && isModalOpen ? (
    <>
      {isModalOpen && (
        <div className='modal-overlay' onClick={onOverlayClick}>
          <div className={`modal-popup ${className}`}>
            <div className='modal-item'>{children}</div>
          </div>
        </div>
      )}
    </>
  ) : (
    <></>
  );
}

Modal.propTypes = {
  children: PropTypes.node.isRequired,
  modalName: PropTypes.string,
  className: PropTypes.string,
  isOpen: PropTypes.bool,
};

Modal.defaultProps = {
  className: '',
  isOpen: false,
};

export default Modal;
