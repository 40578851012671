import React, { Component } from 'react';
import PropTypes from 'prop-types';

import Container from '../../../common/container/Container';
import AdventuresItem from './AdventuresItem';
import BookRequest from 'components/BookRequest';
import img0 from '../../../assets/adventures/img1.png';
import user0 from '../../../assets/adventures/user1.png';

import './Adventures.scss';

class Adventures extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isloader: false,
      initial: true,
      adventure: {},
      selectedDays: [],
      yearAndMonthToOpen: '',
      tripMessage: 'test',
      numberOfTravelers: 1,
      isModalVisible: false,
      isParticipantAgree: true,
      isOpenSendMessageModal: false,
      isBookNowModal: false,
      name: '',
      email: '',
      bookDescription: 'I would like to book a trip to ',
      sendMessageError: false,
      openBookTourModal: false,
      errorName: '',
      dateRange: '01/04/2019',
      questName: '',
      questEmail: '',
      QuestTripMessage: '',
      adventureTitle: '',
    };
  }

  render() {
    const { isBookNowModal } = this.state;
    let { data } = this.props;
    let adventures = data[0].great_guides[0].adventures.advertiseItems;
    return (
      <div className='adventures ladakh'>
        <Container>
          {isBookNowModal && (
            <BookRequest
              handleBook={this.handleBook}
              sendContactDetail={this.sendContact}
              closeContact={this.closeContact}
            />
          )}
          <div className='row'>
            {adventures.map((advertise, i) => (
              <AdventuresItem
                key={i}
                mainImg={img0}
                userImg={user0}
                allData={advertise}
                {...this.props}
              />
            ))}
          </div>
        </Container>
      </div>
    );
  }
}

Adventures.propTypes = {
  data: PropTypes.array,
};

export default Adventures;
