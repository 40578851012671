/**
 * @file Holds the <ErrorHandling> component
 * @since 0.1.0
 * @author Andrey Tuhel
 */
import React from 'react';
import PropTypes from 'prop-types';

import style from '../styles/components/ErrorHandling.module.scss';

/* Component definition */
const ErrorHandling = ({ category }) => {
  return <div className={style.errorHandling}>{category} is required.</div>;
};

/* PropTypes definition */
ErrorHandling.propTypes = {
  category: PropTypes.string.isRequired,
};

export default ErrorHandling;
