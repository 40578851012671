import React, { Component } from 'react';

import Blog from '../components/Blog';

class News extends Component {
  render() {
    return (
      <div className='blog-page'>
        <Blog />
      </div>
    );
  }
}

export default News;
