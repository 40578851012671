import { takeLatest, select, call } from 'redux-saga/effects';
import apiCall from '../api/apiCall';
import { getActivelymeAPIEnvUrl } from 'utils/initializers';
import {
  ADVENTURE_CREATE,
  ADVENTURE_UPDATE,
  ADVENTURE_IMAGE_UPLOAD,
  ADVENTURE_IMAGE_REMOVE,
} from '../modules/CreateAdventure/types';
import {
  adventureSelector,
  adventureIDSelector,
  mediaSelector,
} from '../modules/CreateAdventure/selectors';
import { userIdSelector } from '../modules/users/selectors';

const doCreateAPI = (adventure, method, path) =>
  apiCall({
    type: ADVENTURE_CREATE,
    method: method,
    baseURL: getActivelymeAPIEnvUrl(),
    path: path,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: adventure,
  });

const doMediaAPI = (method, path, file) =>
  apiCall({
    type: ADVENTURE_IMAGE_UPLOAD,
    method: method,
    baseURL: getActivelymeAPIEnvUrl(),
    path: path,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
      'Content-Type': 'application/octet-stream',
    },
    body: { data: file },
    original: true,
  });

const doMediaRemoveAPI = (method, path) =>
  apiCall({
    type: ADVENTURE_IMAGE_REMOVE,
    method: method,
    baseURL: getActivelymeAPIEnvUrl(),
    path: path,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
      'Content-Type': 'application/octet-stream',
    },
  });

const doCreateAdventure = function*() {
  const adventure = yield select(adventureSelector);
  const userId = yield select(userIdSelector);
  const path = `/adventure/v1/adventures/user/${userId}`;
  yield call(doCreateAPI(adventure, 'POST', path), { payload: '' });
};

const doUpdateAdventure = function*() {
  const adventureID = yield select(adventureIDSelector);
  const adventure = yield select(adventureSelector);
  const path = `/adventure/v1/adventures/${adventureID}`;
  yield call(doCreateAPI(adventure, 'PUT', path), { payload: '' });
};

const doUploadAdventureMedia = function*(params) {
  const adventureID = yield select(adventureIDSelector);
  const path = `/media/v1/adventures/${adventureID}/media?filename=${
    params.payload.fileName
  }&copyright=${params.payload.copyright}`;
  yield call(doMediaAPI('POST', path, params.payload.file), { payload: '' });
};

const doRemoveAdventureMedia = function*(params) {
  const media = yield select(mediaSelector);
  const path = `/media/v1/media/${media[params.payload.id]}`;
  yield call(doMediaRemoveAPI('DELETE', path), { payload: '' });
};

export default function* rootSaga() {
  yield takeLatest(ADVENTURE_CREATE, doCreateAdventure);
  yield takeLatest(ADVENTURE_UPDATE, doUpdateAdventure);
  yield takeLatest(ADVENTURE_IMAGE_UPLOAD, doUploadAdventureMedia);
  yield takeLatest(ADVENTURE_IMAGE_REMOVE, doRemoveAdventureMedia);
}
