import React from 'react';
import moment from 'moment';
import dateFns from 'date-fns';
import PropTypes from 'prop-types';
import styles from 'styles/pages/CreateAdventure/calendar.module.scss';

const Calendar = ({ dates, selectedDates, selected }) => {
  function groupDates() {
    const groupedDates = [];

    const result = dates.reduce((acc, item) => {
      /* E.g.: January 2019 */
      const date = dateFns.format(item, 'MMMM YYYY');

      if (acc[date]) {
        acc[date].push(item);
      } else {
        acc[date] = [item];
      }

      return acc;
    }, {});

    for (const key in result) {
      if (result.hasOwnProperty(key)) {
        groupedDates.push({
          month: key.split(' ')[0],
          year: key.split(' ')[1],
          dates: result[key],
        });
      }
    }

    return groupedDates;
  }

  /* Renders dates blocks */
  function renderDates() {
    /* Takes grouped dates */
    const groupedDates = groupDates();

    /* Returns markup */
    return groupedDates.map((item, index) => {
      // setCurrentCalendar(calendar);
      return <div key={index}>{renderCalendar(groupedDates[index])}</div>;
    });
  }

  function checkBookAvailable(calendar, startDate) {
    if (calendar) {
      const day = moment(calendar.year + '-' + calendar.month + '-' + startDate).format(
        'YYYY-MM-DD'
      );
      const isExisting = selectedDates.indexOf(day);

      if (isExisting < 0) {
        return '';
      } else return styles.available;
    }
  }

  const handleDate = (calendar, startDate) => {
    const day = moment(calendar.year + '-' + calendar.month + '-' + startDate).format('YYYY-MM-DD');
    selected(day);
  };

  /* Renders calendar UI */
  function renderCalendar(calendar) {
    /* Initializers */
    const daysOfWeek = ['Su', 'Mo', 'Tu', 'We', 'Th', 'Fr', 'Sa'],
      daysOfMonth = [],
      monthStart = dateFns.startOfMonth('2019-11-23'),
      monthEnd = dateFns.endOfMonth(monthStart),
      endDate = dateFns.endOfWeek(monthEnd);

    let startDate = dateFns.startOfWeek(monthStart);

    /**
     * Calculates all days in month of given date
     * and generates markup
     */
    while (startDate <= endDate) {
      /* Formats to days number */
      const formattedDay = dateFns.format(startDate, 'D');

      const isBookAvailable = checkBookAvailable(calendar, formattedDay);

      /* Hides dates out of the current month */
      const isHidden = !dateFns.isSameMonth(startDate, monthStart) ? styles.hidden : '';

      /* Sets classname for day item */
      const className = [isHidden, isBookAvailable].join(' ');

      /* Pushes final markup to array */
      daysOfMonth.push(
        <li
          className={className}
          key={startDate + isHidden}
          onClick={() => handleDate(calendar, formattedDay)}
        >
          {formattedDay}
        </li>
      );

      /* Restarts loop */
      startDate = dateFns.addDays(startDate, 1);
    }

    /* Renders markup */
    if (calendar) {
      return (
        <div className={styles.calendar}>
          <h3>
            {calendar.month} {calendar.year}
          </h3>

          <ul className={styles.daysOfWeek}>
            {daysOfWeek.map((item, i) => (
              <li key={i}>{item}</li>
            ))}
          </ul>

          <ul className={styles.daysOfMonth}>{daysOfMonth}</ul>
        </div>
      );
    }
  }

  /* Basic component's markup */
  return renderDates();
};

/* Prop types definition */
Calendar.propTypes = {
  dates: PropTypes.array.isRequired,
  selectedDates: PropTypes.array.isRequired,
  selected: PropTypes.func.isRequired,
};

export default Calendar;
