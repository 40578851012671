/**
 * @file Holds the <HomePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toast } from 'react-toastify';
import ReactPixel from 'react-facebook-pixel';

import { createStructuredSelector } from 'reselect';
import { compose, graphql, withApollo } from 'react-apollo';
import styles from 'styles/pages/HomePage.module.scss';
import HomeHeader from 'components/HomeHeader';
// import SocialReference from 'components/SocialReference';
import HomeLocations from '../components/HomeLocations.js';
import AdventureCard from 'components/AdventureCard';
import GuideCard from 'components/GuideCard';
import CardList from 'containers/CardList';
import SubscribeToNewsletter from 'components/SubscribeToNewsletter';
import ReadMoreTextBlock from 'components/ReadMoreTextBlock';
import MapBox from 'components/MapBox';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {
  actions as categoriesActions,
  selectors as categoriesSelectors,
} from '../redux/modules/categories';
import {
  actions as locationsActions,
  selectors as locationsSelectors,
} from '../redux/modules/locations';
import {
  actions as adventuresSearchFilterActions,
  selectors as adventuresFilterSelectors,
} from '../redux/modules/AdventuresFilter';
import {
  actions as adventuresActions,
  selectors as adventuresSelectors,
} from '../redux/modules/adventures';
import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../redux/modules/SendContact';
import { actions as updateUserActions } from '../redux/modules/UpdateUser';
import { actions as userActions, selectors as userSelectors } from '../redux/modules/users';
import { actions as guidesActions, selectors as guidesSelectors } from '../redux/modules/guides';
import {
  actions as guidesRegionsActions,
  selectors as guidesRegionsSelectors,
} from '../redux/modules/GuidesRegions';

import { getUserAuthID } from 'api/user';
import { getUserDetails } from 'api/userDetails';

const moment = require('moment');

/* Component definition */
class HomePage extends React.Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    location: PropTypes.string,
    day_multi: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
    location: null,
    day_multi: null,
    category: 'All',
    difficulty: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentGuidePage: 1,
      regions: [],
      region: 'All',
      guides: [],
      currentAdventurePage: 1,
      showSubscribe: true,

      endDate: '',
      location: '',
      dayMulti: '',
      category: '',
    };
  }

  shouldComponentUpdate(nextProps) {
    const { loggedInUser } = nextProps;

    if (loggedInUser) {
      localStorage.setItem('loggedInUser', loggedInUser.id);
      if (nextProps.user !== this.props.user && nextProps.user) {
        localStorage.setItem('userRole', nextProps.user.userRole);
      }
    }

    if (nextProps.sendContactStatus && nextProps.sendContactStatus === 'Success') {
      toast.success('Thank you for subscribing our message list.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else if (nextProps.sendContactStatus && nextProps.sendContactStatus === 'fail') {
      toast.error('There is some problem in sending your message.', {
        position: toast.POSITION.TOP_RIGHT,
      });
    }

    return true;
  }

  componentDidMount() {
    const {
      getGuides,
      getCategories,
      getLocations,
      setAdventuresFilter,
      getAdventures,
      getGuidesRegions,
      getUserID,
    } = this.props;
    getUserID();
    getLocations(); // Getting Locations
    getGuides({ isLoadMore: false }); //Getting Guides
    getCategories(); // Getting Categories
    getGuidesRegions(); // Getting Regions of Users
    let d = new Date();
    var year = d.getFullYear();
    setAdventuresFilter({
      ['startDate']: moment(new Date()).locale(''),
      ['endDate']: moment(year + 1 + '-12-31').locale(''),
      ['dest']: '',
      ['category']: 'All',
    });
    getAdventures({ isLoadMore: false }); //Getting Adventures
    this.getFormatRegions();
    let subscribe = sessionStorage.getItem('subscribe');
    this.setState({
      subscribe: subscribe,
    });
  }

  getFormatRegions = () => {
    const { guidesRegions } = this.props;

    let regions = [];
    regions.push('All');

    guidesRegions.map(region => {
      regions.push(region.region);
    });
  };

  updateRegionType = type => {
    let { guides } = this.state;
    this.setState({ region: type, guides: [] });

    this.props.guides.map(guide => {
      if (guide.region === type) guides.push(guide);
    });
  };

  getLoadMore = type => {
    const { getGuides, getAdventures } = this.props;

    if (type === 'guides') {
      getGuides({ isLoadMore: true });
    } else {
      getAdventures({ isLoadMore: true });
    }
  };

  changePage = data => {
    const {
      history: { push },
    } = this.props;
    push(`/adventures?${new URLSearchParams(data).toString()}`);
  };

  sendContact = email => {
    const { sendContact } = this.props;
    sendContact({ email: email, tripMessage: 'Subscribe to newsletter' });
  };

  generateUniqueRegions = guidesRegions => {
    //Generate unique regions
    let regions = [];
    regions.push('All');

    let newRegions = [...new Set(guidesRegions.map(region => region.region))];
    newRegions.map(item => {
      if (item !== null && item !== '') regions.push(item);
    });

    return regions;
  };

  closeSubscribe = () => {
    this.setState({
      showSubscribe: false,
    });
    sessionStorage.setItem('subscribe', 'hidden');
  };

  setFilter = data => {
    this.setState({
      ...data,
    });
  };

  submitFilter = () => {
    const { endDate, location, dayMulti, category } = this.state;
    const {
      setAdventuresFilter,
      history: { push },
    } = this.props;

    setAdventuresFilter({
      ...(location && { ['location']: location }),
      ...(endDate && { ['endDate']: moment(endDate) }),
      ...(dayMulti && { ['day_multi']: dayMulti }),
      ...(category && { ['category']: category }),
    });

    const urlParams = {
      ...(location && { location: location }),
      ...(endDate && { endDate: endDate }),
      ...(dayMulti && { dayMulti: dayMulti }),
      ...(category && { category: category }),
    };

    push(`/adventures?${new URLSearchParams(urlParams).toString()}`);
  };

  render() {
    const advancedMatching = { em: 'some@email.com' };
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    const {
        adventures,
        categories,
        guidesRegions,
        guides,
        allGuides,
        locations,
        startDate,
        history: { push },
      } = this.props,
      { region } = this.state;

    const { endDate, dayMulti, category, location } = this.state;

    let regions = this.generateUniqueRegions(guidesRegions);
    return (
      <main className={styles.wrapper}>
        {/* Same as */}
        <HomeHeader
          guides={guides}
          locations={locations}
          categories={categories}
          startDate={startDate}
          endDate={endDate}
          dayMulti={dayMulti}
          category={category}
          filterLocation={location}
          setFilter={this.setFilter}
          submitFilter={this.submitFilter}
          push={push}
        />
        {/* <SocialReference */}
        <HomeLocations
          setAdventuresFilter={this.props.setAdventuresFilter}
          locations={this.props.locations}
          changePage={this.changePage}
        />
        {/* Popular adventures */}
        <LazyLoadComponent>
          <CardList
            categories={categories}
            bgColor='grey'
            isMore
            columns='c3'
            type='adventures'
            getLoadMore={this.getLoadMore}
          >
            <h3>Popular Adventures</h3>
            <p>
              Our guides have a lot of great adventures in different categories like Hiking,
              Rafting, Walking and Cultural experiences.
            </p>

            {adventures.length
              ? adventures.map((item, index) => <AdventureCard key={index} adventure={item} />)
              : []}
          </CardList>
        </LazyLoadComponent>

        {/* Our Guides */}
        {guides.length ? (
          // <LazyLoadComponent>
          <div id='homeGuide'>
            <CardList
              categories={categories}
              regions={regions}
              updateRegion={this.updateRegionType}
              bgColor='white'
              columns='c4'
              isMore
              getLoadMore={this.getLoadMore}
              type='guides'
              className='testtest'
            >
              <h3>Our guides</h3>

              {guides
                .filter(item => item.region === region || region === 'All')
                .map((item, index) => (
                  <GuideCard key={index} guide={item} />
                ))}
            </CardList>
          </div>
        ) : (
          // </LazyLoadComponent>
          ''
        )}

        {/* Map */}
        {guides.length ? (
          <LazyLoadComponent>
            <MapBox guides={allGuides} height={500} />
          </LazyLoadComponent>
        ) : (
          ''
        )}

        {/* Read more blocks */}
        <ReadMoreTextBlock bgColor='white' type='about'>
          <h3>About us</h3>
          <p>
            We are GreatGuides.com, an adventure travel site for the adventure seeker to meet and
            journey with inspirational guides. We believe in making adventure travel a personal
            connection between the adventurer and their guide.
          </p>
          <p>
            Our mission is to make the adventure travel experience transparent and accessible to
            everyone. We help guides launch sustainable and independent ventures with no upfront
            costs. Our goal is to build an adventure marketplace that benefits the guide, the local
            community and provides the adventurer a unique experience.
          </p>
          <q>
            Atma and Roddy are the brains and creative force behind GreatGuides.com. During a
            trekking holiday in Ladakh in 2011, Atma realised that to have a truly great travel
            experience, you need a truly great guide. A frequent traveller, Atma knew that it wasn’t
            always easy to find such a person. He’d also seen firsthand the challenges those guides
            faced trying to build their business. What if he could solve both those problems?
            <br />
            Atma created a site called ActivelyMe.com. While researching competitors, he came across
            GreatGuides.org, with its catalogue of stories from guides across the globe. After
            reaching out to its founder Roddy with the hope of collaborating, Atma ended up buying
            the site and expanding its reach to incorporate ActivelyMe. The site relaunched as
            GreatGuides.com, connecting adventure travellers with local guides offering both
            outstanding knowledge and a warm welcome. Meanwhile, the guides themselves benefit from
            gaining access to a platform that pairs them with potential clients and provides them
            with the tools to build a sustainable business. With a greater proportion of income
            staying in the local economy, everyone wins.
          </q>
        </ReadMoreTextBlock>
        <ReadMoreTextBlock bgColor='grey' isReverseOrder type='howItWorks'>
          <h3>How it works?</h3>
          <p>
            Guides can set up their profile and are approved based on their level of experience,
            their cultural and regional knowledge and past reviews. We recommend all guides follow
            our guide standards document. Once approved, guides remain in control of the itineraries
            they create. They work out the trip schedule they want us to promote and the price they
            wish to charge for their services, leaving GreatGuides.com to take care of admin behind
            the scenes.
          </p>
          <q>
            Meanwhile, travellers get to access reviews and trip histories as well as check what
            skills a guide brings to the table. Via the site, the traveller and guide have the
            opportunity to discuss any...
          </q>
        </ReadMoreTextBlock>
        <ReadMoreTextBlock bgColor='white' type='whyDifferent'>
          <h3>Why we are different</h3>
          <p>
            GreatGuides.com facilitates direct bookings between the adventure traveller and the
            guide ensuring an independent rather than a corporate experience. Until now, that’s been
            difficult to achieve – setting up a website and paying for marketing doesn’t come cheap.
            Plus, the few independent guides that do have a significant online presence are mostly
            city-based.
          </p>
          <q>
            Via GreatGuides.com, the guides get to keep the majority of their fee, rather than
            losing much of it to intermediary tour operators. Travellers can therefore leave home
            secure in the knowledge that they’re supporting the local economy without risking the
            quality…
          </q>
        </ReadMoreTextBlock>

        {/* Atma Remove popup - Subscribe to newsletter */}
        <SubscribeToNewsletter sendContact={this.sendContact} />
        
        {this.state.showSubscribe && this.state.subscribe !== 'hidden' ? (
         

          // <div className='subscribe-popup'>
          <div>
          {/*
            <div className='close-subscribe' onClick={this.closeSubscribe}>
              x
            </div>
           
            <SubscribeToNewsletter sendContact={this.sendContact} />
           */}  
          </div>
        
        ) : null}
         
      </main>
    );
  }
}

HomePage.propTypes = {
  history: PropTypes.object.isRequired,
  getLocations: PropTypes.func.isRequired,
  getAdventures: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  allGuides: PropTypes.array.isRequired,
  guides: PropTypes.array.isRequired,
  adventures: PropTypes.array.isRequired,
  setAdventuresFilter: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  getGuides: PropTypes.func.isRequired,
  getGuidesRegions: PropTypes.func.isRequired,
  guidesRegions: PropTypes.array.isRequired,
  loggedInUser: PropTypes.object,
  user: PropTypes.object,
  sendContact: PropTypes.func.isRequired,
  sendContactStatus: PropTypes.string,
  getUserID: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  loggedUser: PropTypes.string,
};

const selectors = createStructuredSelector({
  locations: locationsSelectors.locationsSelector,
  startDate: adventuresFilterSelectors.startDateSelector,
  endDate: adventuresFilterSelectors.endDateSelector,
  location: adventuresFilterSelectors.locationSelector,
  day_multi: adventuresFilterSelectors.dayMultiSelector,
  category: adventuresFilterSelectors.categorySelector,
  difficulty: adventuresFilterSelectors.difficultySelector,
  adventures: adventuresSelectors.adventuresSelector,
  categories: categoriesSelectors.categoriesSelector,
  guides: guidesSelectors.guidesSelector,
  allGuides: guidesSelectors.guidesAllSelector,
  guidesRegions: guidesRegionsSelectors.guidesRegionSelector,
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
  loggedUser: userSelectors.userIdSelector,
});

const actions = {
  getLocations: locationsActions.getLocations,
  getAdventures: adventuresActions.getAdventures,
  setAdventuresFilter: adventuresSearchFilterActions.setAdventuresFilter,
  getCategories: categoriesActions.getCategories,
  getGuides: guidesActions.getGuides,
  getGuidesRegions: guidesRegionsActions.getGuidesRegions,
  sendContact: sendContactActions.sendContact,
  getUserID: userActions.getUserID,
  setUser: userActions.setUser,
  updateUser: updateUserActions.updateUser,
};

const userId = {
  name: 'loggedInUser',
  // options for the initial query
  options() {
    return {
      variables: {},
    };
  },
  // convert the ugly apollo results into custom props
  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

const UserOptions = {
  name: 'userGetDetails',
  // options for the initial query
  options() {
    return {
      variables: { id: localStorage.getItem('loggedInUser') },
      fetchPolicy: 'cache-and-network',
    };
  },
  // convert the ugly apollo results into custom props
  props({ userGetDetails: { user } }) {
    return { user };
  },
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  graphql(getUserDetails, UserOptions),

  connect(selectors, actions)
)(HomePage);
