import React from 'react';
import PropTypes from 'prop-types';

import styles from 'styles/components/GuideDetails/Description.module.scss';

const ReferenceIcon = props => {
  const { iconName, title, value } = props;
  return (
    <div className={styles.icon_container}>
      <div className={styles.icon_wrapper}>
        <i className={iconName} />
      </div>
      <div>
        <span>{value}&nbsp;</span>
        <span className={styles.title}>{title}</span>
      </div>
    </div>
  );
};

/* PropTypes definition */
ReferenceIcon.propTypes = {
  iconName: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  value: PropTypes.any,
};

export default ReferenceIcon;
