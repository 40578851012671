import React from 'react';

import styles from 'styles/components/Loading.module.scss';

const Loading = () => (
  <div className={styles.loading}>
    <div className={styles.loader} />
  </div>
);

export default Loading;
