import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'redux';

import styles from 'styles/components/AdventureDetails/Response.module.scss';

const Responce = ({ heading, paragraph, isBookingForm, id }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <h2>{heading}</h2>
        <p>{paragraph}</p>
        <Link to={isBookingForm ? `/pay-adventure/${id}` : '/'} className={styles.button}>
          <button className={styles.button}>OK</button>
        </Link>
      </div>
    </div>
  );
};

Responce.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  isBookingForm: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
};

export default compose(withRouter)(Responce);
