export function environment() {
  if (process.env.NODE_ENV === 'production') return 'production';
  else if (process.env.NODE_ENV === 'development') return 'development';
}
export function getActivelymePlaygroundEnvUrl() {
  const env = environment();
  if (env === 'production') {
    return 'https://api.services.activelyme.com/graphql';
  }
  return 'https://api-test.services.activelyme.com/graphql';
}

export function getActivelymeAPIEnvUrl() {
  const env = environment();
  if (env === 'development') {
    return 'https://api-test.services.activelyme.com';
  }
  return 'https://api.services.activelyme.com';
}

export const non_EditableConfig = {
  key: 'RA3C3B14B11iB6E5E4H5I2H3B7B6D6D5C-11fpfeqaramoxF4nab==',
  placeholderText: 'Welcome Text Editing',
  charCounterCount: false,
  codeMirror: window.codeMirror,
  imageUploadParams: { class: 'fedit' },
  imageUploadToS3: {
    // Your bucket region
    bucket: 'activelyme-prod-media',
    region: 's3-us-west-2',
    keyStart: 'GuideAboutMe/',
    params: {
      acl: 'public-read', // ACL according to Amazon Documentation.
      AWSAccessKeyId: 'AKIAZ4L6E3MQIVZH2LKE', // Access Key from Amazon.
      policy: 's3:PutObjectAcl', // Policy string computed in the backend.
      signature: 's3:Put*', // Signature computed in the backend.
    },
  },
  events: {
    'froalaEditor.focus': function(e, editor) {
      editor.edit.off();
      editor.heightMin = 100;
      editor.heightMax = 200;
    },
  },
};

export const editableConfig = {
  key: 'RA3C3B14B11iB6E5E4H5I2H3B7B6D6D5C-11fpfeqaramoxF4nab==',
  placeholderText: 'Welcome Text Editing',
  charCounterCount: false,
  codeMirror: window.codeMirror,
  heightMin: 100,
  heightMax: 200,
  imageUploadParams: { class: 'fedit' },
  imageUploadToS3: {
    // Your bucket region
    bucket: 'activelyme-prod-media',
    region: 's3-us-west-2',
    keyStart: 'GuideAboutMe/',
    params: {
      acl: 'public-read', // ACL according to Amazon Documentation.
      AWSAccessKeyId: 'AKIAZ4L6E3MQIVZH2LKE', // Access Key from Amazon.
      policy: 's3:PutObjectAcl', // Policy string computed in the backend.
      signature: 's3:Put*', // Signature computed in the backend.
    },
  },
};
