import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import GuideModalHead from '../../components/GuideModal/GuideModalHead';
import GuideModalItem from '../../components/GuideModal/GuideModalItem';
import user from '../../assets/AdeventureDetails/2-users.png';
import emailImg from '../../assets/AdeventureDetails/email.jpg';
import Icon from 'components/Icon';

export default function BookAdventureView({
  guide: { profilePictureUrl, fullName, extra, adventures },
  data: { inquiry, name, email, phone, numberOfTravelers, date },
  validationRules: {
    inquiry: { errorMessage: inquiryError },
    name: { errorMessage: nameError },
    email: { errorMessage: emailError },
    numberOfTravelers: { errorMessage: numberOfTravelersError },
    date: { errorMessage: dateError },
    phone: { errorMessage: phoneError },
  },
  adventure: { id, title, dates, pricePerPerson, pricePerGroup, maxParticipants, minParticipants },
  changeData,
  submitData,
  loading,
}) {
  const isNotGroup = numberOfTravelers === '' || Number(numberOfTravelers) < 5;

  return (
    <div className='book-adventure'>
      <div className='book-adventure__head'>
        <h1>
          Book adventure :{' '}
          <Link to={`/adventure/${id}`} target='_blank'>
            {title}
          </Link>
        </h1>
      </div>
      <div className='book-adventure__form'>
        <GuideModalHead
          profilePictureUrl={profilePictureUrl}
          fullName={fullName}
          extra={extra}
          adventures={adventures}
        />
        <GuideModalItem
          field={name}
          error={nameError}
          name='name'
          type='text'
          placeholder='Your Name'
          onChange={changeData}
          icon={<img src={user} alt='icon' />}
        />
        <GuideModalItem
          field={email}
          error={emailError}
          name='email'
          type='text'
          placeholder='Your Email'
          onChange={changeData}
          icon={<img src={emailImg} alt='icon' />}
        />
        <GuideModalItem
          field={phone}
          error={phoneError}
          name='phone'
          type='text'
          placeholder='Your Phone Number'
          onChange={changeData}
          icon={<Icon name='difficulty' />}
        />
        <GuideModalItem
          field={numberOfTravelers}
          error={numberOfTravelersError}
          name='numberOfTravelers'
          type='number'
          maxMin={{
            min: minParticipants,
            max: maxParticipants,
          }}
          placeholder='Number of travelers'
          onChange={changeData}
          icon={<Icon name='persons' />}
        />
        <div className='book-adventure__price'>
          <p>Price {isNotGroup ? 'per person' : 'per group'} :</p>
          <span>US$ {isNotGroup ? pricePerPerson : pricePerGroup}</span>
        </div>
        <GuideModalItem
          field={date}
          error={dateError}
          name='date'
          type='datePicker'
          dates={dates}
          placeholder='Choose date'
          onChange={changeData}
          icon={<Icon name='season' />}
        />
        <GuideModalItem
          field={inquiry}
          error={inquiryError}
          name='inquiry'
          type='textarea'
          placeholder='Please add your inquiry here'
          onChange={changeData}
          icon={<Icon name='messages' />}
        />
        <button
          className={`guide-modal__body__submit ${loading ? 'loading' : ''}`}
          onClick={submitData}
          disabled={loading}
        >
          {loading ? <div className='loader' /> : <>Book adventure</>}
        </button>
      </div>
    </div>
  );
}

BookAdventureView.propTypes = {
  guide: PropTypes.shape({
    fullName: PropTypes.string,
    profilePictureUrl: PropTypes.string.isRequired,
    extra: PropTypes.shape({}),
    adventures: PropTypes.arrayOf(PropTypes.object),
  }).isRequired,
  data: PropTypes.shape({
    inquiry: PropTypes.string,
    name: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string,
    numberOfTravelers: PropTypes.string,
    date: PropTypes.string,
  }).isRequired,
  validationRules: PropTypes.shape({
    inquiry: PropTypes.shape({ errorMessage: PropTypes.string }),
    name: PropTypes.shape({ errorMessage: PropTypes.string }),
    email: PropTypes.shape({ errorMessage: PropTypes.string }),
    numberOfTravelers: PropTypes.shape({ errorMessage: PropTypes.string }),
    date: PropTypes.shape({ errorMessage: PropTypes.string }),
  }).isRequired,
  adventure: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    dates: PropTypes.arrayOf(PropTypes.string),
    pricePerPerson: PropTypes.number,
    pricePerGroup: PropTypes.number,
    minParticipants: PropTypes.number,
    maxParticipants: PropTypes.number,
  }).isRequired,
  changeData: PropTypes.func.isRequired,
  submitData: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
};
