import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';

import { GUIDE_CONTACT } from './FormFieldData';
import InputField from '../InputField';
import AccountTabList from './AccountTabList';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import { selectors as userSelectors } from '../../redux/modules/users';
import {
  actions as updateUserActions,
  selectors as updateUserSelectors,
} from '../../redux/modules/UpdateUser';

import styles from 'styles/pages/AccountDetailsPage.module.scss';
import 'react-toastify/dist/ReactToastify.min.css';

/* Component definition */
class GuideContact extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      phoneNumber: '',
      businessAddress: '',
      website: '',
      youtubeChannel: '',
    };
  }

  componentDidMount() {
    const { phoneNumber, businessAddress, website, youtubeChannel } = this.props;
    const { updateUserStatus } = this.props;

    updateUserStatus({
      ['status']: null,
    });

    this.setState({
      phoneNumber: phoneNumber,
      businessAddress: businessAddress,
      website: website,
      youtubeChannel: youtubeChannel,
    });
  }

  shouldComponentUpdate(nextProps) {
    const { userStatus } = nextProps;

    if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('success') && this.props.userStatus.includes('pending')) {
        toast.success('Successfully updated your profile', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('fail')) {
        toast.error('Failed to update your profile.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }
    return true;
  }

  onInputTextChange = () => {};

  setTabIndex = index => {
    const { setTabIndex } = this.props;

    setTabIndex(index);
    this.saveGuideContact();
  };

  saveGuideContact = () => {
    if (!this.validateForm()) {
      return;
    }

    const { setGuideInfo, updateUserContact, loggedUser } = this.props;
    const { phoneNumber, businessAddress, website, youtubeChannel } = this.state;

    //Set Guides' information
    setGuideInfo({
      ['phoneNumber']: phoneNumber,
      ['businessAddress']: businessAddress,
      ['website']: website,
      ['youtubeChannel']: youtubeChannel,
    });

    if (loggedUser) updateUserContact();
  };

  validateForm() {
    const { businessAddress } = this.state;

    if (businessAddress === '') {
      this.setState({ errorName: 'Location' });
      return false;
    }
    return true;
  }

  setFieldValue = (value, type) => {
    if (type === 'phone') this.setState({ phoneNumber: value });
    else if (type === 'Location') this.setState({ businessAddress: value });
    else if (type === 'youtube') this.setState({ youtubeChannel: value });
    else if (type === 'website') this.setState({ website: value });
  };

  render() {
    const { tabIndex } = this.props;
    const { phoneNumber, businessAddress, website, youtubeChannel, errorName } = this.state;

    return (
      <div>
        <AccountTabList setTabIndex={this.setTabIndex} tabIndex={tabIndex} />
        <form className={styles.form}>
          <div>
            {GUIDE_CONTACT.map((data, index) => {
              return (
                <div key={index}>
                  <InputField
                    wrapClass={styles.form_group}
                    label={data.label}
                    type={data.type}
                    holder={data.holder}
                    name={data.name}
                    labelClass={styles.label}
                    inputClass={styles.inputBorder}
                    inputErrorClass={styles.inputBorderError}
                    value={
                      data.name === 'phone'
                        ? phoneNumber
                        : data.name === 'Location'
                          ? businessAddress
                          : data.name === 'youtube'
                            ? youtubeChannel
                            : data.name === 'website'
                              ? website
                              : ''
                    }
                    setFieldValue={this.setFieldValue}
                    errorName={errorName}
                    errorText={styles.errorText}
                  />
                </div>
              );
            })}
          </div>

          <div className={styles.form_group}>
            <button
              type='button'
              className={styles.form_button}
              onClick={() => this.saveGuideContact()}
            >
              Save Changes
            </button>
          </div>
        </form>
        {tabIndex === 2 && <ToastContainer autoClose={3000} />}
      </div>
    );
  }
}

GuideContact.propTypes = {
  setGuideInfo: PropTypes.func.isRequired,
  updateUserContact: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  userStatus: PropTypes.string,
  phoneNumber: PropTypes.string,
  businessAddress: PropTypes.string,
  youtubeChannel: PropTypes.string,
  website: PropTypes.string,
  loggedUser: PropTypes.string,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({
  phoneNumber: GuideSetFilterSelectors.phoneNumberSelector,
  businessAddress: GuideSetFilterSelectors.businessAddressSelector,
  youtubeChannel: GuideSetFilterSelectors.youtubeChannelSelector,
  website: GuideSetFilterSelectors.websiteSelector,
  loggedUser: userSelectors.userIdSelector,
  userStatus: updateUserSelectors.userStatusSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserContact: updateUserActions.updateUserContact,
  updateUserStatus: updateUserActions.updateUserStatus,
};

export default connect(
  selectors,
  actions
)(GuideContact);
