// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { MESSAGE_SEND } from '../modules/SendMessage/types';

import { getActivelymeAPIEnvUrl } from 'utils/initializers';

export const doSendMessage = apiCall({
  type: MESSAGE_SEND,
  method: 'POST',
  baseURL: getActivelymeAPIEnvUrl(),
  headers: {
    Authorization: `Bearer ${localStorage.id_token}`,
  },
  path: ({ payload }) => {
    return `/conversation/v1/conversations/guide/${payload.id}/message`;
  },
  body: ({ payload }) => {
    return {
      text: payload.tripMessage,
    };
  },
});

export default function* rootSaga() {
  yield takeLatest(MESSAGE_SEND, doSendMessage);
}
