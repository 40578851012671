import { call, takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';

import { getGuide } from 'api/userGuides';

import { GUIDE_GET } from '../modules/GetGuide/types';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doAPICall = id =>
  apiCall({
    type: GUIDE_GET,
    method: 'POST',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    path: '',
    body: { query: getGuide, variables: { id: id } },
  });

export const doGetGuide = function*(params) {
  yield call(doAPICall(params.payload.id), { payload: '' });
};

export default function* rootSaga() {
  yield takeLatest(GUIDE_GET, doGetGuide);
}
