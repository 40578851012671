/**
 * @file Holds the <AdventureCard> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';

import Icon from 'components/Icon';

import styles from 'styles/components/Account/MyTrip/TripItem.module.scss';

const moment = require('moment');

/* Component definition */
class CustomerTripItem extends React.Component {
  componentDidMount() {}

  getUpdatedTime = updatedAt => {
    const today = moment(new Date());
    const updated = moment(updatedAt);
    const weeks = today.diff(updated, 'weeks');
    const days = today.diff(updated, 'days');
    const hours = today.diff(updated, 'hours');

    if (weeks) {
      return `${weeks} w ${days - 7 * weeks !== 0 ? `${days - 7 * weeks} d ` : ''}
        ${hours - 24 * days !== 0 ? `${hours - 24 * days} h ` : ''}ago`;
    } else if (days) {
      return `${days} d${hours - 24 * days !== 0 ? ` ${hours - 24 * days} h ` : ''}ago`;
    } else if (hours) {
      return `${hours} hours ago`;
    }
  };

  render() {
    const { bookings, border } = this.props;

    const tripStatus = item => (
      <div className={styles.status}>
        <div className={styles.statusInfo}>
          <div className={styles.bookInfo}>
            <div className={styles.gudieTxt}>{item.numberOfTravelers} Adults</div>
            <div className={styles.date}>{moment(item.updatedAt).format('DD MMM, YYYY')}</div>
            <div className={styles.gudieTxt}>${item.price}</div>
          </div>
          <div className={styles.big_vr} />
          <div className={styles.bookStatus}>
            <div className={styles.gudieTxt}>Status</div>
            <div className={styles.bookStatusTxt}>{item.bookingState}</div>
            <div className={styles.gudieTxt}>{this.getUpdatedTime(item.updatedAt)}</div>
          </div>
        </div>
      </div>
    );

    const desktopUserBreadCrumb = item => (
      <div className={styles.guideDeskTopBreadCrumb}>
        <div className={styles.guideAvatar}>
          <img src={item.user.profilePictureUrl} className={styles.guideThumb} />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.guideName}>{item.user.fullName}</div>
        <div className={styles.shot_vr} />
        <div>
          <Icon className={styles.message} name='message' />
          <span className={styles.messageTxt}>SEND MESSAGE</span>
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.txt}>DETAILS</div>
      </div>
    );

    const mobileUserBreadCrumb = item => (
      <div className={styles.guideMobileBreadCrumb}>
        <div className={styles.guideAvatar}>
          <img src={item.user.profilePictureUrl} className={styles.guideThumb} />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.guideName}>{item.user.fullName}</div>
        <div>
          <Icon className={styles.message} name='message' />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.txt}>DETAILS</div>
      </div>
    );

    const statusButtons = item => (
      <div>
        {item.bookingState === 'ToBeApproved' ? (
          <div className={styles.payment}>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button className={styles.paymentBtn}>DECLINE</button>
              </Link>
            </div>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button className={styles.paymentBtn}>CONFIRM</button>
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    const mobileStatusButtons = item => (
      <div>
        {item.bookingState === 'ToBeApproved' ? (
          <div className={styles.mobilePayment}>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button className={styles.paymentBtn}>DECLINE</button>
              </Link>
            </div>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button className={styles.paymentBtn}>CONFIRM</button>
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    return (
      <div className={styles.wrapper}>
        {bookings.map((item, index) => (
          <div
            className={[
              `${styles.item} ${border && bookings.length === index + 1 ? '' : styles.border}`,
            ]}
            key={index}
          >
            <div className={styles.thumb}>
              <img src={item.adventure.media[0].imageHQUrl} />
              <div className={styles.mobileTripStatus}>{tripStatus(item)}</div>
            </div>
            <div className={styles.details}>
              <div className={styles.adventure}>
                <div className={styles.adventureDetail}>
                  <div className={styles.adCategory}>{item.adventure.categories}</div>
                  <div className={styles.adTitle}>{item.adventure.title}</div>
                  <div className={styles.adLocation}>
                    <Icon className={styles.locationIcon} name='location' />
                    {item.adventure.address}
                  </div>
                </div>
                <div className={styles.desktopTripStatus}>{tripStatus(item)}</div>
              </div>
              <div className={styles.guide}>
                <div className={styles.guideInfo}>
                  {desktopUserBreadCrumb(item)}
                  {mobileUserBreadCrumb(item)}
                  {statusButtons(item)}
                </div>
              </div>
            </div>
            {mobileStatusButtons(item)}
          </div>
        ))}
      </div>
    );
  }
}

/* Prop types definition */
CustomerTripItem.propTypes = {
  bookings: PropTypes.array.isRequired,
  border: PropTypes.bool,
};

export default withRouter(CustomerTripItem);
