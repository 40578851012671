import React, { Component } from 'react';

import post_image from '../../assets/GuideProfile/post_image.png';
import { Link } from 'react-router-dom';

import star_rating from '../../assets/GuideProfile/star_rating.svg';

import './GuideProfile.scss';

class ProfileLeft extends Component {
  render() {
    return (
      <article className='profile-left'>
        <ul className='guide-nav'>
          <li>HIKING</li>
          <li>WALKING</li>
        </ul>
        <h1>Hello, my name is Steve!</h1>
        <div className='place'>Kigali, Rwanda</div>
        <p>
          Steve grew up in Zimbabwe in southern Africa. It is a varied country, rich in wildlife and
          Steve was raised, like so many Zimbabweans, exploring wild places. He became fascinated by
          plants and animals, particularly elephants. Steve was inspired by a generation of
          conservationists whose work in Rhodesia (as Zimbabwe was called) created some of the
          finest nature reserves in the world, including Mana Pools and Hwange. As a young man he
          began working as a guide and also helped fight poaching.{' '}
        </p>
        <Link to='/'>Read more</Link>
        <figure>
          <img src={post_image} alt='Post' />
          <figcaption>Steve Bolnick: Walking with Elephants</figcaption>
        </figure>
        <h3>Techincal Skills</h3>
        <p>
          Through experience I have excellent navigational and orientation skills. The safety of my
          clients is my top priority.
        </p>
        <h3>Customer Service And Management Skills</h3>
        <p>
          I speak fluent Portuguese, English and French. I have lived and worked in interior of the
          Algarve for the past 20 years and I have numerous contacts, business, personal and family.
        </p>
        <h3>Experties In Natural And Cultural History</h3>
        <p>
          During my 18 years working in the town hall of Loulé where I did guided tours,
          environmental education awareness sessions, information boards for the pedestrian trails
          in the two protected areas of Rocha da Pena and Fonte Benémola and fliers for publicity of
          the already mentioned protected areas. All these activities and tasks require a good
          knowledge of the natural and cultural history of this region.
        </p>
        <h3>Sustainable Standards</h3>
        <p>
          Like all Tour Animation Agents here in Portugal I signed on behalf of Morgans Nature Walks
          a Code of Conduct that requires me to guarantee that my clients respect the nature of the
          pedestrian trails and leave the natural heritage as they found it.
        </p>
        <div className='icon-views'>
          <div className='icon'>
            <div className='small-icon'>
              <img src={star_rating} alt='icon' />
            </div>
            <span className='name'>Rating</span>
            <span className='status'>4.9</span>
          </div>
          <div className='icon'>
            <div className='small-icon'>
              <img src={star_rating} alt='icon' />
            </div>
            <span className='name'>Reviews</span>
            <span className='status'>125</span>
          </div>
          <div className='icon'>
            <div className='small-icon'>
              <img src={star_rating} alt='icon' />
            </div>
            <span className='name'>Adventures</span>
            <span className='status'>4</span>
          </div>
          <div className='icon'>
            <div className='small-icon'>
              <img src={star_rating} alt='icon' />
            </div>
            <span className='name'>Expert</span>
            <span className='status'>Verified skills</span>
          </div>
        </div>
        {/* <div className='all-reviews'>
          <h4>125 Review
            <span>
              <img src={review_stars} alt='Review Star' />
              <img src={review_stars} alt='Review Star' />
              <img src={review_stars} alt='Review Star' />
              <img src={review_stars} alt='Review Star' />
              <img src={review_stars} alt='Review Star' />
              5.0/5
            </span>
          </h4>
        </div> */}
      </article>
    );
  }
}

export default ProfileLeft;
