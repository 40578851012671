// Privacy Policy data
export const ACCEPTABLE_USE_DATA =
  'You agree not to use the GreatGuides website located https://GreatGuides.com or the GreatGuides services of facilitating the posting and review of Activties (collectively, the “GreatGuides Platform”) to collect, upload, transmit, display, or distribute any content (a) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third party.';
export const PRIVACY_POLICY = [
  'GreatGuides Corp("us", "we", or "our") operates the www.greatguides.com website and the GreatGuides mobile application (the "Service").',
  'This page informs you of our policies regarding the collection, use, and disclosure of personal data when you use our Service and the choices you have associated with that data.',
  'We use your data to provide and improve the Service. By using the Service, you agree to the collection and use of information in accordance with this policy. Unless otherwise defined in this Privacy Policy, terms used in this Privacy Policy have the same meanings as in our Terms and Conditions.',
];

export const BINDING_DATA =
  'This Agreement and all rights and obligations thereunder shall be binding upon and inure to the benefit of each Party\'s successors and may not be assigned without the other Party\'s consent.';

export const ACCEPTABLE_USE_SUBLIST = [
  'a. In addition, You agree not to use any part of the GreatGuides Platform to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the GreatGuides Platform or violate the regulations, policies or procedures of such networks; (e) attempt to gain unauthorized access to the GreatGuides Platform, other computer systems or networks connected to or used together with the GreatGuides Platform, through password mining or other means; (f) harass or interfere with another user’s use and enjoyment of the GreatGuides Platform; or (g) introduce software or automated agents or scripts to the GreatGuides Platform so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the GreatGuides Platform.',
  'b. GreatGuides reserves the right (but has no obligation) to review any content submitted by You (“Content”), investigate, and/or take appropriate action against You in our sole discretion (including removing or modifying Your Content, terminating Your Account in accordance with this Agreement or the Terms of Use, and/or reporting You to law enforcement authorities) if You violate the Acceptable Use Policy or any other provision of this Agreement or otherwise create liability for GreatGuides or any other person.',
];

export const SEVERABILITY =
  'Any determination by any court of competent jurisdiction that any provision of this Agreement is invalid shall not affect the validity of any other provision of this Agreement, which shall remain in full force and effect and which shall be construed as to be valid under applicable law.';

export const LIABILITY_DATA =
  'Guide acknowledges that GreatGuides’s service is intended to be used to facilitate the booking of adventure travel trips and that GreatGuides does not guarantee the accuracy or reliability of any Participant accounts, the content of any listings, or the condition, legality, or suitability of any Activities. GreatGuides does not endorse any particular Participant or their actions. By offering their services, Guide hereby acknowledges and agrees that any and all services provided by it and its interactions with Participants are undertaken at Guide’s own risk. GreatGuides is not responsible and disclaims any and all liability related to Guide’s listings and Activities.';

export const SURVIVAL =
  'Notwithstanding anything to the contrary, the provisions of Sections 3, 4, 5, 6, 7, 8, 9, 10, 11, 13, 14, 15, 16, 17, and 18 hereof shall survive the termination of this Agreement.';

export const OFAC_DATA =
  'Guide represents and warrants that: (i) it is not on an SDN List (defined below), nor is it directly or indirectly owned or controlled by an SDN (defined below); and (ii) the consummation of any transaction contemplated under this Agreement will not violate any country sanctions program administered and enforced by the Office of Foreign Assets Control (“OFAC”) of the U.S. Department of the Treasury. For the purposes hereof, an SDN List is defined as one of the lists published by OFAC of individuals and companies owned or controlled by, or acting for or on behalf of, OFAC targeted countries, as well as individuals, groups, and entities, such as terrorists and narcotics traffickers, designated under OFAC programs that are not country-specific, and an SDN is one of the individuals or companies listed on an SDN List. This is a link to the Treasury Department’s page with the lists. Essentially all U.S. persons and companies are prohibited from doing business with anyone on the list. https://www.treasury.gov/resource-center/sanctions/SDN-List/Pages/default.aspx';

export const INDEMNIFICATION =
  'Guide hereby agrees to release, indemnify, hold harmless, and, at GreatGuides’s discretion, defend GreatGuides and its officers, directors, employees, and independent contractors from and against any third party claims, liabilities, damages, losses, and expenses arising out of or in any way connected with Guide’s breach of this Agreement, Guide’s improper use of GreatGuides’s services, or Guide’s interaction with any Participant or other third party including, but not limited to, any injuries, damages or losses of any kind arising out of any connection with or as a result of such interaction.';

export const LAW_DATA =
  'This Agreement shall be governed by and interpreted in accordance with the laws of the State of Washington, USA without reference to its conflicts of laws rules or principles.  The Parties consent to the exclusive jurisdiction of either (i) the Washington State Superior Court in and for King County or (ii) the federal court for the Western District of the State of Washington in connection with any dispute arising under this Agreement and hereby waives, to the maximum extent permitted by law, any objection, including any objection based on forum non conveniens, to the bringing of any such proceeding in such jurisdictions.';

export const LICENSE_DATA =
  'Guide represents and warrants that they carry adequate insurance coverage, the adequacy of which is in GreatGuides’s sole discretion, and Guide shall provide accurate proof of such insurance coverage as a condition to accessing GreatGuides’s platform. Guide represents and warrants that the insurance coverage information provided by it is true and accurate. Guide understands and agrees that GreatGuides does not act as an insurer or as a contracting agent for Guide. If a Participant requests to book an Activity, any agreement Guide enters into with such Participant is between Guide and the Participant and GreatGuides is not a party thereto. Notwithstanding the foregoing, GreatGuides serves as the limited authorized agent of Guide for the purpose of accepting payments from Participants on behalf of Guide and is responsible for transmitting such payments to the Guide. GreatGuides is licensed as a Seller of Travel only under the laws of the State of Washington.';

export const NOTICE_DATA =
  'Any notice given hereunder shall be deemed given, if to Guide, if sent electronically to the email address GreatGuides has on record for Guide’s account or if mailed by registered or certified mail, return receipt requested, to the Parties hereto at their respective address stated herein. Guide also consents to electronic service of process, with service to be made to the email address GreatGuides has on record for their account.';

export const EXPENSES =
  'Guide is responsible for their own expenses that may be incurred as a result of performing the duties and responsibilities set forth in this Agreement. Guide acknowledges that they are undertaking their duties and responsibilities under this agreement at their own risk and acknowledges that GreatGuides is not responsible for any damages or injuries to Guide suffered as a result of Guide’s performance of their duties and responsibilities under this Agreement or Guide’s interaction with a Participant or other third party.';

export const INTEGRATION =
  'This Agreement (i) constitutes the entire agreement of the Parties with respect to its subject matter, (ii) supersedes all prior agreements, if any, of the Parties with respect to its subject matter, and (iii) may not be amended except by mutual agreement of the Parties in writing.  The failure of GreatGuides to enforce any provision of this Agreement shall not in any way constitute a waiver of said provision and shall in no way preclude GreatGuides from exercising the same or any other right under this agreement.';

export const NO_ENDORSEMENT_DATA =
  'GreatGuides does not endorse any Guides or Activities. Furthermore, although GreatGuides requires that Guides provide accurate information, GreatGuides does not guarantee the accuracy or completeness of any information provided by Guides. Participant is responsible for determining the suitability of a Guide and any Activities.';
export const PARTICIPANT_ACCURATE_INFO =
  'Participant hereby represents and warrants that all information provided to GreatGuides pertaining to Participant is true and correct; and Participant shall hold GreatGuides harmless from any and all liability, expenses or claims arising from the disclosure or use of such information.';

export const PARTICIPANT_LAW_DATA =
  'This Agreement shall be governed by and interpreted in accordance with the laws of the State of Washington, USA without reference to its conflicts of laws rules or principles.  The Parties consent to the exclusive jurisdiction of either (i) the Washington State Superior Court in and for King County or (ii) the federal court for the Western District of the State of Washington in connection with any dispute arising under this Agreement and hereby waives, to the maximum extent permitted by law, any objection, including any objection based on forum non conveniens, to the bringing of any such proceeding in such jurisdictions.';

export const PARTICIPANT_OFAC_DATA =
  'Participant represents and warrants that: (i) it is not on an SDN List (defined below), nor is it directly or indirectly owned or controlled by an SDN (defined below); and (ii) the consummation of any transaction contemplated under this Agreement will not violate any country sanctions program administered and enforced by the Office of Foreign Assets Control (“OFAC”) of the U.S. Department of the Treasury. For the purposes hereof, an SDN List is defined as one of the lists published by OFAC of individuals and companies owned or controlled by, or acting for or on behalf of, OFAC targeted countries, as well as individuals, groups, and entities, such as terrorists and narcotics traffickers, designated under OFAC programs that are not country-specific, and an SDN is one of the individuals or companies listed on an SDN List.';

export const PARTICIPANT_DISPUTE_DATA =
  'Participant shall resolve any claim against GreatGuides and any other related party on an individual basis in arbitration, as set forth below.  This will preclude Participant from bringing any class, collective, or representative action against GreatGuides and also preclude You from participating in or recovering relief under any current or future class, collective, consolidated, or representative action brought against GreatGuides by someone else.  GreatGuides reserves all of its rights to bring any dispute in any court of competent jurisdiction. You acknowledge and agree that You are waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Unless both Participant and GreatGuides agree otherwise in writing, any arbitration will be conducted only on an individual basis and not in a class, collective, consolidated, or representative proceeding, and only in the venue required under the previous section.';

export const PARTICIPANT_LIABILITY_DATA =
  'Participant acknowledges that GreatGuides’s service is intended to be used to facilitate the booking of AcTivities and that GreatGuides cannot and does not offer any guarantees regarding the content of any listings, or the condition, legality, or suitability of any Activities. By using the services, Participant hereby acknowledges and agrees that any and all of the risk associated with the use of the services remains with the Participant. Any and all interactions with Guides or other third parties are undertaken at Participant’s own risk. GreatGuides is not responsible for and, to the maximum extent permitted by law, disclaims any and all liability related to Participant’s activities or interactions with Guides or other third parties. GreatGuides is not responsible for any damage, injury, loss, or harm resulting from your interactions with Guides, other customers, or any third parties. By using the GreatGuides service, Participant agrees that any legal remedy or liability that they seek to obtain for actions or omissions of Guides, Other customers, or other third parties will be limited to a claim against those particular parties who caused the harm and Participant agrees to not attempt to impose any liability on, or to seek any legal remedy from GreatGuides with respect to such actions or omissions.';

export const PARTICIPANT_PAYMENT = [
  'Participant shall be required to provide a payment method, along with customary billing information, to associate with their GreatGuides account prior to requesting any bookings. Upon the addition or use of any new payment method, GreatGuides retains the right to authorize a nominal amount to authenticate Participant’s account. Please note that payment methods may involve the use of third-party service providers. These service providers may change Participant additional fees when processing payments. GreatGuides is not responsible for any such fees and disclaims all liability for such fees.',
  'Participant authorizes GreatGuides to store their payment information and charge the payment method as outlined in this Agreement. Participant is solely responsible for the accuracy and completeness of their payment method. GreatGuides is not responsible for any loss suffered by Participant as a result of incorrect payment information. If Participant’s payment information changes, they shall be required to update the information in their GreatGuides account prior to any future bookings.',
  'Participant authorizes GreatGuides to charge their payment method for the total cost of booking a trip plus any applicable taxes and fees. GreatGuides will only charge the payment method upon the acceptance of the booking request by the Guide. Any additional fees will be included in the total cost displayed at the time of requesting to book and Participant agrees to pay such fees by submitting the request to book.',
];
export const PARTICIPANT_NOTICE_DATA =
  'Any notice given hereunder shall be deemed given, if to Participant, if sent electronically to the email address GreatGuides has on record for Participant’s account or if mailed by registered or certified mail, return receipt requested, to the Parties hereto at their respective address stated herein. Participant also consents to electronic service of process, with service to be made to the email address GreatGuides has on record for their account.';

export const PARTICIPANT_LICENSE_DATA =
  'Participant understands and agrees that GreatGuides does not act as an insurer or as a contracting agent for Participant. GreatGuides does not require, but strongly recommends, Participant to purchase Your own insurance prior to any Activity booked via GreatGuides. If a Participant requests to book an Activity, any agreement Participant enters into with a Guide is between Participant and the Guide and GreatGuides is not a party thereto. Notwithstanding the foregoing, GreatGuides serves as the limited authorized agent of Participant for the purpose of accepting payments from Participant on behalf of Guides and is responsible for transmitting such payments to the Guide. GreatGuides is licensed as a Seller of Travel only under the laws of the State of Washington.';

export const PARTICIPANT_INDEMNIFICATION =
  'Participant hereby agrees to release, indemnify, hold harmless, and, at GreatGuides’s discretion, defend GreatGuides and its officers, directors, employees, and independent contractors from and against any third party claims, liabilities, damages, losses, and expenses arising out of or in any way connected with Participant’s breach of this Agreement, Participant’s improper use of GreatGuides’s services, or Participant’s interaction with any Guides or other third parties including, but not limited to, any injuries, damages or losses of any kind arising out of any connection with or as a result of such interaction.';
export const PARTICIPANT_EXPENSES =
  'You are responsible for Your own expenses that may be incurred as a result of Your travels. You acknowledge that You are undertaking travels at Your own risk and acknowledge that GreatGuides is not responsible for any damages or injuries to You suffered as a result of Your decision to book an Activity via GreatGuides’s service or Participant’s interaction with a Guide or other third parties.';

export const PARTICIPANT_ACCEPTABLE_USE_DATA =
  'You agree not to use the GreatGuides website located https://GreatGuides.com or the GreatGuides services of facilitating the posting and review of Activties (collectively, the “GreatGuides Platform”) to collect, upload, transmit, display, or distribute any content (a) that violates any third-party right, including any copyright, trademark, patent, trade secret, moral right, privacy right, right of publicity, or any other intellectual property or proprietary right; (b) that is unlawful, harassing, abusive, tortious, threatening, harmful, invasive of another’s privacy, vulgar, defamatory, false, intentionally misleading, trade libelous, pornographic, obscene, patently offensive (e.g., material that promotes racism, bigotry, hatred, or physical harm of any kind against any group or individual) or otherwise objectionable material of any kind or nature or which is harmful to minors in any way; or (c) in violation of any law, regulation, or obligations or restrictions imposed by any third party.';
export const COMPENSATION = [
  'In consideration of the duties and responsibilities as defined in Section 1(d) of this Agreement, Guide shall receive payment of their posted fee, minus GreatGuides’s fifteen percent (15%) commission and any applicable fees or discounts, for any trip on which they were hired and performed the Services (the “Cash Compensation”). Guide is solely responsible for setting a listing’s price, including any taxes if applicable. Once a Participant requests a booking of a listing, Guide may not request that the Participant pays a higher price than in the booking request. In order to receive a payment, Guide must have a valid payout method linked to its account.',
  'GreatGuides shall release payment of the Cash Compensation to Guide within sixty (60) days upon the completion of a trip. The time it takes to receive a payout once released by GreatGuides may depend upon the payout method selected and the payout method provider’s processing schedule. Guide is solely responsible for determining their obligation to report, collect, remit, or include in the listing price any and all applicable taxes. Depending upon the Activity, GreatGuides may advance some or all of the payment in its sole discretion.',
];

export const ENDORSEMENT_DATA =
  'It is understood that Guide is acting as an independent contractor only and shall have no authority to enter into any commitments on GreatGuides’s behalf. GreatGuides does not endorse any Participants and does not attempt to confirm any Participant’s purported identity or other information provided by such Participant. Guide is responsible for determining the identity and suitability of all parties who Guide contacts via the Services.';

export const PARTICIPANT_ACCEPTABLE_USE_SUBLIST = [
  'a. In addition, You agree not to use the GreatGuides Platform to: (a) upload, transmit, or distribute any computer viruses, worms, or any software intended to damage or alter a computer system or data; (b) send unsolicited or unauthorized advertising, promotional materials, junk mail, spam, chain letters, pyramid schemes, or any other form of duplicative or unsolicited messages, whether commercial or otherwise; (c) harvest, collect, gather or assemble information or data regarding other users, including e-mail addresses, without their consent; (d) interfere with, disrupt, or create an undue burden on servers or networks connected to the GreatGuides Platform or violate the regulations, policies or procedures of such networks; (e) attempt to gain unauthorized access to the GreatGuides Platform, other computer systems or networks connected to or used together with the GreatGuides Platform, through password mining or other means; (f) harass or interfere with another user’s use and enjoyment of the GreatGuides Platform; or (g) introduce software or automated agents or scripts to the GreatGuides Platform so as to produce multiple accounts, generate automated searches, requests and queries, or to strip, scrape, or mine data from the GreatGuides Platform.',
  'b. We reserve the right (but have no obligation) to review any content submitted by You (“Content”), investigate, and/or take appropriate action against You in our sole discretion (including removing or modifying Your Content, terminating Your Account in accordance with this Agreement or the Terms of Use, and/or reporting You to law enforcement authorities) if You violate the Acceptable Use Policy or any other provision of this Agreement or otherwise create liability for GreatGuides or any other person.',
];

export const DUTIES_SUBLIST = [
  [
    'i. To provide correct and detailed information, including Guide’s certifications, organization memberships, and competencies. GreatGuides adopts the Adventure Travel Trade Association’s (“ATTA”) guide qualification criteria for all Guides. These standards promote safety, help with conservation, and will raise the Guide’s profile to potential Participants. Guides are required to state their competencies in these standards;',
  ],
  [
    'ii. to design high quality Activities that align with GreatGuides’s mission and guiding principles and do not exceed the Guide’s ability and competencies;',
  ],
  [
    'iii. to provide correct and detailed information on an Activity to which they are able and qualified to offer their services as a guide. Guide is solely responsible for compliance with all laws, rules and regulations associated with the Activity. Guide hereby represents and warrants that no part of the Activity violates any relevant laws, rules, or regulations. Any listing containing inappropriate material may be removed at GreatGuides’s sole discretion; and',
  ],
  [
    'iv. to accept requests from GreatGuides’s Participants to book Activities and provide adventure travel guide services to GreatGuides’s Participants carrying out the Activity in accordance with and as described in the listing as approved in writing by GreatGuides (the “Approved Listing”) (collectively, the “Services”). Guide represents that it will fulfill the Services as set forth and described in the Approved Listing. GreatGuides disclaims all responsibility for Guide’s deviations from or actions beyond the scope of the Approved Listing. Accepting a booking request from a Participant enters into a legally binding agreement with the Participant and Guide shall be required to provide the Services as described in the Approved Listing when the booking request was made. Guides and Participants shall be responsible for any modifications to a booking made via the GreatGuides platform and agree to pay any additional applicable fees and taxes associated with such booking modifications.',
  ],
];
export const DISPUTE_DATA =
  'Guide shall resolve any claim against GreatGuides and any other related party on an individual basis in arbitration, as set forth below.  This will preclude You from bringing any class, collective, or representative action against GreatGuides and also preclude You from participating in or recovering relief under any current or future class, collective, consolidated, or representative action brought against GreatGuides by someone else.  GreatGuides reserves all of its rights to bring any dispute in any court of competent jurisdiction. Guide acknowledges and agrees that they are waiving the right to a trial by jury or to participate as a plaintiff or class member in any purported class action or representative proceeding. Unless both Guide and GreatGuides agree otherwise in writing, any arbitration will be conducted only on an individual basis and not in a class, collective, consolidated, or representative proceeding.';

export const CONFIDENTIALITY_DATA =
  'During the Term of this Agreement, Guide may acquire certain confidential information of GreatGuides. During the Term of this Agreement and at all times thereafter, Guide will keep all of the confidential information in confidence and will not disclose any of the same to any other person without the express written consent of GreatGuides.';

export const PARTICIPANT_CONFIDENTIALITY_DATA =
  'During the Term of this Agreement, Participant may acquire certain confidential information of GreatGuides. During the Term of this Agreement and at all times thereafter, Participant will keep all of the confidential information in confidence and will not disclose any of the same to any other person without the express written consent of GreatGuides.';

export const ACCURATE_INFO =
  'Guide hereby represents and warrants that all information provided to GreatGuides pertaining to Guide is true and correct; and Guide shall hold GreatGuides harmless from any and all liability, expenses or claims arising from the disclosure or use of such information.';
export const DEFINITIONS = [
  [
    'Service',
    'Service means the www.greatguides.com website and the GreatGuides mobile application operated by GreatGuides',
  ],
  [
    'Personal Data',
    'Personal Data means data about a living individual who can be identified from those data (or from those and other information either in our possession or likely to come into our possession).',
  ],
  [
    'Usage Data',
    'Usage Data is data collected automatically either generated by the use of the Service or from the Service infrastructure itself (for example, the duration of a page visit).',
  ],
  [
    'Cookies',
    'Cookies are small pieces of data stored on your device (computer or mobile device).',
  ],
  [
    'Data Controller',
    'Data Controller means the natural or legal person who (either alone or jointly or in common with other persons) determines the purposes for which and the manner in which any personal information are, or are to be, processed.',
    'For the purpose of this Privacy Policy, we are a Data Controller of your Personal Data.',
  ],
  [
    'Data Processors (or Service Providers)',
    'Data Processor (or Service Provider) means any natural or legal person who processes the data on behalf of the Data Controller.',
    'We may use the services of various Service Providers in order to process your data more effectively.',
  ],
  [
    'Data Subject (or User)',
    'Data Subject is any living individual who is using our Service and is the subject of Personal Data.',
  ],
];
export const INFO_COLLECT_HEAD = 'Information Collection and Use';
export const INFO_COLLECT =
  'We collect several different types of information for various purposes to provide and improve our Service to you.';
export const TYPE_OF_DATA = 'Types of Data Collected';
export const PER_DATA =
  'While using our Service, we may ask you to provide us with certain personally identifiable information that can be used to contact or identify you ("Personal Data"). Personally identifiable information may include, but is not limited to:';
export const PER_DATA2 =
  'We may use your Personal Data to contact you with newsletters, marketing or promotional materials and other information that may be of interest to you. You may opt out of receiving any, or all, of these communications from us by following the unsubscribe link or instructions provided in any email we send.';

export const USES_DATA = [
  'We may also collect information that your browser sends whenever you visit our Service or when you access the Service by or through a mobile device ("Usage Data").',
  'This Usage Data may include information such as your computer\'/s Internet Protocol address (e.g. IP address), browser type, browser version, the pages of our Service that you visit, the time and date of your visit, the time spent on those pages, unique device identifiers and other diagnostic data.',
  'When you access the Service by or through a mobile device, this Usage Data may include information such as the type of mobile device you use, your mobile device unique ID, the IP address of your mobile device, your mobile operating system, the type of mobile Internet browser you use, unique device identifiers and other diagnostic data.',
];

export const TRACK_DATA = [
  'We use cookies and similar tracking technologies to track the activity on our Service and hold certain information.',
  'Cookies are files with small amount of data which may include an anonymous unique identifier. Cookies are sent to your browser from a website and stored on your device. Tracking technologies also used are beacons, tags, and scripts to collect and track information and to improve and analyze our Service.',
  'You can instruct your browser to refuse all cookies or to indicate when a cookie is being sent. However, if you do not accept cookies, you may not be able to use some portions of our Service.',
];
export const USE_COOKIE = [
  ['Session Cookies.', 'We use Session Cookies to operate our Service.'],
  [
    'Preference Cookies.',
    'We use Preference Cookies to remember your preferences and various settings.',
  ],
  ['Security Cookies.', 'We use Security Cookies for security purposes.'],
];
export const USE_DATA2 = [
  'To provide and maintain our Service',
  'To notify you about changes to our Service',
  'To allow you to participate in interactive features of our Service when you choose to do so',
  'To provide customer support',
  'To gather analysis or valuable information so that we can improve our Service',
  'To monitor the usage of our Service',
  'To detect, prevent and address technical issues',
  'To provide you with news, special offers and general information about other goods, services and events which we offer that are similar to those that you have already purchased or enquired about unless you have opted not to receive such information',
];
export const USE_DATA3 = [
  'Legal Basis for Processing Personal Data Under General Data Protection Regulation (GDPR)',
  'If you are from the European Economic Area (EEA), GreatGuides legal basis for collecting and using the personal information described in this Privacy Policy depends on the Personal Data we collect and the specific context in which we collect it.',
  'GreatGuides may process your Personal Data because:',
];
export const USE_DATA4 = [
  'We need to perform a contract with you',
  'You have given us permission to do so',
  'The processing is in our legitimate interests and it\'s not overridden by your rights',
  'For payment processing purposes',
  'To comply with the law',
];
export const RET_DATA = [
  'Retention of Data',
  'GreatGuides will retain your Personal Data only for as long as is necessary for the purposes set out in this Privacy Policy. We will retain and use your Personal Data to the extent necessary to comply with our legal obligations (for example, if we are required to retain your data to comply with applicable laws), resolve disputes, and enforce our legal agreements and policies.',
  'GreatGuides will also retain Usage Data for internal analysis purposes. Usage Data is generally retained for a shorter period of time, except when this data is used to strengthen the security or to improve the functionality of our Service, or we are legally obligated to retain this data for longer time periods.',
];
export const DATA_TRANS = [
  'Transfer of Data',
  'Your information, including Personal Data, may be transferred to — and maintained on — computers located outside of your state, province, country or other governmental jurisdiction where the data protection laws may differ than those from your jurisdiction.',
  'If you are located outside United States and choose to provide information to us, please note that we transfer the data, including Personal Data, to United States and process it there.',
  'Your consent to this Privacy Policy followed by your submission of such information represents your agreement to that transfer.',
  'GreatGuides will take all steps reasonably necessary to ensure that your data is treated securely and in accordance with this Privacy Policy and no transfer of your Personal Data will take place to an organization or a country unless there are adequate controls in place including the security of your data and other personal information.',
];
export const DATA_DISLOUSER = [
  'Data Disclosure',
  'Business Transaction',
  'If GreatGuides is involved in a merger, acquisition or asset sale, your Personal Data may be transferred. We will provide notice before your Personal Data is transferred and becomes subject to a different Privacy Policy.',
  'Disclosure for Law Enforcement',
  'Under certain circumstances, GreatGuides may be required to disclose your Personal Data if required to do so by law or in response to valid requests by public authorities (e.g. a court or a government agency).',
  'Legal Requirements',
  'GreatGuides may disclose your Personal Data in the good faith belief that such action is necessary to:',
];
export const DATA_DISLOUSER2 = [
  'To comply with a legal obligation',
  'To protect and defend the rights or property of GreatGuides',
  'To prevent or investigate possible wrongdoing in connection with the Service',
  'To protect the personal safety of users of the Service or the public',
  'To protect against legal liability',
];
export const RECITALS = [
  'WHEREAS, GreatGuides is engaged in the operation of an adventure travel service through which customers may purchase guided adventure travel trips and other activities (“Activity” or “Activities”);',
  'WHEREAS, GreatGuides seeks to fulfill its mission to bring adventure into people’s lives and to do it with the guiding principles of sustainability and transparency;',
  'WHEREAS, Guide is an experienced adventure travel guide in one or more of the specific type of Activities GreatGuides presents to its customers (“Participant” or “Participants”); and',
  'WHEREAS, GreatGuides desires to secure Guide’s adventure travel guide services for potential Participant Activities and Guide desires to provide such services.',
];
export const PARTICIPANT_RECITALS = [
  'WHEREAS, GreatGuides (“GreatGuides”) is engaged in the operation of an adventure travel service through which customers may purchase guided adventure travel trips and other activities (“Activity” or “Activities”) which are selected, listed upon GreatGuides’s service, and led by one or more guides (“Guide” or “Guides”);',
  'WHEREAS, GreatGuides seeks to fulfill its mission to bring adventure into people’s lives and to do it with the guiding principles of sustainability and transparency;',
  'WHEREAS, Participant seeks to purchase an Activity of the type GreatGuides presents to its customers; and',
  'WHEREAS, GreatGuides desires to facilitate the booking of Activities between Participant and Guides who have listed trips for bookings on GreatGuides’s service.',
];
export const DATA_SECURITY = [
  [
    'Security of Data',
    'The security of your data is important to us, but remember that no method of transmission over the Internet, or method of electronic storage is 100% secure. While we strive to use commercially acceptable means to protect your Personal Data, we cannot guarantee its absolute security.',
  ],
  [
    '"Do Not Track" Signals Under California Online Privacy Protection Act (CalOPPA)',
    'We do not support Do Not Track ("DNT"). Do Not Track is a preference you can set in your web browser to inform websites that you do not want to be tracked.',
    'You can enable or disable Do Not Track by visiting the Preferences or Settings page of your web browser.',
  ],
  [
    'Your Data Protection Rights Under General Data Protection Regulation (GDPR)',
    'If you are a resident of the European Economic Area (EEA), you have certain data protection rights. GreatGuides aims to take reasonable steps to allow you to correct, amend, delete, or limit the use of your Personal Data.',
    'If you wish to be informed what Personal Data we hold about you and if you want it to be removed from our systems, please contact us.',
    'In certain circumstances, you have the following data protection rights:',
  ],
];
export const DATA_SECURITY2 = [
  [
    'The right to access, update or to delete the information we have on you.',
    'Whenever made possible, you can access, update or request deletion of your Personal Data directly within your account settings section. If you are unable to perform these actions yourself, please contact us to assist you.',
  ],
  [
    'The right of rectification.',
    'You have the right to have your information rectified if that information is inaccurate or incomplete.',
  ],
  ['The right to object.', 'You have the right to object to our processing of your Personal Data.'],
  [
    'The right of restriction.',
    'You have the right to request that we restrict the processing of your personal information.',
  ],
  [
    'The right to data portability.',
    'You have the right to be provided with a copy of the information we have on you in a structured, machine-readable and commonly used format.',
  ],
  [
    'The right to withdraw consent.',
    'You also have the right to withdraw your consent at any time where GreatGuides relied on your consent to process your personal information.',
  ],
];
export const DATA_SECURITY3 = [
  'Please note that we may ask you to verify your identity before responding to such requests.',
  'You have the right to complain to a Data Protection Authority about our collection and use of your Personal Data. For more information, please contact your local data protection authority in the European Economic Area (EEA).',
];
export const SERV_PROV = [
  'Service Providers',
  'We may employ third party companies and individuals to facilitate our Service ("Service Providers"), to provide the Service on our behalf, to perform Service-related services or to assist us in analyzing how our Service is used.',
  'These third parties have access to your Personal Data only to perform these tasks on our behalf and are obligated not to disclose or use it for any other purpose.',
];
export const ANALYTICS =
  'We may use third-party Service Providers to monitor and analyze the use of our Service.';

export const ANALYTICS2 = [
  'Google Analytics',
  'Google Analytics is a web analytics service offered by Google that tracks and reports website traffic. Google uses the data collected to track and monitor the use of our Service. This data is shared with other Google services. Google may use the collected data to contextualize and personalize the ads of its own advertising network.',
  'For more information on the privacy practices of Google, please visit the Google Privacy & Terms web page:',
];

export const PAYMENTS = [
  'We may provide paid products and/or services within the Service. In that case, we use third-party services for payment processing (e.g. payment processors).',
  'We will not store or collect your payment card details. That information is provided directly to our third-party payment processors whose use of your personal information is governed by their Privacy Policy. These payment processors adhere to the standards set by PCI-DSS as managed by the PCI Security Standards Council, which is a joint effort of brands like Visa, Mastercard, American Express and Discover. PCI-DSS requirements help ensure the secure handling of payment information.',
  'The payment processors we work with are:',
];

export const PAYMENT2 = ['PayPal or Braintree', 'Their Privacy Policy can be viewed at '];

export const LINK_OTHER = [
  [
    'Links to Other Sites',
    'Our Service may contain links to other sites that are not operated by us. If you click on a third party link, you will be directed to that third party\'s site. We strongly advise you to review the Privacy Policy of every site you visit.',
    'We have no control over and assume no responsibility for the content, privacy policies or practices of any third party sites or services.',
  ],
  [
    'Children\'s Privacy',
    'Our Service does not address anyone under the age of 18 ("Children").',
    'We do not knowingly collect personally identifiable information from anyone under the age of 18. If you are a parent or guardian and you are aware that your child has provided us with Personal Data, please contact us. If we become aware that we have collected Personal Data from children without verification of parental consent, we take steps to remove that information from our servers.',
  ],
  [
    'Changes to This Privacy Policy',
    'We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new Privacy Policy on this page.',
    'We will let you know via email and/or a prominent notice on our Service, prior to the change becoming effective and update the "effective date" at the top of this Privacy Policy.',
    'You are advised to review this Privacy Policy periodically for any changes. Changes to this Privacy Policy are effective when they are posted on this page.',
  ],
  [
    'Contact Us',
    'If you have any questions about this Privacy Policy, please contact us:',
    'By email: info@GreatGuides.com',
  ],
];

export const SERVICES = [
  [
    'a. Services.',
    'GreatGuides hereby engages Guide as an independent contractor from time to time to provide to GreatGuides and its Participants the services specified below, which services may be modified from time to time by mutual agreement by and between the Parties.  Guide shall provide the services to GreatGuides in accordance with the terms and conditions of this Agreement.',
  ],
  [
    'b. Eligibility.',
    'Guide must be at least 18 years old and legally able to enter into binding contracts to provide any services under this Agreement.',
  ],
  [
    'c. Term.',
    'The term of this Agreement (“Term”) will commence upon signing this Agreement (“Start Date”) and shall continue until the termination of Guide’s account with GreatGuides. Notwithstanding the foregoing or anything to the contrary in this Agreement, GreatGuides may terminate this Agreement and Guide’s account at any time.',
  ],
  [
    'd. Duties and Responsibilities.',
    'GreatGuides hereby retains the Guide, and the Guide hereby agrees, for the Term of this Agreement and under the terms and conditions hereinafter set forth:',
  ],
];

export const SCOPE = [
  [
    'a. Use.',
    'The GreatGuides platform is intended to be used only to facilitate the booking of Activities. GreatGuides provides a platform for experienced Guides to list and describe the Activities and offer their services as a guide for customers to book for the Activity.',
  ],
  [
    'b. Eligibility.',
    'Participant must be at least18 years old and legally able to enter binding contracts in order to use the services.',
  ],
  [
    'c. Term.',
    'The term of this Agreement (“Term”) will commence upon signing this Agreement (“Start Date”) and shall continue until the termination of Participant’s account with GreatGuides. Notwithstanding the foregoing or anything to the contrary in this Agreement, GreatGuides may terminate this Agreement and Participant’s account at any time.',
  ],
];

export const LAST_SER = [
  [
    'e. Independent Contractor.',
    'The Parties agree that Guide is an independent contractor and not an employee of GreatGuides, and that GreatGuides will not make any deductions for any statutory withholdings, such as income tax, pension plans, unemployment insurance or worker’s compensation.  Guide will not be entitled to participate in any medical, dental, extended health or group life insurance plans of GreatGuides or any other benefits that may apply to employees of GreatGuides.',
  ],
];
