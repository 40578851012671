import React, { Component } from 'react';

import data from '../mocks/africansharktours.json';

import Hero from '../components/Ladakh/Hero/Hero';
import Ladakh from '../components/Ladakh/adventures/Adventures';
import Question from '../components/Adventures/questions/Questions';
import Footer from '../components/Adventures/footer/Footer';
/* Component adventures */
class AdventuresLadakh extends Component {
  render() {
    return (
      <div>
        <Hero data={data} />
        <Ladakh data={data} />
        <Question />
        <Footer />
      </div>
    );
  }
}

export default AdventuresLadakh;
