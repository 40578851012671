import gql from 'graphql-tag';

export const myTripsBook = gql`
  query getAllTripsByUserId($userId: ID!) {
    trips(where: { user: { id: $userId } }) {
      id
      user {
        id
        fullName
      }
      bookings(where: { user: { id: $userId } }) {
        id
        user {
          id
          fullName
        }
        adventure {
          id
          title
          categories
          address
          media {
            imageHQUrl
          }
          guide {
            id
            fullName
            profilePictureUrl
          }
        }
        bookingState
        numberOfTravelers
        updatedAt
        payment
        price
      }
    }
  }
`;
