import React, { Component } from 'react';

import newsData from '../../mocks/news.json';
import Container from '../../common/container/Container';
import NewsItem from './NewsItem';

import './News.scss';

class NewsComponent extends Component {
  render() {
    let news = newsData[0].news[0].newsItem;
    return (
      <Container>
        <div className='news-title'>
          <h1>{newsData[0].news[0].title}</h1>
          <p>{newsData[0].news[0].description}</p>
        </div>
        <div className='news-list'>
          {news.map((advertise, i) => (
            <NewsItem key={i} allData={advertise} />
          ))}
        </div>
      </Container>
    );
  }
}

export default NewsComponent;
