// import { put, takeLatest } from 'redux-saga/effects'
import { call, select, takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import {
  USER_INFO_UPDATE,
  USER_CONTACT_UPDATE,
  USER_ABOUT_UPDATE,
  USER_REQUIREMENT_UPDATE,
  USER_ROLE_UPDATE,
} from '../modules/UpdateUser/types';

import { filterSelector } from '../modules/GuideFilter/selectors';
import { userSelector } from '../modules/users/selectors';

import { getActivelymeAPIEnvUrl } from 'utils/initializers';

const doAPICALL = (user, bodyData, apiType) =>
  apiCall({
    type: apiType,
    method: 'put',
    baseURL: getActivelymeAPIEnvUrl(),
    path: `/user/v1/users/${user.id}`,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: bodyData,
  });

const doUserRoleCALL = (user, apiType) =>
  apiCall({
    type: apiType,
    method: 'put',
    baseURL: getActivelymeAPIEnvUrl(),
    path: `/user/v1/users/${user.id}/userRole/GuidePending`,
    headers: {
      Authorization: `Bearer ${localStorage.id_token}`,
    },
    body: {},
  });

const doUpdateGuideInfo = function*() {
  const user = yield select(userSelector);
  const filter = yield select(filterSelector);

  const bodyData = {
    firstName: filter.firstName,
    lastName: filter.lastName,
    emailAddress: filter.emailAddress,
    gender: filter.gender,
    profilePictureUrl: filter.profilePictureUrl,
  };
  yield call(doAPICALL(user, bodyData, USER_INFO_UPDATE), { payload: '' });
};

const doUpdateGuideContact = function*() {
  const user = yield select(userSelector);
  const filter = yield select(filterSelector);
  const bodyData = {
    phoneNumber: filter.phoneNumber,
    businessAddress: filter.businessAddress,
    website: filter.website,
    youtubeChannel: filter.youtubeChannel,
  };
  yield call(doAPICALL(user, bodyData, USER_CONTACT_UPDATE), { payload: '' });
};

const doUpdateGuideAbout = function*() {
  const user = yield select(userSelector);
  const filter = yield select(filterSelector);
  const bodyData = {
    aboutMe: filter.aboutMe,
  };
  yield call(doAPICALL(user, bodyData, USER_ABOUT_UPDATE), { payload: '' });
};

const doUpdateGuideRequirement = function*() {
  const user = yield select(userSelector);
  const filter = yield select(filterSelector);
  const bodyData = {
    extra: filter.extra,
  };
  yield call(doAPICALL(user, bodyData, USER_REQUIREMENT_UPDATE), { payload: '' });
};

const doUpdateUserRole = function*() {
  const user = yield select(userSelector);
  yield call(doUserRoleCALL(user, USER_ROLE_UPDATE), { payload: '' });
};

export default function* rootSaga() {
  yield takeLatest(USER_INFO_UPDATE, doUpdateGuideInfo);
  yield takeLatest(USER_CONTACT_UPDATE, doUpdateGuideContact);
  yield takeLatest(USER_ABOUT_UPDATE, doUpdateGuideAbout);
  yield takeLatest(USER_REQUIREMENT_UPDATE, doUpdateGuideRequirement);
  yield takeLatest(USER_ROLE_UPDATE, doUpdateUserRole);
}
