import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { GUIDES_REGION_GET } from './types';

const initialState = {
  data: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(GUIDES_REGION_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(GUIDES_REGION_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(GUIDES_REGION_GET)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(GUIDES_REGION_GET),
        data: payload,
        error: null,
      };
    },

    [requestFail(GUIDES_REGION_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(GUIDES_REGION_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
