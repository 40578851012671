/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactPixel from 'react-facebook-pixel';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../redux/modules/GuideFilter';
import { actions as userActions, selectors as userSelectors } from '../redux/modules/users';

import RequireSignUp from 'components/RequireSignUp';
import CircleStep from 'components/BecomeAGuide/CircleStep';
import IntroduceForGuide from 'components/BecomeAGuide/IntroduceForGuide';
import UserInfo from 'components/BecomeAGuide/UserInfo';
import UserContact from 'components/BecomeAGuide/UserContact';
import AboutMe from 'components/BecomeAGuide/AboutMe';
import Requirements from 'components/BecomeAGuide/Requirements';
import Certification from 'components/BecomeAGuide/Certification';

import styles from 'styles/pages/NewGuidePage.module.scss';

import { getUserAuthID } from 'api/user';

/* Component definition */
class NewGuidePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUpModal: false,
      step: 1,
      profilePicture: null,
      licenses: null,
      cpr: null,
      insurances: null,
      errorName: '',
      loading: true,
    };
  }

  componentDidMount() {
    const { getUserID } = this.props;
    localStorage.setItem('page', '/new-guide');
    getUserID(); //Get LoggedIn User ID and Set All User information
  }

  shouldComponentUpdate(nextProps) {
    const { guideFilterStatus, loggedUser } = nextProps;
    if (loggedUser !== this.props.loggedUser && loggedUser) {
      localStorage.setItem('userRole', loggedUser.userRole);
    }

    if (guideFilterStatus) {
      if (
        guideFilterStatus.includes('success') &&
        this.props.guideFilterStatus.includes('pending')
      ) {
        this.setState({ loading: false });
      }
    }

    return true;
  }

  handleInputChange = () => {};

  nextStep = () => {
    let { step } = this.state;

    if (step === 1 && !localStorage.isLoggedIn) {
      this.setState({ isSignUpModal: true });
      return;
    }

    this.setState({ step: Math.min(++step, 6) });
  };

  previewStep = () => {
    let { step } = this.state;
    if (step === 1) {
      return;
    }
    this.setState({ step: --step });
  };

  signUp = () => {
    this.props.auth.signup();
  };

  cancelSignUp = () => {
    this.setState({ isSignUpModal: false });
  };

  render() {
    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    const { step, isSignUpModal, loading } = this.state;
    if (loading && step === 2) {
      return (
        <div className={styles.loaderSpinner}>
          <div className={styles.loader} />
        </div>
      );
    } else {
      return (
        <main className={styles.wrapper}>
          {isSignUpModal && (
            <RequireSignUp
              signUp={this.signUp}
              heading='Sign up is required'
              paragraph='Please sign up to create a Guide account'
              type={2}
              cancelSignUp={this.cancelSignUp}
            />
          )}
          <div className={styles.row}>
            {step !== 1 && <CircleStep step={step} />}
            {step === 1 && (
              <IntroduceForGuide nextStep={this.nextStep} previewStep={this.previewStep} />
            )}
            {step === 2 && (
              <UserInfo
                profilePicture={this.state.profilePicture}
                nextStep={this.nextStep}
                previewStep={this.previewStep}
              />
            )}
            {step === 3 && <UserContact nextStep={this.nextStep} previewStep={this.previewStep} />}
            {step === 4 && <AboutMe nextStep={this.nextStep} previewStep={this.previewStep} />}
            {step === 5 && <Requirements nextStep={this.nextStep} previewStep={this.previewStep} />}
            {step === 6 && (
              <Certification nextStep={this.nextStep} previewStep={this.previewStep} />
            )}
          </div>
        </main>
      );
    }
  }
}

NewGuidePage.propTypes = {
  loggedInUser: PropTypes.object,
  emailAddress: PropTypes.string,
  getUser: PropTypes.func.isRequired,
  getUserID: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  loggedUser: PropTypes.object,
  auth: PropTypes.object.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  setUserAllInfo: PropTypes.func.isRequired,
  guideFilterStatus: PropTypes.string,
};

const selectors = createStructuredSelector({
  loggedUser: userSelectors.userSelector,
  emailAddress: GuideSetFilterSelectors.emailAddressSelector,
  guideFilterStatus: GuideSetFilterSelectors.GuideFilterStatusSelector,
});

const actions = {
  getUser: userActions.getUser,
  getUserID: userActions.getUserID,
  setUser: userActions.setUser,
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  setUserAllInfo: GuideSetFilterActions.setGuideAllFilter,
};

const userId = {
  name: 'loggedInUser',

  options() {
    return {
      variables: {},
    };
  },

  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(
    selectors,
    actions
  )
)(NewGuidePage);
