import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { ADVENTURES_GET } from './types';

const initialState = {
  data: null,
  status: null,
  adventures: [],
  loading: true,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(ADVENTURES_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(ADVENTURES_GET),
      loading: true,
      error: false,
      message: '',
    }),

    [requestSuccess(ADVENTURES_GET)]: (state, { payload }) => {
      const { isLoadMore, adventuresByDateRangeCategoryAndLocation } = payload;
      let { adventures } = state;
      let dataAdventure = [];

      if (payload.userAdventure) {
        dataAdventure = adventuresByDateRangeCategoryAndLocation.filter(
          item => item.guide.fullName === payload.userAdventure
        );
      } else {
        dataAdventure = adventuresByDateRangeCategoryAndLocation;
      }
      if (isLoadMore) {
        const totalLength = dataAdventure.length;
        const currentLength = adventures.length;

        if (currentLength < totalLength) {
          const addedData = dataAdventure.slice(
            currentLength,
            Math.min(currentLength + 3, totalLength)
          );

          adventures = adventures.concat(addedData);
        }
      } else {
        const totalLength = dataAdventure.length;

        if (totalLength < 3) {
          adventures = dataAdventure;
        } else {
          adventures = dataAdventure.slice(0, Math.min(totalLength, 9));
        }
      }

      return {
        ...state,
        status: requestSuccess(ADVENTURES_GET),
        data: payload,
        adventures,
        loading: false,
        id: null,
        error: null,
      };
    },

    [requestFail(ADVENTURES_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(ADVENTURES_GET),
      error: true,
      loading: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
