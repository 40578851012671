/**
 * @file Holds the <AdventureCardList> container
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/containers/TripList.module.scss';

import Icon from 'components/Icon';

/* Component definition */
class TripList extends React.Component {
  render() {
    const { children } = this.props;
    return (
      <div className={[`${styles.wrapper}`]}>
        <div className={styles.row}>
          <div>
            {children.find(item => item.type === 'h2')}

            {children.filter(item => item.type === 'p')}

            {children.filter(item => item.type === 'div')}

            <div className={[`${styles.cards}`]}>
              {children.find(item => item.length) &&
                children.find(item => item.length).map(item => item)}
            </div>
          </div>
          <div className={styles.see_more} onClick={this.getLoadMore}>
            <span>See more</span>
            <Icon name='see-more' />
          </div>
        </div>
      </div>
    );
  }
}

/* Prop types definition */
TripList.propTypes = {
  children: PropTypes.array.isRequired,
};

export default TripList;
