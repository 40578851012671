/**
 * @file Holds the <HomePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import ReactPixel from 'react-facebook-pixel';

import { createStructuredSelector } from 'reselect';
import { compose, graphql, withApollo } from 'react-apollo';
import styles from 'styles/pages/HomePage.module.scss';
import AdventureCard from 'components/AdventureCard';
import CardList from 'containers/CardList';
import { LazyLoadComponent } from 'react-lazy-load-image-component';

import {
  actions as categoriesActions,
  selectors as categoriesSelectors,
} from '../redux/modules/categories';
import {
  actions as adventuresSearchFilterActions,
  selectors as adventuresFilterSelectors,
} from '../redux/modules/AdventuresFilter';
import {
  actions as adventuresActions,
  selectors as adventuresSelectors,
} from '../redux/modules/adventures';
import { actions as userActions, selectors as userSelectors } from '../redux/modules/users';
import { getUserAuthID } from 'api/user';

const moment = require('moment');

/* Component definition */
class MyAdventuresPage extends React.Component {
  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    location: PropTypes.string,
    day_multi: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
    location: null,
    day_multi: null,
    category: 'All',
    difficulty: null,
  };

  constructor(props) {
    super(props);

    this.state = {
      currentGuidePage: 1,
      regions: [],
      region: 'All',
      guides: [],
      currentAdventurePage: 1,
    };
  }

  componentDidMount() {
    const { getCategories, setAdventuresFilter, getAdventures, getUserID, loggedUser } = this.props;

    getUserID();
    getCategories();

    let d = new Date();
    var year = d.getFullYear();
    setAdventuresFilter({
      ['startDate']: moment('1992-01-01').locale(''),
      ['endDate']: moment(year + 1 + '-12-31').locale(''),
      ['location']: '',
      ['category']: 'All',
    });

    if (loggedUser) getAdventures({ isLoadMore: false, userAdventure: loggedUser.fullName }); //Getting Adventures
  }

  getLoadMore = type => {
    const { getAdventures, loggedUser } = this.props;

    if (type === 'adventures') {
      getAdventures({ isLoadMore: true, userAdventure: loggedUser.fullName });
    }
  };

  render() {
    const advancedMatching = { em: 'some@email.com' };
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    const { adventures } = this.props;

    console.log('GET ADVENTURES', this.props.loggedUser.adventures);
    console.log('EEE', adventures);
    return (
      <main className={styles.wrapper}>
        <LazyLoadComponent>
          <CardList
            bgColor='grey'
            isMore
            columns='c3'
            type='adventures'
            getLoadMore={this.getLoadMore}
          >
            <h3 />
            <p />
            {this.props.loggedUser.adventures && this.props.loggedUser.adventures.length
              ? this.props.loggedUser.adventures.map((item, index) => (
                <AdventureCard key={index} adventure={item} edit={true} />
              ))
              : []}
          </CardList>
        </LazyLoadComponent>
      </main>
    );
  }
}

MyAdventuresPage.propTypes = {
  getAdventures: PropTypes.func.isRequired,
  loggedInUser: PropTypes.object,
  adventures: PropTypes.array.isRequired,
  setAdventuresFilter: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  getUserID: PropTypes.func.isRequired,
  loggedUser: PropTypes.object,
};

const selectors = createStructuredSelector({
  startDate: adventuresFilterSelectors.startDateSelector,
  endDate: adventuresFilterSelectors.endDateSelector,
  location: adventuresFilterSelectors.locationSelector,
  day_multi: adventuresFilterSelectors.dayMultiSelector,
  category: adventuresFilterSelectors.categorySelector,
  difficulty: adventuresFilterSelectors.difficultySelector,
  adventures: adventuresSelectors.adventuresSelector,
  categories: categoriesSelectors.categoriesSelector,
  loggedUser: userSelectors.userSelector,
});

const actions = {
  getAdventures: adventuresActions.getAdventures,
  setAdventuresFilter: adventuresSearchFilterActions.setAdventuresFilter,
  getCategories: categoriesActions.getCategories,
  getUserID: userActions.getUserID,
};

const userId = {
  name: 'loggedInUser',

  options() {
    return {
      variables: {},
    };
  },

  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(
    selectors,
    actions
  )
)(MyAdventuresPage);
