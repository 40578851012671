/**
 * @file Holds the <Main> layout
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React, { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import Header from 'components/Header';
import Footer from 'components/Footer';
import PropTypes from 'prop-types';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { FOOTER_HEIGHT, FOOTER_MOBILE_HEIGHT } from '../constants/objectValues';
import { actions } from '../redux/modules/general';

/* Component definition */
const Main = ({ page: Page, ...props }) => {
  const { scrollIsBottom, isModalOpen } = useSelector(
    state => ({
      scrollIsBottom: state.general.scrollIsBottom,
      isModalOpen: state.general.modal.isModalOpen,
    }),
    shallowEqual
  );

  const dispatch = useDispatch();

  const checkPosition = () => {
    const isBrowser = typeof window !== 'undefined';
    if (isBrowser) {
      const pageHeight = document.documentElement.offsetHeight;
      const pageScroll = document.documentElement.scrollTop + document.documentElement.clientHeight;
      const position =
        pageScroll >=
        pageHeight -
          (window.matchMedia('(max-width: 768px)').matches ? FOOTER_MOBILE_HEIGHT : FOOTER_HEIGHT);
      if (position && !scrollIsBottom) {
        dispatch(actions.scrollIsBottom(true));
      } else if (!position && scrollIsBottom) {
        dispatch(actions.scrollIsBottom(false));
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', checkPosition);
    return () => {
      window.removeEventListener('scroll', checkPosition);
    };
  });

  return (
    <div className={`App ${isModalOpen ? 'modal-open' : ''}`}>
      <Header {...props} />
      <Page {...props} />
      <Footer />
    </div>
  );
};

/* Prop types definition */
Main.propTypes = {
  page: PropTypes.func,
};

export default withRouter(Main);
