import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import {
  NEW_ADVENTURE_BOOK,
  DELETE_ADVENTURE_BOOK,
  START_PAY_ADVENTURE_BOOK,
  SET_ADVENTURE_BOOKING_ID,
  CREATE_ADVENTURE_BOOKING_PAYMENT,
  UPDATE_ADVENTURE_BOOKING_PAYMENT,
} from './types';

const initialState = {
  data: null,
  status: null,
  adventures: [],
  bookingId: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(NEW_ADVENTURE_BOOK)]: state => {
      return {
        ...state,
        data: null,
        status: requestPending(NEW_ADVENTURE_BOOK),
        error: false,
        message: '',
      };
    },

    [requestSuccess(NEW_ADVENTURE_BOOK)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(NEW_ADVENTURE_BOOK),
        data: payload,
        error: null,
      };
    },

    [requestFail(NEW_ADVENTURE_BOOK)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(NEW_ADVENTURE_BOOK),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [requestPending(DELETE_ADVENTURE_BOOK)]: state => ({
      ...state,
      data: null,
      status: requestPending(DELETE_ADVENTURE_BOOK),
      error: false,
      message: '',
    }),

    [requestSuccess(DELETE_ADVENTURE_BOOK)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(DELETE_ADVENTURE_BOOK),
        data: payload,
        error: null,
      };
    },

    [requestFail(DELETE_ADVENTURE_BOOK)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(DELETE_ADVENTURE_BOOK),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [requestPending(START_PAY_ADVENTURE_BOOK)]: state => ({
      ...state,
      data: null,
      status: requestPending(START_PAY_ADVENTURE_BOOK),
      error: false,
      message: '',
    }),

    [requestSuccess(START_PAY_ADVENTURE_BOOK)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(START_PAY_ADVENTURE_BOOK),
        data: payload,
        error: null,
      };
    },

    [requestFail(START_PAY_ADVENTURE_BOOK)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(START_PAY_ADVENTURE_BOOK),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [requestPending(CREATE_ADVENTURE_BOOKING_PAYMENT)]: state => ({
      ...state,
      data: null,
      status: requestPending(CREATE_ADVENTURE_BOOKING_PAYMENT),
      error: false,
      message: '',
    }),

    [requestSuccess(CREATE_ADVENTURE_BOOKING_PAYMENT)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(CREATE_ADVENTURE_BOOKING_PAYMENT),
        data: payload,
        error: null,
      };
    },

    [requestFail(CREATE_ADVENTURE_BOOKING_PAYMENT)]: (state, { payload }) => ({
      ...state,
      data: payload,
      status: requestFail(CREATE_ADVENTURE_BOOKING_PAYMENT),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [requestPending(UPDATE_ADVENTURE_BOOKING_PAYMENT)]: state => ({
      ...state,
      data: null,
      status: requestPending(UPDATE_ADVENTURE_BOOKING_PAYMENT),
      error: false,
      message: '',
    }),

    [requestSuccess(UPDATE_ADVENTURE_BOOKING_PAYMENT)]: (state, { payload }) => {
      return {
        ...state,
        status: requestSuccess(UPDATE_ADVENTURE_BOOKING_PAYMENT),
        data: payload,
        error: null,
      };
    },

    [requestFail(UPDATE_ADVENTURE_BOOKING_PAYMENT)]: (state, { payload }) => ({
      ...state,
      data: payload,
      status: requestFail(UPDATE_ADVENTURE_BOOKING_PAYMENT),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
    [SET_ADVENTURE_BOOKING_ID]: (state, { payload }) => ({
      ...state,
      ...payload,
    }),
  },
  initialState
);
