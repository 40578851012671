import React from 'react';
import PropTypes from 'prop-types';

import { Container, Row } from 'react-bootstrap';

import Icon from '../Icon';
import SearchFilterSelect from './SearchFilterSelect';

const moment = require('moment');

function SearchFilter({
  locations,
  categories,
  location,
  endDate,
  category,
  dayMulti,
  setFilter,
  submitFilter,
  displayStyleField,
  customStyleName,
}) {
  const locationsFormat = () => {
    const data = {
      ...locations.reduce((acc, location) => {
        return {
          ...acc,
          ...(location.country !== '' && {
            [location.country]: {
              value: location.country,
              label: location.country,
              flagIcon: location.flagIcon ? location.flagIcon : '',
            },
          }),
        };
      }, {}),
    };
    return Object.values(data);
  };

  const dates = () => {
    let months = [];
    const monthsRequired = 12;
    for (let i = 0; i < monthsRequired; i++) {
      const month = moment()
        .add(i, 'months')
        .endOf('month');
      months.push({ value: month.format('MM-DD-YYYY'), label: month.format('MMMM YYYY') });
    }
    return months;
  };
  const categoriesFormat = () => {
    const data = {
      ...categories.reduce((acc, { name }) => {
        return {
          ...acc,
          ...(name !== '' && {
            [name]: {
              value: name,
              label: name.replace(/_/, ' '),
            },
          }),
        };
      }, {}),
    };
    return Object.values(data);
  };

  const dayMultiDays = [
    { value: 'Day', label: 'Day' },
    { value: 'Multi', label: 'MultiDay' },
  ];

  const changeValue = (data, name) => {
    const value = data && data.value ? data.value : '';
    setFilter({ [name]: value });
  };

  return (
    <Container>
      <Row noGutters={true}>
        <div className={`search-filter ${customStyleName}`}>
          <SearchFilterSelect
            changeValue={e => changeValue(e, 'location')}
            data={locationsFormat()}
            selectedData={location}
            className={'search-filter__destination'}
            placeholder='Destination'
          />
          <SearchFilterSelect
            changeValue={e => changeValue(e, 'endDate')}
            data={dates()}
            selectedData={endDate}
            placeholder='Departure Date'
          />
          {displayStyleField && (
            <SearchFilterSelect
              changeValue={e => changeValue(e, 'category')}
              data={categoriesFormat()}
              selectedData={category}
              placeholder='Style'
              isClearable={false}
            />
          )}
          <SearchFilterSelect
            changeValue={e => changeValue(e, 'dayMulti')}
            data={dayMultiDays}
            selectedData={dayMulti}
            placeholder='Day/Multi-Day'
            className='search-filter__day-multi'
          />
          <button onClick={submitFilter}>
            Find
            <Icon name='arrow-right-big' />
          </button>
        </div>
      </Row>
    </Container>
  );
}

SearchFilter.propTypes = {
  locations: PropTypes.arrayOf(
    PropTypes.shape({
      country: PropTypes.string,
      flagIcon: PropTypes.string,
    })
  ).isRequired,
  categories: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
    })
  ).isRequired,
  location: PropTypes.string.isRequired,
  endDate: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  dayMulti: PropTypes.string.isRequired,
  setFilter: PropTypes.func.isRequired,
  submitFilter: PropTypes.func.isRequired,
  displayStyleField: PropTypes.bool,
  customStyleName: PropTypes.string,
};

SearchFilter.defaultProps = {
  displayStyleField: true,
  customStyleName: '',
};

export default SearchFilter;
