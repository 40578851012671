/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ToastContainer, toast } from 'react-toastify';
import { createStructuredSelector } from 'reselect';

import RichEditor from 'components/RichEditor';
import AccountTabList from './AccountTabList';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import { selectors as userSelectors } from '../../redux/modules/users';
import {
  actions as updateUserActions,
  selectors as updateUserSelectors,
} from '../../redux/modules/UpdateUser';

import styles from 'styles/pages/AccountDetailsPage.module.scss';
import 'react-toastify/dist/ReactToastify.min.css';
import 'styles/components/RichEditorNewGuide.overrides.scss';

/* Component definition */
class GuideQualification extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      aboutMe: '',
      certifications: [false, false],
      memberships: [false, false],
      selectedCategories: [],
    };
  }

  componentDidMount() {
    const { aboutMe, categories } = this.props;
    let { selectedCategories } = this.state;
    const { updateUserStatus } = this.props;

    updateUserStatus({
      ['status']: null,
    });

    this.setState({ aboutMe: aboutMe });

    selectedCategories = categories.map(item => {
      return { [item.name]: false };
    });

    this.setState({ selectedCategories: selectedCategories });
  }

  shouldComponentUpdate(nextProps) {
    const { userStatus } = nextProps;

    if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('success') && this.props.userStatus.includes('pending')) {
        toast.success('Successfully updated your profile', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    } else if (userStatus && this.props.userStatus && userStatus !== this.props.userStatus) {
      if (userStatus.includes('fail')) {
        toast.error('Failed to update your profile.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
    }

    return true;
  }

  onInputTextChange = () => {};

  setTabIndex = index => {
    const { setTabIndex } = this.props;

    setTabIndex(index);
    this.saveGuideQualifications();
  };

  saveGuideQualifications = () => {
    if (!this.validateForm()) {
      return;
    }

    const { updateUserAbout, setGuideInfo, loggedUser } = this.props;
    const { aboutMe } = this.state;
    //Set Guides' information
    setGuideInfo({
      ['aboutMe']: aboutMe,
    });
    if (loggedUser) updateUserAbout();
  };

  validateForm() {
    const { aboutMe } = this.state;
    console.log('checkingAboutME', aboutMe);
    if (aboutMe === '') {
      console.log('checkingAboutME1', aboutMe);
      this.setState({ errorName: 'Your overview' });
      return false;
    }
    return true;
  }

  froalaEditorModel = value => {
    this.setState({ aboutMe: value });
  };

  isCertificated = status => {
    if (status) this.setState({ certifications: [true, false] });
    else this.setState({ certifications: [false, true] });
  };

  isMembership = status => {
    if (status) this.setState({ certifications: [true, false] });
    else this.setState({ certifications: [false, true] });
  };

  selectedCategory = key => {
    let { selectedCategories } = this.state;

    Object.keys(selectedCategories).map(key => {
      selectedCategories[key] = false;
    });
    selectedCategories[key] = true;
    this.setState({ selectedCategories: selectedCategories });
  };

  render() {
    const { aboutMe, selectedCategories, errorName } = this.state;
    const { categories, tabIndex } = this.props;

    return (
      <div>
        <AccountTabList setTabIndex={this.setTabIndex} tabIndex={tabIndex} />
        <div className='userAboutSection'>
          <form className={styles.form}>
            <div className={styles.form_group}>
              <div className={styles.categoryList}>
                {categories.map(item => (
                  <div className={styles.checkbox} key={item.name}>
                    <label className='checkbox'>
                      {item.name}
                      <input
                        type='checkbox'
                        className='checkboxInput'
                        checked={selectedCategories[item.name] || ''}
                        onChange={() => this.selectedCategory(item.name)}
                      />
                      <span className='guideCheckmark' />
                    </label>
                  </div>
                ))}
              </div>
            </div>
            <div className={styles.form_group}>
              <label className={styles.label}>About me</label>
              <RichEditor
                value={aboutMe}
                handleFroalaModelChange={this.froalaEditorModel}
                isEditable={true}
              />
            </div>
            {errorName && <div className={styles.errorText}>{errorName} is required</div>}
            <div className={styles.form_group}>
              <button
                type='button'
                className={styles.form_button}
                onClick={() => this.saveGuideQualifications()}
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
        {tabIndex === 3 && <ToastContainer autoClose={3000} />}
      </div>
    );
  }
}

GuideQualification.propTypes = {
  setGuideInfo: PropTypes.func.isRequired,
  updateUserAbout: PropTypes.func.isRequired,
  updateUserStatus: PropTypes.func.isRequired,
  userStatus: PropTypes.string,
  aboutMe: PropTypes.string,
  loggedUser: PropTypes.string,
  categories: PropTypes.array.isRequired,
  tabIndex: PropTypes.number.isRequired,
  setTabIndex: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({
  aboutMe: GuideSetFilterSelectors.aboutMeSelector,
  loggedUser: userSelectors.userIdSelector,
  userStatus: updateUserSelectors.userStatusSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserAbout: updateUserActions.updateUserAbout,
  updateUserStatus: updateUserActions.updateUserStatus,
};

export default connect(
  selectors,
  actions
)(GuideQualification);
