export const DETAIL_CON_FORM = [
  {
    label: 'What is your phone?',
    type: 'text',
    name: 'phone',
    holder: 'Phone',
  },
  {
    label: 'What is your location?',
    type: 'text',
    name: 'Location',
    holder: 'City, State',
  },
  {
    label: 'Website (not required)',
    type: 'text',
    name: 'website',
    holder: 'Please list any websites you own',
  },
  {
    label: 'Youtube channel (not required)',
    type: 'text',
    name: 'youtube',
    holder: 'List your YouTube channel',
  },
];

export const BASIC_DETAIL_FORM = [
  {
    label: 'What is your first name?',
    type: 'text',
    name: 'firstName',
    holde: 'First name',
  },
  {
    label: 'What is your last name?',
    type: 'text',
    name: 'lastName',
    holde: 'Last name',
  },
  {
    label: 'Email address',
    type: 'email',
    name: 'email',
    holde: 'Email address',
  },
];
