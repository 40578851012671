import React, { Component } from 'react';

import { Link } from 'react-router-dom';

import Adventure_iamge from '../../assets/GuideProfile/adventure.png';
import mount from '../../assets/GuideProfile/mount.png';

import './GuideProfile.scss';

class ProfileRight extends Component {
  render() {
    return (
      <article className='profile-right'>
        <div className='top-info'>
          <img src={Adventure_iamge} className='adventure' alt='Adventure' />
          <h5>Steve Bolnick</h5>
          <div className='rating'>
            <ul>
              <li>
                <i className='fa fa-star' aria-hidden='true' /> 4.9
              </li>
              <li>
                <i className='fa fa-circle' aria-hidden='true' /> 225 reviews
              </li>
              <li>
                <i className='fa fa-circle' aria-hidden='true' /> 4 adventures
              </li>
            </ul>
          </div>
          <div className='guide-right-form'>
            <form>
              <select>
                <option value='Adventure subject'>Adventure subject</option>
              </select>
              <textarea defaultValue='Hi Steve. I would like to book adventure «Trek to Ade Medhanealem village».' />
              <button>Send a message</button>
            </form>
          </div>
        </div>
        <div className='adventures-lg'>
          <div className='guide-adventures'>
            Adventures <span>(4)</span>
          </div>
          <div className='box'>
            <img src={mount} alt='Mount' />
            <div className='small-content'>
              <h5>HIKING</h5>
              <p>The best rafting adventure with good awesome Jackson man</p>
            </div>
          </div>
          <Link className='see-all-adventure' to='/'>
            See all adventures
          </Link>
        </div>
      </article>
    );
  }
}

export default ProfileRight;
