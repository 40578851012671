import React from 'react';
import PropTypes from 'prop-types';
import defaultImage from 'assets/guides/avatar.svg';
import Rating from '../Rating';
import FixedFooter from '../FixedFooter';
import { ADVENTURE_DETAILS_MODAL } from '../../constants/common';

function AdventureAbout({ guide, showModal, handleBook }) {
  const addDefaultSrc = e => {
    e.target.src = defaultImage;
  };

  return (
    <FixedFooter>
      <div className='adventure-about__author'>
        <img
          src={guide.profilePictureUrl}
          alt={guide.fullName || 'Guider'}
          onError={addDefaultSrc}
        />
        <span>{guide.fullName || 'Guider'}</span>
      </div>
      {guide.extra && guide.extra.rating && guide.extra.NumOfReviews && (
        <div className='adventure-about__rate'>
          <Rating rating={guide.extra.rating} />
          <span>Based on {guide.extra.NumOfReviews} reviews</span>
        </div>
      )}
      <div className='adventure-about__buttons'>
        <button
          className='adventure-about__buttons__request'
          onClick={() => showModal(ADVENTURE_DETAILS_MODAL)}
        >
          Request info
        </button>
        
        {/* Commented by atma 
        
        <button className='adventure-about__buttons__check-dates' onClick={handleBook}>
          Book now
        </button>
        */}

      </div>
    </FixedFooter>
  );
}

AdventureAbout.propTypes = {
  guide: PropTypes.shape({
    fullName: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    extra: PropTypes.shape({
      rating: PropTypes.number,
      NumOfReviews: PropTypes.number,
    }),
  }).isRequired,
  showModal: PropTypes.func.isRequired,
  handleBook: PropTypes.func.isRequired,
};

export default AdventureAbout;
