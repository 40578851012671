import gql from 'graphql-tag';
export const getUserGuides = gql`
  query {
    users(where: { userRole: Guide }) {
      id
      fullName
      extra
      gender
      tripAdvisor
      flagIcon
      businessAddress
      businessName
      technicalSkills
      customerServiceSkills
      firstAidTraining
      sustainableStandards
      website
      youtubeChannel
      phoneNumber
      memberships
      region
      adventures {
        id
        title
        price
        categories
        address
        description
        comments {
          rating
        }
        media {
          imageMQUrl
          imageHQUrl
        }
      }
      comments {
        note
        rating
        createdAt
        author {
          fullName
          businessAddress
          profilePictureUrl
        }
        adventure {
          title
          media {
            imageMQUrl
          }
        }
      }
      expertise
      certificate
      aboutMe
      profilePictureUrl
    }
  }
`;

export const getGuide = gql`
  query($id: ID) {
    users(where: { userRole: Guide, id: $id }) {
      id
      fullName
      extra
      gender
      tripAdvisor
      flagIcon
      businessAddress
      businessName
      technicalSkills
      customerServiceSkills
      firstAidTraining
      sustainableStandards
      website
      youtubeChannel
      phoneNumber
      memberships
      region
      adventures {
        id
        title
        price
        categories
        address
        description
        comments {
          rating
        }
        media {
          imageMQUrl
          imageHQUrl
        }
      }
      comments {
        note
        rating
        createdAt
        author {
          fullName
          businessAddress
          profilePictureUrl
        }
        adventure {
          title
          media {
            imageMQUrl
          }
        }
      }
      expertise
      certificate
      aboutMe
      profilePictureUrl
    }
  }
`;

export const getUserRegion = gql`
  query {
    users(where: { userRole: Guide }) {
      region
    }
  }
`;
