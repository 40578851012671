/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
//import Dropzone from 'react-dropzone';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import { actions as updateUserActions } from '../../redux/modules/UpdateUser';
import { selectors as userSelectors } from '../../redux/modules/users';
import InputField from '../InputField';
import avatar from '../../assets/guides/avatar.svg';
import styles from 'styles/pages/NewGuidePage.module.scss';

import { getGreatGuidesEnvUrl } from 'utils/initializer';

/* Component definition */
class UserInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      type: null,
      firstName: '',
      lastName: '',
      emailAddress: '',
      profilePicture: '',
    };
  }

  componentDidMount() {
    const { firstName, lastName, emailAddress, profilePictureUrl } = this.props;
    this.setState({
      firstName: firstName,
      lastName: lastName,
      emailAddress: emailAddress,
      profilePicture: profilePictureUrl,
    });
  }

  onInputTextChange = () => {};

  nextStep = () => {
    if (!this.validateForm()) {
      return;
    }

    const { nextStep, setGuideInfo, updateUserInfo, loggedUser } = this.props;
    const { firstName, lastName, emailAddress, profilePicture } = this.state;

    //Set Guides' information
    setGuideInfo({
      ['firstName']: firstName,
      ['lastName']: lastName,
      ['emailAddress']: emailAddress,
      ['profilePictureUrl']: profilePicture,
    });

    if (loggedUser) updateUserInfo();

    nextStep();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  validateForm() {
    const { emailAddress } = this.state;

    if (emailAddress === '') {
      this.setState({ errorName: 'Email' });
      return false;
    }
    return true;
  }

  setFieldValue = (value, type) => {
    if (type === 'firstName') this.setState({ firstName: value });
    else if (type === 'lastName') this.setState({ lastName: value });
    else if (type === 'Email') this.setState({ emailAddress: value });
  };

  uploadAvatar = async files => {
    let reader = new FileReader();

    if (files.length > 0) {
      const baseUrl = `${getGreatGuidesEnvUrl()}media/v1/users/profilePicture`;
      let response;
      try {
        response = await fetch(baseUrl, {
          method: 'PUT',
          body: files[0],
          headers: {
            'Content-Type': 'application/octet-stream',
            Authorization: `Bearer ${localStorage.id_token}`,
          },
        });
      } catch (e) {
        console.log(e);
      }
      let imageJson;
      try {
        if (response) {
          imageJson = await response.json();
        } else imageJson = undefined;
      } catch (e) {
        console.log(e);
      }

      reader.onload = function(e) {
        try {
          console.log(e);
        } catch (e) {
          console.log(e);
        }
      }.bind(this);

      reader.readAsDataURL(files[0]);

      if (imageJson) {
        this.setState({ profilePicture: imageJson.imageUrl });
      }
    } else alert('Image size should be less or equal to 800kb');
  };

  render() {
    const { firstName, lastName, emailAddress, profilePicture } = this.state;
    const { errorName } = this.state;
    /*
    const dropzoneStyle = {
      border: 'none',
      position: 'absolute',
      width: '400px',
      height: '63px',
      borderStyle: 'noen',
      top: 0,
    };
*/
    return (
      <div>
        <div>
          <h2>Welcome to GreatGuides</h2>
          <p>Alright, let&apos;s start! Tell us a bit about yourself.</p>
        </div>
        <form className={styles.form}>
          <div className={styles.form_content}>
            <span styles={styles.form_avatar}>
              <img
                src={profilePicture ? profilePicture : avatar}
                className={styles.avatar}
                alt='avatar'
              />
            </span>
            {/*
            <div className={styles.form_avatar_exp}>
              <h4 className={styles.form_header}>Upload Image</h4>
              <Dropzone
                onDrop={this.uploadAvatar}
                accept='image/jpeg, image/png'
                style={dropzoneStyle}
                multiple
              >
                {({ getRootProps, getInputProps }) => {
                  return (
                    <section>
                      <div {...getRootProps()}>
                        <input {...getInputProps()} />
                        <p className={styles.form_title}>
                          A square image 400x400 px is recommended
                        </p>
                      </div>
                    </section>
                  );
                }}
              </Dropzone>
            </div>
          */}
          </div>

          <div>
            <div className={styles.input}>
              <InputField
                wrapClass={styles.form_group}
                label='What is your first name?'
                type='text'
                holder='First name'
                name='firstName'
                value={firstName}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                setFieldValue={this.setFieldValue}
              />
            </div>
            <div className={styles.input}>
              <InputField
                wrapClass={styles.form_group}
                label='What is your last name?'
                type='text'
                holder='Last name'
                name='lastName'
                value={lastName}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                setFieldValue={this.setFieldValue}
              />
            </div>
            <div className={styles.input}>
              <InputField
                wrapClass={styles.form_group}
                label='Email address'
                type='email'
                holder='Email address'
                name='Email'
                value={emailAddress}
                labelClass={styles.label}
                inputClass={styles.inputBorder}
                inputErrorClass={styles.inputBorderError}
                setFieldValue={this.setFieldValue}
                errorName={errorName}
                errorText={styles.errorText}
              />
            </div>
          </div>

          <div className={styles.form_group}>
            <button type='button' className={styles.form_button} onClick={() => this.nextStep()}>
              Continue
            </button>
            <button type='button' className={styles.form_button} onClick={() => this.previewStep()}>
              Back
            </button>
          </div>
        </form>
      </div>
    );
  }
}

UserInfo.propTypes = {
  props: PropTypes.object,
  profilePicture: PropTypes.array,
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  updateUserInfo: PropTypes.func.isRequired,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  emailAddress: PropTypes.string,
  profilePictureUrl: PropTypes.string,
  loggedUser: PropTypes.string,
};

const selectors = createStructuredSelector({
  firstName: GuideSetFilterSelectors.firstNameSelector,
  lastName: GuideSetFilterSelectors.lastNameSelector,
  emailAddress: GuideSetFilterSelectors.emailAddressSelector,
  profilePictureUrl: GuideSetFilterSelectors.profilePictureUrlSelector,
  loggedUser: userSelectors.userIdSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserInfo: updateUserActions.updateUserInfo,
};

export default connect(selectors, actions)(UserInfo);
