// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest, select, call } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { TRIPS_GET } from '../modules/trips/types';
import { userSelector } from '../modules/users/selectors';
import { doGetUsersID } from './users';
import { myTripsBook } from 'api/trips';
import { getGuideTrips } from 'api/GuideTrip';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doMyTripAPICALL = userID =>
  apiCall({
    type: TRIPS_GET,
    method: 'POST',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    path: '',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: {
      query: myTripsBook,
      variables: {
        userId: userID,
      },
    },
    payloadOnSuccess: (responseData, action) => {
      responseData.userId = action.payload.userId;
      responseData.tripType = 'Client';
      return responseData;
    },
  });

const doCustomerTripAPICALL = userID =>
  apiCall({
    type: TRIPS_GET,
    method: 'POST',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    path: '',
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: {
      query: getGuideTrips,
      variables: {
        userId: userID,
      },
    },
    payloadOnSuccess: (responseData, action) => {
      console.log('checkingTripPayloadData', action);
      responseData.userId = action.payload.userId;
      responseData.tripType = 'Guide';
      return responseData;
    },
  });

export const doGetTrips = function*() {
  let user = yield select(userSelector);
  if (!user) {
    yield call(doGetUsersID);
    user = yield select(userSelector);
  }

  if (user.userRole === 'Client')
    yield call(doMyTripAPICALL(user.id), { payload: { userId: user.id } });
  else yield call(doCustomerTripAPICALL(user.id), { payload: { userId: user.id } });
};

export default function* rootSaga() {
  yield takeLatest(TRIPS_GET, doGetTrips);
}
