/**
 * @file Holds the <CardList> container
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

// import InputSelect from 'styles/components/CategorySelector.module.scss';

/* Component definition */
class InputSelect extends React.Component {
  state = {
    category: [],
  };

  selectGender = selectedOption => {
    const { selectGender } = this.props;
    this.setState({ category: selectedOption });
    selectGender(selectedOption.label);
  };

  setCategory = () => {
    return { value: this.props.value, label: this.props.value };
  };

  render() {
    const category = this.setCategory();
    const categories = this.props.categories.map(category => {
      return {
        value: category,
        label: category,
      };
    });

    const categoryStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        'align-items': 'center',
        display: 'flex',
        'flex-wrap': 'wrap',
        'justify-content': 'space-between',
        'white-space': 'nowrap',
        ':focus-within > :last-child': {
          transform: 'rotate(180deg)',
        },
        border: '1px solid rgba(230, 230, 230, .6)',
        padding: '10px 0px',
        'border-radius': '8px',
        'max-width': '200px',
      }),
      indicatorSeparator: () => ({}),
      input: () => ({
        visibility: 'visible',
      }),
      dropdownIndicator: () => ({
        display: 'block',
        color: 'black',
        marginRight: '5px',
      }),
      menu: styles => {
        return {
          ...styles,
          'max-width': '200px',
        };
      },
    };

    return (
      <div>
        <Select
          value={category}
          options={categories}
          styles={categoryStyles}
          placeholder='You can select a gender'
          isSearchable={true}
          onChange={this.selectGender}
          isClearable={false}
        />
      </div>
    );
  }
}

/* Prop types definition */
InputSelect.propTypes = {
  categories: PropTypes.array.isRequired,
  selectGender: PropTypes.func.isRequired,
  value: PropTypes.string,
};

export default InputSelect;
