/**
 * @file Holds the <Filter> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { compose } from 'redux';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Icon from 'components/Icon';
import styles from 'styles/components/HomeFilter.module.scss';
import { Container, Row, Col } from 'react-bootstrap';
import 'bootstrap/dist/css/bootstrap.css';
import Select from 'react-select';
import { DateRangePicker } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import '../styles/components/react_dates_overridesMenu.scss';

import {
  actions as adventuresSearchFilterActions,
  selectors as adventuresFilterSelectors,
} from '../redux/modules/AdventuresFilter';

import {
  actions as locationsActions,
  selectors as locationsSelectors,
} from '../redux/modules/locations';

// var moment = require('moment');

/* Component definition */
class HomeFilter extends React.Component {
  state = {
    location: '',
    startDate: '',
    endDate: '',
    focusedInput: null,
    focusedDateInput: false,
  };

  static propTypes = {
    startDate: PropTypes.string,
    endDate: PropTypes.string,
    location: PropTypes.string,
    day_multi: PropTypes.string,
    category: PropTypes.string,
    difficulty: PropTypes.string,
  };

  static defaultProps = {
    startDate: null,
    endDate: null,
    location: null,
    day_multi: null,
    category: null,
    difficulty: null,
  };

  componentDidMount() {
    const { getLocations } = this.props;

    getLocations();
    // this.initiateSet();
  }

  initiateSet() {
    let input = document.getElementsByClassName('DateInput_input_1');
    input[0].value = 'Dates';
    input[0].style.textAlign = 'left';
    input[1].style.display = 'none';

    let arrow = document.getElementsByClassName('DateRangePickerInput_arrow_1');
    arrow[0].style.display = 'none';
  }

  updateSet() {
    let input = document.getElementsByClassName('DateInput_input_1');
    input[1].style.display = 'block';
    input[0].style.textAlign = 'center';

    let arrow = document.getElementsByClassName('DateRangePickerInput_arrow_1');
    arrow[0].style.display = 'block';
  }

  onChangeLocation = selectedOption => {
    this.setState({ location: selectedOption });

    // let findBtn = document.getElementsByClassName('menuFindBtn');
    // findBtn.style.display = 'block';
  };

  onClickLocation = () => {
    let findBtn = document.getElementsByClassName('menuFindBtn');

    findBtn.style.display = 'none';
  };

  onChangeDate = (startDate, endDate) => {
    const { setAdventuresFilter } = this.props;
    setAdventuresFilter({
      ['startDate']: startDate,
      ['endDate']: endDate,
    });
  };

  onChangeFocus = focusedInput => {
    this.setState({ focusedInput });
    this.updateSet();

    if (focusedInput === null) {
      this.setState({ focusedDateInput: 'closeDate' });
      this.revertSelectDate();
    } else if (focusedInput === 'startDate') {
      this.setState({ focusedDateInput: 'startDate' });
    }
  };

  onFind() {}

  closeMenu = () => {
    const { statusMenu } = this.props;

    statusMenu(false);
  };

  selectInput = type => {
    switch (type) {
    case 'location':
      break;
    case 'date':
      this.convertSelectDate();
      break;
    }
  };

  convertSelectDate = () => {
    let locationBtn = document.getElementsByClassName('locationInpt');
    locationBtn[0].style.display = 'none';

    let section = document.getElementsByClassName('section');
    section[0].style.display = 'none';
  };

  revertSelectDate = () => {
    let locationBtn = document.getElementsByClassName('locationInpt');
    locationBtn[0].style.display = 'grid';

    let section = document.getElementsByClassName('section');
    section[0].style.display = 'block';
  };

  render() {
    const { startDate, endDate } = this.props;
    const { location, focusedDateInput } = this.state;
    const locations = this.props.locations.map(location => {
      return {
        value:
          location.city + ', ' + (location.state ? location.state + ', ' : '') + location.country,
        label:
          location.city + ', ' + (location.state ? location.state + ', ' : '') + location.country,
        flagIcon: location.flagIcon ? location.flagIcon : '',
      };
    });

    const menuFindBtnSetStyle = {
      backgroundColor: 'white',
      width: '100%',
      position: 'absolute',
      bottom: '0',
      left: '0',
      transform: 'translate(-50% 0)',
      padding: '35px 15px',
      zIndex: '2',
    };

    const menuFindBtnInitialStyle = {
      backgroundColor: 'white',
      width: '100%',
      paddingRight: '15px',
      zIndex: '2',
    };

    const dot = flagIcon => ({
      ':before': {
        backgroundImage: `url(${flagIcon})`,
        backgroundRepeat: 'no-repeat',
        content: '" "',
        'margin-right': 8,
        'margin-top': 3,
        float: 'left',
        height: 16,
        width: 16,
      },
      ':hover': {
        backgroundColor: 'rgba(255, 149, 20, 0.1)',
        color: '#FF9514',
      },
      ':active': {
        backgroundColor: 'rgba(255, 149, 20, 0.1)',
        color: '#FF9514',
      },
    });
    const locationStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        border: 'none',
        'align-items': 'center',
        display: 'flex',
        'flex-wrap': 'wrap',
        'justify-content': 'space-between',
        position: 'relative',
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      input: () => ({
        visibility: 'visible',
        color: 'hsl(0,0%,20%)',
        'box-sizing': 'border-box',
      }),
      dropdownIndicator: () => ({
        display: 'none',
      }),
      singleValue: (styles, { data }) => ({ ...styles, ...dot(data.flagIcon) }),
      option: (styles, { data }) => ({ ...styles, ...dot(data.flagIcon) }),
      menu: styles => {
        return {
          ...styles,
          width: '117% !important',
          left: '-30px',
          top: 'calc((100% + 9px))',
          border: 'none',
          boxShadow: 'none',
          height: '100vh',
          div: {
            position: 'relative',
            'overflow-y': 'scroll',
            'overflow-x': 'hidden',
            div: {
              overflow: 'hidden',
              paddingRight: '2px',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
            },
          },
        };
      },
    };

    return (
      <div className={styles.wrapper}>
        <div className={styles.exitIcon} onClick={() => this.closeMenu()}>
          <Icon name='exit' />
        </div>
        <div className={`section ${styles.section}`}>
          <h1 className={styles.sectionTitle}>Adventure search</h1>
          <h3 className={styles.tipDetail}>We can have small tagline with tips</h3>
        </div>
        <div className={styles.row}>
          <Container>
            <Row noGutters={true}>
              <Col xs={12} md={12} className={styles['clm-location']}>
                <div className='locationInpt' onClick={() => this.selectInput('location')}>
                  <div className={`locationInpt ${styles.input}`}>
                    <Icon name='location' />
                    <Select
                      value={location}
                      options={locations}
                      styles={locationStyles}
                      placeholder='Location'
                      isSearchable={true}
                      backspaceRemovesValue={true}
                      captureMenuScroll={true}
                      onChange={this.onChangeLocation}
                      onClick={this.onClickLocation}
                    />
                  </div>
                </div>
              </Col>
              <Col xs={12} md={12} className={styles['clm-date']}>
                <div
                  className={`menuFilter ${styles.input} dateInput`}
                  onClick={() => this.selectInput('date')}
                >
                  <Icon name='season' />
                  <DateRangePicker
                    startDate={startDate}
                    startDateId='start_date'
                    endDate={endDate}
                    endDateId='end_date'
                    onDatesChange={({ startDate, endDate }) => {
                      this.setState({ startDate, endDate });
                      this.onChangeDate(startDate, endDate);
                    }}
                    focusedInput={this.state.focusedInput}
                    onFocusChange={focusedInput => this.onChangeFocus(focusedInput)}
                    noBorder
                    displayFormat='DD MMM'
                    customArrowIcon='-'
                    numberOfMonths={2}
                    hideKeyboardShortcutsPanel={true}
                  />
                  <svg
                    height='20'
                    width='20'
                    viewBox='0 0 20 20'
                    aria-hidden='true'
                    focusable='false'
                    className='css-19bqh2r'
                  >
                    <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z' />
                  </svg>
                </div>
              </Col>
              {/* <Col xs={12} md={12} className={styles['clm-findbtn']}>
                <button className={styles.find_button} onClick={this.onFind.bind(this)}>
                  Find 
                </button>
              </Col> */}
            </Row>
          </Container>
          <div
            className='menuFindBtn'
            style={focusedDateInput === 'startDate' ? menuFindBtnSetStyle : menuFindBtnInitialStyle}
          >
            <Link to='/adventures'>
              <button className={`${styles.find_button}`}>Find Adventure</button>
            </Link>
          </div>
        </div>
      </div>
    );
  }
}

HomeFilter.propTypes = {
  locations: PropTypes.array,
  category: PropTypes.string,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setAdventuresFilter: PropTypes.func.isRequired,
  getLocations: PropTypes.func.isRequired,
  statusMenu: PropTypes.func.isRequired,
};

const selectors = createStructuredSelector({
  startDate: adventuresFilterSelectors.startDateSelector,
  endDate: adventuresFilterSelectors.endDateSelector,
  location: adventuresFilterSelectors.locationSelector,
  day_multi: adventuresFilterSelectors.dayMultiSelector,
  category: adventuresFilterSelectors.categorySelector,
  difficulty: adventuresFilterSelectors.difficultySelector,
  locations: locationsSelectors.locationsSelector,
});

const actions = {
  getLocations: locationsActions.getLocations,
  setAdventuresFilter: adventuresSearchFilterActions.setAdventuresFilter,
};

export default compose(
  withRouter,
  connect(
    selectors,
    actions
  )
)(HomeFilter);
