import gql from 'graphql-tag';

export const getUserDetails = gql`
  query userDetails($id: ID!) {
    user(where: { id: $id }) {
      id
      handle
      gender
      userRole
      firstName
      lastName
      fullName
      externalProfileLink
      auth0ProfilePictureUrl
      profilePictureUrl
      isUserActive
      isEmailVerified
      emailAddress
      profile
      phoneNumber
      businessName
      businessAddress
      otherQualifications
      website
      youtubeChannel
      technicalSkills
      firstAidTraining
      customerServiceSkills
      expertise
      sustainableStandards
      certificate
      aboutMe
      participantAgree
      guideAgree
      tripAdvisor
      flagIcon
      region
      city
      state
      country
      zip
      extra
      memberships
      categories
      adventures {
        id
        title
        pricePerPerson
        categories
        dates
        address
        media {
          imageMQUrl
        }
        guide {
          fullName
          profilePictureUrl
        }
      }
      conversations {
        id
        users {
          id
          fullName
          profilePictureUrl
        }
        messages {
          text
          createdAt
          author {
            id
            profilePictureUrl
          }
        }
      }
    }
  }
`;
