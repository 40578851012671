/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import ReactPixel from 'react-facebook-pixel';

import styles from 'styles/pages/AdventuresPage.module.scss';

import stylesFilter from 'styles/components/Filter.module.scss';
import AdventureCard from 'components/AdventureCard';
import AdventureCardList from 'containers/AdventureCardList';

import SearchFilter from '../components/SearchFilter/SearchFilter';

import {
  actions as categoriesActions,
  selectors as categoriesSelectors,
} from '../redux/modules/categories';
import {
  actions as locationsActions,
  selectors as locationsSelectors,
} from '../redux/modules/locations';
import {
  actions as adventuresSearchFilterActions,
  selectors as adventuresFilterSelectors,
} from '../redux/modules/AdventuresFilter';
import {
  actions as adventuresActions,
  selectors as adventuresSelectors,
} from '../redux/modules/adventures';

const moment = require('moment');

/* Component definition */
class AdventuresPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      filter: {
        endDate: '',
        location: '',
        dayMulti: '',
        category: '',
      },
      searchParams: '',
    };
  }

  componentDidMount() {
    const { getCategories, getLocations } = this.props;

    this.loadDataFromUrl();

    getLocations(); // Getting Locations
    getCategories(); // Getting Categories
  }

  loadDataFromUrl = () => {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const params = {
      category: 'All',
      endDate: '',
      location: '',
      dayMulti: '',
    };
    const { filter } = this.state;
    new URLSearchParams(search).forEach((value, key) => {
      if (filter.hasOwnProperty(key)) {
        params[key] = value;
      }
    });
    this.setAdventuresFilter({ ...params });
    this.setState({
      filter: { ...params },
      search,
    });

    this.getLoadMore(false);
  };

  componentDidUpdate() {
    const {
      history: {
        location: { search },
      },
    } = this.props;
    const { search: stateSearch } = this.state;
    if (stateSearch !== search) {
      this.loadDataFromUrl();
    }
  }

  setAdventuresFilter = params => {
    const { setAdventuresFilter } = this.props;
    setAdventuresFilter({
      // Atma : Change Date filter to look back 3 years
      ['startDate']: moment(new Date()).subtract(10000, 'day'),
      ['endDate']: moment(params.endDate || new Date().getFullYear() + 1 + '-12-31'),
      ['category']: params.category || '',
      ['location']: params.location || '',
      ['day_multi']: params.dayMulti || '',
    });
  };

  setFilter = data => {
    const { filter } = this.state;
    this.setState({
      filter: { ...filter, ...data },
    });
  };

  submitFilter = () => {
    const {
      history: { push },
    } = this.props;
    const { origin, pathname } = window.location;
    const url = new URL(origin + pathname);
    const { filter } = this.state;
    const paramKeys = Object.keys(filter);
    paramKeys.forEach(name => {
      if (filter[name] !== '') {
        url.searchParams.set(name, filter[name]);
      }
    });
    push(url.pathname + url.search);
  };

  getLoadMore = (isLoadMore = true) => {
    const { getAdventures } = this.props;
    getAdventures({ isLoadMore });
  };

  render() {
    const advancedMatching = { em: 'some@email.com' };
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view

    const { categories, allAdventures, adventures, locations, loading } = this.props;
    const {
      filter: { endDate, dayMulti, category, location },
    } = this.state;

    return loading ? (
      <div className={styles.loaderSpinner}>
        <div className={styles.loader} />
      </div>
    ) : (
      <main className={styles.wrapper}>
        <div className='search-filter-bg'>
          <div className={stylesFilter.wrapper}>
            <div className={stylesFilter.row}>
              <SearchFilter
                locations={locations}
                categories={categories}
                location={location}
                endDate={endDate}
                category={category}
                dayMulti={dayMulti}
                setFilter={this.setFilter}
                submitFilter={this.submitFilter}
              />
            </div>
          </div>
        </div>

        {/* Found adventures */}
        <AdventureCardList
          categories={categories}
          bgColor='grey'
          isMore
          columns='c4__adventures'
          getLoadMore={this.getLoadMore}
        >
          <h2>{allAdventures.length} Adventures found</h2>

          {adventures.map((item, index) => (
            <AdventureCard key={index} adventure={item} />
          ))}
        </AdventureCardList>
      </main>
    );
  }
}

AdventuresPage.propTypes = {
  getLocations: PropTypes.func.isRequired,
  getAdventures: PropTypes.func.isRequired,
  locations: PropTypes.array.isRequired,
  allAdventures: PropTypes.array.isRequired,
  adventures: PropTypes.array.isRequired,
  loading: PropTypes.bool.isRequired,
  setAdventuresFilter: PropTypes.func.isRequired,
  categories: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  dayMulti: PropTypes.string,
  category: PropTypes.string,
  difficulty: PropTypes.string,
  location: PropTypes.string,
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }),
};

const selectors = createStructuredSelector({
  locations: locationsSelectors.locationsSelector,
  startDate: adventuresFilterSelectors.startDateSelector,
  endDate: adventuresFilterSelectors.endDateSelector,
  location: adventuresFilterSelectors.locationSelector,
  dayMulti: adventuresFilterSelectors.dayMultiSelector,
  category: adventuresFilterSelectors.categorySelector,
  difficulty: adventuresFilterSelectors.difficultySelector,
  allAdventures: adventuresSelectors.adventuresAllSelector,
  adventures: adventuresSelectors.adventuresSelector,
  loading: adventuresSelectors.loadingSelector,
  categories: categoriesSelectors.categoriesSelector,
});

const actions = {
  getLocations: locationsActions.getLocations,
  getAdventures: adventuresActions.getAdventures,
  setAdventuresFilter: adventuresSearchFilterActions.setAdventuresFilter,
  getCategories: categoriesActions.getCategories,
};

export default connect(selectors, actions)(AdventuresPage);
