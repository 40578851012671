import React from 'react';
import PropTypes from 'prop-types';

import Icon from 'components/Icon';

import styles from 'styles/components/RequireSignUp.module.scss';

class RequireSignUp extends React.Component {
  cancelSignUp = () => {
    const { cancelSignUp } = this.props;

    cancelSignUp();
  };

  signUp = () => {
    const { signUp } = this.props;

    signUp();
  };
  render() {
    const { heading, paragraph, type } = this.props;
    return (
      <div className={styles.wrapper}>
        <div className={styles.row}>
          {type === 2 && (
            <div className={styles.exitIcon} onClick={() => this.cancelSignUp()}>
              <Icon name='exit' />
            </div>
          )}
          <h2>{heading}</h2>
          <p>{paragraph}</p>
          {type === 1 && (
            <div className={styles.buttons}>
              <div>
                <button className={styles.button} onClick={() => this.signUp()}>
                  SIGN UP
                </button>
              </div>
              <div>
                <button className={styles.button} onClick={this.cancelSignUp}>
                  CANCEL
                </button>
              </div>
            </div>
          )}
          {type === 2 && (
            <div className={styles.buttons}>
              <div className={styles.signUp}>
                <button className={styles.button} onClick={() => this.signUp()}>
                  SIGN UP
                  <Icon name='arrow-right-big' />
                </button>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

RequireSignUp.propTypes = {
  heading: PropTypes.string,
  paragraph: PropTypes.string,
  cancelSignUp: PropTypes.func.isRequired,
  signUp: PropTypes.func.isRequired,
  type: PropTypes.number,
};

export default RequireSignUp;
