import { createAction } from 'redux-actions';

import {
  GUIDE_All_FILTER_SET,
  GUIDE_FILTER_SET,
  GUIDE_FILTER_UPDATE,
  GUIDE_FILTER_CLEAR,
} from './types';

export const updateGuideFilter = createAction(GUIDE_FILTER_UPDATE);

export const setGuideFilter = createAction(GUIDE_FILTER_SET);

export const setGuideAllFilter = createAction(GUIDE_All_FILTER_SET);

export const clearGuideFilter = createAction(GUIDE_FILTER_CLEAR);
