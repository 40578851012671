import { handleActions } from 'redux-actions';

import { SCROLL_IS_BOTTOM, TOGGLE_MODAL } from './types';

const initialState = {
  modal: {
    isModalOpen: false,
    modalName: '',
  },
  scrollIsBottom: false,
};

export default handleActions(
  {
    [TOGGLE_MODAL]: (state, { payload }) => ({
      ...state,
      modal: {
        isModalOpen: !state.modal.isModalOpen,
        modalName: payload || '',
      },
    }),
    [SCROLL_IS_BOTTOM]: (state, { payload }) => ({
      ...state,
      scrollIsBottom: payload,
    }),
  },
  initialState
);
