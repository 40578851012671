import React, { Component } from 'react';

import Sidebar from '../../components/Account/SideBar';
import Container from '../../common/container/Container';

import userImage from '../../assets/GuideProfile/Oval.png';
import Male from '../../assets/account/male.png';
import FeMale from '../../assets/account/female.png';
import './account.scss';

class AccountDetail extends Component {
  render() {
    return (
      <Container>
        <div className='account-page'>
          <Sidebar />
          <div className='account-right'>
            <h1>Account Details</h1>
            <div className='user-image'>
              <div className='col-2'>
                <img src={userImage} alt='User' />
              </div>
              <div className='col-10'>
                <p>A square image 400x400 px is recommended</p>
                <label htmlFor='uploadImage'>Upload a new image</label>
                <input
                  accept='image/jpeg, image/png'
                  type='file'
                  className='hidden'
                  id='uploadImage'
                />
              </div>
            </div>
            <div className='user-form'>
              <div className='gender'>
                <label>Gender</label>
                <div className='form-group'>
                  <input type='radio' id='male' name='gender' className='hidden' />
                  <label htmlFor='male'>
                    <figure>
                      <img src={Male} alt='Trulli' />
                      <figcaption>Male</figcaption>
                    </figure>
                  </label>
                </div>
                <div className='form-group'>
                  <input type='radio' name='gender' id='female' className='hidden' />
                  <label htmlFor='female'>
                    <figure>
                      <img src={FeMale} alt='Trulli' />
                      <figcaption>Female</figcaption>
                    </figure>
                  </label>
                </div>
              </div>
              <form>
                <div className='form-group'>
                  <label>Full Name</label>
                  <input type='text' placeholder='your full name' />
                </div>
                <div className='form-group'>
                  <label>Email Address</label>
                  <input type='email' placeholder='your email address' />
                </div>
                <div className='form-group'>
                  <label>Password</label>
                  <input type='password' placeholder='******' />
                </div>
                <button>Save Changes</button>
              </form>
            </div>
          </div>
        </div>
      </Container>
    );
  }
}

export default AccountDetail;
