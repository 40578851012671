/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { createStructuredSelector } from 'reselect';
import { connect } from 'react-redux';

import { actions as adventuresByIdActions } from '../redux/modules/AdventuresID';
import { actions as adventuresSearchFilterActions } from '../redux/modules/AdventuresFilter';
import { selectors as createAdventureSelectors } from '../redux/modules/CreateAdventure';

import Loading from 'components/Loading';
import NewAdventure from '../components/CreateAdventure/NewAdventure';
import AddLocation from '../components/CreateAdventure/AddLocation';
import AddDates from '../components/CreateAdventure/AddDates';
import AddDescription from '../components/CreateAdventure/AddDescription';
import AddItinerary from '../components/CreateAdventure/AddItinerary';
import Included from '../components/CreateAdventure/Included';
import UploadImages from '../components/CreateAdventure/UploadImages';
import CircleStep from 'components/CreateAdventure/CircleStep';

import Style from 'styles/pages/CreateAdventure.module.scss';

/* Component definition */
class EditAdventure extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isSignUpModal: false,
      step: 1,
      profilePicture: null,
      licenses: null,
      cpr: null,
      insurances: null,
      errorName: '',
    };
  }

  componentDidMount() {
    const { editAdventuresById, setAdventureId } = this.props;

    // Setting Adventure ID
    setAdventureId({
      ['id']: this.props.match.params.id,
    });

    editAdventuresById({ id: this.props.match.params.id });
  }

  nextStep = () => {
    let { step } = this.state;

    this.setState({ step: Math.min(++step, 7) });
  };

  previewStep = () => {
    let { step } = this.state;
    if (step === 1) {
      return;
    }
    this.setState({ step: --step });
  };

  render() {
    const { step } = this.state;
    const { adventureID } = this.props;
    return (
      <main className={Style.new_adventure}>
        {!adventureID ? (
          <Loading />
        ) : (
          <>
            <CircleStep step={step} />
            {step === 1 && (
              <div>
                <NewAdventure
                  nextStep={this.nextStep}
                  previewStep={this.previewStep}
                  editStatus='update'
                />
              </div>
            )}
            {step === 2 && (
              <div className='add-location'>
                <AddLocation nextStep={this.nextStep} previewStep={this.previewStep} />
              </div>
            )}
            {step === 3 && (
              <div>
                <AddDates nextStep={this.nextStep} previewStep={this.previewStep} />
              </div>
            )}
            {step === 4 && (
              <div>
                <AddDescription nextStep={this.nextStep} previewStep={this.previewStep} />
              </div>
            )}
            {step === 5 && (
              <div>
                <AddItinerary nextStep={this.nextStep} previewStep={this.previewStep} />
              </div>
            )}
            {step === 6 && (
              <div>
                <Included
                  nextStep={this.nextStep}
                  previewStep={this.previewStep}
                  editStatus='update'
                />
              </div>
            )}
            {step === 7 && (
              <div>
                <UploadImages nextStep={this.nextStep} previewStep={this.previewStep} />
              </div>
            )}
          </>
        )}
      </main>
    );
  }
}

/* Prop types definition */
EditAdventure.propTypes = {
  match: PropTypes.object,
  editAdventuresById: PropTypes.func.isRequired,
  setAdventureId: PropTypes.func.isRequired,
  adventureID: PropTypes.string,
};

const selectors = createStructuredSelector({
  adventureID: createAdventureSelectors.adventureIDSelector,
});

const actions = {
  editAdventuresById: adventuresByIdActions.editAdventuresById,
  setAdventureId: adventuresSearchFilterActions.setAdventuresFilter,
};

export default connect(
  selectors,
  actions
)(EditAdventure);
