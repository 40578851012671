import gql from 'graphql-tag';

export const getAdventures = gql`
  query adventuresByDateRangeCategoryAndLocation(
    $startDate: DateTime!
    $endDate: DateTime!
    $location: String
    $category: Category!
    $dayOrMulti: String
    $qdifficulty: String
  ) {
    adventuresByDateRangeCategoryAndLocation(
      startDate: $startDate
      endDate: $endDate
      location: $location
      category: $category
      dayOrMulti: $dayOrMulti
      difficulty: $qdifficulty
    ) {
      id
      title
      categories
      pricePerPerson
      address
      durationInDays
      dayOrMulti
      guide {
        profilePictureUrl
        fullName
        extra
        aboutMe
        tripAdvisor
      }
      media {
        imageMQUrl
        imageHQUrl
      }
    }
  }
`;

// export const getAdventures = gql`
//   query adventures(
//     $categories: Category
//     $isTrue: Boolean
//     ){
//       adventures(where: {categories: $categories, isDefault: $isTrue }) {
//         id
//         title
//         description
//         dates
//         categories
//         media {
//           id
//           imageMQUrl
//           imageUrl
//         }
//         guide {
//           id
//           fullName
//           profilePictureUrl
//         }
//         price
//         address
//         isDefault
//         reviews
//         comments {
//           id
//           author {
//             id
//             fullName
//             profilePictureUrl
//           }
//           note
//           rating
//           createdAt
//         }
//       }
//     }
// `;
