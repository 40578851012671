// import { put, takeLatest } from 'redux-saga/effects'
import { call, select, takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { GUIDE_All_FILTER_SET } from '../modules/GuideFilter/types';

import { userIdSelector } from '../modules/users/selectors';

import { getUserDetails } from 'api/userDetails';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doAPICall = id =>
  apiCall({
    type: GUIDE_All_FILTER_SET,
    method: 'POST',
    path: '',
    baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
    headers: {
      Authorization: `Bearer ${localStorage.getItem('id_token')}`,
    },
    body: { query: getUserDetails, variables: { id: id } },
  });

export const doGetUserAllInfo = function*() {
  const id = yield select(userIdSelector);
  yield call(doAPICall(id), { payload: '' });
};

export default function* rootSaga() {
  yield takeLatest(GUIDE_All_FILTER_SET, doGetUserAllInfo);
}
