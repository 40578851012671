/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { compose, graphql, withApollo } from 'react-apollo';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../redux/modules/GuideFilter';
import { actions as userActions, selectors as userSelectors } from '../redux/modules/users';
import {
  actions as categoriesActions,
  selectors as categoriesSelectors,
} from '../redux/modules/categories';

import UserInfo from 'components/AccountDetails/UserInfo';
import GuideInfo from 'components/AccountDetails/GuideInfo';
import GuideContact from 'components/AccountDetails/GuideContact';
import GuideQualification from 'components/AccountDetails/GuideQualification';
import GuideCompetency from 'components/AccountDetails/GuideCompetency';

import styles from 'styles/pages/AccountDetailsPage.module.scss';

import { getUserAuthID } from 'api/user';

/* Component definition */
class AccountDetailsPage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tabIndex: 1,
      errorField: '',
      loading: true,
    };
  }

  componentDidMount() {
    const { getCategories, getUserID } = this.props;

    if (localStorage.isLoggedIn !== 'true') {
      this.props.history.replace('/');
    }
    localStorage.setItem('page', '/profile/account');

    getCategories(); // Getting Categories
    getUserID();
  }

  shouldComponentUpdate(nextProps) {
    const { guideFilterStatus } = nextProps;
    if (guideFilterStatus) {
      if (
        guideFilterStatus.includes('success') &&
        this.props.guideFilterStatus.includes('pending')
      ) {
        this.setState({ loading: false });
      }
    }

    return true;
  }

  setTabIndex = index => {
    this.setState({ tabIndex: index });
  };

  handleInputChange = () => {};

  render() {
    const { userRole, categories } = this.props;
    const { tabIndex, loading } = this.state;
    if (loading) {
      return (
        <div className={styles.loaderSpinner}>
          <div className={styles.loader} />
        </div>
      );
    } else {
      return (
        <main className={styles.wrapper}>
          {userRole === 'Client' && (
            <div className={styles.row}>
              <UserInfo />
            </div>
          )}
          {userRole !== 'Client' && (
            <div className={styles.row}>
              {tabIndex === 1 && <GuideInfo setTabIndex={this.setTabIndex} tabIndex={tabIndex} />}
              {tabIndex === 2 && (
                <GuideContact setTabIndex={this.setTabIndex} tabIndex={tabIndex} />
              )}
              {tabIndex === 3 && (
                <GuideQualification
                  setTabIndex={this.setTabIndex}
                  categories={categories}
                  tabIndex={tabIndex}
                />
              )}
              {tabIndex === 4 && (
                <GuideCompetency setTabIndex={this.setTabIndex} tabIndex={tabIndex} />
              )}
            </div>
          )}
        </main>
      );
    }
  }
}

AccountDetailsPage.propTypes = {
  history: PropTypes.object,
  loggedInUser: PropTypes.object,
  getUserID: PropTypes.func.isRequired,
  setUser: PropTypes.func.isRequired,
  loggedUser: PropTypes.string,
  auth: PropTypes.object.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  setUserAllInfo: PropTypes.func.isRequired,
  userRole: PropTypes.string,
  categories: PropTypes.array.isRequired,
  getCategories: PropTypes.func.isRequired,
  guideFilterStatus: PropTypes.string,
};

const selectors = createStructuredSelector({
  loggedUser: userSelectors.userIdSelector,
  userRole: GuideSetFilterSelectors.userRoleSelector,
  categories: categoriesSelectors.categoriesSelector,
  guideFilterStatus: GuideSetFilterSelectors.GuideFilterStatusSelector,
});

const actions = {
  getUserID: userActions.getUserID,
  setUser: userActions.setUser,
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  setUserAllInfo: GuideSetFilterActions.setGuideAllFilter,
  getCategories: categoriesActions.getCategories,
};

const userId = {
  name: 'loggedInUser',

  options() {
    return {
      variables: {},
    };
  },

  props({ loggedInUser: { loading, error, loggedInUser, refetch } }) {
    return { loading, error, loggedInUser, authRefetch: refetch };
  },
};

export default compose(
  withApollo,
  graphql(getUserAuthID, userId),
  connect(
    selectors,
    actions
  )
)(AccountDetailsPage);
