import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { TRIPS_GET } from './types';

const initialState = {
  data: null,
  myTrips: null,
  customerTrips: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(TRIPS_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(TRIPS_GET),
      error: false,
      message: '',
    }),

    [requestSuccess(TRIPS_GET)]: (state, { payload }) => {
      let { myTrips, customerTrips } = state;

      if (payload.tripType === 'Client') {
        const allTrips = payload.trips;
        myTrips = [];

        allTrips.map(item => {
          if (item.bookings.length) {
            myTrips.push(item);
          }
        });
      } else {
        console.log('checkingGuideTripAllData', payload);
        const allTrips = payload.bookings;

        customerTrips = allTrips;
      }

      return {
        ...state,
        status: requestSuccess(TRIPS_GET),
        data: payload,
        myTrips: myTrips,
        customerTrips: customerTrips,
        error: null,
      };
    },

    [requestFail(TRIPS_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      myTrips: null,
      customerTrips: null,
      status: requestFail(TRIPS_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
