/**
 * @file Holds the <AdventuresPage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { SingleDatePicker, isInclusivelyBeforeDay } from 'react-dates';
import S3FileUpload from 'react-s3';

import FileUpload from './FileUpload';
import InputField from '../InputField';

import {
  actions as GuideSetFilterActions,
  selectors as GuideSetFilterSelectors,
} from '../../redux/modules/GuideFilter';
import { actions as updateUserActions } from '../../redux/modules/UpdateUser';
import { selectors as userSelectors } from '../../redux/modules/users';

import {
  S3_CONFIG_LICENSE,
  S3_CONFIG_TRACE_CERTIFIACTE,
  S3_CONFIG_CPR_CERTICATE,
  S3_CONFIG_TRIP_INSURANCE,
} from './config/config';

import 'styles/components/guide/react_dates_overrides.scss';
import styles from 'styles/pages/NewGuidePage.module.scss';
import requirStyle from 'styles/components/guide/Requirements.module.scss';

const uuid = require('uuid/v1');
const moment = require('moment');

/* Component definition */
class Requirements extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      focused: null,
      date: moment('201-05-09'),
      professionalGuide: false,
      hasLicense: [false, false],
      permittedGuide: [false, false],
      licenses: [],
      traceCertification: [],
      cprCertification: [],
      insurances: [],
      trip: '',
      lastTripTitle: '',
      lastTripCompany: '',
      s3Licenses: [],
      s3Trace: [],
      s3CPR: [],
      s3Insurances: [],
    };
  }

  componentDidMount() {
    const {
      extra,
      GuideExp,
      LastTripCompany,
      LastTripTitle,
      LastTripDate,
      GuideLic,
      GuideLicLink,
      GuidePermit,
      GuideNoTrace,
      GuideFirstAid,
      GuideInsurance,
      GuideTripLink,
    } = this.props;
    if (extra) {
      this.setState({
        professionalGuide: GuideExp ? true : false,
        lastTripTitle: LastTripTitle ? LastTripTitle : '',
        lastTripCompany: LastTripCompany ? LastTripCompany : '',
        date: LastTripDate ? moment(LastTripDate) : '',
        hasLicense:
          GuideLic === 0 ? [true, false] : GuideLic === 1 ? [false, true] : [false, false],
        licenses: GuideLicLink ? GuideLicLink : [],
        permittedGuide:
          GuidePermit === 0 ? [true, false] : GuidePermit === 1 ? [false, true] : [false, false],
        traceCertification: GuideNoTrace ? GuideNoTrace : [],
        cprCertification: GuideFirstAid ? GuideFirstAid : [],
        insurances: GuideInsurance ? GuideInsurance : [],
        tripUrl: GuideTripLink ? GuideTripLink : '',
      });
      this.setState({
        s3Licenses: GuideLicLink ? GuideLicLink.slice(0) : [],
        s3Trace: GuideNoTrace ? GuideNoTrace.slice(0) : [],
        s3CPR: GuideFirstAid ? GuideFirstAid.slice(0) : [],
        s3Insurances: GuideInsurance ? GuideInsurance.slice(0) : [],
      });
    }
  }

  checkingObjectProperty = property => {
    const { extra } = this.props;

    return 'undefined' === typeof extra[property] ? false : true;
  };

  onInputTextChange = () => {};

  onChangeFocus = focused => {
    this.setState({ focused });
  };

  nextStep = () => {
    const { s3Licenses, s3Trace } = this.state;
    const { nextStep, setGuideInfo, updateUserRequirement, loggedUser, extra } = this.props;
    const {
      tripUrl,
      professionalGuide,
      lastTripTitle,
      date,
      lastTripCompany,
      hasLicense,
      permittedGuide,
      s3CPR,
      s3Insurances,
    } = this.state;
    console.log('checkingS3DAta', s3Licenses, s3Trace, s3CPR, s3Insurances);
    //Set Guides' information
    setGuideInfo({
      ['extra']: {
        rating: extra && extra.rating !== undefined ? extra.rating : null,
        NumOfReviews: extra && extra.NumOfReviews !== undefined ? extra.NumOfReviews : null,
        Lat: extra && extra.Lat !== undefined ? extra.Lat : null,
        Long: extra && extra.Long !== undefined ? extra.Long : null,
        GuideExp: professionalGuide,
        LastTripTitle: lastTripTitle,
        LastTripDate: date,
        LastTripCompany: lastTripCompany,
        GuideLic: hasLicense[0] ? 0 : hasLicense[1] ? 1 : 2,
        GuideLicLink: s3Licenses,
        GuidePermit: permittedGuide[0] ? 0 : permittedGuide[1] ? 1 : 2,
        GuideNoTrace: s3Trace,
        GuideFirstAid: s3CPR,
        GuideInsurance: s3Insurances,
        GuideTripLink: tripUrl,
      },
      ['GuideExp']: professionalGuide,
      ['LastTripTitle']: lastTripTitle,
      ['LastTripDate']: date,
      ['LastTripCompany']: lastTripCompany,
      ['GuideLic']: hasLicense[0] ? 0 : hasLicense[1] ? 1 : 2,
      ['GuideLicLink']: s3Licenses,
      ['GuidePermit']: permittedGuide[0] ? 0 : permittedGuide[1] ? 1 : 2,
      ['GuideNoTrace']: s3Trace,
      ['GuideFirstAid']: s3CPR,
      ['GuideInsurance']: s3Insurances,
      ['GuideTripLink']: tripUrl,
    });

    if (loggedUser) updateUserRequirement();

    nextStep();
  };

  updateS3 = () => {
    const { s3Licenses, s3Trace } = this.state;
    const { setGuideInfo, updateUserRequirement, loggedUser } = this.props;
    const {
      tripUrl,
      professionalGuide,
      lastTripTitle,
      date,
      lastTripCompany,
      hasLicense,
      permittedGuide,
      s3CPR,
      s3Insurances,
    } = this.state;

    //Set Guides' information
    setGuideInfo({
      ['extra']: {
        GuideExp: professionalGuide,
        LastTripTitle: lastTripTitle,
        LastTripDate: date,
        LastTripCompany: lastTripCompany,
        GuideLic: hasLicense[0] ? 0 : hasLicense[1] ? 1 : 2,
        GuideLicLink: s3Licenses,
        GuidePermit: permittedGuide[0] ? 0 : permittedGuide[1] ? 1 : 2,
        GuideNoTrace: s3Trace,
        GuideFirstAid: s3CPR,
        GuideInsurance: s3Insurances,
        GuideTripLink: tripUrl,
      },
    });

    if (loggedUser) updateUserRequirement();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  setFieldValue = (value, type) => {
    console.log('checkingInput', value, type);
    if (type === 'trip') this.setState({ tripUrl: value });
    else if (type === 'lastTripTitle') {
      this.setState({ lastTripTitle: value });
    } else if (type === 'lastTripCompany') {
      this.setState({ lastTripCompany: value });
    }
  };

  removeFile = (type, index) => {
    let {
      licenses,
      s3Licenses,
      traceCertification,
      s3Trace,
      cprCertification,
      s3CPR,
      insurances,
      s3Insurances,
    } = this.state;
    let removeFile;
    switch (type) {
    case 'license':
      licenses.splice(index, 1);
      this.setState({ licenses: licenses });
      s3Licenses.splice(index, 1);
      this.setState({ s3Licenses: s3Licenses });
      S3FileUpload.deleteFile(removeFile, S3_CONFIG_LICENSE)
        .then(response => {
          console.log('researched', response);
        })
        .catch(err => console.error('err', err));
      break;
    case 'trace':
      traceCertification.splice(index, 1);
      this.setState({ traceCertification: traceCertification });
      removeFile = s3Trace[index].s3Name;
      s3Trace.splice(index, 1);
      this.setState({ s3Trace: s3Trace });
      S3FileUpload.deleteFile(removeFile, S3_CONFIG_TRACE_CERTIFIACTE)
        .then(response => {
          console.log('researched', response);
        })
        .catch(err => console.error('err', err));
      break;
    case 'cpr':
      cprCertification.splice(index, 1);
      this.setState({ cprCertification: cprCertification });
      removeFile = s3CPR[index].s3Name;
      s3CPR.splice(index, 1);
      this.setState({ s3CPR: s3CPR });
      S3FileUpload.deleteFile(removeFile, S3_CONFIG_CPR_CERTICATE)
        .then(response => {
          console.log('researched', response);
        })
        .catch(err => console.error('err', err));
      break;
    case 'insurance':
      insurances.splice(index, 1);
      this.setState({ insurances: insurances });
      removeFile = s3Insurances[index].s3Name;
      s3Insurances.splice(index, 1);
      this.setState({ s3Insurances: s3Insurances });
      S3FileUpload.deleteFile(removeFile, S3_CONFIG_TRIP_INSURANCE)
        .then(response => {
          console.log('researched', response);
        })
        .catch(err => console.error('err', err));
      break;
    default:
      break;
    }
  };

  onFileLoader = (file, type) => {
    let reader = new FileReader();

    const fileName = file.path;
    const uuidFile = new File([file], `${uuid()}`, { type: file.type });
    console.log(file);
    console.log(uuidFile);

    reader.onloadend = function() {
      try {
        let {
          licenses,
          s3Licenses,
          traceCertification,
          s3Trace,
          cprCertification,
          s3CPR,
          insurances,
          s3Insurances,
        } = this.state;
        switch (type) {
        case 'license':
          licenses.push({ name: fileName, path: '', s3Name: '' });
          S3FileUpload.uploadFile(uuidFile, S3_CONFIG_LICENSE)
            .then(data => {
              console.log(data);
              let name = data.key.substring(data.key.indexOf('/') + 1);
              s3Licenses.push({ name: fileName, path: data.location, s3Name: name });
              this.setState({ s3Licenses: s3Licenses });
            })
            .catch(err => console.error('err', err));
          this.setState({ licenses: licenses });
          break;
        case 'trace':
          traceCertification.push({ name: fileName, path: '', s3Name: '' });
          this.setState({ traceCertification: traceCertification });
          S3FileUpload.uploadFile(uuidFile, S3_CONFIG_TRACE_CERTIFIACTE)
            .then(data => {
              console.log(data);
              let name = data.key.substring(data.key.indexOf('/') + 1);
              s3Trace.push({ name: fileName, path: data.location, s3Name: name });
              this.setState({ s3Trace: s3Trace });
            })
            .catch(err => console.error('err', err));
          break;
        case 'cpr':
          cprCertification.push({ name: fileName, path: '', s3Name: '' });
          this.setState({ cprCertification: cprCertification });
          S3FileUpload.uploadFile(uuidFile, S3_CONFIG_CPR_CERTICATE)
            .then(data => {
              console.log(data);
              let name = data.key.substring(data.key.indexOf('/') + 1);
              s3CPR.push({ name: fileName, path: data.location, s3Name: name });
              this.setState({ s3CPR: s3CPR });
            })
            .catch(err => console.error('err', err));
          break;
        case 'insurance':
          insurances.push({ name: fileName, path: '', s3Name: '' });
          this.setState({ insurances: insurances });
          S3FileUpload.uploadFile(uuidFile, S3_CONFIG_TRIP_INSURANCE)
            .then(data => {
              console.log(data);
              let name = data.key.substring(data.key.indexOf('/') + 1);
              s3Insurances.push({ name: fileName, path: data.location, s3Name: name });
              this.setState({ s3Insurances: s3Insurances });
            })
            .catch(err => console.error('err', err));
          break;
        default:
          break;
        }
      } catch (e) {
        console.log(e);
      }
    }.bind(this);

    reader.readAsDataURL(file);
  };

  uploadLicenses = async files => {
    if (files.length > 0) {
      files.map(file => {
        this.onFileLoader(file, 'license');
      });
    }
  };

  uploadTraceCertification = async files => {
    if (files.length > 0) {
      files.map(file => {
        this.onFileLoader(file, 'trace');
      });
    }
  };

  uploadCPRCertification = async files => {
    if (files.length > 0) {
      files.map(file => {
        this.onFileLoader(file, 'cpr');
      });
    }
  };

  uploadInsurance = async files => {
    if (files.length > 0) {
      files.map(file => {
        this.onFileLoader(file, 'insurance');
      });
    }
  };

  isProfessionalGuide = () => {
    console.log('isProfessionalGuide');
    const { professionalGuide } = this.state;
    this.setState({ professionalGuide: !professionalGuide });
  };

  hasLicense = status => {
    console.log('hasLicense');
    if (status) this.setState({ hasLicense: [true, false] });
    else this.setState({ hasLicense: [false, true] });
  };

  isPermittedGuide = status => {
    console.log('isPermittedGuide');
    if (status) this.setState({ permittedGuide: [true, false] });
    else this.setState({ permittedGuide: [false, true] });
  };

  render() {
    console.log(this.state.hasLicense);
    console.log(this.state.permittedGuide);
    const {
        licenses,
        traceCertification,
        cprCertification,
        insurances,
        //       tripUrl,
        lastTripTitle,
        lastTripCompany,
      } = this.state,
      { professionalGuide, hasLicense, permittedGuide } = this.state;
    return (
      <div>
        <div className={requirStyle.headerSection}>
          <h2>Basic Level Requirements (all guides)</h2>
          <p>
            Friendly line of text to make this complex form more friendlyh.
            <br />
            Links to videos, Images, ..
          </p>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>Your Guide Experience</div>
          <div className={requirStyle.sectionContent}>
            <label className='checkbox'>
              I confirm that I have guided professionally for at least one year
              <input
                type='checkbox'
                className='checkboxInput'
                checked={professionalGuide}
                onChange={this.isProfessionalGuide}
              />
              <span className='checkmark' />
            </label>
          </div>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>Last trip details</div>
          <div className={requirStyle.sectionTitle}>
            To demonstrate that you have guided ofr at leat one year, please list the first trip(or
            a trip at
            <br />
            least one year ago)you guided, including date and company
          </div>
          <div className={requirStyle.sectionContent}>
            <InputField
              wrapClass={styles.form_group}
              label=''
              type='text'
              holder='Title of Trip'
              name='lastTripTitle'
              value={lastTripTitle}
              labelClass={styles.label}
              inputClass={styles.inputBorder}
              setFieldValue={this.setFieldValue}
            />
            <div className={requirStyle.inputs}>
              <div className={requirStyle.date}>
                <SingleDatePicker
                  date={this.state.date}
                  onDateChange={date => this.setState({ date })}
                  focused={this.state.focused} // PropTypes.bool
                  onFocusChange={({ focused }) => this.setState({ focused })}
                  numberOfMonths={1}
                  noBorder
                  displayFormat='MMM DD'
                  isOutsideRange={day => !isInclusivelyBeforeDay(day, moment())}
                />
              </div>
              <div className={requirStyle.company}>
                <InputField
                  wrapClass={styles.form_group}
                  label=''
                  type='text'
                  holder='Company'
                  name='lastTripCompany'
                  value={lastTripCompany}
                  labelClass={styles.label}
                  inputClass={styles.inputBorder}
                  hideKeyboardShortcutsPanel={false}
                  setFieldValue={this.setFieldValue}
                />
              </div>
            </div>
          </div>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>
            Legal documentation to guide in the destination
          </div>
          <div className={requirStyle.sectionTitle}>
            GreatGuides requires guides to have all guide licenses and certifications prior to
            uploading
            <br />
            trips. It is your responsibility to ensure you have researched any permitting,
            licensing, safety,
            <br />
            and health regulations that may apply.
          </div>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>
            Do you have any required licenses from government?
          </div>
          <div className={requirStyle.sectionContent}>
            <div className={requirStyle.radio}>
              <label className='checkbox'>
                Yes
                <input
                  type='radio'
                  className='radioInput'
                  checked={hasLicense[0]}
                  onChange={() => this.hasLicense(true)}
                />
                <span className='radioMark' />
              </label>
            </div>
            <div className={requirStyle.radio}>
              <label className='checkbox'>
                No requirements are required to guide in my locatoin
                <input
                  type='radio'
                  className='radioInput'
                  checked={hasLicense[1]}
                  onChange={() => this.hasLicense(false)}
                />
                <span className='radioMark' />
              </label>
            </div>
          </div>
        </div>

        <div className={requirStyle.section}>
          <form className={styles.form}>
            <div className={styles.form_content}>
              {licenses &&
                licenses.map((item, index) => (
                  <div className={`${requirStyle.form_item} ${styles.form_avatar}`} key={index}>
                    {item.name}
                    <div
                      className={requirStyle.close}
                      onClick={() => this.removeFile('license', index)}
                    />
                  </div>
                ))}

              <div
                className={
                  licenses && licenses.length > 0 ? `${requirStyle.form_item}` : requirStyle.noData
                }
              >
                <FileUpload uploadFiles={this.uploadLicenses} title='Upload Files here' />
              </div>
            </div>
          </form>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>
            Do you have required permits to guide in your location
          </div>
          <div className={requirStyle.sectionContent}>
            <div className={requirStyle.radio}>
              <label className='checkbox'>
                Yes
                <input
                  type='radio'
                  className='radioInput'
                  checked={permittedGuide[0]}
                  onChange={() => this.isPermittedGuide(true)}
                />
                <span className='radioMark' />
              </label>
            </div>
            <div className={requirStyle.radio}>
              <label className='checkbox'>
                No requirements are required to guide in my locatoin
                <input
                  type='radio'
                  className='radioInput'
                  checked={permittedGuide[1]}
                  onChange={() => this.isPermittedGuide(false)}
                />
                <span className='radioMark' />
              </label>
            </div>
          </div>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>Leave No Trace certification</div>
          <div className={requirStyle.sectionTitle}>
            Required for all guides. Take the test here if you don&apos;t have cerification:
          </div>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href='https://lnt.org/learn/online-awareness-course'
          >
            https://lnt.org/learn/online-awareness-course
          </a>
        </div>

        <div className={requirStyle.section}>
          <form className={styles.form}>
            <div className={styles.form_content}>
              {traceCertification &&
                traceCertification.map((item, index) => (
                  <div className={`${requirStyle.form_item} ${styles.form_avatar}`} key={index}>
                    {item.name}
                    <div
                      className={requirStyle.close}
                      onClick={() => this.removeFile('trace', index)}
                    />
                  </div>
                ))}

              <div
                className={
                  traceCertification && traceCertification.length > 0
                    ? `${requirStyle.form_item}`
                    : requirStyle.noData
                }
              >
                <FileUpload
                  uploadFiles={this.uploadTraceCertification}
                  title='Upload certiifcate'
                />
              </div>
            </div>
          </form>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>First Aid and CPR certification</div>
          <div className={requirStyle.sectionTitle}>Required for all guides</div>
        </div>

        <div className={requirStyle.section}>
          <form className={styles.form}>
            <div className={styles.form_content}>
              {cprCertification &&
                cprCertification.map((item, index) => (
                  <div className={`${requirStyle.form_item} ${styles.form_avatar}`} key={index}>
                    {item.name}
                    <div
                      className={requirStyle.close}
                      onClick={() => this.removeFile('cpr', index)}
                    />
                  </div>
                ))}

              <div
                className={
                  cprCertification && cprCertification.length > 0
                    ? `${requirStyle.form_item}`
                    : requirStyle.noData
                }
              >
                <FileUpload uploadFiles={this.uploadCPRCertification} title='Upload certificate' />
              </div>
            </div>
          </form>
        </div>

        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>Insurance coverage for the trips</div>
          <div className={requirStyle.sectionTitle}>
            It is your responsibility that you have appropriate insurance coverage for the trips and
            <br />
            activities you offer.
          </div>
        </div>

        <div className={requirStyle.section}>
          <form className={styles.form}>
            <div className={styles.form_content}>
              {insurances &&
                insurances.map((item, index) => (
                  <div className={`${requirStyle.form_item} ${styles.form_avatar}`} key={index}>
                    {item.name}
                    <div
                      className={requirStyle.close}
                      onClick={() => this.removeFile('insurance', index)}
                    />
                  </div>
                ))}

              <div
                className={
                  insurances && insurances.length > 0
                    ? `${requirStyle.form_item}`
                    : requirStyle.noData
                }
              >
                <FileUpload
                  uploadFiles={this.uploadInsurance}
                  title={
                    insurances.length === 0
                      ? 'Upload your insurance documents here'
                      : 'Upload File here'
                  }
                />
              </div>
            </div>
          </form>
        </div>

        {/*
        <div className={requirStyle.section}>
          <div className={requirStyle.sectionHeader}>
            List At least one trip posted at GreatGudies.com
          </div>
          <div className={requirStyle.sectionTitle}>Must be adventure or ecotourism related</div>
          <div className={requirStyle.sectionContent}>
            <InputField
              wrapClass={styles.form_group}
              label=''
              type='text'
              holder='Your trip url'
              name='trip'
              value={tripUrl}
              labelClass={styles.label}
              inputClass={styles.inputBorder}
              setFieldValue={this.setFieldValue}
            />
          </div>
        </div>
       */}

        <div className={requirStyle.section}>
          <form>
            <div className={styles.form_group}>
              <button type='button' className={styles.form_button} onClick={() => this.nextStep()}>
                Continue
              </button>
              <button
                type='button'
                className={styles.form_button}
                onClick={() => this.previewStep()}
              >
                Back
              </button>
            </div>
          </form>
        </div>
      </div>
    );
  }
}

Requirements.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setGuideInfo: PropTypes.func.isRequired,
  updateUserRequirement: PropTypes.func.isRequired,
  tripUrl: PropTypes.string,
  GuideExp: PropTypes.any,
  LastTripTitle: PropTypes.string,
  LastTripDate: PropTypes.any,
  LastTripCompany: PropTypes.string,
  GuideLic: PropTypes.any,
  GuideLicLink: PropTypes.array,
  GuidePermit: PropTypes.any,
  GuideNoTrace: PropTypes.array,
  GuideFirstAid: PropTypes.array,
  GuideInsurance: PropTypes.array,
  GuideTripLink: PropTypes.string,
  extra: PropTypes.object,
  loggedUser: PropTypes.string,
};

const selectors = createStructuredSelector({
  tripUrl: GuideSetFilterSelectors.tripAdvisorSelector,
  GuideExp: GuideSetFilterSelectors.GuideExpSelector,
  LastTripTitle: GuideSetFilterSelectors.LastTripTitleSelector,
  LastTripDate: GuideSetFilterSelectors.LastTripDateSelector,
  LastTripCompany: GuideSetFilterSelectors.LastTripCompanySelector,
  GuideLic: GuideSetFilterSelectors.GuideLicSelector,
  GuideLicLink: GuideSetFilterSelectors.GuideLicLinkSelector,
  GuidePermit: GuideSetFilterSelectors.GuidePermitSelector,
  GuideNoTrace: GuideSetFilterSelectors.GuideNoTraceSelector,
  GuideFirstAid: GuideSetFilterSelectors.GuideFirstAidSelector,
  GuideInsurance: GuideSetFilterSelectors.GuideInsuranceSelector,
  GuideTripLink: GuideSetFilterSelectors.GuideTripLinkSelector,
  extra: GuideSetFilterSelectors.extraSelector,
  loggedUser: userSelectors.userIdSelector,
});

const actions = {
  setGuideInfo: GuideSetFilterActions.setGuideFilter,
  updateUserRequirement: updateUserActions.updateUserRequirement,
};

export default connect(selectors, actions)(Requirements);
