// import { put, takeLatest } from 'redux-saga/effects'
import { takeLatest } from 'redux-saga/effects';

import apiCall from '../api/apiCall';
import { GUIDES_GET } from '../modules/guides/types';
import { getUserGuides } from 'api/userGuides';

const { REACT_APP_GRAPHQL_API_URL } = process.env;

const doGetGuides = apiCall({
  type: GUIDES_GET,
  method: 'POST',
  baseURL: `${REACT_APP_GRAPHQL_API_URL}/`,
  path: '',
  body: { query: getUserGuides, variables: {} },
  payloadOnSuccess: (responseData, action) => {
    responseData.isLoadMore = action.payload.isLoadMore;
    return responseData;
  },
});

export default function* rootSaga() {
  yield takeLatest(GUIDES_GET, doGetGuides);
}
