import { handleActions } from 'redux-actions';

import { requestPending, requestSuccess, requestFail } from '../../../redux/api/request';
import { GUIDE_GET } from './types';

const initialState = {
  data: null,
  status: null,
  error: null,
  message: '',
};

export default handleActions(
  {
    [requestPending(GUIDE_GET)]: state => ({
      ...state,
      data: null,
      status: requestPending(GUIDE_GET),
      error: false,
      message: '',
    }),
    [requestSuccess(GUIDE_GET)]: (state, { payload: { data: { users } = { users: [] } } }) => ({
      ...state,
      status: requestSuccess(GUIDE_GET),
      data: users[0],
      error: null,
    }),
    [requestFail(GUIDE_GET)]: (state, { payload }) => ({
      ...state,
      data: payload.data,
      status: requestFail(GUIDE_GET),
      error: true,
      message: `${payload.status} - ${payload.statusText}`,
    }),
  },
  initialState
);
