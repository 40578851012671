import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import Select from 'react-select';

import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import ErrorHandling from '../ErrorHandling';
import InputForm from '../../common/Form/Input/Input';

import Style from '../../styles/pages/CreateAdventure.module.scss';

const categoriesFilter = [
  { name: 'All', value: 'All', status: false },
  { name: 'Safari', value: 'Safari', status: false },
  { name: 'Hiking', value: 'Hiking', status: false },
  { name: 'Kayaking', value: 'Kayaking', status: false },
  { name: 'Walking', value: 'Walking', status: false },
  { name: 'Cultural', value: 'Cultural', status: false },
  { name: 'Kayaking', value: 'Kayaking', status: false },
  { name: 'Biking', value: 'Biking', status: false },
  { name: 'EcoTours', value: 'EcoTours', status: false },
];

const difficulties = [
  { value: 'All', label: 'All' },
  { value: 'Easy', label: 'Easy' },
  { value: 'Moderate', label: 'Moderate' },
  { value: 'Hard', label: 'Hard' },
];

const seasons = [
  { value: 'All Season', label: 'All Season' },
  { value: 'Summer', label: 'Summer' },
  { value: 'Winter', label: 'Winter' },
  { value: 'Fall', label: 'Fall' },
  { value: 'Spring', label: 'Spring' },
];

class NewAdventure extends Component {
  constructor(props) {
    super(props);
    this.state = {
      adventureName: props.title,
      categories: props.categories,
      distance: props.distance,
      difficulty: difficulties.filter(item => item.value === props.difficulty)[1],
      season: seasons.filter(item => item.value === props.season)[0],
      age: props.minimumAge,
      maxParticipants: props.maxParticipants,
      minParticipants: props.minParticipants,
      pricePerPerson: props.pricePerPerson,
      pricePerGroup: props.pricePerGroup,
      errorType: '',
    };

    categoriesFilter.forEach(item => {
      if (item.value === props.categories) {
        item.status = true;
      }
    });
  }

  componentDidMount() {
    const { clearAdventureFilter, editStatus } = this.props;
    if (editStatus === 'update ') {
      clearAdventureFilter();
    }
  }

  nextStep = () => {
    const { nextStep, setAdventureFilter } = this.props;
    const {
      adventureName,
      categories,
      difficulty,
      season,
      age,
      distance,
      maxParticipants,
      minParticipants,
      pricePerGroup,
      pricePerPerson,
    } = this.state;

    setAdventureFilter({
      ['title']: adventureName,
      ['categories']: categories,
      ['difficulty']: difficulty ? difficulty.value : 'Easy',
      ['season']: season ? season.value : 'Summer',
      ['distance']: distance,
      ['minimumAge']: age,
      ['maxParticipants']: maxParticipants,
      ['minParticipants']: minParticipants,
      ['price']: pricePerPerson,
      ['pricePerPerson']: pricePerPerson,
      ['pricePerGroup']: pricePerGroup,
    });

    if (this.checkValue() === '') {
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  checkValue = () => {
    const {
      adventureName,
      categories,
      // difficulty,
      maxParticipants,
      minParticipants,
      pricePerGroup,
      pricePerPerson,
    } = this.state;
    let errorType = '';

    if (!adventureName && adventureName === '') {
      errorType = errorType.concat('Title');
    }
    if (Object.keys(categories).length === 0) {
      errorType = errorType.concat('Category');
    }
    // if (Object.keys(difficulty).length === 0) {
    //   errorType = errorType.concat('Difficulty');
    // }
    if (!maxParticipants) {
      errorType = errorType.concat('Participants');
    }
    if (!minParticipants) {
      errorType = errorType.concat('Participants');
    }
    if (!pricePerGroup) {
      errorType = errorType.concat('Price Group');
    }
    if (!pricePerPerson) {
      errorType = errorType.concat('Price Person');
    }

    this.setState({ errorType });
    return errorType;
  };

  handleChangeInput = (e, type) => {
    switch (type) {
    case 'adventureName':
      this.setState({ adventureName: e.target.value });
      break;
    case 'distance':
      this.setState({ distance: e.target.value });
      break;
    case 'age':
      this.setState({ age: e.target.value });
      break;
    case 'maxParticipants':
      this.setState({ maxParticipants: e.target.value });
      break;
    case 'minParticipants':
      this.setState({ minParticipants: e.target.value });
      break;
    case 'pricePerPerson':
      this.setState({ pricePerPerson: e.target.value });
      break;
    case 'pricePerGroup':
      this.setState({ pricePerGroup: e.target.value });
      break;
    default:
      break;
    }
  };

  handleSelectCategory = idx => {
    categoriesFilter.forEach(item => {
      item.status = false;
    });
    categoriesFilter[idx].status = true;
    this.setState({ categories: categoriesFilter[idx].value });
  };

  onChangeDifficulty = selectedOption => {
    this.setState({ difficulty: selectedOption });
  };

  onChangeSeason = selectedOption => {
    this.setState({ season: selectedOption });
  };

  render() {
    const {
      adventureName,
      difficulty,
      season,
      age,
      distance,
      maxParticipants,
      minParticipants,
      pricePerPerson,
      pricePerGroup,
      errorType,
    } = this.state;

    const customStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        border: 'none',
        'align-items': 'center',
        display: 'flex',
        'flex-wrap': 'wrap',
        'justify-content': 'space-between',
        position: 'relative',
        'white-space': 'nowrap',
        ':focus-within > :last-child': {
          transform: 'rotate(180deg)',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      input: () => ({
        visibility: 'visible',
        color: 'hsl(0,0%,20%)',
        height: '120px',
        'box-sizing': 'border-box',
      }),
      dropdownIndicator: () => ({
        color: 'hsl(0,0%,80%)',
        display: 'flex',
        transition: 'color 150ms',
        'box-sizing': 'border-box',
      }),
      menu: styles => {
        return {
          ...styles,
          width: '250px',
          left: '-12px',
          top: 'calc((100% + 14px))',
        };
      },
    };

    return (
      <div className='new-adventure'>
        <div className={Style.header_title}>
          <h2>Add new Adventure</h2>
          <p>Alright, lets start! Tell us a bit about your Adventure.</p>
        </div>
        <InputForm
          borderColor={errorType.includes('Title') ? 'errorBorder' : ''}
          type='text'
          placeholder='Adventure name'
          labelText='What is your Adventure Name?'
          labelFor='adventureName'
          inputVal={adventureName}
          onChange={this.handleChangeInput}
        />
        {errorType.includes('Title') ? <ErrorHandling category='Title' /> : ''}
        <div className={Style.radio_button}>
          <label>Categories</label>
          <ul>
            {categoriesFilter.map((item, index) => (
              <li key={`category-${index}`}>
                <input type='checkbox' id='check1' checked={item.status} onChange={() => {}} />
                <label htmlFor='check1' onClick={() => this.handleSelectCategory(index)}>
                  {item.name}
                </label>
              </li>
            ))}
          </ul>
        </div>
        {errorType.includes('Category') ? <ErrorHandling category='Category' /> : ''}
        <div className={'form-group ' + Style.select_options}>
          <label>Difficulty</label>
          <div
            className={Style.input + `${errorType.includes('Difficulty') ? ' errorBorder' : ''}`}
          >
            <Select
              value={difficulty}
              options={difficulties}
              styles={customStyles}
              placeholder='Difficulty'
              isSearchable={false}
              onChange={this.onChangeDifficulty}
            />
          </div>
        </div>
        {errorType.includes('Difficulty') ? <ErrorHandling category='Difficulty' /> : ''}
        <InputForm
          type='text'
          placeholder='Enter distance'
          labelText='Distance (in miles)'
          labelFor='distance'
          inputVal={distance}
          onChange={this.handleChangeInput}
        />
        <InputForm
          type='text'
          placeholder='age'
          labelText='Minimum age'
          labelFor='age'
          inputVal={age}
          onChange={this.handleChangeInput}
        />
        <div className={'form-group ' + Style.select_options}>
          <label>Season</label>
          <div className={Style.input}>
            <Select
              value={season}
              options={seasons}
              styles={customStyles}
              placeholder='Season'
              isSearchable={false}
              onChange={this.onChangeSeason}
            />
          </div>
        </div>

        <InputForm
          type='text'
          placeholder='ft.'
          labelText='Max.Elevation (not required)'
          labelFor='elevation'
          inputVal=''
          onChange={this.handleChangeInput}
        />

        <div className={Style.inline_group}>
          <label>Number Of Participants</label>
          <div className={Style.participants}>
            <InputForm
              type='text'
              placeholder='Minimum number'
              labelText=''
              labelFor='minParticipants'
              inputVal={minParticipants}
              borderColor={errorType.includes('Participants') ? 'errorBorder' : ''}
              onChange={this.handleChangeInput}
            />
            <InputForm
              type='text'
              placeholder='Maximum number'
              labelFor='maxParticipants'
              inputVal={maxParticipants}
              borderColor={errorType.includes('Participants') ? 'errorBorder' : ''}
              onChange={this.handleChangeInput}
            />
          </div>
          {errorType.includes('Participants') ? <ErrorHandling category='Participants' /> : ''}
        </div>
        <InputForm
          type='text'
          placeholder='$USD'
          labelText='Price Per Person(USD)'
          labelFor='pricePerPerson'
          inputVal={pricePerPerson}
          borderColor={errorType.includes('Price Person') ? 'errorBorder' : ''}
          onChange={this.handleChangeInput}
        />
        {errorType.includes('Price Person') ? <ErrorHandling category='Price Person' /> : ''}
        <InputForm
          type='text'
          placeholder='$USD'
          labelText='Price Per Person(USD) for over 5 people'
          labelFor='pricePerGroup'
          inputVal={pricePerGroup}
          borderColor={errorType.includes('Price Group') ? 'errorBorder' : ''}
          onChange={this.handleChangeInput}
        />
        {errorType.includes('Price Group') ? <ErrorHandling category='Price Person' /> : ''}
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
        </div>
      </div>
    );
  }
}

NewAdventure.propTypes = {
  editStatus: PropTypes.string,
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  createAdventure: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  clearAdventureFilter: PropTypes.func.isRequired,
  title: PropTypes.string,
  categories: PropTypes.string,
  difficulty: PropTypes.any,
  season: PropTypes.any,
  minimumAge: PropTypes.any,
  distance: PropTypes.any,
  maxParticipants: PropTypes.any,
  minParticipants: PropTypes.any,
  pricePerPerson: PropTypes.any,
  pricePerGroup: PropTypes.any,
};

const selectors = createStructuredSelector({
  title: createAdventureSelectors.titleSelector,
  categories: createAdventureSelectors.categoriesSelector,
  difficulty: createAdventureSelectors.difficultySelector,
  season: createAdventureSelectors.seasonSelector,
  minimumAge: createAdventureSelectors.minimumAgeSelector,
  distance: createAdventureSelectors.distanceSelector,
  maxParticipants: createAdventureSelectors.maxParticipantsSelector,
  minParticipants: createAdventureSelectors.minParticipantsySelector,
  pricePerGroup: createAdventureSelectors.pricePerGroupSelector,
  pricePerPerson: createAdventureSelectors.pricePerPersonSelector,
});

const actions = {
  createAdventure: createAdventureActions.createAdventure,
  setAdventureFilter: createAdventureActions.setAdventureFilter,
  clearAdventureFilter: createAdventureActions.clearAdventureFilter,
};

export default connect(selectors, actions)(NewAdventure);
