import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Link } from 'react-router-dom';

import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';

import { selectors as adventureSelectors } from '../../redux/modules/AdventuresID';

import Loading from 'components/Loading';
import FileUpload from '../../components/BecomeAGuide/FileUpload';
import styles from 'styles/pages/NewGuidePage.module.scss';
import requirStyle from 'styles/pages/CreateAdventure/UploadImages.module.scss';
import Style from '../../styles/pages/CreateAdventure.module.scss';

const uuid = require('uuid/v1');

class UploadImage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      media: [],
      fileNames: props.adventure
        ? props.adventure.media.map(file => {
          return {
            name: file.imageMQUrl,
            content: file.imageMQUrl,
            path: file.imageMQUrl,
            s3Name: file.imageMQUrl,
          };
        })
        : [],
    };
  }

  nextStep = () => {
    const { nextStep } = this.props;
    nextStep();
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  onFileLoader = file => {
    let reader = new FileReader();
    const { uploadAdventureMedia } = this.props;
    let { fileNames } = this.state;

    const fileName = file.path;
    const uuidFile = new File([file], `${uuid()}`, { type: file.type });

    reader.onloadend = function(f) {
      try {
        uploadAdventureMedia({ file: uuidFile, fileName: fileName, copyright: '' });
        fileNames.push({ name: fileName, content: f.target.result, path: '', s3Name: '' });
        this.setState({ fileNames: fileNames });
      } catch (e) {
        console.log(e);
      }
    }.bind(this);

    reader.readAsDataURL(file);
  };

  removeFile = index => {
    // const { removeAdventureMedia } = this.props;
    let { fileNames } = this.state;
    const mediaGallery = fileNames.filter((item, idx) => item && idx !== index);
    this.setState({ fileNames: mediaGallery });
    // removeAdventureMedia({id: index});
  };

  uploadMedia = async files => {
    if (files.length > 0) {
      files.map(file => {
        this.onFileLoader(file);
      });
    }
  };

  render() {
    const { uploadingImage } = this.props;
    const { fileNames } = this.state;

    return (
      <div className='add-dates'>
        <div className={Style.header_title}>
          <h2>Upload Images</h2>
          <p>Upload some images and why it’s so important</p>
        </div>
        <div className={requirStyle.section}>
          <form className={requirStyle.form}>
            <div className={requirStyle.form_content}>
              {fileNames &&
                fileNames.map((item, index) => (
                  <div className={`${requirStyle.form_item} ${styles.form_avatar}`} key={index}>
                    <img src={item.content} />
                    <div className={requirStyle.close} onClick={() => this.removeFile(index)} />
                  </div>
                ))}

              <div
                className={
                  fileNames && fileNames.length > 0
                    ? `${requirStyle.form_item}`
                    : requirStyle.noData
                }
              >
                <FileUpload uploadFiles={this.uploadMedia} title='Upload Files here' />
              </div>
            </div>
          </form>
        </div>
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            <Link to='/'>Finish</Link>
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
        {uploadingImage && <Loading />}
      </div>
    );
  }
}

UploadImage.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  uploadingImage: PropTypes.bool.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  createAdventure: PropTypes.func.isRequired,
  uploadAdventureMedia: PropTypes.func.isRequired,
  removeAdventureMedia: PropTypes.func.isRequired,
  adventureID: PropTypes.string,
  adventure: PropTypes.object,
};

const selectors = createStructuredSelector({
  uploadingImage: createAdventureSelectors.loadSelector,
  adventureID: createAdventureSelectors.adventureIDSelector,
  adventure: adventureSelectors.adventuresByIdSelector,
});

const actions = {
  createAdventure: createAdventureActions.createAdventure,
  uploadAdventureMedia: createAdventureActions.uploadAdventureMedia,
  setAdventureFilter: createAdventureActions.setAdventureFilter,
  removeAdventureMedia: createAdventureActions.removeAdventureMedia,
};

export default connect(
  selectors,
  actions
)(UploadImage);
