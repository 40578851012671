import React from 'react';
import PropTypes from 'prop-types';
import Dropzone from 'react-dropzone';

import Icon from 'components/Icon';

import styles from 'styles/components/guide/FileUpload.module.scss';

class FileUpload extends React.Component {
  async uploadDataType() {}
  render() {
    const { uploadFiles, title } = this.props;

    const dropzoneStyle = {
      border: '1px dotted red',
      width: '100%',
      height: '94px',
      borderStyle: 'none',
      top: 0,
    };
    return (
      <div
        className={`${styles.wrapper} ${styles.borderBottom} ${styles.borderLeft} ${
          styles.borderTop
        } ${styles.borderRight}`}
      >
        <div className={styles.borderTop}>
          <div className={styles.borderRight}>
            <div className={styles.borderBottom}>
              <div className={styles.borderLeft}>
                <Dropzone onDrop={uploadFiles} style={dropzoneStyle} multiple>
                  {({ getRootProps, getInputProps }) => {
                    return (
                      <section>
                        <div {...getRootProps()}>
                          <input {...getInputProps()} />
                          <Icon name='uploader' className={styles.uploader} />
                          <p className={styles.title}>{title}</p>
                        </div>
                      </section>
                    );
                  }}
                </Dropzone>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

FileUpload.propTypes = {
  title: PropTypes.string.isRequired,
  uploadFiles: PropTypes.func.isRequired,
};

export default FileUpload;
