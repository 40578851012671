/**
 * @file Holds the <AdventureCard> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { Link } from 'react-router-dom';
import { compose } from 'react-apollo';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import Icon from 'components/Icon';

import {
  actions as BookAdventureActions,
  selectors as BookAdventureSelectors,
} from '../../../redux/modules/BookAdventure';

import styles from 'styles/components/Account/MyTrip/TripItem.module.scss';

const moment = require('moment');

/* Component definition */
class TripItem extends React.Component {
  componentDidMount() {}

  getUpdatedTime = updatedAt => {
    const today = moment(new Date());
    const updated = moment(updatedAt);
    const weeks = today.diff(updated, 'weeks');
    const days = today.diff(updated, 'days');
    const hours = today.diff(updated, 'hours');

    if (weeks) {
      return `${weeks} w ${days - 7 * weeks !== 0 ? `${days - 7 * weeks} d ` : ''}
        ${hours - 24 * days !== 0 ? `${hours - 24 * days} h ` : ''}ago`;
    } else if (days) {
      return `${days} d${hours - 24 * days !== 0 ? ` ${hours - 24 * days} h ` : ''}ago`;
    } else if (hours) {
      return `${hours} hours ago`;
    }
  };

  cancelRequest = id => {
    const { cancelBookRequest } = this.props;

    cancelBookRequest({ bookingId: id });
  };

  setBookId = (id, adventureId) => {
    const { setBookingId } = this.props;
    localStorage.setItem('bookingId', id);
    localStorage.setItem('adventureId', adventureId);
    setBookingId({ ['bookingId']: id });
  };

  render() {
    const { bookings, border } = this.props;

    const tripStatus = item => (
      <div className={styles.status}>
        <div className={styles.statusInfo}>
          <div className={styles.bookInfo}>
            <div className={styles.gudieTxt}>{item.numberOfTravelers} Adults</div>
            <div className={styles.date}>{moment(item.updatedAt).format('DD MMM, YYYY')}</div>
            <div className={styles.gudieTxt}>${item.price}</div>
          </div>
          <div className={styles.big_vr} />
          <div className={styles.bookStatus}>
            <div className={styles.gudieTxt}>Status</div>
            <div className={styles.bookStatusTxt}>{item.bookingState}</div>
            <div className={styles.gudieTxt}>{this.getUpdatedTime(item.updatedAt)}</div>
          </div>
        </div>
      </div>
    );

    const desktopGuideBreadCrumb = item => (
      <div className={styles.guideDeskTopBreadCrumb}>
        <div className={styles.guideAvatar}>
          <img src={item.adventure.guide.profilePictureUrl} className={styles.guideThumb} />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.guideName}>{item.adventure.guide.fullName}</div>
        <div className={styles.shot_vr} />
        <div>
          <Icon className={styles.message} name='message' />
          <span className={styles.messageTxt}>SEND MESSAGE</span>
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.txt}>DETAILS</div>
      </div>
    );

    const mobileGuideBreadCrumb = item => (
      <div className={styles.guideMobileBreadCrumb}>
        <div className={styles.guideAvatar}>
          <img src={item.adventure.guide.profilePictureUrl} className={styles.guideThumb} />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.guideName}>{item.adventure.guide.fullName}</div>
        <div>
          <Icon className={styles.message} name='message' />
        </div>
        <div className={styles.shot_vr} />
        <div className={styles.txt}>DETAILS</div>
      </div>
    );

    const statusButtons = item => (
      <div>
        {item.bookingState === 'ToBeApproved' ? (
          <div className={styles.payment}>
            <div className={styles.paymentItem} onClick={() => this.cancelRequest(item.id)}>
              <button className={styles.paymentBtn}>CANCEL REQUEST</button>
            </div>
          </div>
        ) : (
          ''
        )}
        {item.bookingState === 'Approved' ? (
          <div className={styles.payment}>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button
                  className={styles.paymentBtn}
                  onClick={() => this.setBookId(item.id, item.adventure.id)}
                >
                  MAKE PAYMENT
                </button>
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    const mobileStatusButtons = item => (
      <div>
        {item.bookingState === 'ToBeApproved' ? (
          <div className={styles.mobilePayment}>
            <div className={styles.paymentItem}>
              <button className={styles.paymentBtn} onClick={() => this.cancelRequest(item.id)}>
                CANCEL REQUEST
              </button>
            </div>
          </div>
        ) : (
          ''
        )}
        {item.bookingState === 'Approved' ? (
          <div className={styles.mobilePayment}>
            <div className={styles.paymentItem}>
              <Link to={`/pay-adventure/${item.adventure.id}`}>
                <button
                  className={styles.paymentBtn}
                  onClick={() => this.cancelRequest(item.id, item.adventure.id)}
                >
                  MAKE PAYMENT
                </button>
              </Link>
            </div>
          </div>
        ) : (
          ''
        )}
      </div>
    );

    return (
      <div className={styles.wrapper}>
        {bookings.map((item, index) => (
          <div
            className={[
              `${styles.item} ${border && bookings.length === index + 1 ? '' : styles.border}`,
            ]}
            key={index}
          >
            <div className={styles.thumb}>
              <img src={item.adventure.media[0].imageHQUrl} />
              <div className={styles.mobileTripStatus}>{tripStatus(item)}</div>
            </div>
            <div className={styles.details}>
              <div className={styles.adventure}>
                <div className={styles.adventureDetail}>
                  <div className={styles.adCategory}>{item.adventure.categories}</div>
                  <div className={styles.adTitle}>{item.adventure.title}</div>
                  <div className={styles.adLocation}>
                    <Icon className={styles.locationIcon} name='location' />
                    {item.adventure.address}
                  </div>
                </div>
                <div className={styles.desktopTripStatus}>{tripStatus(item)}</div>
              </div>
              <div className={styles.guide}>
                <div className={styles.guideInfo}>
                  {desktopGuideBreadCrumb(item)}
                  {mobileGuideBreadCrumb(item)}
                  {statusButtons(item)}
                </div>
              </div>
              {mobileStatusButtons(item)}
            </div>
          </div>
        ))}
      </div>
    );
  }
}

/* Prop types definition */
TripItem.propTypes = {
  bookings: PropTypes.array.isRequired,
  border: PropTypes.bool,
  cancelBookRequest: PropTypes.func.isRequired,
  setBookingId: PropTypes.func.isRequired,
  bookingId: PropTypes.string,
};

const selectors = createStructuredSelector({
  bookingId: BookAdventureSelectors.bookingIdSelector,
});

const actions = {
  cancelBookRequest: BookAdventureActions.deleteBookAdventure,
  setBookingId: BookAdventureActions.setBookingId,
};

export default compose(
  withRouter,
  connect(
    selectors,
    actions
  )
)(TripItem);
