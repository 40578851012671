import React from 'react';
import PropTypes from 'prop-types';
import styles from 'styles/components/MapBox.module.scss';
import '../styles/components/MapBoxPopup.overrides.css';

import mapboxgl from 'mapbox-gl/dist/mapbox-gl.js';

mapboxgl.accessToken = process.env.REACT_APP_MAPBOX_ACCESS_TOKEN;

class MapBox extends React.Component {
  state = {
    map: null,
    guides: null,
  };

  UNSAFE_componentWillReceiveProps(props) {
    this.setState({ guides: props.guides });
  }

  componentDidMount() {
    let map = new mapboxgl.Map({
      container: 'mapBoxContainer',
      style: 'mapbox://styles/mapbox/streets-v11',
      center: [15.911136, 20.097267],
      zoom: 2.1,
    });

    this.setState({
      map,
    });
  }

  render() {
    const { guides, height } = this.props;

    const mapHeightStyle = {
      height: `${height}px`,
    };

    const creatGuidesMap = guides => {
      const { map } = this.state;
      if (guides != undefined && guides.length != 0 && map) {
        guides.forEach(marker => {
          // create a DOM element for the marker
          let el = document.createElement('div');
          el.className = 'marker';
          el.style.backgroundImage = 'url(' + marker.profilePictureUrl + ')';
          el.style.backgroundSize = 'contain';
          el.style.width = '50px';
          el.style.zIndex = '2';
          el.style.height = '50px';
          el.style.borderRadius = '25px';
          el.style.border = '2px solid white';
          el.style.boxShadow = '3px 6px 3px rgba(0, 0, 0, 0.1)';
          el.addEventListener('click', () => {
            map.removeLayer(this);
          });
          let flag = document.createElement('div');
          flag.className = 'marker';
          flag.style.backgroundImage = 'url(' + marker.flagIcon + ')';
          flag.style.width = '25px';
          flag.style.height = '25px';
          flag.style.borderRadius = '13px';
          flag.style.position = 'absolute';
          flag.style.bottom = 0;
          flag.style.left = '50%';
          flag.style.transform = 'translate(-50%, 50%)';

          el.appendChild(flag);
          if (marker.extra && marker.extra.Lat !== null && marker.extra.Long !== null) {
            if (marker.extra.Lat !== undefined && marker.extra.Long !== undefined)
              new mapboxgl.Marker(el)
                .setLngLat([marker.extra.Long, marker.extra.Lat])
                .setPopup(
                  new mapboxgl.Popup({ offset: 15 }) // add popups
                    .setHTML(
                      '<div class="guidesInfo" style="text-align:center"><p class="address">' +
                        marker.businessAddress +
                        '</p><h5 class="mapGuideName">' +
                        marker.fullName +
                        '</h5><p class="mapDetails">' +
                        (marker.extra != null && marker.extra.rating
                          ? '<span class="star">&#9733;</span>&nbsp;' + marker.extra.rating
                          : '') +
                        (marker.extra != null && marker.extra.NumOfReviews
                          ? '&nbsp;&bull;&nbsp;' + marker.extra.NumOfReviews + ' reviews'
                          : '') +
                        '&nbsp;&bull;&nbsp;' +
                        marker.adventures.length +
                        '&nbsp;' +
                        'adventures' +
                        '</p></div>'
                    )
                )
                .addTo(map);
          }
        });
      }
    };
    return (
      <div className={styles.wrapper} id='mapBoxContainer' style={mapHeightStyle}>
        {creatGuidesMap(guides)}
      </div>
    );
  }
}

MapBox.propTypes = {
  height: PropTypes.number.isRequired,
  guides: PropTypes.array,
};

export default MapBox;
