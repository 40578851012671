import React from 'react';
import PropTypes from 'prop-types';
import Icon from 'components/Icon';

import styles from 'styles/components/GuidePersonalInfo.module.scss';

const GuidePersonalInfo = ({ guide }) => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.info}>
        {guide.extra && guide.extra.rating ? (
          <div className={styles.rating}>
            <Icon name='star' />
            &nbsp;
            <span className={styles.data}>{guide.extra.rating}</span>
            <span>&nbsp;</span>
          </div>
        ) : (
          ''
        )}
        {guide.extra && guide.extra.numerOfReviews ? (
          <div>
            <span>&bull;</span>
            <span className={styles.data}>{guide.extra.NumOfReviews} reviews</span>
            <span>&nbsp;</span>
          </div>
        ) : (
          ''
        )}
        <div>
          {guide.extra ? <span>&bull;</span> : ''}
          <span className={styles.data}>{guide.adventures.length} adventures</span>
        </div>
      </div>
    </div>
  );
};

GuidePersonalInfo.propTypes = {
  guide: PropTypes.object.isRequired,
};

export default GuidePersonalInfo;
