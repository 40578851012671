import React from 'react';
import PropTypes from 'prop-types';
import FixedFooter from '../FixedFooter';
import Rating from '../Rating';
import defaultImage from 'assets/guides/avatar.svg';
import { GUIDES_MODAL } from '../../constants/common';

function GuidesFooter({ guide, openModal }) {
  const addDefaultSrc = e => {
    e.target.src = defaultImage;
  };

  return (
    <FixedFooter>
      <div className='guides-footer__author'>
        <img
          src={guide.profilePictureUrl}
          alt={guide.fullName || 'Guider'}
          onError={addDefaultSrc}
        />
        <span>{guide.fullName || 'Guider'}</span>
      </div>
      {guide.extra && guide.extra.rating && guide.extra.NumOfReviews && (
        <div className='guides-footer__rate'>
          <Rating rating={guide.extra.rating} />
          <span>Based on {guide.extra.NumOfReviews} reviews</span>
        </div>
      )}
      <button className='guides-footer__button' onClick={() => openModal(GUIDES_MODAL)}>
        Send message
      </button>
    </FixedFooter>
  );
}

GuidesFooter.propTypes = {
  guide: PropTypes.shape({
    fullName: PropTypes.string,
    profilePictureUrl: PropTypes.string,
    extra: PropTypes.shape({
      rating: PropTypes.string,
      NumOfReviews: PropTypes.number,
    }),
  }).isRequired,
  openModal: PropTypes.func.isRequired,
};

export default GuidesFooter;
