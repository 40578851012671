/**
 * @file Holds the <BookRequest> component
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */

import React from 'react';
import PropTypes from 'prop-types';
import ReactGA from 'react-ga';

import styles from 'styles/components/AdventureDetails/ContactDetail.module.scss';
import user from '../../assets/AdeventureDetails/2-users.png';
// import phone from '../../assets/AdeventureDetails/phone.png';
import email from '../../assets/AdeventureDetails/email.jpg';
import DatePicker from 'react-datepicker';
import Icon from 'components/Icon';

/* Component definition */
class ContactDetail extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isBookingForm: false,
      name: null,
      email: null,
      tripMessage: '',
      errorName: null,
      isError: null,
      phone: '',
      startDate: null,
      departureDate: null,
      adults: null,
      children: null,
    };
  }

  handleSendMessageModal = () => {};

  handleInputChange = () => {};

  checkContactFormData = () => {
    const { name, email } = this.state;
    if (!name) {
      return { errorName: 'name', isError: true };
    }
    if (!email) {
      return { errorName: 'email', isError: true };
    }
    return { isError: false };
  };

  sendContact = () => {
    const { sendContactDetail } = this.props;
    const { name, email, tripMessage, phone, adults, children, departureDate } = this.state;

    const isValidated = this.checkContactFormData();

    if (isValidated.isError) {
      this.setState({ errorName: isValidated.errorName, isError: isValidated.isError });
      return;
    }

    let tripMess =
      ' Adventure Name: ' +
      ' ' +
      this.props.adventureTitle +
      ' - Message: ' +
      tripMessage +
      ' - Phone Number: ' +
      phone +
      ' - Number of Adults ' +
      adults +
      ' - Number of Children ' +
      children +
      ' - Start Date ' +
      departureDate;
    sendContactDetail(name, email, tripMess, false);
    ReactGA.event({
      category: 'Request Book',
      action: 'Book',
    });
  };

  requireBookInformation = () => {
    const { sendContactDetail } = this.props;
    const { name, email, tripMessage, phone } = this.state;

    const isValidated = this.checkContactFormData();

    if (isValidated.isError) {
      this.setState({ errorName: isValidated.errorName, isError: isValidated.isError });
      return;
    }

    let tripMess =
      'Adventure Name:' +
      ' ' +
      this.props.adventureTitle +
      '- Message' +
      tripMessage +
      '- Phone Number:' +
      phone;
    sendContactDetail(name, email, tripMess, true);
    ReactGA.event({
      category: 'Book Now',
      action: 'Book',
    });
  };

  handleInputName = e => {
    this.setState({ name: e.target.value });
  };

  handleInputEmail = e => {
    this.setState({ email: e.target.value });
  };

  handleInputPhone = e => {
    this.setState({ phone: e.target.value });
  };

  handleTripMessage = e => {
    this.setState({ tripMessage: e.target.value });
  };

  handleAdults = e => {
    this.setState({ adults: e.target.value });
  };

  handleChildren = e => {
    this.setState({ children: e.target.value });
  };

  handleChange = date => {
    const moment = require('moment');

    this.setState({
      error: false,
      startDate: date,
      departureDate: date && moment(date).format('DD MMM YYYY'),
    });
  };

  render() {
    const { isError, errorName } = this.state;
    const { dates } = this.props;
    const datepicker = dates => {
      return (
        <DatePicker
          className='contact-detail-picker'
          selected={this.state.startDate}
          onChange={e => this.handleChange(e)}
          value={this.state.departureDate}
          includeDates={dates}
          dateFormat='DD MMM YYYY'
          placeholderText='Start date'
        />
      );
    };
    return (
      <div className={styles.wrapper}>
        <div className={styles.form}>
          <div className={styles.form_input}>
            <img src={user} alt='icon' />
            <input
              className={styles.inputField}
              type='text'
              min={1}
              name='name'
              placeholder='Your Name'
              onChange={e => this.handleInputName(e)}
              // required
              // value={name}
            />
          </div>
          <div className={styles.form_input}>
            <img src={email} alt='icon' />
            <input
              className={styles.inputField}
              type='text'
              min={1}
              name='email'
              placeholder='Email'
              onChange={e => this.handleInputEmail(e)}
              required
              // value={email}
            />
          </div>

          {/* 
          <div className={styles.form_input}>
            <img className='phone-icon' src={phone} alt='icon' />
            <input
              className={styles.inputField}
              type='text'
              min={1}
              name='phone'
              placeholder='Phone Number'
              onChange={e => this.handleInputPhone(e)}
              required
          
            />
          </div>
          <div className='row-grid'>
            <div className='box-item'>
              <label>Number of Adults:</label>
              <div className='new-access'>
                <select
                  className='select-children-age'
                  onChange={e => this.handleAdults(e)}
                  name='adults'
                  required
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </div>
            </div>
            <div className='box-item'>
              <label>Number of Children:</label>
              <div className='new-access'>
                <select
                  className='select-children-age'
                  onChange={e => this.handleChildren(e)}
                  name='children'
                >
                  <option>0</option>
                  <option>1</option>
                  <option>2</option>
                  <option>3</option>
                  <option>4</option>
                  <option>5</option>
                  <option>6</option>
                  <option>7</option>
                  <option>8</option>
                  <option>9</option>
                  <option>10</option>
                  <option>11</option>
                  <option>12</option>
                  <option>13</option>
                  <option>14</option>
                  <option>15</option>
                </select>
              </div>
            </div>
          </div>

         */}
          <div className='input-style'>
            <Icon name='season' />
            {datepicker(dates)}
            <Icon name='arrow-down' className='arrow-right' />
          </div>
          {errorName === 'departureDate' && <p className={styles.errorText}>Date is Required </p>}
          {isError && <p className={styles.errorText}>All fields are required</p>}
          <div className={styles.form_textArea}>
            <textarea
              className={styles.inputField}
              name='tripMessage'
              placeholder='Please add your inquiry here'
              onChange={e => this.handleTripMessage(e)}
              required
            />
          </div>
          <div className={styles.request}>
            <input
              type='button'
              value='Request information'
              className={styles.contactBtn + ' adventureDetailButton'}
              onClick={() => this.sendContact()}
            />
          </div>
          <div className={styles.request}>
            <input
              type='button'
              value='BOOK NOW'
              className={styles.contactBtn}
              onClick={() => this.requireBookInformation()}
            />
          </div>
        </div>
      </div>
    );
  }
}

/* PropTypes definition */

ContactDetail.propTypes = {
  sendContactDetail: PropTypes.func.isRequired,
  dates: PropTypes.array.isRequired,
  closeContact: PropTypes.func.isRequired,
  adventureTitle: PropTypes.string,
};

export default ContactDetail;
