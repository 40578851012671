import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import PropTypes from 'prop-types';
import auth from 'utils/auth';
import Callback from 'components/Callback';
import MainLayout from 'layouts/Main';
/* Pages */
import HomePage from 'pages/HomePage';
import AccountDetailsPage from 'pages/AccountDetailsPage';
import NewGuidePage from 'pages/NewGuidePage';
import AdventuresPage from 'pages/AdventuresPage';
import AdventureDetailsPage from 'pages/AdventureDetailsPage';
import GuidePage from 'pages/GuidePage';
import GuideAgreementDoc from 'pages/GuideAgreementDoc';
// import Adventures from '../pages/Adventures';
import AdventuresLadakh from '../pages/Ladakh';
import Peru from '../pages/Peru';
import Rwanda from '../pages/Rwanda';
import Africansharktours from '../pages/Africansharktours';
import Africansafari from '../pages/Africansafari';
import News from '../pages/News';
import Team from '../pages/Team';
import MyAdventuresPage from '../pages/MyAdventuresPage';
import GuideProfile from '../pages/GuideProfile';
import AccountDetail from '../pages/Account/Detail';
import AccountFavorites from '../pages/Account/MyFavorites';
import AccountTrip from '../pages/Account/Trip/Trip';
import AccountMessages from '../pages/Account/MyMessages';
import AccountBlog from '../pages/Account/Blog';
import Blog from '../pages/Blog';
import PayPal from '../pages/PayPal';
import PayAdventure from '../pages/PayAdventure';
import PaySuccess from '../pages/PaySuccess';
import PayFailed from '../pages/PayFailed';
import CreateAdventure from '../pages/CreateAdventure';
import EditAdventure from '../pages/EditAdeventure';
import privacyPolicy from '../pages/privacyPolicy';
import BookAdventure from '../pages/BookAdventure/BookAdventure';

const handleAuthentication = props => {
  if (/access_token|id_token|error/.test(props.location.hash)) {
    auth.handleAuthentication(props.history);
  }
};

/* Component definition */
const Routes = ({ childProps }) => (
  <Router>
    <Switch>
      <Route
        path='/adventure/:id'
        component={props => (
          <MainLayout auth={auth} page={AdventureDetailsPage} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/'
        component={props => <MainLayout auth={auth} page={HomePage} {...props} {...childProps} />}
      />
      <Route path='/stories' component={() => { 
        window.location.href = 'https://greatguides.com/destinations/stories/'; 
        return null;
       }}
      />

      <Route
        exact
        path='/profile/account'
        component={props => (
          <MainLayout auth={auth} page={AccountDetailsPage} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/participantAgreement'
        component={props => (
          <MainLayout auth={auth} page={GuideAgreementDoc} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/adventures'
        component={props => (
          <MainLayout auth={auth} page={AdventuresPage} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/new-guide'
        component={props => (
          <MainLayout auth={auth} page={NewGuidePage} {...props} {...childProps} />
        )}
      />
      <Route
        exact
        path='/guide-profile/:id'
        component={props => (
          <MainLayout auth={auth} page={GuideProfile} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/account/my-adventures'
        component={props => (
          <MainLayout auth={auth} page={MyAdventuresPage} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/account/detail'
        component={props => (
          <MainLayout auth={auth} page={AccountDetail} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/account/favorites'
        component={props => (
          <MainLayout auth={auth} page={AccountFavorites} {...props} {...childProps} />
        )}
      />
      <Route
        exact
        path='/payment'
        component={props => <MainLayout auth={auth} page={PayPal} {...props} {...childProps} />}
      />
      <Route
        exact
        path='/pay-adventure/:id'
        component={props => (
          <MainLayout auth={auth} page={PayAdventure} {...props} {...childProps} />
        )}
      />
      <Route
        exact
        path='/payment/success'
        component={props => <MainLayout auth={auth} page={PaySuccess} {...props} {...childProps} />}
      />
      <Route
        exact
        path='/payment/failed'
        component={props => <MainLayout auth={auth} page={PayFailed} {...props} {...childProps} />}
      />

      <Route
        exact
        path='/account/trip'
        component={props => (
          <MainLayout auth={auth} page={AccountTrip} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/account/messages'
        component={props => (
          <MainLayout auth={auth} page={AccountMessages} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/account/blog'
        component={props => (
          <MainLayout auth={auth} page={AccountBlog} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/guides/:id'
        component={props => <MainLayout auth={auth} page={GuidePage} {...props} {...childProps} />}
      />

      <Route
        exact
        path='/callback'
        render={props => {
          handleAuthentication(props);
          return <Callback {...props} />;
        }}
      />
      <Route path='/adventures/ladakh' component={AdventuresLadakh} />
      <Route path='/adventures/peru' component={Peru} />
      <Route path='/adventures/rwanda' component={Rwanda} />
      <Route path='/adventures/africansafari' component={Africansafari} />
      <Route path='/adventures/africansharktours' component={Africansharktours} />
      <Route
        exact
        path='/news'
        component={props => <MainLayout auth={auth} page={News} {...props} {...childProps} />}
      />
      <Route
        exact
        path='/team'
        component={props => <MainLayout auth={auth} page={Team} {...props} {...childProps} />}
      />
      <Route
        exact
        path='/blog'
        component={props => <MainLayout auth={auth} page={Blog} {...props} {...childProps} />}
      />
      <Route
        exact
        path='/create-adventure'
        component={props => (
          <MainLayout auth={auth} page={CreateAdventure} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/edit-adventure/:id'
        component={props => (
          <MainLayout auth={auth} page={EditAdventure} {...props} {...childProps} />
        )}
      />

      <Route
        exact
        path='/book-adventure'
        component={props => (
          <MainLayout auth={auth} page={BookAdventure} {...props} {...childProps} />
        )}
      />

      <Route exact path='/privacyPolicy' component={privacyPolicy} />
    </Switch>
  </Router>
);

/* PropTypes definition */
Routes.propTypes = {
  childProps: PropTypes.object,
};

export default Routes;
