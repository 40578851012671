/**
 * @file Holds the <AdventurePage> page
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { Container, Row, Col } from 'react-bootstrap';
import ReactPixel from 'react-facebook-pixel';

import { actions as guideActions, selectors as guideSelectors } from '../redux/modules/GetGuide';

import Avatar from 'components/GuideDetails/Avatar';
import RequireSignUp from 'components/RequireSignUp';
import Description from 'components/GuideDetails/Description';
import GuidesFooter from '../components/GuideDetails/GuidesFooter';

import { actions as generalActions, selectors as generalSelectors } from '../redux/modules/general';

import {
  actions as sendMessageActions,
  selectors as sendMessageSelectors,
} from '../redux/modules/SendMessage';
import {
  actions as sendContactActions,
  selectors as sendContactSelectors,
} from '../redux/modules/SendContact';
import {
  GUIDES_MODAL,
  VALIDATOR_IS_EMAIL,
  VALIDATOR_IS_NUMBER,
  VALIDATOR_REQUIRED,
  VALIDATOR_STRING_NOT_EMPTY,
} from '../constants/common';
import { toast, ToastContainer } from 'react-toastify';

import 'react-toastify/dist/ReactToastify.css';
import styles from 'styles/pages/GuidePage.module.scss';
import 'styles/components/RichEditorGuide.overrides.scss';
import { isDataValid, validate } from '../utils/validator';
import AdventureDetailsPageModal from '../components/AdventureDetailPageModal/AdventureDetailPageModal';

const moment = require('moment');

/* Component definition */
class GuidePage extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      isSignUpModal: false,
      modalData: {
        inquiry: '',
        name: localStorage.getItem('name') || '',
        email: localStorage.getItem('email') || '',
        phone: '',
        numberOfTravelers: '',
        date: moment(new Date())
          .format('DD MMM YYYY')
          .toString(),
      },
      validationRules: {
        inquiry: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        name: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        email: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_IS_EMAIL, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
        numberOfTravelers: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY, VALIDATOR_IS_NUMBER],
          errorMessage: undefined,
        },
        date: {
          rules: [VALIDATOR_REQUIRED, VALIDATOR_STRING_NOT_EMPTY],
          errorMessage: undefined,
        },
      },
      isModalLoading: false,
    };
  }

  componentDidMount() {
    const { getGuide, match } = this.props;
    getGuide({ id: match.params.id });
  }

  componentDidUpdate(prevProps) {
    const { sendContactStatus: nextSContactStatus, guide: nextGuide } = this.props;
    const { sendContactStatus: previewSCStatus, guide } = prevProps;
    const { toggleModal } = this.props;
    const { isModalLoading } = this.state;
    if (nextSContactStatus) {
      if (nextSContactStatus !== previewSCStatus && nextSContactStatus === 'Success') {
        toast.success('Message successfully posted', {
          position: toast.POSITION.TOP_RIGHT,
        });
      } else if (nextSContactStatus === 'fail') {
        toast.error('There is some problem in sending your message.', {
          position: toast.POSITION.TOP_RIGHT,
        });
      }
      if (isModalLoading) {
        toggleModal();
        this.setState({
          isModalLoading: false,
        });
      }
    }
    if (!guide && nextGuide) {
      const { modalData } = this.state;
      this.setState({
        modalData: {
          ...modalData,
          inquiry: `Hi ${
            nextGuide.fullName === undefined ? 'Guider' : nextGuide.fullName
          }. I would like to book an adventer..`,
        },
      });
    }
  }

  cancelSignUp = () => {
    this.setState({ isSignUpModal: false });
  };

  onSendMessage = () => {
    const loggedInUser = localStorage.getItem('isLoggedIn');
    if (loggedInUser) {
      const { toggleModal } = this.props;
      toggleModal(GUIDES_MODAL);
    } else {
      this.setState({ isSignUpModal: true });
    }
  };

  changeModalData = e => {
    const { modalData } = this.state;
    this.setState({
      modalData: {
        ...modalData,
        [e.target.name]: e.target.value,
      },
    });
  };

  validateData = (modalData, validationRules) => {
    let modalErrors = null;
    Object.entries(modalData).forEach(([key, field]) => {
      if (validationRules[key]) {
        const isValid = validate(validationRules[key].rules, field);
        modalErrors = {
          ...modalErrors,
          [key]: {
            ...validationRules[key],
            errorMessage: isValid,
          },
        };
      }
    });
    const isValid = isDataValid(modalErrors);
    this.setState({
      validationRules: { ...modalErrors },
    });
    return isValid;
  };

  sendMessage = () => {
    const { guide, sendMessage, sendContact } = this.props;
    const { modalData, validationRules } = this.state;
    const modalErrorsFields = this.validateData(modalData, validationRules);
    if (modalErrorsFields) {
      const { name, email, numberOfTravelers, date, inquiry, phone } = modalData;
      this.setState({
        isModalLoading: true,
      });
      const message = `Guide: ${guide.fullName}, Message: ${inquiry}, Phone: ${phone}`;
      sendContact({
        name: name,
        email: email,
        departureDate: date,
        numberOfTravelers: numberOfTravelers,
        tripMessage: message,
      });
      sendMessage({
        id: guide.id,
        tripMessage: modalData.tripMessage,
      });
    }
  };

  signUp = () => {
    this.props.auth.signup();
  };

  render() {
    const advancedMatching = { em: 'some@email.com' }; // optional, more info: https://developers.facebook.com/docs/facebook-pixel/pixel-with-ads/conversion-tracking#advanced_match
    const options = {
      autoConfig: true, // set pixel's autoConfig
      debug: false, // enable logs
    };
    ReactPixel.init('2151876878446248', advancedMatching, options);
    ReactPixel.pageView(); // For tracking page view
    const {
      guide,
      modalData: { isModalOpen, modalName },
      toggleModal,
    } = this.props;
    const { isSignUpModal, modalData, validationRules, isModalLoading } = this.state;
    return guide ? (
      <main className={styles.wrapper}>
        <div className={styles.row}>
          <Container>
            <Row noGutters={false}>
              <Col lg={8} md={12}>
                <Description guide={guide} />
              </Col>
              <Col lg={4} md={12}>
                <Row>
                  <Col lg={12} md={12} sm={12}>
                    <Avatar guide={guide} adventures={guide.adventures} />
                  </Col>
                </Row>
              </Col>
            </Row>
          </Container>
        </div>
        <div className={styles.messageBox}>
          {isModalOpen && modalName === GUIDES_MODAL && (
            <AdventureDetailsPageModal
              guide={guide}
              modalData={modalData}
              validationRules={validationRules}
              changeModalData={this.changeModalData}
              submitModal={this.sendMessage}
              isModalLoading={isModalLoading}
              closeModal={toggleModal}
              buttonText='Send message'
              modalName={GUIDES_MODAL}
            />
          )}
        </div>
        {isSignUpModal && (
          <RequireSignUp
            heading='Sign up is required'
            paragraph='Please sign up to send message to guide'
            cancelSignUp={this.cancelSignUp}
            signUp={this.signUp}
            type={1}
          />
        )}
        <GuidesFooter guide={guide} openModal={this.onSendMessage} />
        <ToastContainer autoClose={3000} />
      </main>
    ) : (
      <div className={styles.loaderSpinner}>
        <div className={styles.loader} />
      </div>
    );
  }
}

/* Prop types definition */
GuidePage.propTypes = {
  mql: PropTypes.string,
  match: PropTypes.object,
  guide: PropTypes.shape({}),
  getGuide: PropTypes.func.isRequired,
  auth: PropTypes.object.isRequired,
  modalData: PropTypes.shape({
    isModalOpen: PropTypes.bool,
    modalName: PropTypes.string,
  }),
  toggleModal: PropTypes.func.isRequired,
  sendMessage: PropTypes.func.isRequired,
  sendContact: PropTypes.func.isRequired,
  sendMessageStatus: PropTypes.string,
  sendContactStatus: PropTypes.string,
};

const selectors = createStructuredSelector({
  guide: guideSelectors.guideSelector,
  modalData: generalSelectors.modalSelector,
  sendMessageStatus: sendMessageSelectors.sendMessageStatusSelector,
  sendContactStatus: sendContactSelectors.sendContactStatusSelector,
});

const actions = {
  getGuide: guideActions.getGuide,
  toggleModal: generalActions.toggleModal,
  sendMessage: sendMessageActions.sendMessage,
  sendContact: sendContactActions.sendContact,
};

export default connect(selectors, actions)(GuidePage);
