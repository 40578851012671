import { all } from 'redux-saga/effects';

import locations from './locations';
import categories from './categories';
import users from './users';
import adventures from './adventures';
import guides from './guides';
import GuidesRegions from './GuidesRegions';
import SendContact from './SendContact';
import SendMessage from './SendMessage';
import BookAdventure from './BookAdventure';
import AdventuresById from './AdventuresById';
import GuideFilter from './GuideFilter';
import GuidesById from './GuidesById';
import UpdateUser from './UpdateUser';
import trips from './trips';
import PayPal from './PayPal';
import CreateAdventure from './CreateAdventure';
import GetGuide from './GetGuide';

export default function* rootSaga() {
  yield all([
    users(),
    adventures(),
    locations(),
    users(),
    categories(),
    GuideFilter(),
    guides(),
    GuidesRegions(),
    SendContact(),
    SendMessage(),
    BookAdventure(),
    AdventuresById(),
    GuidesById(),
    UpdateUser(),
    trips(),
    PayPal(),
    CreateAdventure(),
    GetGuide(),
  ]);
}
