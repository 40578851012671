/**
 * @file Holds the <CardList> container
 * @since 0.1.0
 * @author Anton Komarenko <mi3ta@sent.as>
 */
import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import styles from 'styles/components/CategorySelector.module.scss';

/* Component definition */
class CategorySelector extends React.Component {
  state = {
    category: 'All',
  };

  onChangeCategory = selectedOption => {
    const { updateFilter } = this.props;

    this.setState({ category: selectedOption });

    updateFilter(selectedOption.label);
  };

  render() {
    const { category } = this.state;

    const categories = Object.values(this.props.categories).map(category => {
      return {
        value: category.name,
        label: category.name,
      };
    });

    const categoryStyles = {
      control: () => ({
        // none of react-select's styles are passed to <Control />
        border: 'none',
        'align-items': 'center',
        display: 'flex',
        'flex-wrap': 'wrap',
        'justify-content': 'space-between',
        'div:first-child': {
          color: '#FF8C00',
        },
        width: '70px',
        'white-space': 'nowrap',
        ':focus-within > :last-child': {
          transform: 'rotate(180deg)',
        },
      }),
      indicatorSeparator: () => ({
        display: 'none',
      }),
      input: () => ({
        visibility: 'visible',
        color: '#FF8C00',
        'box-sizing': 'border-box',
      }),
      dropdownIndicator: () => ({
        display: 'block',
        color: '#FF8C00',
      }),
      menu: styles => {
        return {
          ...styles,
          width: '250px',
          left: '-75px',
          top: 'calc((100% + 0px))',
          color: '#666666',
          zIndex: '100 !important',
          opacity: '1 !important',
          'overflow-y': 'hidden',
          'overflow-x': 'hidden',
          div: {
            width: '270px',
            'overflow-x': 'hidden',
            'overflow-y': 'scroll',
            div: {
              paddingRight: '2px',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap',
            },
          },
        };
      },
    };

    return (
      <div className={styles.wrapper}>
        <div style={{ marginTop: '2px' }}>Category:</div>
        <Select
          value={category}
          options={categories}
          styles={categoryStyles}
          placeholder='All'
          isSearchable={true}
          onChange={this.onChangeCategory}
          isClearable={false}
        />
      </div>
    );
  }
}

/* Prop types definition */
CategorySelector.propTypes = {
  categories: PropTypes.array.isRequired,
  updateFilter: PropTypes.func.isRequired,
};

export default CategorySelector;
