import React from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';

import defaultFlag from 'assets/locations/defaultFlag.svg';

export default function SearchFilterSelect({
  className,
  changeValue,
  data,
  selectedData,
  placeholder,
  isClearable,
}) {
  const addDefaultSrc = e => {
    e.target.src = defaultFlag;
  };

  /* eslint-disable */
  const optionLayout = props => {
    const {
      innerProps,
      innerRef,
      isSelected,
      selectProps: { classNamePrefix },
      data,
    } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={`${classNamePrefix}__option ${
          isSelected ? `${classNamePrefix}__option__selected` : ""
        }`}
      >
        <div className={`${classNamePrefix}__option__item`}>
          {data.hasOwnProperty("flagIcon") ? (
            <img src={data.flagIcon} alt={data.label} onError={addDefaultSrc} />
          ) : (
            <span />
          )}
        </div>
        <div className={"sub"}>{data.label} </div>
      </div>
    );
  };
  /* eslint-enable */

  /* eslint-disable */
  const menuLayout = props => {
    const {
      innerProps,
      innerRef,
      children,
      selectProps: { classNamePrefix },
    } = props;
    return (
      <div ref={innerRef} {...innerProps} className={`${classNamePrefix}__menu`}>
        {children}
      </div>
    );
  };
  /* eslint-enable */

  /* eslint-disable */
  const controlLayout = props => {
    const {
      innerProps,
      innerRef,
      children,
      selectProps: { classNamePrefix },
      hasValue,
      isFocused,
      menuIsOpen,
    } = props;
    return (
      <div
        ref={innerRef}
        {...innerProps}
        className={
          `${classNamePrefix}__control` +
          ` ${hasValue ? `${classNamePrefix}__control__fill` : ""}` +
          ` ${isFocused ? `${classNamePrefix}__control__focused` : ""}` +
          ` ${menuIsOpen ? `${classNamePrefix}__control__isOpen` : ""}`
        }
      >
        {children}
      </div>
    );
  };
  /* eslint-enable */

  return (
    <Select
      className={`search-filter__select ${className}`}
      classNamePrefix='search-filter__select'
      onChange={e => changeValue(e, 'location')}
      placeholder={placeholder}
      value={data.filter(({ value }) => value === selectedData)[0]}
      options={data}
      isClearable={isClearable}
      components={{ Option: optionLayout, Menu: menuLayout, Control: controlLayout }}
    />
  );
}

SearchFilterSelect.propTypes = {
  changeValue: PropTypes.func.isRequired,
  data: PropTypes.array.isRequired,
  selectedData: PropTypes.string,
  className: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  isClearable: PropTypes.bool,
};

SearchFilterSelect.defaultProps = {
  selectedData: null,
  className: '',
  isClearable: true,
};
