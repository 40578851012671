import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'react-router-dom';

const ResponceModal = props => {
  return (
    <div className='signup_popup'>
      <div className='center_div'>
        <h2>{props.heading}</h2>
        <p>{props.para}</p>
        <div className='text-center'>
          <Link to='/' className='guide_btn'>
            OK
          </Link>
        </div>
      </div>
    </div>
  );
};

ResponceModal.propTypes = {
  heading: PropTypes.string,
  para: PropTypes.string,
};
export default ResponceModal;
