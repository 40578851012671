import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';

import {
  actions as createAdventureActions,
  selectors as createAdventureSelectors,
} from '../../redux/modules/CreateAdventure';
import ErrorHandling from '../ErrorHandling';
import RichEditor from 'components/RichEditor';
import Style from '../../styles/pages/CreateAdventure.module.scss';
import './index.scss';

class Included extends Component {
  constructor(props) {
    super(props);
    this.state = {
      advisory: props.advisory,
      inclusions: props.inclusions,
      exclusions: props.exclusions,
      directions: props.directions,
      highlights: props.highlights,
      errorType: '',
    };
  }
  nextStep = () => {
    const {
      nextStep,
      setAdventureFilter,
      createAdventure,
      updateAdventure,
      editStatus,
    } = this.props;
    const { advisory, inclusions, exclusions, directions, highlights } = this.state;

    setAdventureFilter({
      ['advisory']: advisory,
      ['inclusions']: inclusions,
      ['exclusions']: exclusions,
      ['directions']: directions,
      ['highlights']: highlights,
    });
    if (this.checkValue() === '') {
      switch (editStatus) {
      case '':
        createAdventure();
        break;
      case 'create':
        createAdventure();
        break;
      case 'update':
        updateAdventure();
        break;
      default:
        break;
      }
      nextStep();
    } else return;
  };

  previewStep = () => {
    const { previewStep } = this.props;
    previewStep();
  };

  checkValue = () => {
    const { advisory, inclusions, exclusions, directions, highlights } = this.state;
    let errorType = '';

    if (!advisory && advisory === '') {
      errorType = errorType.concat('advisory');
    }
    if (!inclusions && inclusions === '') {
      errorType = errorType.concat('inclusions');
    }
    if (!exclusions && exclusions === '') {
      errorType = errorType.concat('exclusions');
    }
    if (!directions && directions === '') {
      errorType = errorType.concat('directions');
    }
    if (!highlights && highlights === '') {
      errorType = errorType.concat('highlights');
    }

    this.setState({ errorType });
    return errorType;
  };

  handleChangeInput = (value, type) => {
    switch (type) {
    case 'advisoory':
      this.setState({ advisory: value });
      break;
    case 'included':
      this.setState({ inclusions: value });
      break;
    case 'excluded':
      this.setState({ exclusions: value });
      break;
    case 'location':
      this.setState({ directions: value });
      break;
    case 'highlight':
      this.setState({ highlights: value });
      break;
    default:
      break;
    }
  };

  render() {
    const { advisory, inclusions, exclusions, directions, highlights, errorType } = this.state;
    return (
      <div className='add-dates'>
        <div className={Style.header_title}>
          <h2>What’s Included?</h2>
          <p>Almost done! Just let us know what included/excluded and what to carry</p>
        </div>
        <div className='form-group requirementsAdd'>
          <label>Advisory (things to carry)</label>
          <RichEditor
            value={advisory}
            handleFroalaModelChange={model => this.handleChangeInput(model, 'advisoory')}
            isEditable={true}
          />
        </div>
        {errorType.includes('advisory') ? <ErrorHandling category='Advisory' /> : ''}
        <div className='form-group requirementsAdd'>
          <label>What Is Included</label>
          <RichEditor
            value={inclusions}
            handleFroalaModelChange={model => this.handleChangeInput(model, 'included')}
            isEditable={true}
          />
        </div>
        {errorType.includes('inclusions') ? <ErrorHandling category='Inclusions' /> : ''}
        <div className='form-group requirementsAdd'>
          <label>What Is Excluded</label>
          <RichEditor
            value={exclusions}
            handleFroalaModelChange={model => this.handleChangeInput(model, 'excluded')}
            isEditable={true}
          />
        </div>
        {errorType.includes('exclusions') ? <ErrorHandling category='Exclusions' /> : ''}
        <div className='form-group descriptionAdd'>
          <label>How to reach location</label>
          <RichEditor
            value={directions}
            handleFroalaModelChange={model => this.handleChangeInput(model, 'location')}
            isEditable={true}
          />
        </div>
        {errorType.includes('directions') ? <ErrorHandling category='Directions' /> : ''}
        <div className='form-group descriptionAdd'>
          <label>Highlight</label>
          <RichEditor
            value={highlights}
            handleFroalaModelChange={model => this.handleChangeInput(model, 'highlight')}
            isEditable={true}
          />
        </div>
        {errorType.includes('highlights') ? <ErrorHandling category='Highlights' /> : ''}
        <div className={Style.paginationButtons}>
          <button type='button' onClick={() => this.nextStep()}>
            Continue
          </button>
          <button type='button' onClick={() => this.previewStep()}>
            Back
          </button>
        </div>
      </div>
    );
  }
}

Included.propTypes = {
  nextStep: PropTypes.func.isRequired,
  previewStep: PropTypes.func.isRequired,
  setAdventureFilter: PropTypes.func.isRequired,
  createAdventure: PropTypes.func.isRequired,
  updateAdventure: PropTypes.func.isRequired,
  advisory: PropTypes.string,
  inclusions: PropTypes.string,
  exclusions: PropTypes.string,
  directions: PropTypes.string,
  highlights: PropTypes.string,
  editStatus: PropTypes.string,
};

const selectors = createStructuredSelector({
  advisory: createAdventureSelectors.advisorySelector,
  inclusions: createAdventureSelectors.inclusionsSelector,
  exclusions: createAdventureSelectors.exclusionsSelector,
  directions: createAdventureSelectors.directionsSelector,
  highlights: createAdventureSelectors.highlightsSelector,
});

const actions = {
  createAdventure: createAdventureActions.createAdventure,
  updateAdventure: createAdventureActions.updateAdventure,
  setAdventureFilter: createAdventureActions.setAdventureFilter,
};

export default connect(
  selectors,
  actions
)(Included);
