const instruction_content =
  'GreatGuides is a powerful platform for you to reach customers from around the world. We take care of the marketing for you so you can focus on creating and delivering amazing trips.';
const setup_guide =
  'The first thing you will need to do is set up a profile. This will provide all the basic information we need to contact you and to identify what part of the world you would like to guide in. Additionally, you will need to upload your guiding credentials. This includes certifications, degrees, and any licenses required to guide in your area.';
const learn_guide =
  'In order to feel confident with our label “Great” you will need to watch our “Becoming a Great Guide” e-learning module.';
const assessment =
  'After watching the e-learning module, you will need to take an assessment to demonstrate mastery in the concepts presented in the e-learning.';
const upload_trip =
  'Finally, after you’ve completed all the steps above, you will be required to upload at least one trip. If you have specific availability for this trip you can include that. Otherwise customers will contact you to book the trip with you.';
export { instruction_content, setup_guide, learn_guide, assessment, upload_trip };
