import fp from 'lodash/fp';

const getFilter = state => {
  const filters = {
    fullName: state.fullName,
    handle: state.handle,
    profilePictureUrl: state.profilePictureUrl,
    profilePictureBase64: state.profilePictureBase64,
    isUserActive: state.isUserActive,
    isEmailVerified: state.isEmailVerified,
    userRole: state.userRole,
    emailAddress: state.emailAddress,
    profile: state.profile,
    gender: state.gender,
    externalProfileLink: state.externalProfileLink,
    phoneNumber: state.phoneNumber,
    businessName: state.businessAddress,
    businessAddress: state.businessAddress,
    website: state.website,
    youtubeChannel: state.youtubeChannel,
    otherQualifications: state.otherQualifications,
    technicalSkills: state.technicalSkills,
    firstAidTraining: state.firstAidTraining,
    customerServiceSkills: state.customerServiceSkills,
    expertise: state.expertise,
    sustainableStandards: state.sustainableStandards,
    certificate: state.certificate,
    aboutMe: state.aboutMe,
    participantAgree: state.participantAgree,
    guideAgree: state.guideAgree,
    flagIcon: state.flagIcon,
    tripAdvisor: state.tripAdvisor,
    region: state.region,
    firstName: state.firstName,
    lastName: state.lastName,
    city: state.city,
    state: state.state,
    country: state.country,
    zip: state.zip,
    extra: state.extra,
    memberships: state.memberships,
    categories: state.categories,
    adventures: state.adventures,
    // GuideExp: state.GuideExp,
    // LastTripTitle: state.LastTripTitle,
    // LastTripDate: state.LastTripDate,
    // LastTripCompany: state.LastTripCompany,
    // GuideLic: state.GuideLic,
    // GuideLicLink: state.GuideLicLink,
    // GuidePermit: state.GuidePermit,
    // GuideNoTrace: state.GuideNoTrace,
    // GuideFirstAid: state.GuideFirstAid,
    // GuideInsurance: state.GuideInsurance,
    // GuideTripLink: state.GuideTripLink,
  };
  return filters;
};

export const fullNameSelector = fp.get('GuideFilter.fullName');
export const handleSelector = fp.get('GuideFilter.handle');
export const profilePictureUrlSelector = fp.get('GuideFilter.profilePictureUrl');
export const profilePictureBase64Selector = fp.get('GuideFilter.profilePictureBase64');
export const isUserActiveSelector = fp.get('GuideFilter.isUserActive');
export const isEmailVerifiedSelector = fp.get('GuideFilter.isEmailVerified');
export const userRoleSelector = fp.get('GuideFilter.userRole');
export const emailAddressSelector = fp.get('GuideFilter.emailAddress');
export const profileSelector = fp.get('GuideFilter.profile');
export const genderSelector = fp.get('GuideFilter.gender');
export const externalProfileLinkSelector = fp.get('GuideFilter.externalProfileLink');
export const phoneNumberSelector = fp.get('GuideFilter.phoneNumber');
export const businessNameSelector = fp.get('GuideFilter.businessName');
export const businessAddressSelector = fp.get('GuideFilter.businessAddress');
export const websiteSelector = fp.get('GuideFilter.website');
export const youtubeChannelSelector = fp.get('GuideFilter.youtubeChannel');
export const otherQualificationsSelector = fp.get('GuideFilter.otherQualifications');
export const technicalSkillsSelector = fp.get('GuideFilter.technicalSkills');
export const firstAidTrainingSelector = fp.get('GuideFilter.firstAidTraining');
export const customerServiceSkillsSelector = fp.get('GuideFilter.customerServiceSkills');
export const expertiseSelector = fp.get('GuideFilter.expertise');
export const sustainableStandardsSelector = fp.get('GuideFilter.sustainableStandards');
export const certificateSelector = fp.get('GuideFilter.certificate');
export const aboutMeSelector = fp.get('GuideFilter.aboutMe');
export const participantAgreeSelector = fp.get('GuideFilter.participantAgree');
export const guideAgreeSelector = fp.get('GuideFilter.guideAgree');
export const flagIconSelector = fp.get('GuideFilter.flagIcon');
export const tripAdvisorSelector = fp.get('GuideFilter.tripAdvisor');
export const regionSelector = fp.get('GuideFilter.region');
export const firstNameSelector = fp.get('GuideFilter.firstName');
export const lastNameSelector = fp.get('GuideFilter.lastName');
export const citySelector = fp.get('GuideFilter.city');
export const stateSelector = fp.get('GuideFilter.state');
export const countrySelector = fp.get('GuideFilter.country');
export const zipSelector = fp.get('GuideFilter.zip');
export const extraSelector = fp.get('GuideFilter.extra');
export const membershipsSelector = fp.get('GuideFilter.memberships');
export const categoriesSelector = fp.get('GuideFilter.categories');
export const adventuresSelector = fp.get('GuideFilter.adventures');
export const GuideExpSelector = fp.get('GuideFilter.GuideExp');
export const LastTripTitleSelector = fp.get('GuideFilter.LastTripTitle');
export const LastTripDateSelector = fp.get('GuideFilter.LastTripDate');
export const LastTripCompanySelector = fp.get('GuideFilter.LastTripCompany');
export const GuideLicSelector = fp.get('GuideFilter.GuideLic');
export const GuideLicLinkSelector = fp.get('GuideFilter.GuideLicLink');
export const GuidePermitSelector = fp.get('GuideFilter.GuidePermit');
export const GuideNoTraceSelector = fp.get('GuideFilter.GuideNoTrace');
export const GuideFirstAidSelector = fp.get('GuideFilter.GuideFirstAid');
export const GuideInsuranceSelector = fp.get('GuideFilter.GuideInsurance');
export const GuideTripLinkSelector = fp.get('GuideFilter.GuideTripLink');
export const GuideFilterStatusSelector = fp.get('GuideFilter.status');

export const filterSelector = fp.compose(
  getFilter,
  fp.get('GuideFilter')
);
