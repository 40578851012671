import React, { useState } from 'react';
import PropTypes from 'prop-types';
import matchMedia from 'utils/matchMedia';

import Slider from 'react-slick';

import styles from 'styles/components/PhotoSlider.module.scss';
import 'styles/components/PhotoSlider_overrides.scss';

import 'styles/components/adventuresSlider.scss';

function PhotoSlider({ photos }) {
  const [mql] = useState(matchMedia());

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    lazyLoad: 'progressive',
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          dots: false,
        },
      },
    ],
  };
  return (
    <div className={styles.wrapper}>
      <div className={styles.row}>
        <Slider {...settings}>
          {photos.map(({ imageHQUrl, imageVHQUrl, fileName, copyright }) => (
            <div key={imageHQUrl} className='slick-slide-item'>
              <span className='slick-slide-item-title'>{fileName.replace(/.jpg/, '')}</span>
              <img src={mql === 'mobile' ? imageHQUrl : imageVHQUrl} alt={fileName || 'photo'} />
              {copyright && copyright !== '' && (
                <span className='slick-slide-item-copy'>{copyright}</span>
              )}
            </div>
          ))}
        </Slider>
      </div>
    </div>
  );
}

PhotoSlider.propTypes = {
  photos: PropTypes.array.isRequired,
};

export default PhotoSlider;
