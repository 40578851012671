import fp from 'lodash/fp';

export const tripsAllSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('trips.data.data.trips')
);

export const myTripsSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('trips.myTrips')
);

export const customerTripsSelector = fp.compose(
  fp.defaultTo([]),
  fp.get('trips.customerTrips')
);
